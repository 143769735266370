import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../slices/cart/reducer"; // Redux'tan removeItem'ı içe aktar
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

const MyCartDropdown = () => {
  const formatCurrency = (value) => {
    const numberValue = parseInt(value, 10);
    return numberValue.toLocaleString("tr-TR");
  };

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items); // Redux store'dan sepetteki ürünleri al
  if (!Array.isArray(cartItems)) {
    console.error("cartItems, beklenen dizi formatında değil!");
  }
  const [isCartDropdown, setIsCartDropdown] = useState(false);

  const toggleCartDropdown = () => {
    setIsCartDropdown(!isCartDropdown);
  };

  const handleRemoveItem = (id) => {
    dispatch(removeItem(id)); // Redux'ta removeItem action'ını dispatch et
  };

  return (
    <Dropdown
      isOpen={isCartDropdown}
      toggle={toggleCartDropdown}
      className="topbar-head-dropdown ms-1 header-item"
    >
      <DropdownToggle
        type="button"
        tag="button"
        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
      >
        <i className="bx bx-shopping-bag fs-22"></i>
        <span className="position-absolute topbar-badge translate-middle badge rounded-pill bg-info">
          {cartItems.length}
          <span className="visually-hidden">Okunmamış mesajlar</span>
        </span>
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu-xl dropdown-menu-end p-0"
        aria-labelledby="page-header-cart-dropdown"
      >
        <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
          <Row className="align-items-center">
            <Col>
              <h6 className="m-0 fs-16 fw-semibold">Sepetim</h6>
            </Col>
            <div className="col-auto">
              <span className="badge bg-warning-subtle text-warning fs-13">
                <span className="cartitem-badge">{cartItems.length}</span>
                <span className="px-1">Ürün</span>
              </span>
            </div>
          </Row>
        </div>
        <SimpleBar style={{ maxHeight: "300px" }}>
          <div className="p-2">
            {cartItems.length === 0 ? (
              <div
                className="text-center empty-cart"
                style={{ display: "block" }}
              >
                <div className="avatar-md mx-auto my-3">
                  <div className="avatar-title bg-info-subtle text-info fs-36 rounded-circle">
                    <i className="bx bx-cart"></i>
                  </div>
                </div>
                <h5 className="mb-3">Sepetiniz Boş</h5>
                <Link to="/urunler" className="btn btn-success w-md mb-3">
                  Şimdi Al
                </Link>
              </div>
            ) : (
              cartItems.map((item, index) => (
                <div
                  className="d-block dropdown-item text-wrap dropdown-item-cart px-3 py-2"
                  key={index}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={item.img}
                      className="me-3 rounded-circle avatar-sm p-2 bg-light"
                      alt="user-pic"
                    />
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1 fs-14">
                        <Link
                          to="/apps-ecommerce-product-details"
                          className="text-reset"
                        >
                          {item.product}
                        </Link>
                      </h6>
                      <p className="mb-0 fs-12 text-muted">
                        Adet:{" "}
                        <span>
                          {item.quantity} x {formatCurrency(item.price)} ₺
                        </span>
                      </p>
                    </div>
                    <div className="px-2">
                      <h5 className="m-0 fw-normal">
                        <span className="cart-item-price">
                          {formatCurrency(item.quantity * item.price)}
                        </span>
                        <span className="px-1">₺</span>
                      </h5>
                    </div>
                    <div className="ps-2">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleRemoveItem(item.id)}
                      >
                        <i className="ri-close-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </SimpleBar>
        <div
          className="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border"
          id="checkout-elem"
        >
          <div className="d-flex justify-content-between align-items-center pb-3">
            <h5 className="m-0 text-muted">Toplam:</h5>
            <div className="px-2">
              <h5 className="m-0">
                <span id="cart-item-total">
                  {formatCurrency(
                    cartItems.reduce(
                      (acc, item) => acc + item.quantity * item.price,
                      0
                    )
                  )}
                </span>
                <span className="px-1">₺</span>
              </h5>
            </div>
          </div>
          <Link
            to={cartItems.length === 0 ? "#" : "/apps-ecommerce-checkout"}
            className={
              cartItems.length === 0
                ? "btn btn-secondary text-center w-100"
                : "btn btn-success text-center w-100"
            }
          >
            Alışverişi Tamamla
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MyCartDropdown;
