// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-box {
  padding-bottom: 30px;
  padding-top: 30px;
  background: black;
}
.category-item {
  position: relative;
  width: 25%;
  aspect-ratio: 1 / 1;
  height: auto;
  cursor: pointer;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.product-image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hover-image {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateY(20px); /* Başlangıçta biraz aşağıda */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Geçiş efekti */
  z-index: 5;
  object-fit: contain;
}

.image-wrapper:hover .hover-image {
  opacity: 1;
  transform: translateY(0); /* Hover durumunda yukarı kayarak görünecek */
}

.image-wrapper:hover .label-image {
  background: #0000005e;
}

.label-image {
  transition: 0.3s;
  background: #00000021;
  position: absolute;
  object-fit: contain;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Landing/OnePage/components/CategoryBox/CategoryBox.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;EACV,2BAA2B,EAAE,8BAA8B;EAC3D,kDAAkD,EAAE,iBAAiB;EACrE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,wBAAwB,EAAE,6CAA6C;AACzE;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,SAAS;EACT,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".category-box {\n  padding-bottom: 30px;\n  padding-top: 30px;\n  background: black;\n}\n.category-item {\n  position: relative;\n  width: 25%;\n  aspect-ratio: 1 / 1;\n  height: auto;\n  cursor: pointer;\n}\n\n.image-wrapper {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.product-image {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n}\n\n.hover-image {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  opacity: 0;\n  transform: translateY(20px); /* Başlangıçta biraz aşağıda */\n  transition: opacity 0.3s ease, transform 0.3s ease; /* Geçiş efekti */\n  z-index: 5;\n  object-fit: contain;\n}\n\n.image-wrapper:hover .hover-image {\n  opacity: 1;\n  transform: translateY(0); /* Hover durumunda yukarı kayarak görünecek */\n}\n\n.image-wrapper:hover .label-image {\n  background: #0000005e;\n}\n\n.label-image {\n  transition: 0.3s;\n  background: #00000021;\n  position: absolute;\n  object-fit: contain;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 100%;\n  height: 100%;\n  z-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
