import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const LayoutExternal = ({ children }) => {
  return (
    <div className="layout-external">
      <Header />
      <main className="content bg-gray">{children}</main>
      <Footer />
    </div>
  );
};

export default LayoutExternal;
