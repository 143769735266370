import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const BreadCrumb = ({ section, category, condition, brand, productName }) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-start">
            {/* <h4 className="mb-sm-0 text-dark">Mağaza</h4> */}

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#section">{section}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="#category">{category}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="#condition">{condition}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="#brand">{brand}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#productname">{productName}</Link>
                </li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
