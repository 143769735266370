import React from "react";
import "./BannerDown/BannerDown.css";

const BannerDown = () => {
  return (
    <div className="banner-down-container">
      <div className="banner-content">
        <div className="banner-texts">
          <h2>
            Sürekli Yenilenen <span className="highlight">Outlet Köşesine</span>{" "}
            Göz Attın Mı?
          </h2>
          <p>Ürün incelemelerini izle, avantajları kaçırma!</p>
        </div>
        <div className="banner-button">
          <a href="/outlet" className="btn-shop">
            Alışverişe Başla
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerDown;
