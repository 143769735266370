import React, { useState, useEffect } from "react";
import { FaTruck } from "react-icons/fa";

function ShippingInfo() {
  const [timeLeft, setTimeLeft] = useState("");
  const [shippingDay, setShippingDay] = useState("");

  useEffect(() => {
    calculateShippingInfo();
  }, []);

  const calculateShippingInfo = () => {
    const currentTime = new Date();
    const cutoffTime = new Date();
    cutoffTime.setHours(16, 0, 0, 0); // Kargo çıkışı için kesme saati (16:00)

    const currentDay = currentTime.getDay();
    const daysOfWeek = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];

    let shippingMessage = "";
    let nextCutoffTime = new Date(cutoffTime);

    if (currentDay >= 1 && currentDay <= 5) {
      // Pazartesi'den Cuma'ya
      if (currentTime <= cutoffTime) {
        shippingMessage = "Bugün";
      } else {
        shippingMessage = "Yarın";
        nextCutoffTime.setDate(nextCutoffTime.getDate() + 1);
      }
    } else if (currentDay === 6) {
      // Cumartesi
      shippingMessage = "Pazartesi";
      nextCutoffTime.setDate(nextCutoffTime.getDate() + (2 - currentDay));
    } else if (currentDay === 0) {
      // Pazar
      shippingMessage = "Yarın";
      nextCutoffTime.setDate(nextCutoffTime.getDate() + 1);
    }

    // Zaman farkını hesapla
    const timeDiff = nextCutoffTime - currentTime;
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);

    setTimeLeft(`${hours} saat ${minutes} dakika`);
    setShippingDay(shippingMessage);
  };

  return (
    <div
      className="shipping-info d-flex align-items-center p-3 rounded mt-3"
      style={{
        backgroundColor: "#e8f7e8",
        border: "1px solid #4caf50",
        color: "#2e7d32",
      }}
    >
      {/* Kargo İkonu */}
      <FaTruck style={{ fontSize: "1.5rem", marginRight: "10px" }} />

      {/* Mesaj */}
      <p style={{ margin: 0, fontSize: "1rem" }}>
        <strong style={{ fontWeight: "bold" }}>{timeLeft}</strong> içinde
        sipariş verirsen <strong>{shippingDay}</strong> kargoda!
      </p>
    </div>
  );
}

export default ShippingInfo;
