import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import ProductCard from "./ProductCard";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import axios from "axios";

const Products = () => {
  document.title = "Outlet";

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const observerRef = useRef();

  const fetchProducts = async (page = 1) => {
    if (page === 1) {
      setLoading(true);
    }
    setError("");
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;

      const response = await axios.get(
        `https://marketplace.deltaservis.com.tr/api/outletProducts?limit=9&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ` + token,
          },
        }
      );

      if (response.products && Array.isArray(response.products)) {
        setProducts((prevProducts) => [...prevProducts, ...response.products]);
        // Yeni veri gelmezse daha fazla yüklemeyi durdur
        if (response.products.length < 9) {
          setHasMore(false);
        }
      }
    } catch (error) {
      setError("Veri yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
      console.error("Veri çekme hatası:", error);
    } finally {
      if (page === 1) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (loading || !hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [loading, hasMore]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Outlet" pageTitle="Outlet Ürünler" />
          {loading && currentPage === 1 && (
            <Row
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "60vh" }}
            >
              <lord-icon
                src="https://cdn.lordicon.com/msoeawqm.json"
                trigger="loop"
                colors="primary:#405189,secondary:#0ab39c"
                style={{ width: "72px", height: "72px" }}
              ></lord-icon>
            </Row>
          )}

          {error && (
            <Row
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "60vh" }}
            >
              <div className="alert alert-danger">{error}</div>
            </Row>
          )}

          {!loading && products.length > 0 && (
            <Row className="row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1">
              {products.map((product, index) => (
                <ProductCard product={product} key={index} />
              ))}
            </Row>
          )}

          <div ref={observerRef}></div>

          {loading && currentPage > 1 && (
            <Row
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "10vh" }}
            >
              <lord-icon
                src="https://cdn.lordicon.com/msoeawqm.json"
                trigger="loop"
                colors="primary:#405189,secondary:#0ab39c"
                style={{ width: "48px", height: "48px" }}
              ></lord-icon>
            </Row>
          )}

          {!hasMore && (
            <Row
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "10vh" }}
            >
              <p className="text-muted">Daha fazla ürün yok.</p>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Products;
