import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "./Carousel/Carousel.css";
import ProductCard from "./SizinSectiklerimiz/ProductCard";

const ProductCarousel = ({ title, data }) => {
  useEffect(() => {
    // Ensure Swiper navigation buttons are properly initialized after render
    const prevButton = document.querySelector(
      `.${title.toLowerCase().replace(/\s+/g, "-")}-prev-button`
    );
    const nextButton = document.querySelector(
      `.${title.toLowerCase().replace(/\s+/g, "-")}-next-button`
    );
    if (prevButton && nextButton) {
      prevButton.addEventListener("click", () => {});
      nextButton.addEventListener("click", () => {});
    }
  }, [title]);

  return (
    <>
      <div className="sm-hide mb-5 flex items-center justify-end"></div>
      <div className="slider-container relative">
        <Swiper
          modules={[Navigation]}
          navigation={{
            prevEl: `.${title.toLowerCase().replace(/\s+/g, "-")}-prev-button`,
            nextEl: `.${title.toLowerCase().replace(/\s+/g, "-")}-next-button`,
          }}
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {data.map((products, index) => (
            <SwiperSlide key={products.variant_id || index}>
              <ProductCard product={products} />
            </SwiperSlide>
          ))}
          <div className="carousel-buttons">
            <button
              className={`${title
                .toLowerCase()
                .replace(
                  /\s+/g,
                  "-"
                )}-prev-button action-icon rounded-full outline`}
            >
              <i className="h-auto w-5 rtl:rotate-180 ri-arrow-left-s-line" />
            </button>
            <button
              className={`${title
                .toLowerCase()
                .replace(
                  /\s+/g,
                  "-"
                )}-next-button action-icon rounded-full outline`}
            >
              <i className="h-auto w-5 rtl:rotate-180 ri-arrow-right-s-line" />
            </button>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default ProductCarousel;
