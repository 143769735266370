// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-opt {
  padding: 5px;

  display: flex;
  align-items: center;

  margin-right: 15px;
}
.indirim {
  clip-path: polygon(0% 0, 100% 25%, 100% 100%, 0% 100%);
  padding: 55px 10px 15px 5px;
}

/* .color-box {
  height: 50px;
  position: relative;
} */

.color-box {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(22% - 20px); /* Kutuların genişliği ve aradaki boşluklar */
  max-width: calc(22% - 20px);
  max-width: 100%;
  position: relative;

  height: 50px;

}

.font {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.75rem;
  color: #ff0000;
}
.h2-title {
  font-size: 2em;
}

.installment-info {
  font-size: 1rem;
  color: #555;
}

.installment-advantage {
  margin-bottom: 0px;
}

.installment-details {
  color: #333; /* Daha koyu gri */
  margin-top: 0; /* Ekstra boşluk bırakmaz */
}


.tab-button {
  flex: 1 1;
  padding: 10px 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: #888;
}

.tab-button.active {
  font-weight: bold;
  color: black;
  border-bottom: 2px solid black;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;;EAEZ,aAAa;EACb,mBAAmB;;EAEnB,kBAAkB;AACpB;AACA;EACE,sDAAsD;EACtD,2BAA2B;AAC7B;;AAEA;;;GAGG;;AAEH;EACE,YAA0B;EAA1B,cAA0B;EAA1B,4BAA0B,EAAE,6CAA6C;EACzE,2BAA2B;EAC3B,eAAe;EACf,kBAAkB;;EAElB,YAAY;;AAEd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW,EAAE,kBAAkB;EAC/B,aAAa,EAAE,2BAA2B;AAC5C;;;AAGA;EACE,SAAO;EACP,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,8BAA8B;AAChC","sourcesContent":[".color-opt {\n  padding: 5px;\n\n  display: flex;\n  align-items: center;\n\n  margin-right: 15px;\n}\n.indirim {\n  clip-path: polygon(0% 0, 100% 25%, 100% 100%, 0% 100%);\n  padding: 55px 10px 15px 5px;\n}\n\n/* .color-box {\n  height: 50px;\n  position: relative;\n} */\n\n.color-box {\n  flex: 1 1 calc(22% - 20px); /* Kutuların genişliği ve aradaki boşluklar */\n  max-width: calc(22% - 20px);\n  max-width: 100%;\n  position: relative;\n\n  height: 50px;\n\n}\n\n.font {\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  font-size: 0.75rem;\n  color: #ff0000;\n}\n.h2-title {\n  font-size: 2em;\n}\n\n.installment-info {\n  font-size: 1rem;\n  color: #555;\n}\n\n.installment-advantage {\n  margin-bottom: 0px;\n}\n\n.installment-details {\n  color: #333; /* Daha koyu gri */\n  margin-top: 0; /* Ekstra boşluk bırakmaz */\n}\n\n\n.tab-button {\n  flex: 1;\n  padding: 10px 15px;\n  border: none;\n  background: none;\n  cursor: pointer;\n  color: #888;\n}\n\n.tab-button.active {\n  font-weight: bold;\n  color: black;\n  border-bottom: 2px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
