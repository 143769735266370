import React, { useEffect, useState } from "react";
import "./SlidingTextVideo.css";
import WordRotate from "./WordRotate";

const SlidingTextVideoComponent = () => {
  const [words] = useState([
    "Sürdürülebilir",
    "Kazançlı",
    "Kaliteli",
    "Güvenilir",
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Her 3 saniyede bir değişim
    return () => clearInterval(interval);
  }, [words]);

  return (
    <div className="sliding-container">
      <div className="left-section">
        <div className="gradient-text">
          Delta Servis olarak iş ortaklarımıza
          <br />
          <WordRotate words={words} currentIndex={currentIndex} />
          çözümler sunuyoruz.
        </div>
      </div>
      <div className="right-section">
        <video width="100%" height="100%" controls autoPlay loop muted>
          <source src="/real123.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default SlidingTextVideoComponent;
