import React, { useEffect, useState } from "react";
import "./SingleCarousel/SingleCarousel.css";
import productData from "./SingleCarousel/SingleCarousel.json";
import { FaMemory, FaPalette, FaStar } from "react-icons/fa";
const ProductCarouselComponent = () => {
  const [products, setProducts] = useState([]);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const gradeMapping = {
    "Delta Platinum": "Mükemmel",
    "Beta Gold": "Çok İyi",
    "Alfa Silver": "İyi",
    "Bayi Özel": "Bayi Özel",
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        //  API bağlantısı:
        // const response = await axios.get("API_URL");
        // const data = response.data;

        const data = productData;
        const parsedData = parseProductData(data);
        setProducts(parsedData);
      } catch (error) {
        console.error("Veri yükleme hatası:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentProductIndex(
          (prevIndex) => (prevIndex + 1) % products.length
        );
        setIsVisible(true);
      }, 500);
    }, 66000);

    return () => clearInterval(interval);
  }, [products]);

  const currentProduct = products[currentProductIndex] || {};

  return (
    <div className="container d-flex featured-product">
      <div className="row w-100">
        {/* Sol Bölüm */}
        <div className="col-12 col-md-6 left-small d-flex flex-column align-items-center justify-content-center  text-white rounded shadow">
          <h2 className="section-titleheading mb-3">Heading</h2>
          <div>
            <p className="text-center">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla,
              ipsa minima vitae quosLorem ipsum, dolor sit amet consectetur
              adipisicing elit. Nulla,ipsa minima vitae quosadipisicing
              elit.Nulla,ipsa minima vitae
            </p>
            <ul className="list-unstyled outlet-list">
              <li>List Item</li>
              <li>List Item</li>
              <li>List Item</li>
              <li>List Item</li>
              <li>List Item</li> <li>List Item</li>
            </ul>
            <button className="check-button btn btn-dark mt-3 flex-column">
              Tüm Outlet Cihazlar
            </button>
          </div>
        </div>

        {/* Sağ Bölüm */}
        <div className="col-12 col-md-6 right-large mt-4 mt-md-0">
          <h2 className="product-name">
            {currentProduct.name?.split(" ")[0] && (
              <span style={{ fontWeight: "800" }}>
                {currentProduct.name.split(" ")[0]} {/* İlk kelime (Apple) */}
              </span>
            )}
            <span style={{ fontWeight: "100" }}>
              {currentProduct.name?.substring(
                currentProduct.name.indexOf(" ") + 1
              )}{" "}
              {/* Diğer kelimeler */}
            </span>
          </h2>

          <div
            className={`product-card ${
              isVisible ? "fade-in" : "fade-out"
            } d-flex flex-column flex-md-row p-3 align-items-center mx-auto`}
          >
            <div className="info-section flex-grow-1">
              <div className="details with-line">
                <div className="detail-row ">
                  <span className="detail-value">
                    <FaMemory
                      color="#ff0000"
                      size={20}
                      style={{ marginRight: "2px" }}
                    />{" "}
                    Hafıza:{" "}
                    <span className="detail-value">
                      {currentProduct.memory}
                    </span>
                  </span>
                </div>

                <div className="detail-row">
                  <span className="detail-value">
                    <FaPalette
                      color="#ff0000"
                      size={20}
                      style={{ marginRight: "2px" }}
                    />{" "}
                    Renk:{" "}
                    <span className="detail-value color-detail">
                      <span>{currentProduct.color}</span>
                    </span>
                  </span>
                </div>
                <div className="detail-row">
                  <span className="detail-value">
                    <FaStar
                      color="#ff0000"
                      size={20}
                      style={{ marginRight: "2px" }}
                    />{" "}
                    Kalite:{" "}
                    <span className="detail-value color-detail">
                      {gradeMapping[currentProduct.status] || "Bilinmiyor"}
                    </span>
                  </span>
                </div>
              </div>
              <div className="icons mt-3 d-flex gap-1 flex-column with-line">
                <span className="detail-label">Outlet Nedenleri</span>
                <div className="detail-row ">
                  <span className="detail-value gradient-cell">
                    {currentProduct.cosmetic}
                  </span>
                </div>
              </div>
              <div className="spacing">
                <span className="detail-value old-price">
                  {currentProduct.retailPrice
                    ? `${currentProduct.retailPrice} ₺`
                    : "Belirtilmemiş"}
                </span>
                <span className="detail-value new-price">
                  {currentProduct.price
                    ? `${currentProduct.price} ₺`
                    : "Belirtilmemiş"}
                </span>
              </div>
            </div>
            <div className="warranty-section text-center  mt-md-0">
              <div className="product-image-single-carousel">
                <img
                  src={currentProduct.imageUrl}
                  alt={currentProduct.name}
                  className="img-fluid rounded"
                />
              </div>
            </div>
            <div className="action-buttons">
              <button className="check-button1 btn btn-dark">Hemen Al</button>
              <button className="check-button2 btn btn-light">
                <span className="video-icon ">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 576 512"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
                  </svg>
                </span>{" "}
                İnceleme Videosu
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const parseProductData = (data) => {
  return data.flatMap((product) =>
    product.forms.map((form) => ({
      status: form.grade.grade_title,
      name: form.variant_title,
      memory: `${form.memory.storage} GB`,
      color: form.color.color_name,
      batteryHealth: "Bilinmiyor",
      cosmetic: form.outletAnswers[0] || "Belirtilmemiş",
      // warranty: "12 AY GARANTİ",
      imageUrl: form.images.image1 || "",
      price: form.price,
      retailPrice: form.retail_price || "Belirtilmemiş",
    }))
  );
};

export default ProductCarouselComponent;
