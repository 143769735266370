import React, { useState } from "react";
import InstallmentGrid from "./InstallmentCard/InstallmentGrid";

const ProductTabs = ({ selectedVariant }) => {
  const [activeTab, setActiveTab] = useState("payment");

  return (
    <div>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #ddd",
          marginBottom: "1rem",
          marginTop: "2rem",
        }}
      >
        {/* <button
          onClick={() => setActiveTab("features")}
          style={{
            flex: 1,
            padding: "10px 15px",
            border: "none",
            background: "none",
            fontWeight: activeTab === "features" ? "bold" : "normal",
            color: activeTab === "features" ? "black" : "#888",
            cursor: "pointer",
            borderBottom: activeTab === "features" ? "2px solid black" : "none",
          }}
        >
          Ürün Özellikleri
        </button> */}
        <button
          onClick={() => setActiveTab("description")}
          style={{
            flex: 1,
            padding: "10px 15px",
            border: "none",
            background: "none",
            fontWeight: activeTab === "description" ? "bold" : "normal",
            color: activeTab === "description" ? "black" : "#888",
            cursor: "pointer",
            borderBottom:
              activeTab === "description" ? "2px solid black" : "none",
          }}
        >
          Ürün Açıklaması
        </button>
        <button
          onClick={() => setActiveTab("payment")}
          style={{
            flex: 1,
            padding: "10px 15px",
            border: "none",
            background: "none",
            fontWeight: activeTab === "payment" ? "bold" : "normal",
            color: activeTab === "payment" ? "black" : "#888",
            cursor: "pointer",
            borderBottom: activeTab === "payment" ? "2px solid black" : "none",
          }}
        >
          Ödeme Seçenekleri
        </button>
      </div>

      {/* Sekme İçerikleri */}
      <div>
        {activeTab === "features" && (
          <div>
            <h4>Ürün Özellikleri</h4>
            <p>Burada ürün özellikleri listelenecek.</p>
          </div>
        )}
        {activeTab === "description" && (
          <div>
            <h4>Ürün Açıklaması</h4>
            <p>Burada ürün açıklaması bulunacak.</p>
          </div>
        )}
        {activeTab === "payment" && (
          <div>
            {selectedVariant?.price?.length > 0 ? (
              <InstallmentGrid price={selectedVariant.price[0]?.price} />
            ) : (
              <p>Fiyat bilgisi mevcut değil.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductTabs;
