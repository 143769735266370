import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Collapse } from "reactstrap";
import axios from "axios";
import ShopBreadCrumb from "../../../Components/Common/shopBreadCrumb";
import { useDispatch } from "react-redux";
import { addItem } from "../../../slices/cart/reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingUsd,
  faShieldAlt,
  faShoppingCart,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FaInfoCircle } from "react-icons/fa";
import ShippingInfo from "../../../Components/Common/ShippingInfo";

import SizinSectiklerimiz from "../../Landing/OnePage/components/RelatedProducts/RelatedProducts";
import ProductTabs from "../../../Components/Common/ProductTabs";
function EcommerceProductDetail() {
  const { id } = useParams();
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedMemory, setSelectedMemory] = useState("");
  const [selectedColor, setSelectedColor] = useState("");

  const [filteredGrades, setFilteredGrades] = useState([]);
  const [filteredMemories, setFilteredMemories] = useState([]);
  const [filteredColors, setFilteredColors] = useState([]);

  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");

  const defaultImage =
    "https://static.vecteezy.com/ti/vecteur-libre/t1/22014063-disparu-image-page-pour-site-internet-conception-ou-mobile-app-conception-non-image-disponible-icone-vecteur-vectoriel.jpg";

  const formatCurrency = (value) => {
    const numberValue = parseInt(value, 10);
    return numberValue.toLocaleString("tr-TR");
  };

  const fetchProducts = async () => {
    setLoading(true);
    setError("");
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(
        `https://marketplace.deltaservis.com.tr/api/productDetail/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // if (response.data.products) {
      //   setProduct(response.data.products[0]);
      // }
      if (response.data.products?.[0]?.variants?.[0]?.price?.[0]) {
        setProduct(response.data.products[0]);
      } else {
        setError("Ürün bilgisi eksik. Lütfen tekrar deneyin.");
      }
    } catch (error) {
      setError("Veri yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
      console.error("Veri çekme hatası:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [id]);

  // Ürün yüklendiğinde unique liste oluştur.
  useEffect(() => {
    if (product && product.variants) {
      const grades = [
        ...new Set(
          product.variants.map((variant) => variant.grade.grade_title)
        ),
      ];
      const memories = [
        ...new Map(
          product.variants.map((variant) => [
            variant.memory.storage,
            variant.memory,
          ])
        ).values(),
      ];
      const colors = [
        ...new Map(
          product.variants.map((variant) => [
            variant.color.color_name,
            variant.color,
          ])
        ).values(),
      ];

      setFilteredGrades(grades);
      setFilteredMemories(memories);
      setFilteredColors(colors);

      // İsterseniz varsayılan seçim yapmayabilirsiniz.
      if (product.variants.length > 0) {
        const defaultVariant = product.variants[0];
        setSelectedGrade(defaultVariant.grade.grade_title);
        setSelectedMemory(defaultVariant.memory.storage);
        setSelectedColor(defaultVariant.color.color_name);
      }
    }
  }, [product]);

  // Seçimler değiştikçe filtreleme yap
  useEffect(() => {
    if (!product || !product.variants) return;

    let currentVariants = product.variants;

    // Grade filtrele
    if (selectedGrade) {
      currentVariants = currentVariants.filter(
        (v) => v.grade.grade_title === selectedGrade
      );
    }

    // Grade'e göre memory listesi
    const memoryList = [
      ...new Map(
        currentVariants.map((variant) => [
          variant.memory.storage,
          variant.memory,
        ])
      ).values(),
    ];
    setFilteredMemories(memoryList);

    // Memory filtrele
    if (selectedMemory) {
      currentVariants = currentVariants.filter(
        (v) => v.memory.storage === Number(selectedMemory)
      );
    }

    // Memory'e göre color listesi
    const colorList = [
      ...new Map(
        currentVariants.map((variant) => [
          variant.color.color_name,
          variant.color,
        ])
      ).values(),
    ];
    setFilteredColors(colorList);

    // Color filtrele
    if (selectedColor) {
      currentVariants = currentVariants.filter(
        (v) => v.color.color_name === selectedColor
      );
    }

    // Varyantı seç
    if (currentVariants.length > 0) {
      const chosenVariant = currentVariants[0];
      const index = product.variants.findIndex(
        (v) => v.variant_id === chosenVariant.variant_id
      );
      setSelectedVariantIndex(index);
    }
  }, [selectedGrade, selectedMemory, selectedColor, product]);

  const handleIncrement = () => setQuantity(quantity + 1);
  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const gradeMapping = {
    "Delta Platinum": {
      title: "Mükemmel",
      description:
        "Cihazın ekranında ve kasasında gözle görülebilecek herhangi bir çizik veya deformasyon bulunmaz.",
    },
    "Beta Gold": {
      title: "Çok İyi",
      description:
        "Cihazın ekranında çok küçük çizikler olabilir. Kasasında ise hafif deformasyonlar görülebilir.",
    },
    "Alfa Silver": {
      title: "İyi",
      description:
        "Cihazın ekranında gözle görülebilecek düzeyde çizikler olabilir. Cihazın kasasında gözle görülebilecek düzeyde çizik veya deformeler vardır.",
    },
    "Bayi Özel": {
      title: "Bayi Özel",
      description:
        "Cihazın durumu bayi müşterileri için özel olarak belirtilmiştir.",
    },
  };
  const handleAddToCart = () => {
    if (!product) return;

    // const selectedVariant = product.variants[selectedVariantIndex] ;
    const selectedVariant =
      product?.variants && product.variants[selectedVariantIndex];

    if (!selectedVariant?.price || selectedVariant.price.length === 0) {
      console.warn("Seçili varyant için fiyat bilgisi bulunamadı.");
    }

    const item = {
      row_outlet: 0,
      variant_id: selectedVariant.variant_id,
      img: selectedVariant?.images?.image1 || defaultImage,
      product: selectedVariant.variant_title,
      quantity,
      price: selectedVariant?.price[0]?.price || 0,
    };

    dispatch(addItem(item));
  };

  document.title = "Ürün Detayı | İş Ortağım - Delta Group";

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  const selectedVariant =
    product?.variants && product.variants[selectedVariantIndex];

  return (
    <div className="page-content">
      <Container fluid>
        {product && (
          <ShopBreadCrumb
            section="Mağaza"
            category="Cep Telefonu"
            condition="Ürün Detay"
            brand={product.brand_title}
            productName={product.product_name}
          />
        )}
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                {product && selectedVariant && (
                  <Row className="gx-lg-5">
                    <Col xl={6} md={8} className="mx-auto">
                      <div className="product-images-container mt-4 text-center">
                        {/* Ana Görsel */}
                        <div
                          className="main-image-wrapper mb-4"
                          style={{
                            width: "100%",
                            maxWidth: "800px",
                            height: "auto",
                            margin: "0 auto",
                          }}
                        >
                          <img
                            src={
                              selectedImage ||
                              selectedVariant.images.image1 ||
                              defaultImage
                            }
                            alt="Ana Ürün Görseli"
                            className="main-image img-fluid rounded shadow"
                            style={{
                              width: "100%",
                              height: "600px",
                              objectFit: "contain",
                              borderRadius: "20px",
                              border: "1px solid #ddd",
                              mixBlendMode: "multiply",
                            }}
                          />
                        </div>

                        {/* Alt Görseller (Thumbnail) */}
                        <div className="thumbnail-wrapper d-flex justify-content-center gap-2 mt-3">
                          {Object.values(selectedVariant.images).map(
                            (image, index) =>
                              image ? (
                                <div
                                  key={index}
                                  className="thumbnail-container"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                    border:
                                      selectedImage === image
                                        ? "2px solid #007bff"
                                        : "1px solid #ddd",
                                    borderRadius: "10px",
                                    boxShadow:
                                      selectedImage === image
                                        ? "0px 0px 5px #007bff"
                                        : "none",
                                    backgroundColor: "white",
                                  }}
                                  onClick={() => setSelectedImage(image)} // Ana görseli değiştir
                                >
                                  <img
                                    src={image}
                                    alt={`Ürün Görseli ${index + 1}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </div>
                              ) : null
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col xl={6}>
                      <div className="mt-xl-0 mt-5">
                        <div className="d-flex">
                          <div className="flex-grow-1 border-bottom pb-3">
                            <h2 className="text-dark fw-bold h2-title">
                              {product.brand_title +
                                " " +
                                product.top_model_title +
                                " (" +
                                selectedVariant?.grade.grade_title +
                                ") " +
                                selectedVariant?.memory.storage +
                                "GB"}
                            </h2>
                            <div className="mt-2">
                              {" "}
                              <h5 className="font-weight-light">
                                Yenilenmiş Cep Telefonu -{" "}
                                <span className="bold">Mükemmel</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3 mt-4 mb-3 ">
                          <div className="bg-black text-white text-center indirim">
                            <span
                              className="fw-bold d-block"
                              style={{ fontSize: "1rem" }}
                            >
                              10%
                            </span>
                            <span>İndirim</span>
                          </div>

                          <div className="container">
                            <h3 className="text-muted text-decoration-line-through mb-1">
                              {selectedVariant?.price?.length > 0
                                ? formatCurrency(
                                    selectedVariant.price[0]?.price * 1.1
                                  )
                                : "Fiyat Bilgisi Yok"}
                              ₺
                            </h3>
                            <h3 className="fw-bold mb-0 text-black">
                              {selectedVariant?.price?.length > 0
                                ? formatCurrency(
                                    selectedVariant.price[0]?.price
                                  )
                                : "Fiyat Bilgisi Yok"}
                              ₺
                            </h3>

                            <div className="installment-info">
                              <p className="installment-advantage">
                                12 Ay Taksit Avantajıyla
                              </p>
                              <p className="installment-details">
                                7.583,22 TL X 12 Ay
                              </p>
                            </div>
                          </div>
                          <div className="container mt-3">
                            <div className="col">
                              <div className="col-12  d-flex align-items-center gap-2 mb-3">
                                <FontAwesomeIcon icon={faShieldAlt} size="lg" />
                                <span>12 Ay Garanti</span>
                              </div>
                              <div className="col-12  d-flex align-items-center gap-2 mb-3">
                                <FontAwesomeIcon
                                  icon={faHandHoldingUsd}
                                  size="lg"
                                />
                                <span>14 Gün İade Garantisi</span>
                              </div>
                              <div className="col-12  d-flex align-items-center gap-2 mb-3">
                                <FontAwesomeIcon icon={faTruck} size="lg" />
                                <span>Hızlı Kargo</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Renk Seçimi */}
                        <h6 className="mt-3">Renk</h6>
                        <div className="color-selection d-flex gap-2 mt-3">
                          {filteredColors.map((colorObj, index) => {
                            const variantWithSelectedColor =
                              product.variants.find(
                                (variant) =>
                                  variant.color.color_name ===
                                    colorObj.color_name &&
                                  variant.memory.storage === selectedMemory &&
                                  variant.grade.grade_title === selectedGrade
                              );

                            const isOutOfStock =
                              variantWithSelectedColor?.sale_stock <= 0;

                            return (
                              <button
                                key={index}
                                onClick={() => {
                                  if (isOutOfStock) return;
                                  setSelectedColor(colorObj.color_name);
                                  setSelectedImage(
                                    variantWithSelectedColor?.images?.image1 ||
                                      defaultImage
                                  );
                                }}
                                className={`color-box d-flex flex-row align-items-center justify-content-center p-7 rounded ${
                                  selectedColor === colorObj.color_name
                                    ? "border border-danger"
                                    : "border"
                                }`}
                                style={{
                                  width: "100px",
                                  height: "60px",
                                  cursor: isOutOfStock
                                    ? "not-allowed"
                                    : "pointer",
                                  backgroundColor: "white",
                                  opacity: isOutOfStock ? "0.6" : "1",
                                }}
                              >
                                {/* Görsel */}
                                <img
                                  src={
                                    variantWithSelectedColor?.images?.image1 ||
                                    defaultImage
                                  }
                                  alt={colorObj.color_name}
                                  style={{
                                    width: "32px",
                                    height: "32px",
                                    objectFit: "contain",
                                    borderRadius: "100px",
                                    mixBlendMode: "multiply",
                                  }}
                                />

                                {/* Renk Adı */}
                                <div
                                  style={{
                                    fontWeight:
                                      selectedColor === colorObj.color_name
                                        ? "bold"
                                        : "normal",
                                    fontSize: "14px",
                                  }}
                                >
                                  {colorObj.color_name}
                                </div>

                                {/* Tükendi Mesajı */}
                                {isOutOfStock && (
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                      fontWeight: "bold",
                                      marginTop: "4px",
                                    }}
                                  >
                                    Tükendi
                                  </div>
                                )}
                              </button>
                            );
                          })}
                        </div>

                        {/* Kozmetik Durumu */}
                        <h6 className="mt-2">Kozmetik Durumu</h6>
                        <div className="grade-selection d-flex gap-3 mt-3">
                          {filteredGrades.map((gradeTitle, index) => (
                            <button
                              key={index}
                              onClick={() => {
                                setSelectedGrade(gradeTitle);

                                const newVariants = product.variants.filter(
                                  (variant) =>
                                    variant.grade.grade_title === gradeTitle
                                );
                                const defaultMemory =
                                  newVariants.length > 0
                                    ? newVariants[0].memory.storage
                                    : "";
                                const defaultColor =
                                  newVariants.length > 0
                                    ? newVariants[0].color.color_name
                                    : "";

                                setSelectedMemory(defaultMemory);
                                setSelectedColor(defaultColor);
                              }}
                              className={`col grade-box d-flex align-items-center justify-content-center rounded ${
                                selectedGrade === gradeTitle
                                  ? "bg-black text-white"
                                  : "border text-muted"
                              }`}
                              style={{
                                width: "100px",
                                height: "50px",
                                cursor: "pointer",
                                backgroundColor:
                                  selectedGrade === gradeTitle
                                    ? "white"
                                    : "white",
                                fontWeight:
                                  selectedGrade === gradeTitle
                                    ? "bold"
                                    : "normal",
                                border:
                                  selectedGrade === gradeTitle
                                    ? "2px solid white"
                                    : "1px solid #ddd",
                                transition: "all 0.3s",
                              }}
                            >
                              {gradeMapping[gradeTitle]?.title || gradeTitle}
                            </button>
                          ))}
                        </div>

                        {/* Seçilen Duruma Göre Açıklama */}
                        {selectedGrade && (
                          <div
                            className="mt-3 p-3 rounded"
                            style={{
                              backgroundColor: "#f9f9f9",
                              border: "1px solid #ddd",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              {/* Bilgi Simgesi */}
                              <FaInfoCircle
                                style={{
                                  color: "#ff0000",
                                  fontSize: "1.5rem",
                                  marginRight: "10px", // Simge ile metin arasındaki boşluk
                                }}
                              />

                              {/* Başlık ve Açıklama */}
                              <div>
                                <span
                                  style={{
                                    fontSize: "0.9rem",
                                    color: "#555",
                                  }}
                                >
                                  <strong
                                    style={{
                                      fontSize: "1rem",
                                      color: "#333",
                                    }}
                                  >
                                    {gradeMapping[selectedGrade]?.title ||
                                      "selectedGrade"}
                                    {""} : {""}
                                  </strong>
                                  {gradeMapping[selectedGrade]?.description ||
                                    "Açıklama bulunamadı."}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                        <h6 className="mt-3">Hafıza Seçimi:</h6>
                        <div className="memory-selection d-flex gap-3 mt-3">
                          {filteredMemories.map((memoryObj, index) => (
                            <button
                              key={index}
                              onClick={() => {
                                setSelectedMemory(memoryObj.storage);
                                setSelectedColor("");
                              }}
                              className={`col memory-box d-flex align-items-center justify-content-center rounded ${
                                selectedMemory === memoryObj.storage
                                  ? "border-dark"
                                  : "border-muted"
                              }`}
                              style={{
                                width: "100px",
                                height: "50px",
                                cursor: "pointer",
                                backgroundColor: "white",
                                fontWeight:
                                  selectedMemory === memoryObj.storage
                                    ? "bold"
                                    : "normal",
                                color: "black", // Yazı rengi her zaman siyah olacak
                                border: "1px solid #ddd",
                                transition: "all 0.3s",
                              }}
                            >
                              {memoryObj.storage} GB
                            </button>
                          ))}
                        </div>
                        <ShippingInfo />
                        <div className="row-12 d-flex align-items-center gap-3 mt-3">
                          <button
                            className="btn btn-dark d-inline-flex align-items-center justify-content-center w-50 py-3 h-100 bg-black"
                            onClick={handleAddToCart}
                            disabled={
                              !selectedGrade ||
                              !selectedMemory ||
                              !selectedColor
                            }
                          >
                            <FontAwesomeIcon
                              icon={faShoppingCart}
                              size="lg"
                              className="me-3"
                            />
                            <span>Sepete Ekle</span>
                          </button>

                          <button
                            className="btn btn-secondary w-25 py-3 h-100"
                            onClick={handleDecrement}
                          >
                            -
                          </button>
                          <span>{quantity}</span>
                          <button
                            className="btn btn-dark bg-black w-25 py-3 h-100"
                            onClick={handleIncrement}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <ProductTabs selectedVariant={selectedVariant} />

                <SizinSectiklerimiz />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EcommerceProductDetail;
