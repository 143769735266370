import { useState } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import { faqItems } from "./faqData";
import "./faq.css";

const FAQItem = ({
  question,
  answer,
  isActive,
  onClick,
  buttonText,
  setShowModal,
}) => (
  <motion.div
    className="rounded-md p-7 shadow-xl"
    onClick={onClick}
    initial={{ backgroundColor: "#f8f9fa" }}
    whileHover={{ backgroundColor: "#e3e4e6" }}
    transition={{ duration: 0.2 }}
  >
    <div className="flex cursor-pointer items-center justify-between text-2xl font-medium text-black">
      <p className="text-black">{question}</p>
      <motion.div className="relative flex h-10 w-10 items-center justify-center rounded-md bg-gray-700 transition duration-300 hover:bg-gray-500/10">
        <motion.svg
          className={`arrow transform transition-all duration-300 ${
            isActive ? "rotate-90" : ""
          } hover:text-black`}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id="red-gradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop
                offset="0%"
                style={{ stopColor: "black", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "darkblack", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
          <path
            d="M14.5504 10.5C14.5504 10.7689 14.4478 11.0377 14.2429 11.2426L7.79319 17.6923C7.38291 18.1026 6.71771 18.1026 6.30759 17.6923C5.89747 17.2822 5.89747 16.6171 6.30759 16.2068L12.0147 10.5L6.30779 4.79324C5.89767 4.38295 5.89767 3.71795 6.30779 3.30786C6.71791 2.89738 7.38311 2.89738 7.79339 3.30786L14.2431 9.75744C14.448 9.9625 14.5504 10.2313 14.5504 10.5Z"
            fill="url(#red-gradient)"
          />
        </motion.svg>
      </motion.div>
    </div>

    <AnimatePresence>
      {isActive && (
        <motion.div
          initial={{ opacity: 0, maxHeight: 0 }}
          animate={{ opacity: 1, maxHeight: 500 }}
          exit={{ opacity: 0, maxHeight: 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="mt-4 text-xl text-black"
          >
            {answer}
          </motion.p>
          {buttonText && (
            <div className="mt-4">
              <button
                onClick={() => setShowModal(true)} // Modal açmak için
                className="rounded-md bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 transition"
              >
                {buttonText}
              </button>
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
);

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string, // buttonText prop'u isteğe bağlıdır
  setShowModal: PropTypes.func.isRequired, // setShowModal prop'u gereklidir
};

const FAQ = () => {
  const [showModal, setShowModal] = useState(false);

  const handleSuccess = (data) => {
    console.log("Form Başarıyla Gönderildi:", data);
    alert("Form başarıyla gönderildi!");
  };

  const handleError = (error) => {
    console.error("Error submitting form:", error);
    alert("Form gönderilirken bir hata oluştu.");
  };

  const [activeIndexes, setActiveIndexes] = useState([]);

  const toggleFAQ = (index) => {
    setActiveIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  return (
    <div className="mt-12 py-4 xl:py-24 ">
      <div className="mx-auto max-w-7xl px-4 lg:px-8">
        <div className="relative z-10 mb-12 text-center">
          <h1 className="pb-1 text-6xl font-semibold text-transparent  sm:text-4xl lg:text-6xl text-black">
            Merak Edilenler
          </h1>
          <p className="mt-2 text-center text-lg leading-8 text-black ">
            Delta İş Ortağım hakkında en sık sorulan soruları ve detaylı
            yanıtlarını burada bulabilirsiniz. Bayilik başvuru sürecinden,{" "}
            <br />
            bölgeler ve desteklere kadar merak ettiğiniz her şey burada sizi
            bekliyor.
          </p>
        </div>

        <div className="grid items-start gap-x-8 gap-y-10 text-white lg:grid-cols-1">
          {faqItems.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              answer={item.answer}
              isActive={activeIndexes.includes(index)}
              onClick={() => toggleFAQ(index)}
              buttonText={item.buttonText}
              setShowModal={setShowModal} // setShowModal prop olarak aktarılıyor
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default FAQ;
