import React from 'react';
import './CategoryBox/CategoryBox.css';

const CategoryBox = ({ categories }) => {
  return (
    <div className="category-box">
      {categories.map((category, index) => (
        <div key={category.id || index} className="category-item">
          <div className="image-wrapper">
            <img
              src={category.productImage}
              alt="Product Image"
              className="product-image"
            />
            <img
              src={category.hoverImage}
              alt="Hover Image"
              className="hover-image"
            />
            <img
              src={category.labelImage}
              alt="Label Image"
              className="label-image"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryBox;
