import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Collapse } from "reactstrap";
import axios from "axios";
import ShopBreadCrumb from "../../../Components/Common/shopBreadCrumb";
import { useDispatch } from "react-redux";
import { addItem } from "../../../slices/cart/reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faHandHoldingUsd,
  faShieldAlt,
  faShoppingCart,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";

function EcommerceProductDetail() {
  const { id } = useParams();
  const [isOpenDetail, setIsOpenDetail] = useState(true);
  const [isOpenDescription, setIsOpenDescription] = useState(true);

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const defaultImage =
    "https://static.vecteezy.com/ti/vecteur-libre/t1/22014063-disparu-image-page-pour-site-internet-conception-ou-mobile-app-conception-non-image-disponible-icone-vecteur-vectoriel.jpg";

  const formatCurrency = (value) => {
    const numberValue = parseInt(value, 10);
    return numberValue.toLocaleString("tr-TR");
  };

  const fetchProducts = async () => {
    setLoading(true);
    setError("");
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(
        `https://marketplace.deltaservis.com.tr/api/outletProducts?form_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.products) {
        console.log(response.products);
        setProduct(response.products[0]);
      }
    } catch (error) {
      setError("Veri yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
      console.error("Veri çekme hatası:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [id]);

  const handleAddToCart = () => {
    if (!product) return;

    const item = {
      row_outlet: 1,
      outlet_form : product.forms[0].form_id,
      variant_id: product.forms[0].variant_id,
      img: product.forms[0]?.images?.image1 || defaultImage,
      product:
        product.brand_title +
        " " +
        product.top_model_title +
        " (" +
        product.forms[0]?.grade.grade_title +
        ") " +
        product.forms[0]?.memory.storage +
        "GB",
      quantity: 1,
      price: product.forms[0]?.price || 0,
    };

    dispatch(addItem(item));
  };

  document.title = "Outlet Ürün Detayı | İş Ortağım - Delta Group";

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  return (
    <div className="page-content">
      <Container fluid>
        {product && (
          <ShopBreadCrumb
            section="Mağaza"
            category="Cep Telefonu"
            condition="Ürün Detay"
            brand={product.brand_title}
            productName={product.product_name}
          />
        )}
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                {product && product.forms[0] && (
                  <Row className="gx-lg-5">
                    <Col xl={6} md={8} className="mx-auto">
                      <div className="container mt-4">
                        <div className="row g-3">
                          {product.forms[0].images &&
                            Object.values(product.forms[0].images).map(
                              (image, index) =>
                                image && index < 4 ? (
                                  <div key={index} className="col-6">
                                    <div
                                      className="position-relative mx-auto overflow-hidden rounded bg-light"
                                      style={{
                                        aspectRatio: "4 / 4.65",
                                        width: "100%",
                                      }}
                                    >
                                      <img
                                        src={image}
                                        alt={`Product Image ${index + 1}`}
                                        className="img-fluid h-100 w-100 object-fit-cover"
                                        style={{
                                          position: "absolute",
                                          inset: "0",
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : index === 1 ? (
                                  <div key={index} className="col-6">
                                    <div
                                      className="position-relative mx-auto overflow-hidden rounded bg-light"
                                      style={{
                                        aspectRatio: "4 / 4.65",
                                        width: "100%",
                                      }}
                                    >
                                      <img
                                        src={defaultImage}
                                        alt={`Product Image ${index + 1}`}
                                        className="img-fluid h-100 w-100 object-fit-cover"
                                        style={{
                                          position: "absolute",
                                          inset: "0",
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : null
                            )}
                        </div>
                      </div>
                    </Col>

                    <Col xl={6}>
                      <div className="mt-xl-0 mt-5">
                        <div className="d-flex">
                          <div className="flex-grow-1 border-bottom pb-3">
                            <h2 className="text-dark fw-bold">
                              {product.brand_title +
                                " " +
                                product.top_model_title +
                                " (" +
                                product.forms[0]?.grade.grade_title +
                                ") " +
                                product.forms[0]?.memory.storage +
                                "GB"}
                            </h2>
                          </div>
                        </div>

                        <div className="container mt-3">
                          <div className="row">
                            <div className="col-12 col-md-4 d-flex align-items-center gap-2 mb-3 mb-md-0">
                              <FontAwesomeIcon icon={faShieldAlt} size="lg" />
                              <span>12 Ay Garanti</span>
                            </div>
                            <div className="col-12 col-md-4 d-flex align-items-center gap-2">
                              <FontAwesomeIcon
                                icon={faHandHoldingUsd}
                                size="lg"
                              />
                              <span>14 Gün İade Garantisi</span>
                            </div>
                            <div className="col-12 col-md-4 d-flex align-items-center gap-2">
                              <FontAwesomeIcon icon={faTruck} size="lg" />
                              <span>Hızlı Kargo</span>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center gap-3 mt-4 mb-3">
                          <div className="bg-black text-white text-center indirim">
                            <span
                              className="fw-bold d-block"
                              style={{ fontSize: "1rem" }}
                            >
                              %
                              {product.forms[0]?.retail_price
                                ? (
                                    ((product.forms[0].retail_price -
                                      product.forms[0].price) /
                                      product.forms[0].retail_price) *
                                    100
                                  ).toFixed(0)
                                : 10}
                            </span>
                            İndirim
                          </div>

                          <div>
                            <h3 className="fw-bold mb-0 text-black pt-4">
                              {formatCurrency(product.forms[0]?.price)} ₺
                            </h3>
                            <p className="text-muted text-decoration-line-through mb-1">
                              {product.forms[0]?.retail_price != null
                                ? formatCurrency(
                                    product.forms[0]?.retail_price
                                  ) + " ₺"
                                : formatCurrency(
                                    parseInt(product.forms[0]?.price) * 1.2
                                  ) + " ₺"}{" "}
                            </p>
                          </div>
                        </div>

                        <div>
                          {product.forms[0]?.outletAnswers?.map(
                            (answer, index) => (
                              <p
                                key={index}
                                className={`text-secondary badge bg-light px-2 m-2 text-capitalize ${
                                  index === 0 ? "mx-0" : ""
                                }`}
                              >
                                {answer}
                              </p>
                            )
                          )}
                        </div>

                        <div className="row">
                          {/* Grade Seçimi */}

                          <div className="col-4">
                            <h6 className="mt-3">Grade</h6>
                            <p className="col-12 border rounded p-2 d-flex align-items-center gap-2 color-box margin-auto">
                              <span className="w-100 text-center">
                                {product.forms[0]?.grade.grade_title}
                              </span>
                            </p>
                          </div>

                          {/* Hafıza Seçimi */}
                          <div className="col-4">
                            <h6 className="mt-3">Hafıza</h6>
                            <p className="col-12 border rounded p-2 d-flex align-items-center gap-2 color-box margin-auto">
                              <span className="w-100 text-center">
                                {product.forms[0]?.memory.storage} GB
                              </span>
                            </p>
                          </div>

                          {/* Renk Seçimi */}
                          <div className="col-4">
                            <h6 className="mt-3">Renk</h6>
                            <p className="col-12 border rounded p-2 d-flex align-items-center gap-2 color-box margin-auto">
                              <span className="w-100 text-center">
                                {product.forms[0]?.color.color_name}
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="row-12 d-flex align-items-center gap-3 mt-3">
                          <button
                            className="btn btn-dark d-inline-flex align-items-center justify-content-center w-50 py-3 h-100 bg-black"
                            onClick={handleAddToCart}
                            x
                          >
                            <FontAwesomeIcon
                              icon={faShoppingCart}
                              size="lg"
                              className="me-3"
                            />
                            <span>Sepete Ekle</span>
                          </button>
                        </div>

                        <div className="mt-4 text-muted">
                          <div
                            className="d-flex justify-content-between align-items-center fs-20 cursor-pointer"
                            onClick={() => setIsOpenDetail(!isOpenDetail)}
                            style={{ cursor: "pointer" }}
                          >
                            <h5 className="mb-0 text-black">Ürün Detay</h5>
                            <span style={{ transition: "transform 0.3s ease" }}>
                              <FontAwesomeIcon
                                icon={
                                  isOpenDetail ? faChevronUp : faChevronDown
                                }
                              />
                            </span>
                          </div>

                          <Collapse isOpen={isOpenDetail}>
                            <div>
                              <ul className="list-unstyled product-desc-list">
                                <li className="py-1">
                                  Marka: {product.brand_title}
                                </li>
                                <li className="py-1">
                                  Model: {product.product_name}
                                </li>
                                <li className="py-1">
                                  Hafıza: {product.forms[0]?.memory?.storage} GB
                                </li>
                              </ul>
                            </div>
                          </Collapse>
                        </div>

                        <div className="mt-4 text-muted">
                          <div
                            className="d-flex justify-content-between align-items-center fs-20 cursor-pointer"
                            onClick={() =>
                              setIsOpenDescription(!isOpenDescription)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <h5 className="mb-0 text-black">Ürün Açıklaması</h5>
                            <span style={{ transition: "transform 0.3s ease" }}>
                              <FontAwesomeIcon
                                icon={
                                  isOpenDescription
                                    ? faChevronUp
                                    : faChevronDown
                                }
                              />
                            </span>
                          </div>

                          <Collapse isOpen={isOpenDescription}>
                            <div>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt.
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EcommerceProductDetail;
