import React, { useEffect, useState } from "react";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DeltaPay from "./DeltaPayApi";
import axios from "axios";

const EcommerceCheckout = () => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [addressError, setAddressError] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [editAddressId, setEditAddressId] = useState(null);
  const [deletemodal, setDeleteModal] = useState(false);
  const cartItems = useSelector((state) => state.cart.items);

  const formatCurrency = (value) => {
    const numberValue = parseInt(value, 10);
    return numberValue.toLocaleString("tr-TR");
  };

  const token = JSON.parse(localStorage.getItem("authUser"))?.token;

  useEffect(() => {
    if (!token) {
      console.error("Token bulunamadı!");
      return;
    }

    axios
      .get(`https://marketplace.deltaservis.com.tr/api/omega-customer-info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserInfo(response);
        const primeAddress = response?.addresses?.find(
          (address) => address.prime === 1
        );
        if (primeAddress) {
          setSelectedAddress(primeAddress.address_id);
        }
      })
      .catch((error) => {
        console.error("Kullanıcı bilgisi alınırken hata oluştu:", error);
      });
  }, []);

  const toggledeletemodal = (Address) => {
    setEditAddressId(Address);
    setDeleteModal(!deletemodal);
  };

  const newAddress = () => {
    setEditAddressId(null);
    setModal(true);
  };

  const editAddress = (Address) => {
    setEditAddressId(Address);
    setModal(true);
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  const handlePaymentCheck = async () => {
    const cardName = document.getElementById("cc-name").value.trim();
    const cardNumber = document
      .getElementById("cc-number")
      .value.trim()
      .replaceAll(" ", "");
    const cardExpiry = document.getElementById("cc-expiration").value.trim();
    const cardCVV = document.getElementById("cc-cvv").value.trim();

    console.log(cardNumber);
    if (!cardName || !cardNumber || !cardExpiry || !cardCVV) {
      setApiError("Lütfen tüm ödeme bilgilerini doldurun.");
      return;
    }

    if (cardNumber.length !== 16 || isNaN(cardNumber)) {
      setApiError("Geçerli bir kart numarası girin.");
      return;
    }
    if (cardCVV.length !== 3 || isNaN(cardCVV)) {
      setApiError("Geçerli bir CVV girin.");
      return;
    }

    const paymentData = {
      order: cartItems,
      address_id: selectedAddress,
      payment: {
        installment: 1,
        amount: cartItems.reduce(
          (acc, item) => acc + item.quantity * item.price,
          0
        ),
        name: cardName,
        number: cardNumber,
        expiry: cardExpiry,
        cvv: parseInt(cardCVV, 10),
      },
    };

    try {
      const response = await DeltaPay(paymentData);
      if (response.status !== false) {
        const blob = new Blob([response], { type: "text/html" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        /* toggleTab(activeTab + 1); */
      } else {
        setApiError(response.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setApiError("Ödeme sırasında bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  document.title = "Alışverişi Tamamla | İş Ortağım - Delta Group";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Alışverişi Tamamla" pageTitle="Mağaza" />

          <Row>
            <Col xl="7">
              <Card>
                <CardBody className="checkout-tab">
                  <Form action="#">
                    <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                      <Nav
                        className="nav-pills nav-justified custom-nav"
                        role="tablist"
                      >
                        <NavItem role="presentation">
                          <NavLink
                            href="#"
                            className={classnames(
                              {
                                active: activeTab === 1,
                                done: activeTab <= 4 && activeTab >= 0,
                              },
                              "p-3 fs-15"
                            )}
                            onClick={() => {
                              toggleTab(1);
                            }}
                          >
                            <i className="ri-user-2-line fs-16 p-2 bg-light-subtle text-secondary rounded-circle align-middle me-2"></i>
                            Fatura Bilgileri
                          </NavLink>
                        </NavItem>
                        <NavItem role="presentation">
                          <NavLink
                            href="#"
                            className={classnames(
                              {
                                active: activeTab === 2,
                                done: activeTab <= 4 && activeTab > 1,
                              },
                              "p-3 fs-15"
                            )}
                            onClick={() => {
                              toggleTab(2);
                            }}
                          >
                            <i className="ri-truck-line fs-16 p-2 bg-light-subtle text-secondary rounded-circle align-middle me-2"></i>
                            Teslimat Bilgileri
                          </NavLink>
                        </NavItem>
                        <NavItem role="presentation">
                          <NavLink
                            href="#"
                            className={classnames(
                              {
                                active: activeTab === 3,
                                done: activeTab <= 4 && activeTab > 2,
                              },
                              "p-3 fs-15"
                            )}
                            onClick={() => {
                              toggleTab(3);
                            }}
                          >
                            <i className="ri-bank-card-line fs-16 p-2 bg-light-subtle text-secondary rounded-circle align-middle me-2"></i>
                            Ödeme Bilgileri
                          </NavLink>
                        </NavItem>
                        <NavItem role="presentation">
                          <NavLink
                            href="#"
                            className={classnames(
                              {
                                active: activeTab === 4,
                                done: activeTab <= 4 && activeTab > 3,
                              },
                              "p-3 fs-15"
                            )}
                          >
                            <i className="ri-checkbox-circle-line fs-16 p-2 bg-light-subtle text-secondary rounded-circle align-middle me-2"></i>
                            Sipariş Tamamlandı
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1} id="pills-bill-info">
                        <div>
                          <h5 className="mb-1">Fatura Bilgileri</h5>
                          <p className="text-muted mb-4">
                            Lütfen fatura bilgilerinizi giriniz.
                          </p>
                        </div>

                        <div>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-firstName"
                                  className="form-label"
                                >
                                  Şirket Ünvanı
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="billinginfo-firstName"
                                  placeholder="-"
                                  defaultValue={
                                    userInfo?.billing_info[0]?.title
                                  }
                                  disabled
                                />
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-lastName"
                                  className="form-label"
                                >
                                  {userInfo?.billing_info[0]?.tcno
                                    ? "TC No"
                                    : "Vergi No"}
                                </Label>
                                <Input
                                  type="text"
                                  disabled
                                  defaultValue={
                                    userInfo?.billing_info[0]?.tcno
                                      ? userInfo?.billing_info[0]?.tcno
                                      : userInfo?.billing_info[0]?.taxno
                                  }
                                  className="form-control"
                                  placeholder="-"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-firstName"
                                  className="form-label"
                                >
                                  Vergi Dairesi
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="billinginfo-firstName"
                                  placeholder="-"
                                  defaultValue={
                                    userInfo?.billing_info[0]?.taxoffice
                                  }
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-firstName"
                                  className="form-label"
                                >
                                  Mersis
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="billinginfo-firstName"
                                  placeholder="-"
                                  defaultValue={
                                    userInfo?.billing_info[0]?.mersis
                                  }
                                  disabled
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-email"
                                  className="form-label"
                                >
                                  İl
                                </Label>
                                <Input
                                  disabled
                                  type="email"
                                  className="form-control"
                                  defaultValue={
                                    userInfo?.billing_info[0]?.city_name
                                  }
                                  id="billinginfo-email"
                                  placeholder="-"
                                />
                              </div>
                            </Col>

                            <Col sm={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-phone"
                                  className="form-label"
                                >
                                  İlçe
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  defaultValue={
                                    userInfo?.billing_info[0]?.district_name
                                  }
                                  id="billinginfo-phone"
                                  placeholder="-"
                                />
                              </div>
                            </Col>

                            <Col sm={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-phone"
                                  className="form-label"
                                >
                                  Posta Kodu
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  defaultValue={
                                    userInfo?.billing_info[0]?.pcode
                                  }
                                  id="billinginfo-phone"
                                  placeholder="-"
                                />
                              </div>
                            </Col>
                          </Row>

                          <div className="mb-3">
                            <Label
                              htmlFor="billinginfo-address"
                              className="form-label"
                            >
                              Adres
                            </Label>
                            <textarea
                              className="form-control"
                              id="billinginfo-address"
                              placeholder="-"
                              rows="3"
                              disabled
                              defaultValue={userInfo?.billing_info[0]?.address}
                            ></textarea>
                          </div>

                          <div className="d-flex align-items-start gap-3 mt-3">
                            <button
                              type="button"
                              className="btn btn-dark btn-label right ms-auto nexttab"
                              onClick={() => {
                                toggleTab(activeTab + 1);
                              }}
                            >
                              <i className="ri-truck-line label-icon align-middle fs-16 ms-2"></i>
                              Teslimat Bilgilerine Devam Et
                            </button>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId={2}>
                        <div>
                          <h5 className="mb-1">Teslimat Bilgileri</h5>
                          <p className="text-muted mb-4">
                            Lütfen teslimat bilgilerini giriniz.
                          </p>
                        </div>

                        <div className="mt-4">
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-grow-1">
                              <h5 className="fs-14 mb-0">Kayıtlı Adresler</h5>
                            </div>
                            <div className="flex-shrink-0">
                              <button
                                type="button"
                                className="btn btn-sm btn-dark mb-3"
                                onClick={newAddress}
                              >
                                <i className="ri-add-line align-bottom me-1 fz-15"></i>{" "}
                                Yeni Adres Ekle
                              </button>
                            </div>
                          </div>
                          <Row className="gy-3">
                            {userInfo?.addresses.map((address, key) => (
                              <Col lg={6} sm={12} key={key}>
                                <div className="form-check card-radio">
                                  <Input
                                    id={"shippingAddress" + key}
                                    name="shippingAddress"
                                    type="radio"
                                    className="form-check-input"
                                    defaultChecked={!!address?.prime}
                                    onClick={() => {
                                      setSelectedAddress(address.address_id);
                                      setAddressError("");
                                    }}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor={"shippingAddress" + key}
                                  >
                                    <span className="mb-4 fw-semibold d-block text-muted text-uppercase">
                                      {address?.title}
                                    </span>
                                    <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                      {address?.address}
                                    </span>
                                    <span className="fs-14 mb-2 d-block">
                                      {address?.city_name} /{" "}
                                      {address?.district_name}
                                    </span>
                                  </Label>
                                </div>
                                <div className="d-flex flex-wrap p-2 py-1 bg-light rounded-bottom border mt-n1">
                                  <div>
                                    <Link
                                      to="#"
                                      className="d-block text-dark p-1 px-2"
                                      onClick={() => editAddress(address)}
                                    >
                                      <i className="ri-pencil-fill text-muted align-bottom me-1"></i>
                                      Düzenle
                                    </Link>
                                  </div>
                                  <div>
                                    <Link
                                      to="#"
                                      id="delete-link"
                                      className={`d-block text-dark p-1 px-2 ${
                                        address?.prime ||
                                        userInfo?.addresses.length === 1
                                          ? "text-muted"
                                          : ""
                                      }`}
                                      onClick={(e) => {
                                        if (
                                          address?.prime ||
                                          userInfo?.addresses.length === 1
                                        ) {
                                          setAddressError(
                                            "Varsayılan adres silinemez."
                                          );
                                          e.preventDefault();
                                        } else {
                                          toggledeletemodal(address);
                                        }
                                      }}
                                    >
                                      <i className="ri-delete-bin-fill text-muted align-bottom me-1"></i>
                                      Sil
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            ))}
                            <div className="text-danger">{addressError}</div>
                          </Row>

                          <div className="mt-4">
                            <h5 className="fs-14 mb-3">Gönderim Tipi</h5>

                            <Row className="g-4">
                              <Col lg={12}>
                                <div className="form-check card-radio">
                                  <Input
                                    id="shippingMethod01"
                                    name="shippingMethod"
                                    type="radio"
                                    className="form-check-input"
                                    defaultChecked
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="shippingMethod01"
                                  >
                                    <span className="fs-20 float-end mt-2 text-wrap d-block fw-semibold">
                                      Ücretsiz
                                    </span>
                                    <span className="fs-14 mb-1 text-wrap d-block">
                                      Ücretsiz Teslimat
                                    </span>
                                    <span className="text-muted fw-normal text-wrap d-block">
                                      Tahmini 3-5 İş Günü
                                    </span>
                                  </Label>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-light btn-label previestab"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                            Sipariş Bilgileri Geri Dön
                          </button>
                          <button
                            type="button"
                            className="btn btn-dark btn-label right ms-auto nexttab"
                            onClick={() => {
                              if (!selectedAddress) {
                                setAddressError("Lütfen bir adres seçiniz.");
                              } else {
                                setAddressError("");
                                toggleTab(activeTab + 1);
                              }
                            }}
                          >
                            <i className="ri-bank-card-line label-icon align-middle fs-16 ms-2"></i>
                            Ödeme Yap
                          </button>
                        </div>
                      </TabPane>

                      <TabPane tabId={3}>
                        <div>
                          <h5 className="mb-1">Ödeme Bilgileri</h5>
                          <p className="text-muted mb-4">
                            Lütfen ödeme bilgilerinizi giriniz.
                          </p>
                        </div>

                        <Row className="g-4">
                          <Col lg={12} sm={12}>
                            <div>
                              <div className="form-check card-radio">
                                <Input
                                  id="paymentMethod02"
                                  name="paymentMethod"
                                  type="radio"
                                  className="form-check-input"
                                  defaultChecked
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="paymentMethod02"
                                >
                                  <span className="fs-16 text-muted me-2">
                                    <i className="ri-bank-card-fill align-bottom"></i>
                                  </span>
                                  <span className="fs-14 text-wrap">
                                    Kredi / Banka Kartı
                                  </span>
                                </Label>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div
                          className="collapse show"
                          id="paymentmethodCollapse"
                        >
                          <Card className="p-4 border shadow-none mb-0 mt-4">
                            <Row className="gy-3">
                              <Col md={12}>
                                <Label htmlFor="cc-name" className="form-label">
                                  Kart Sahibi
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cc-name"
                                  placeholder="Enter name"
                                  defaultValue={"Yunus Bey"}
                                />
                                <small className="text-muted">
                                  Kartta görünün ad ve soyad
                                </small>
                              </Col>

                              <Col md={5}>
                                <Label
                                  htmlFor="cc-number"
                                  className="form-label"
                                >
                                  Kart Numarası
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cc-number"
                                  placeholder="xxxx xxxx xxxx xxxx"
                                  defaultValue={"4242 4242 4242 4242"}
                                  maxLength={19}
                                  onChange={(e) => {
                                    let value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    value =
                                      value.match(/.{1,4}/g)?.join(" ") ||
                                      value;
                                    e.target.value = value;
                                  }}
                                />
                              </Col>

                              <Col md={4}>
                                <Label
                                  htmlFor="cc-expiration"
                                  className="form-label"
                                >
                                  Son Kullanma Tarihi
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cc-expiration"
                                  placeholder="MM/YY"
                                  defaultValue={"12/25"}
                                  maxLength={5}
                                  onChange={(e) => {
                                    let value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (value.length > 2) {
                                      value =
                                        value.slice(0, 2) +
                                        "/" +
                                        value.slice(2, 4);
                                    }
                                    e.target.value = value.slice(0, 5);
                                  }}
                                />
                              </Col>

                              <Col md={3}>
                                <Label htmlFor="cc-cvv" className="form-label">
                                  CVV
                                </Label>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="cc-cvv"
                                  placeholder="xxx"
                                  maxLength={3}
                                  defaultValue={"123"}
                                />
                              </Col>
                            </Row>
                          </Card>
                          <div className="text-muted mt-2 fst-italic">
                            <i
                              data-feather="lock"
                              className="text-muted icon-xs"
                            ></i>{" "}
                            İşleminiz SSL şifrelemesi ile güvence altına
                            alınmıştır
                          </div>
                          <div className="text-danger">{apiError}</div>
                        </div>

                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-light btn-label previestab"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                            Teslimat Bilgilerine Geri Dön
                          </button>
                          <button
                            type="button"
                            className="btn btn-dark btn-label right ms-auto nexttab"
                            onClick={() => {
                              handlePaymentCheck();
                            }}
                          >
                            <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                            Siparişi Tamamla
                          </button>
                        </div>
                      </TabPane>

                      <TabPane tabId={4} id="pills-finish">
                        <div className="text-center py-5">
                          <div className="mb-4">
                            <lord-icon
                              src="https://cdn.lordicon.com/lupuorrc.json"
                              trigger="loop"
                              colors="primary:#000,secondary:#ededf0"
                              style={{ width: "120px", height: "120px" }}
                            ></lord-icon>
                          </div>
                          <h5>Siparişiniz Tamamlandı. Teşekkürler!</h5>
                          <p className="text-muted">
                            Siparişizi hazırlamaya başladık bile. Detaylı bilgi
                            için "Siparişlerim" Sayfasından alabilirsiniz.
                          </p>

                          <h3 className="fw-semibold">
                            Sipariş No:{" "}
                            <Link
                              to="/apps-ecommerce-order-details"
                              className="text-decoration-underline text-secondary"
                            >
                              VZ2451
                            </Link>
                          </h3>
                        </div>
                      </TabPane>
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col xl={5}>
              <Card>
                <CardHeader>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">Sipariş Özeti</h5>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive table-card">
                    <table className="table table-borderless align-middle mb-0">
                      <thead className="table-light text-muted">
                        <tr>
                          <th style={{ width: "90px" }} scope="col">
                            Ürün
                          </th>
                          <th scope="col">Ürün Bilgisi</th>
                          <th scope="col" className="text-end">
                            Fiyat
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((item, key) => (
                          <React.Fragment key={key}>
                            <tr>
                              <td>
                                <div className="avatar-md bg-light rounded p-1 h-100">
                                  <img
                                    src={item.img}
                                    alt={item.product}
                                    className="img-fluid d-block"
                                  />
                                </div>
                              </td>
                              <td>
                                <h5 className="fs-14">
                                  <Link
                                    to="/apps-ecommerce-product-details"
                                    className="text-dark"
                                  >
                                    {item.product}
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">
                                  {formatCurrency(item.price)} ₺ x{" "}
                                  {item.quantity} Adet
                                </p>
                              </td>
                              <td className="text-end">
                                {formatCurrency(item.quantity * item.price)} ₺
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}

                        <tr>
                          <td className="fw-semibold" colSpan="2">
                            Ara Toplam :
                          </td>
                          <td className="fw-semibold text-end">
                            {formatCurrency(
                              cartItems.reduce(
                                (acc, item) => acc + item.quantity * item.price,
                                0
                              )
                            )}{" "}
                            ₺
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">Teslimat Ücreti :</td>
                          <td className="text-end">0.00 ₺</td>
                        </tr>
                        <tr className="table-active">
                          <th colSpan="2">Toplam Tutar (₺) :</th>
                          <td className="text-end">
                            <span className="fw-semibold">
                              {formatCurrency(
                                cartItems.reduce(
                                  (acc, item) =>
                                    acc + item.quantity * item.price,
                                  0
                                )
                              )}
                            </span>{" "}
                            ₺
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* modal Delete Address */}
      <Modal
        isOpen={deletemodal}
        role="dialog"
        autoFocus={true}
        centered
        id="removeItemModal"
      >
        <ModalHeader
          toggle={() => {
            setDeleteModal(!deletemodal);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#000,secondary:#555"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Silmek istediğine emin misin?</h4>
              <p className="text-muted mx-4 mb-0">
                Bu adresi silmek istediğine emin misin? Bu islem geri alınamaz.
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => {
                setDeleteModal(!deletemodal);
              }}
            >
              İptal
            </button>
            <button
              type="button"
              className="btn w-sm btn-dark"
              onClick={() => {
                const response = axios.delete(
                  `https://marketplace.deltaservis.com.tr/api/delete-omega-customer-address/` +
                    editAddressId.address_id,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                if (response) {
                  setDeleteModal(!deletemodal);
                  setEditAddressId(null);
                  setSelectedAddress(null);
                  setUserInfo((prevState) => ({
                    ...prevState,
                    addresses: prevState.addresses.filter(
                      (address) =>
                        address.address_id !== editAddressId.address_id
                    ),
                  }));
                }
              }}
            >
              Sil
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* modal Add Address */}
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered
        id="addAddressModal"
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          {editAddressId ? "Adres Düzenle" : "YeniAdres Ekle"}
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="mb-3">
              <Label for="addaddress-Name" className="form-label">
                Adres Başlığı
              </Label>
              <Input
                type="text"
                className="form-control"
                id="addaddress-Name"
                placeholder="Enter Name"
                defaultValue={editAddressId?.title}
              />
            </div>

            <div className="mb-3">
              <Label for="addaddress-textarea" className="form-label">
                Adres
              </Label>
              <textarea
                className="form-control"
                id="addaddress-textarea"
                placeholder="Enter Address"
                rows="2"
                defaultValue={editAddressId?.address}
              ></textarea>
            </div>

            <div className="mb-3">
              <Label for="state" className="form-label">
                İl
              </Label>
              <select className="form-select" id="state" data-plugin="choices">
                <option value="homeAddress">Home (7am to 10pm)</option>
                <option value="officeAddress">Office (11am to 7pm)</option>
              </select>
            </div>

            <div className="mb-3">
              <Label for="state" className="form-label">
                İlçe
              </Label>
              <select className="form-select" id="state" data-plugin="choices">
                <option value="homeAddress">Home (7am to 10pm)</option>
                <option value="officeAddress">Office (11am to 7pm)</option>
              </select>
            </div>
            <div className="mb-3">
              <Input
                type="checkbox"
                id="default-address-checkbox"
                className="form-check-input mx-1"
                defaultChecked={editAddressId?.prime}
              />
              <Label for="default-address-checkbox" className="form-label">
                <span className="text-muted fs-13">
                  Varsayılan Adresim olsun
                </span>
              </Label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setModal(!modal);
            }}
          >
            Kapat
          </button>
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => {
              editAddressId ? updateAddress() : addAddress();
              setModal(!modal);
            }}
          >
            {editAddressId ? "Güncelle" : "Oluştur"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EcommerceCheckout;
