import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb';

const Materialdesign = () => {
  document.title = "Material Design Icons | İş Ortağım - Delta Group";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Material Design" pageTitle="Icons" />
          <Row className="icon-demo-content">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title">New Icons</h4>
                  <p className="card-title-desc mb-2">Use <code>&lt;i className="mdi mdi-*-*"&gt;&lt;/i&gt;</code> class.<span className="badge bg-success">v 6.5.95</span>.</p>
                  <Row className="icon-demo-content" id="newIcons">
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-account-lock-open"></i>
                      <span>mdi-account-lock-open</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-account-lock-open-outline"></i>
                      <span>mdi-account-lock-open-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-align-horizontal-distribute"></i>
                      <span>mdi-align-horizontal-distribute</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-align-vertical-distribute"></i>
                      <span>mdi-align-vertical-distribute</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-bottom-left-bold-box"></i>
                      <span>mdi-arrow-bottom-left-bold-box</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-bottom-left-bold-box-outline"></i>
                      <span>mdi-arrow-bottom-left-bold-box-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-bottom-left-thin"></i>
                      <span>mdi-arrow-bottom-left-thin</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-bottom-right-bold-box"></i>
                      <span>mdi-arrow-bottom-right-bold-box</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-bottom-right-bold-box-outline"></i>
                      <span>mdi-arrow-bottom-right-bold-box-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-bottom-right-thin"></i>
                      <span>mdi-arrow-bottom-right-thin</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-down-thin"></i>
                      <span>mdi-arrow-down-thin</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-left-thin"></i>
                      <span>mdi-arrow-left-thin</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-right-thin"></i>
                      <span>mdi-arrow-right-thin</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-top-left-bold-box"></i>
                      <span>mdi-arrow-top-left-bold-box</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-top-left-bold-box-outline"></i>
                      <span>mdi-arrow-top-left-bold-box-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-top-left-thin"></i>
                      <span>mdi-arrow-top-left-thin</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-top-right-bold-box"></i>
                      <span>mdi-arrow-top-right-bold-box</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                      <span>mdi-arrow-top-right-bold-box-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-top-right-thin"></i>
                      <span>mdi-arrow-top-right-thin</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-arrow-up-thin"></i>
                      <span>mdi-arrow-up-thin</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-bookmark-box-multiple"></i>
                      <span>mdi-bookmark-box-multiple</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-bookmark-box-multiple-outline"></i>
                      <span>mdi-bookmark-box-multiple-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-bullhorn-variant"></i>
                      <span>mdi-bullhorn-variant</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-bullhorn-variant-outline"></i>
                      <span>mdi-bullhorn-variant-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-camera-marker"></i>
                      <span>mdi-camera-marker</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-camera-marker-outline"></i>
                      <span>mdi-camera-marker-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-camera-off-outline"></i>
                      <span>mdi-camera-off-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-candy"></i>
                      <span>mdi-candy</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-candy-off"></i>
                      <span>mdi-candy-off</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-candy-off-outline"></i>
                      <span>mdi-candy-off-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-candy-outline"></i>
                      <span>mdi-candy-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-car-clock"></i>
                      <span>mdi-car-clock</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-clock-edit"></i>
                      <span>mdi-clock-edit</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-clock-edit-outline"></i>
                      <span>mdi-clock-edit-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-cradle"></i>
                      <span>mdi-cradle</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-cradle-outline"></i>
                      <span>mdi-cradle-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-crowd"></i>
                      <span>mdi-crowd</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-currency-rupee"></i>
                      <span>mdi-currency-rupee</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-diving"></i>
                      <span>mdi-diving</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-dots-circle"></i>
                      <span>mdi-dots-circle</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-draw-pen"></i>
                      <span>mdi-draw-pen</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-elevator-passenger-off"></i>
                      <span>mdi-elevator-passenger-off</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-elevator-passenger-off-outline"></i>
                      <span>mdi-elevator-passenger-off-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-elevator-passenger-outline"></i>
                      <span>mdi-elevator-passenger-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-eye-refresh"></i>
                      <span>mdi-eye-refresh</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-eye-refresh-outline"></i>
                      <span>mdi-eye-refresh-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-file-sign"></i>
                      <span>mdi-file-sign</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-folder-check"></i>
                      <span>mdi-folder-check</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-folder-check-outline"></i>
                      <span>mdi-folder-check-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-fraction-one-half"></i>
                      <span>mdi-fraction-one-half</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-home-group-minus"></i>
                      <span>mdi-home-group-minus</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-home-group-plus"></i>
                      <span>mdi-home-group-plus</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-home-group-remove"></i>
                      <span>mdi-home-group-remove</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-human-dolly"></i>
                      <span>mdi-human-dolly</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-human-white-cane"></i>
                      <span>mdi-human-white-cane</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-ip-outline"></i>
                      <span>mdi-ip-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-key-alert"></i>
                      <span>mdi-key-alert</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-key-border-2 bg-body-secondary"></i>
                      <span>mdi-key-border-2 bg-body-secondary</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-kite"></i>
                      <span>mdi-kite</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-kite-outline"></i>
                      <span>mdi-kite-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-light-flood-down"></i>
                      <span>mdi-light-flood-down</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-light-flood-up"></i>
                      <span>mdi-light-flood-up</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-microphone-question"></i>
                      <span>mdi-microphone-question</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-microphone-question-outline"></i>
                      <span>mdi-microphone-question-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-panorama-outline"></i>
                      <span>mdi-panorama-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-panorama-sphere"></i>
                      <span>mdi-panorama-sphere</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-panorama-sphere-outline"></i>
                      <span>mdi-panorama-sphere-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-panorama-variant"></i>
                      <span>mdi-panorama-variant</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-panorama-variant-outline"></i>
                      <span>mdi-panorama-variant-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-phone-refresh"></i>
                      <span>mdi-phone-refresh</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-phone-refresh-outline"></i>
                      <span>mdi-phone-refresh-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-phone-sync"></i>
                      <span>mdi-phone-sync</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-phone-sync-outline"></i>
                      <span>mdi-phone-sync-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-pliers"></i>
                      <span>mdi-pliers</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-razor-double-edge"></i>
                      <span>mdi-razor-double-edge</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-razor-single-edge"></i>
                      <span>mdi-razor-single-edge</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-rotate-360"></i>
                      <span>mdi-rotate-360</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-scale-unbalanced"></i>
                      <span>mdi-scale-unbalanced</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-shield-lock-open"></i>
                      <span>mdi-shield-lock-open</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-shield-lock-open-outline"></i>
                      <span>mdi-shield-lock-open-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-sitemap-outline"></i>
                      <span>mdi-sitemap-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-sprinkler-fire"></i>
                      <span>mdi-sprinkler-fire</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-sun-compass"></i>
                      <span>mdi-sun-compass</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-tab-search"></i>
                      <span>mdi-tab-search</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-timer-sand-complete"></i>
                      <span>mdi-timer-sand-complete</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-timer-sand-paused"></i>
                      <span>mdi-timer-sand-paused</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-truck-minus"></i>
                      <span>mdi-truck-minus</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-truck-minus-outline"></i>
                      <span>mdi-truck-minus-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-truck-plus"></i>
                      <span>mdi-truck-plus</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-truck-plus-outline"></i>
                      <span>mdi-truck-plus-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-truck-remove"></i>
                      <span>mdi-truck-remove</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-truck-remove-outline"></i>
                      <span>mdi-truck-remove-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-truck-snowflake"></i>
                      <span>mdi-truck-snowflake</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-vacuum"></i>
                      <span>mdi-vacuum</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-vacuum-outline"></i>
                      <span>mdi-vacuum-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-video-marker"></i>
                      <span>mdi-video-marker</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-video-marker-outline"></i>
                      <span>mdi-video-marker-outline</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-wind-turbine-alert"></i>
                      <span>mdi-wind-turbine-alert</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-wind-turbine-check"></i>
                      <span>mdi-wind-turbine-check</span>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-wrench-clock"></i>
                      <span>mdi-wrench-clock</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">All Icons</h4>
                  <Row className="icon-demo-content" id="icons">
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ab-testing"></i><span>mdi-ab-testing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-abacus"></i><span>mdi-abacus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-abjad-arabic"></i><span>mdi-abjad-arabic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-abjad-hebrew"></i><span>mdi-abjad-hebrew</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-abugida-devanagari"></i><span>mdi-abugida-devanagari</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-abugida-thai"></i><span>mdi-abugida-thai</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-access-point"></i><span>mdi-access-point</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-access-point-check"></i><span>mdi-access-point-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-access-point-minus"></i><span>mdi-access-point-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-access-point-network"></i><span>mdi-access-point-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-access-point-network-off"></i><span>mdi-access-point-network-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-access-point-off"></i><span>mdi-access-point-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-access-point-plus"></i><span>mdi-access-point-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-access-point-remove"></i><span>mdi-access-point-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account"></i><span>mdi-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-alert"></i><span>mdi-account-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-border-2 bg-body-secondary"></i><span>mdi-account-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-arrow-down"></i><span>mdi-account-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-arrow-down-outline"></i><span>mdi-account-arrow-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-arrow-left"></i><span>mdi-account-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-arrow-left-outline"></i><span>mdi-account-arrow-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-arrow-right"></i><span>mdi-account-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-arrow-right-outline"></i><span>mdi-account-arrow-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-arrow-up"></i><span>mdi-account-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-arrow-up-outline"></i><span>mdi-account-arrow-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-box"></i><span>mdi-account-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-box-multiple"></i><span>mdi-account-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-box-multiple-outline"></i><span>mdi-account-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-box-outline"></i><span>mdi-account-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-cancel"></i><span>mdi-account-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-cancel-outline"></i><span>mdi-account-cancel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-cash"></i><span>mdi-account-cash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-cash-outline"></i><span>mdi-account-cash-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-check"></i><span>mdi-account-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-check-outline"></i><span>mdi-account-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-child"></i><span>mdi-account-child</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-child-circle"></i><span>mdi-account-child-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-child-outline"></i><span>mdi-account-child-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-circle"></i><span>mdi-account-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-circle-outline"></i><span>mdi-account-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-clock"></i><span>mdi-account-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-clock-outline"></i><span>mdi-account-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-cog"></i><span>mdi-account-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-cog-outline"></i><span>mdi-account-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-convert"></i><span>mdi-account-convert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-convert-outline"></i><span>mdi-account-convert-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-cowboy-hat"></i><span>mdi-account-cowboy-hat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-cowboy-hat-outline"></i><span>mdi-account-cowboy-hat-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-details"></i><span>mdi-account-details</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-details-outline"></i><span>mdi-account-details-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-edit"></i><span>mdi-account-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-edit-outline"></i><span>mdi-account-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-eye"></i><span>mdi-account-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-eye-outline"></i><span>mdi-account-eye-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-filter"></i><span>mdi-account-filter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-filter-outline"></i><span>mdi-account-filter-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-group"></i><span>mdi-account-group</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-group-outline"></i><span>mdi-account-group-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-hard-hat"></i><span>mdi-account-hard-hat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-heart"></i><span>mdi-account-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-heart-outline"></i><span>mdi-account-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-injury"></i><span>mdi-account-injury</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-injury-outline"></i><span>mdi-account-injury-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-key"></i><span>mdi-account-key</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-key-outline"></i><span>mdi-account-key-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-lock"></i><span>mdi-account-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-lock-open"></i><span>mdi-account-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-lock-open-outline"></i><span>mdi-account-lock-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-lock-outline"></i><span>mdi-account-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-minus"></i><span>mdi-account-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-minus-outline"></i><span>mdi-account-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple"></i><span>mdi-account-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple-check"></i><span>mdi-account-multiple-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple-check-outline"></i><span>mdi-account-multiple-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple-minus"></i><span>mdi-account-multiple-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple-minus-outline"></i><span>mdi-account-multiple-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple-outline"></i><span>mdi-account-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple-plus"></i><span>mdi-account-multiple-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple-plus-outline"></i><span>mdi-account-multiple-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple-remove"></i><span>mdi-account-multiple-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-multiple-remove-outline"></i><span>mdi-account-multiple-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-music"></i><span>mdi-account-music</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-music-outline"></i><span>mdi-account-music-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-network"></i><span>mdi-account-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-network-outline"></i><span>mdi-account-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-off"></i><span>mdi-account-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-off-outline"></i><span>mdi-account-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-outline"></i><span>mdi-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-plus"></i><span>mdi-account-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-plus-outline"></i><span>mdi-account-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-question"></i><span>mdi-account-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-question-outline"></i><span>mdi-account-question-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-reactivate"></i><span>mdi-account-reactivate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-reactivate-outline"></i><span>mdi-account-reactivate-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-remove"></i><span>mdi-account-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-remove-outline"></i><span>mdi-account-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-search"></i><span>mdi-account-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-search-outline"></i><span>mdi-account-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-settings"></i><span>mdi-account-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-settings-outline"></i><span>mdi-account-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-star"></i><span>mdi-account-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-star-outline"></i><span>mdi-account-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-supervisor"></i><span>mdi-account-supervisor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-supervisor-circle"></i><span>mdi-account-supervisor-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-supervisor-circle-outline"></i><span>mdi-account-supervisor-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-supervisor-outline"></i><span>mdi-account-supervisor-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-switch"></i><span>mdi-account-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-switch-outline"></i><span>mdi-account-switch-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-sync"></i><span>mdi-account-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-sync-outline"></i><span>mdi-account-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-tie"></i><span>mdi-account-tie</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-tie-hat"></i><span>mdi-account-tie-hat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-tie-hat-outline"></i><span>mdi-account-tie-hat-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-tie-outline"></i><span>mdi-account-tie-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-tie-voice"></i><span>mdi-account-tie-voice</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-tie-voice-off"></i><span>mdi-account-tie-voice-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-tie-voice-off-outline"></i><span>mdi-account-tie-voice-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-tie-voice-outline"></i><span>mdi-account-tie-voice-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-voice"></i><span>mdi-account-voice</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-voice-off"></i><span>mdi-account-voice-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-wrench"></i><span>mdi-account-wrench</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-account-wrench-outline"></i><span>mdi-account-wrench-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-adjust"></i><span>mdi-adjust</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-advertisements"></i><span>mdi-advertisements</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-advertisements-off"></i><span>mdi-advertisements-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-air-conditioner"></i><span>mdi-air-conditioner</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-air-filter"></i><span>mdi-air-filter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-air-horn"></i><span>mdi-air-horn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-air-humidifier"></i><span>mdi-air-humidifier</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-air-humidifier-off"></i><span>mdi-air-humidifier-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-air-purifier"></i><span>mdi-air-purifier</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airbag"></i><span>mdi-airbag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airballoon"></i><span>mdi-airballoon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airballoon-outline"></i><span>mdi-airballoon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane"></i><span>mdi-airplane</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-alert"></i><span>mdi-airplane-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-check"></i><span>mdi-airplane-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-clock"></i><span>mdi-airplane-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-cog"></i><span>mdi-airplane-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-edit"></i><span>mdi-airplane-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-landing"></i><span>mdi-airplane-landing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-marker"></i><span>mdi-airplane-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-minus"></i><span>mdi-airplane-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-off"></i><span>mdi-airplane-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-plus"></i><span>mdi-airplane-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-remove"></i><span>mdi-airplane-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-search"></i><span>mdi-airplane-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-settings"></i><span>mdi-airplane-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airplane-takeoff"></i><span>mdi-airplane-takeoff</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-airport"></i><span>mdi-airport</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm"></i><span>mdi-alarm</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-bell"></i><span>mdi-alarm-bell</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-check"></i><span>mdi-alarm-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-light"></i><span>mdi-alarm-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-light-off"></i><span>mdi-alarm-light-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-light-off-outline"></i><span>mdi-alarm-light-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-light-outline"></i><span>mdi-alarm-light-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-multiple"></i><span>mdi-alarm-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-note"></i><span>mdi-alarm-note</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-note-off"></i><span>mdi-alarm-note-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-off"></i><span>mdi-alarm-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-panel"></i><span>mdi-alarm-panel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-panel-outline"></i><span>mdi-alarm-panel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-plus"></i><span>mdi-alarm-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alarm-snooze"></i><span>mdi-alarm-snooze</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-album"></i><span>mdi-album</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert"></i><span>mdi-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-box"></i><span>mdi-alert-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-box-outline"></i><span>mdi-alert-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-circle"></i><span>mdi-alert-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-circle-check"></i><span>mdi-alert-circle-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-circle-check-outline"></i><span>mdi-alert-circle-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-circle-outline"></i><span>mdi-alert-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-decagram"></i><span>mdi-alert-decagram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-decagram-outline"></i><span>mdi-alert-decagram-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-minus"></i><span>mdi-alert-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-minus-outline"></i><span>mdi-alert-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-octagon"></i><span>mdi-alert-octagon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-octagon-outline"></i><span>mdi-alert-octagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-octagram"></i><span>mdi-alert-octagram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-octagram-outline"></i><span>mdi-alert-octagram-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-2 bg-body-secondary"></i><span>mdi-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-plus"></i><span>mdi-alert-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-plus-outline"></i><span>mdi-alert-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-remove"></i><span>mdi-alert-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-remove-outline"></i><span>mdi-alert-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-rhombus"></i><span>mdi-alert-rhombus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alert-rhombus-outline"></i><span>mdi-alert-rhombus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alien"></i><span>mdi-alien</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alien-outline"></i><span>mdi-alien-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-align-horizontal-center"></i><span>mdi-align-horizontal-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-align-horizontal-distribute"></i><span>mdi-align-horizontal-distribute</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-align-horizontal-left"></i><span>mdi-align-horizontal-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-align-horizontal-right"></i><span>mdi-align-horizontal-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-align-vertical-bottom"></i><span>mdi-align-vertical-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-align-vertical-center"></i><span>mdi-align-vertical-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-align-vertical-distribute"></i><span>mdi-align-vertical-distribute</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-align-vertical-top"></i><span>mdi-align-vertical-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-all-inclusive"></i><span>mdi-all-inclusive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-all-inclusive-box"></i><span>mdi-all-inclusive-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-all-inclusive-box-outline"></i><span>mdi-all-inclusive-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-allergy"></i><span>mdi-allergy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha"></i><span>mdi-alpha</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-a"></i><span>mdi-alpha-a</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-a-box"></i><span>mdi-alpha-a-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-a-box-outline"></i><span>mdi-alpha-a-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-a-circle"></i><span>mdi-alpha-a-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-a-circle-outline"></i><span>mdi-alpha-a-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-b"></i><span>mdi-alpha-b</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-b-box"></i><span>mdi-alpha-b-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-b-box-outline"></i><span>mdi-alpha-b-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-b-circle"></i><span>mdi-alpha-b-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-b-circle-outline"></i><span>mdi-alpha-b-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-c"></i><span>mdi-alpha-c</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-c-box"></i><span>mdi-alpha-c-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-c-box-outline"></i><span>mdi-alpha-c-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-c-circle"></i><span>mdi-alpha-c-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-c-circle-outline"></i><span>mdi-alpha-c-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-d"></i><span>mdi-alpha-d</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-d-box"></i><span>mdi-alpha-d-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-d-box-outline"></i><span>mdi-alpha-d-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-d-circle"></i><span>mdi-alpha-d-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-d-circle-outline"></i><span>mdi-alpha-d-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-e"></i><span>mdi-alpha-e</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-e-box"></i><span>mdi-alpha-e-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-e-box-outline"></i><span>mdi-alpha-e-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-e-circle"></i><span>mdi-alpha-e-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-e-circle-outline"></i><span>mdi-alpha-e-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-f"></i><span>mdi-alpha-f</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-f-box"></i><span>mdi-alpha-f-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-f-box-outline"></i><span>mdi-alpha-f-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-f-circle"></i><span>mdi-alpha-f-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-f-circle-outline"></i><span>mdi-alpha-f-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-g"></i><span>mdi-alpha-g</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-g-box"></i><span>mdi-alpha-g-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-g-box-outline"></i><span>mdi-alpha-g-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-g-circle"></i><span>mdi-alpha-g-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-g-circle-outline"></i><span>mdi-alpha-g-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-h"></i><span>mdi-alpha-h</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-h-box"></i><span>mdi-alpha-h-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-h-box-outline"></i><span>mdi-alpha-h-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-h-circle"></i><span>mdi-alpha-h-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-h-circle-outline"></i><span>mdi-alpha-h-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-i"></i><span>mdi-alpha-i</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-i-box"></i><span>mdi-alpha-i-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-i-box-outline"></i><span>mdi-alpha-i-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-i-circle"></i><span>mdi-alpha-i-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-i-circle-outline"></i><span>mdi-alpha-i-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-j"></i><span>mdi-alpha-j</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-j-box"></i><span>mdi-alpha-j-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-j-box-outline"></i><span>mdi-alpha-j-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-j-circle"></i><span>mdi-alpha-j-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-j-circle-outline"></i><span>mdi-alpha-j-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-k"></i><span>mdi-alpha-k</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-k-box"></i><span>mdi-alpha-k-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-k-box-outline"></i><span>mdi-alpha-k-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-k-circle"></i><span>mdi-alpha-k-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-k-circle-outline"></i><span>mdi-alpha-k-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-l"></i><span>mdi-alpha-l</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-l-box"></i><span>mdi-alpha-l-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-l-box-outline"></i><span>mdi-alpha-l-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-l-circle"></i><span>mdi-alpha-l-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-l-circle-outline"></i><span>mdi-alpha-l-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-m"></i><span>mdi-alpha-m</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-m-box"></i><span>mdi-alpha-m-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-m-box-outline"></i><span>mdi-alpha-m-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-m-circle"></i><span>mdi-alpha-m-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-m-circle-outline"></i><span>mdi-alpha-m-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-n"></i><span>mdi-alpha-n</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-n-box"></i><span>mdi-alpha-n-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-n-box-outline"></i><span>mdi-alpha-n-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-n-circle"></i><span>mdi-alpha-n-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-n-circle-outline"></i><span>mdi-alpha-n-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-o"></i><span>mdi-alpha-o</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-o-box"></i><span>mdi-alpha-o-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-o-box-outline"></i><span>mdi-alpha-o-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-o-circle"></i><span>mdi-alpha-o-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-o-circle-outline"></i><span>mdi-alpha-o-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-p"></i><span>mdi-alpha-p</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-p-box"></i><span>mdi-alpha-p-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-p-box-outline"></i><span>mdi-alpha-p-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-p-circle"></i><span>mdi-alpha-p-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-p-circle-outline"></i><span>mdi-alpha-p-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-q"></i><span>mdi-alpha-q</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-q-box"></i><span>mdi-alpha-q-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-q-box-outline"></i><span>mdi-alpha-q-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-q-circle"></i><span>mdi-alpha-q-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-q-circle-outline"></i><span>mdi-alpha-q-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-r"></i><span>mdi-alpha-r</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-r-box"></i><span>mdi-alpha-r-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-r-box-outline"></i><span>mdi-alpha-r-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-r-circle"></i><span>mdi-alpha-r-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-r-circle-outline"></i><span>mdi-alpha-r-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-s"></i><span>mdi-alpha-s</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-s-box"></i><span>mdi-alpha-s-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-s-box-outline"></i><span>mdi-alpha-s-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-s-circle"></i><span>mdi-alpha-s-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-s-circle-outline"></i><span>mdi-alpha-s-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-t"></i><span>mdi-alpha-t</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-t-box"></i><span>mdi-alpha-t-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-t-box-outline"></i><span>mdi-alpha-t-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-t-circle"></i><span>mdi-alpha-t-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-t-circle-outline"></i><span>mdi-alpha-t-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-u"></i><span>mdi-alpha-u</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-u-box"></i><span>mdi-alpha-u-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-u-box-outline"></i><span>mdi-alpha-u-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-u-circle"></i><span>mdi-alpha-u-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-u-circle-outline"></i><span>mdi-alpha-u-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-v"></i><span>mdi-alpha-v</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-v-box"></i><span>mdi-alpha-v-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-v-box-outline"></i><span>mdi-alpha-v-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-v-circle"></i><span>mdi-alpha-v-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-v-circle-outline"></i><span>mdi-alpha-v-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-w"></i><span>mdi-alpha-w</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-w-box"></i><span>mdi-alpha-w-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-w-box-outline"></i><span>mdi-alpha-w-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-w-circle"></i><span>mdi-alpha-w-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-w-circle-outline"></i><span>mdi-alpha-w-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-x"></i><span>mdi-alpha-x</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-x-box"></i><span>mdi-alpha-x-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-x-box-outline"></i><span>mdi-alpha-x-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-x-circle"></i><span>mdi-alpha-x-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-x-circle-outline"></i><span>mdi-alpha-x-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-y"></i><span>mdi-alpha-y</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-y-box"></i><span>mdi-alpha-y-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-y-box-outline"></i><span>mdi-alpha-y-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-y-circle"></i><span>mdi-alpha-y-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-y-circle-outline"></i><span>mdi-alpha-y-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-z"></i><span>mdi-alpha-z</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-z-box"></i><span>mdi-alpha-z-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-z-box-outline"></i><span>mdi-alpha-z-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-z-circle"></i><span>mdi-alpha-z-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alpha-z-circle-outline"></i><span>mdi-alpha-z-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabet-aurebesh"></i><span>mdi-alphabet-aurebesh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabet-cyrillic"></i><span>mdi-alphabet-cyrillic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabet-greek"></i><span>mdi-alphabet-greek</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabet-latin"></i><span>mdi-alphabet-latin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabet-piqad"></i><span>mdi-alphabet-piqad</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabet-tengwar"></i><span>mdi-alphabet-tengwar</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabetical"></i><span>mdi-alphabetical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabetical-off"></i><span>mdi-alphabetical-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabetical-variant"></i><span>mdi-alphabetical-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-alphabetical-variant-off"></i><span>mdi-alphabetical-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-altimeter"></i><span>mdi-altimeter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ambulance"></i><span>mdi-ambulance</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ammunition"></i><span>mdi-ammunition</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ampersand"></i><span>mdi-ampersand</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-amplifier"></i><span>mdi-amplifier</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-amplifier-off"></i><span>mdi-amplifier-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-anchor"></i><span>mdi-anchor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-android"></i><span>mdi-android</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-android-messages"></i><span>mdi-android-messages</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-android-studio"></i><span>mdi-android-studio</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-angle-acute"></i><span>mdi-angle-acute</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-angle-obtuse"></i><span>mdi-angle-obtuse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-angle-right"></i><span>mdi-angle-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-angular"></i><span>mdi-angular</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-angularjs"></i><span>mdi-angularjs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-animation"></i><span>mdi-animation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-animation-outline"></i><span>mdi-animation-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-animation-play"></i><span>mdi-animation-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-animation-play-outline"></i><span>mdi-animation-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ansible"></i><span>mdi-ansible</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-antenna"></i><span>mdi-antenna</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-anvil"></i><span>mdi-anvil</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apache-kafka"></i><span>mdi-apache-kafka</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-api"></i><span>mdi-api</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-api-off"></i><span>mdi-api-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple"></i><span>mdi-apple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple-finder"></i><span>mdi-apple-finder</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple-icloud"></i><span>mdi-apple-icloud</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple-ios"></i><span>mdi-apple-ios</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple-keyboard-caps"></i><span>mdi-apple-keyboard-caps</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple-keyboard-command"></i><span>mdi-apple-keyboard-command</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple-keyboard-control"></i><span>mdi-apple-keyboard-control</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple-keyboard-option"></i><span>mdi-apple-keyboard-option</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple-keyboard-shift"></i><span>mdi-apple-keyboard-shift</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apple-safari"></i><span>mdi-apple-safari</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application"></i><span>mdi-application</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-array"></i><span>mdi-application-array</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-array-outline"></i><span>mdi-application-array-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-braces"></i><span>mdi-application-braces</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-braces-outline"></i><span>mdi-application-braces-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-brackets"></i><span>mdi-application-brackets</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-brackets-outline"></i><span>mdi-application-brackets-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-cog"></i><span>mdi-application-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-cog-outline"></i><span>mdi-application-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-edit"></i><span>mdi-application-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-edit-outline"></i><span>mdi-application-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-export"></i><span>mdi-application-export</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-import"></i><span>mdi-application-import</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-outline"></i><span>mdi-application-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-parentheses"></i><span>mdi-application-parentheses</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-parentheses-outline"></i><span>mdi-application-parentheses-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-settings"></i><span>mdi-application-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-settings-outline"></i><span>mdi-application-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-variable"></i><span>mdi-application-variable</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-application-variable-outline"></i><span>mdi-application-variable-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-approximately-equal"></i><span>mdi-approximately-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-approximately-equal-box"></i><span>mdi-approximately-equal-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apps"></i><span>mdi-apps</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-apps-box"></i><span>mdi-apps-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arch"></i><span>mdi-arch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive"></i><span>mdi-archive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-alert"></i><span>mdi-archive-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-border-2 bg-body-secondary"></i><span>mdi-archive-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-arrow-down"></i><span>mdi-archive-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-arrow-down-outline"></i><span>mdi-archive-arrow-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-arrow-up"></i><span>mdi-archive-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-arrow-up-outline"></i><span>mdi-archive-arrow-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-cancel"></i><span>mdi-archive-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-cancel-outline"></i><span>mdi-archive-cancel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-check"></i><span>mdi-archive-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-check-outline"></i><span>mdi-archive-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-clock"></i><span>mdi-archive-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-clock-outline"></i><span>mdi-archive-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-cog"></i><span>mdi-archive-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-cog-outline"></i><span>mdi-archive-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-edit"></i><span>mdi-archive-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-edit-outline"></i><span>mdi-archive-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-eye"></i><span>mdi-archive-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-eye-outline"></i><span>mdi-archive-eye-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-lock"></i><span>mdi-archive-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-lock-open"></i><span>mdi-archive-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-lock-open-outline"></i><span>mdi-archive-lock-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-lock-outline"></i><span>mdi-archive-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-marker"></i><span>mdi-archive-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-marker-outline"></i><span>mdi-archive-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-minus"></i><span>mdi-archive-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-minus-outline"></i><span>mdi-archive-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-music"></i><span>mdi-archive-music</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-music-outline"></i><span>mdi-archive-music-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-off"></i><span>mdi-archive-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-off-outline"></i><span>mdi-archive-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-outline"></i><span>mdi-archive-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-plus"></i><span>mdi-archive-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-plus-outline"></i><span>mdi-archive-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-refresh"></i><span>mdi-archive-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-refresh-outline"></i><span>mdi-archive-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-remove"></i><span>mdi-archive-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-remove-outline"></i><span>mdi-archive-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-search"></i><span>mdi-archive-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-search-outline"></i><span>mdi-archive-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-settings"></i><span>mdi-archive-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-settings-outline"></i><span>mdi-archive-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-star"></i><span>mdi-archive-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-star-outline"></i><span>mdi-archive-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-sync"></i><span>mdi-archive-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-archive-sync-outline"></i><span>mdi-archive-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arm-flex"></i><span>mdi-arm-flex</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arm-flex-outline"></i><span>mdi-arm-flex-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrange-bring-forward"></i><span>mdi-arrange-bring-forward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrange-bring-to-front"></i><span>mdi-arrange-bring-to-front</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrange-send-backward"></i><span>mdi-arrange-send-backward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrange-send-to-back"></i><span>mdi-arrange-send-to-back</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-all"></i><span>mdi-arrow-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-left"></i><span>mdi-arrow-bottom-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-left-bold-box"></i><span>mdi-arrow-bottom-left-bold-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-left-bold-box-outline"></i><span>mdi-arrow-bottom-left-bold-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-left-bold-outline"></i><span>mdi-arrow-bottom-left-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-left-thick"></i><span>mdi-arrow-bottom-left-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-left-thin"></i><span>mdi-arrow-bottom-left-thin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-left-thin-circle-outline"></i><span>mdi-arrow-bottom-left-thin-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-right"></i><span>mdi-arrow-bottom-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-right-bold-box"></i><span>mdi-arrow-bottom-right-bold-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-right-bold-box-outline"></i><span>mdi-arrow-bottom-right-bold-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-right-bold-outline"></i><span>mdi-arrow-bottom-right-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-right-thick"></i><span>mdi-arrow-bottom-right-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-right-thin"></i><span>mdi-arrow-bottom-right-thin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-bottom-right-thin-circle-outline"></i><span>mdi-arrow-bottom-right-thin-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-collapse"></i><span>mdi-arrow-collapse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-collapse-all"></i><span>mdi-arrow-collapse-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-collapse-down"></i><span>mdi-arrow-collapse-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-collapse-horizontal"></i><span>mdi-arrow-collapse-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-collapse-left"></i><span>mdi-arrow-collapse-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-collapse-right"></i><span>mdi-arrow-collapse-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-collapse-up"></i><span>mdi-arrow-collapse-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-collapse-vertical"></i><span>mdi-arrow-collapse-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-decision"></i><span>mdi-arrow-decision</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-decision-auto"></i><span>mdi-arrow-decision-auto</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-decision-auto-outline"></i><span>mdi-arrow-decision-auto-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-decision-outline"></i><span>mdi-arrow-decision-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down"></i><span>mdi-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-bold"></i><span>mdi-arrow-down-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-bold-box"></i><span>mdi-arrow-down-bold-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-bold-box-outline"></i><span>mdi-arrow-down-bold-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-bold-circle"></i><span>mdi-arrow-down-bold-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-bold-circle-outline"></i><span>mdi-arrow-down-bold-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-bold-hexagon-outline"></i><span>mdi-arrow-down-bold-hexagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-bold-outline"></i><span>mdi-arrow-down-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-box"></i><span>mdi-arrow-down-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-circle"></i><span>mdi-arrow-down-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-circle-outline"></i><span>mdi-arrow-down-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-drop-circle"></i><span>mdi-arrow-down-drop-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-drop-circle-outline"></i><span>mdi-arrow-down-drop-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-left"></i><span>mdi-arrow-down-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-left-bold"></i><span>mdi-arrow-down-left-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-right"></i><span>mdi-arrow-down-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-right-bold"></i><span>mdi-arrow-down-right-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-thick"></i><span>mdi-arrow-down-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-thin"></i><span>mdi-arrow-down-thin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-down-thin-circle-outline"></i><span>mdi-arrow-down-thin-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-expand"></i><span>mdi-arrow-expand</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-expand-all"></i><span>mdi-arrow-expand-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-expand-down"></i><span>mdi-arrow-expand-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-expand-horizontal"></i><span>mdi-arrow-expand-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-expand-left"></i><span>mdi-arrow-expand-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-expand-right"></i><span>mdi-arrow-expand-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-expand-up"></i><span>mdi-arrow-expand-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-expand-vertical"></i><span>mdi-arrow-expand-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-horizontal-lock"></i><span>mdi-arrow-horizontal-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left"></i><span>mdi-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-bold"></i><span>mdi-arrow-left-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-bold-box"></i><span>mdi-arrow-left-bold-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-bold-box-outline"></i><span>mdi-arrow-left-bold-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-bold-circle"></i><span>mdi-arrow-left-bold-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-bold-circle-outline"></i><span>mdi-arrow-left-bold-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-bold-hexagon-outline"></i><span>mdi-arrow-left-bold-hexagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-bold-outline"></i><span>mdi-arrow-left-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-bottom"></i><span>mdi-arrow-left-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-bottom-bold"></i><span>mdi-arrow-left-bottom-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-box"></i><span>mdi-arrow-left-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-circle"></i><span>mdi-arrow-left-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-circle-outline"></i><span>mdi-arrow-left-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-drop-circle"></i><span>mdi-arrow-left-drop-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-drop-circle-outline"></i><span>mdi-arrow-left-drop-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-right"></i><span>mdi-arrow-left-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-right-bold"></i><span>mdi-arrow-left-right-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-right-bold-outline"></i><span>mdi-arrow-left-right-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-thick"></i><span>mdi-arrow-left-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-thin"></i><span>mdi-arrow-left-thin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-thin-circle-outline"></i><span>mdi-arrow-left-thin-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-top"></i><span>mdi-arrow-left-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-left-top-bold"></i><span>mdi-arrow-left-top-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-projectile"></i><span>mdi-arrow-projectile</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-projectile-multiple"></i><span>mdi-arrow-projectile-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right"></i><span>mdi-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-bold"></i><span>mdi-arrow-right-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-bold-box"></i><span>mdi-arrow-right-bold-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-bold-box-outline"></i><span>mdi-arrow-right-bold-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-bold-circle"></i><span>mdi-arrow-right-bold-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-bold-circle-outline"></i><span>mdi-arrow-right-bold-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-bold-hexagon-outline"></i><span>mdi-arrow-right-bold-hexagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-bold-outline"></i><span>mdi-arrow-right-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-bottom"></i><span>mdi-arrow-right-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-bottom-bold"></i><span>mdi-arrow-right-bottom-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-box"></i><span>mdi-arrow-right-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-circle"></i><span>mdi-arrow-right-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-circle-outline"></i><span>mdi-arrow-right-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-drop-circle"></i><span>mdi-arrow-right-drop-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-drop-circle-outline"></i><span>mdi-arrow-right-drop-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-thick"></i><span>mdi-arrow-right-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-thin"></i><span>mdi-arrow-right-thin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-thin-circle-outline"></i><span>mdi-arrow-right-thin-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-top"></i><span>mdi-arrow-right-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-right-top-bold"></i><span>mdi-arrow-right-top-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-split-horizontal"></i><span>mdi-arrow-split-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-split-vertical"></i><span>mdi-arrow-split-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-left"></i><span>mdi-arrow-top-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-left-bold-box"></i><span>mdi-arrow-top-left-bold-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-left-bold-box-outline"></i><span>mdi-arrow-top-left-bold-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-left-bold-outline"></i><span>mdi-arrow-top-left-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-left-bottom-right"></i><span>mdi-arrow-top-left-bottom-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-left-bottom-right-bold"></i><span>mdi-arrow-top-left-bottom-right-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-left-thick"></i><span>mdi-arrow-top-left-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-left-thin"></i><span>mdi-arrow-top-left-thin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-left-thin-circle-outline"></i><span>mdi-arrow-top-left-thin-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-right"></i><span>mdi-arrow-top-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-right-bold-box"></i><span>mdi-arrow-top-right-bold-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-right-bold-box-outline"></i><span>mdi-arrow-top-right-bold-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-right-bold-outline"></i><span>mdi-arrow-top-right-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-right-bottom-left"></i><span>mdi-arrow-top-right-bottom-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-right-bottom-left-bold"></i><span>mdi-arrow-top-right-bottom-left-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-right-thick"></i><span>mdi-arrow-top-right-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-right-thin"></i><span>mdi-arrow-top-right-thin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-top-right-thin-circle-outline"></i><span>mdi-arrow-top-right-thin-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-down-left"></i><span>mdi-arrow-u-down-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-down-left-bold"></i><span>mdi-arrow-u-down-left-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-down-right"></i><span>mdi-arrow-u-down-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-down-right-bold"></i><span>mdi-arrow-u-down-right-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-left-bottom"></i><span>mdi-arrow-u-left-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-left-bottom-bold"></i><span>mdi-arrow-u-left-bottom-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-left-top"></i><span>mdi-arrow-u-left-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-left-top-bold"></i><span>mdi-arrow-u-left-top-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-right-bottom"></i><span>mdi-arrow-u-right-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-right-bottom-bold"></i><span>mdi-arrow-u-right-bottom-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-right-top"></i><span>mdi-arrow-u-right-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-right-top-bold"></i><span>mdi-arrow-u-right-top-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-up-left"></i><span>mdi-arrow-u-up-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-up-left-bold"></i><span>mdi-arrow-u-up-left-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-up-right"></i><span>mdi-arrow-u-up-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-u-up-right-bold"></i><span>mdi-arrow-u-up-right-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up"></i><span>mdi-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-bold"></i><span>mdi-arrow-up-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-bold-box"></i><span>mdi-arrow-up-bold-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-bold-box-outline"></i><span>mdi-arrow-up-bold-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-bold-circle"></i><span>mdi-arrow-up-bold-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-bold-circle-outline"></i><span>mdi-arrow-up-bold-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-bold-hexagon-outline"></i><span>mdi-arrow-up-bold-hexagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-bold-outline"></i><span>mdi-arrow-up-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-box"></i><span>mdi-arrow-up-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-circle"></i><span>mdi-arrow-up-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-circle-outline"></i><span>mdi-arrow-up-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-down"></i><span>mdi-arrow-up-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-down-bold"></i><span>mdi-arrow-up-down-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-down-bold-outline"></i><span>mdi-arrow-up-down-bold-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-drop-circle"></i><span>mdi-arrow-up-drop-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-drop-circle-outline"></i><span>mdi-arrow-up-drop-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-left"></i><span>mdi-arrow-up-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-left-bold"></i><span>mdi-arrow-up-left-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-right"></i><span>mdi-arrow-up-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-right-bold"></i><span>mdi-arrow-up-right-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-thick"></i><span>mdi-arrow-up-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-thin"></i><span>mdi-arrow-up-thin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-up-thin-circle-outline"></i><span>mdi-arrow-up-thin-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-arrow-vertical-lock"></i><span>mdi-arrow-vertical-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-artstation"></i><span>mdi-artstation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-aspect-ratio"></i><span>mdi-aspect-ratio</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-assistant"></i><span>mdi-assistant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-asterisk"></i><span>mdi-asterisk</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-at"></i><span>mdi-at</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-atlassian"></i><span>mdi-atlassian</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-atm"></i><span>mdi-atm</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-atom"></i><span>mdi-atom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-atom-variant"></i><span>mdi-atom-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-attachment"></i><span>mdi-attachment</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-audio-input-rca"></i><span>mdi-audio-input-rca</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-audio-input-stereo-minijack"></i><span>mdi-audio-input-stereo-minijack</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-audio-input-xlr"></i><span>mdi-audio-input-xlr</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-audio-video"></i><span>mdi-audio-video</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-audio-video-off"></i><span>mdi-audio-video-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-augmented-reality"></i><span>mdi-augmented-reality</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-auto-download"></i><span>mdi-auto-download</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-auto-fix"></i><span>mdi-auto-fix</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-auto-upload"></i><span>mdi-auto-upload</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-autorenew"></i><span>mdi-autorenew</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-av-timer"></i><span>mdi-av-timer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-aws"></i><span>mdi-aws</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axe"></i><span>mdi-axe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axe-battle"></i><span>mdi-axe-battle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis"></i><span>mdi-axis</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-arrow"></i><span>mdi-axis-arrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-arrow-info"></i><span>mdi-axis-arrow-info</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-arrow-lock"></i><span>mdi-axis-arrow-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-lock"></i><span>mdi-axis-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-x-arrow"></i><span>mdi-axis-x-arrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-x-arrow-lock"></i><span>mdi-axis-x-arrow-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-x-rotate-clockwise"></i><span>mdi-axis-x-rotate-clockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-x-rotate-counterclockwise"></i><span>mdi-axis-x-rotate-counterclockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-x-y-arrow-lock"></i><span>mdi-axis-x-y-arrow-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-y-arrow"></i><span>mdi-axis-y-arrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-y-arrow-lock"></i><span>mdi-axis-y-arrow-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-y-rotate-clockwise"></i><span>mdi-axis-y-rotate-clockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-y-rotate-counterclockwise"></i><span>mdi-axis-y-rotate-counterclockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-z-arrow"></i><span>mdi-axis-z-arrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-z-arrow-lock"></i><span>mdi-axis-z-arrow-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-z-rotate-clockwise"></i><span>mdi-axis-z-rotate-clockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-axis-z-rotate-counterclockwise"></i><span>mdi-axis-z-rotate-counterclockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-babel"></i><span>mdi-babel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baby"></i><span>mdi-baby</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baby-bottle"></i><span>mdi-baby-bottle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baby-bottle-outline"></i><span>mdi-baby-bottle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baby-buggy"></i><span>mdi-baby-buggy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baby-carriage"></i><span>mdi-baby-carriage</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baby-carriage-off"></i><span>mdi-baby-carriage-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baby-face"></i><span>mdi-baby-face</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baby-face-outline"></i><span>mdi-baby-face-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-backburger"></i><span>mdi-backburger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-backspace"></i><span>mdi-backspace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-backspace-outline"></i><span>mdi-backspace-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-backspace-reverse"></i><span>mdi-backspace-reverse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-backspace-reverse-outline"></i><span>mdi-backspace-reverse-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-backup-restore"></i><span>mdi-backup-restore</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bacteria"></i><span>mdi-bacteria</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bacteria-outline"></i><span>mdi-bacteria-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-badge-account"></i><span>mdi-badge-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-badge-account-alert"></i><span>mdi-badge-account-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-badge-account-border-2 bg-body-secondary"></i><span>mdi-badge-account-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-badge-account-horizontal"></i><span>mdi-badge-account-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-badge-account-horizontal-outline"></i><span>mdi-badge-account-horizontal-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-badge-account-outline"></i><span>mdi-badge-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-badminton"></i><span>mdi-badminton</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-carry-on"></i><span>mdi-bag-carry-on</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-carry-on-check"></i><span>mdi-bag-carry-on-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-carry-on-off"></i><span>mdi-bag-carry-on-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-checked"></i><span>mdi-bag-checked</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-personal"></i><span>mdi-bag-personal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-personal-off"></i><span>mdi-bag-personal-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-personal-off-outline"></i><span>mdi-bag-personal-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-personal-outline"></i><span>mdi-bag-personal-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-suitcase"></i><span>mdi-bag-suitcase</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-suitcase-off"></i><span>mdi-bag-suitcase-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-suitcase-off-outline"></i><span>mdi-bag-suitcase-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bag-suitcase-outline"></i><span>mdi-bag-suitcase-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baguette"></i><span>mdi-baguette</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-balcony"></i><span>mdi-balcony</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-balloon"></i><span>mdi-balloon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ballot"></i><span>mdi-ballot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ballot-outline"></i><span>mdi-ballot-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ballot-recount"></i><span>mdi-ballot-recount</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ballot-recount-outline"></i><span>mdi-ballot-recount-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bandage"></i><span>mdi-bandage</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank"></i><span>mdi-bank</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-check"></i><span>mdi-bank-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-minus"></i><span>mdi-bank-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-off"></i><span>mdi-bank-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-off-outline"></i><span>mdi-bank-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-outline"></i><span>mdi-bank-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-plus"></i><span>mdi-bank-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-remove"></i><span>mdi-bank-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-transfer"></i><span>mdi-bank-transfer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-transfer-in"></i><span>mdi-bank-transfer-in</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bank-transfer-out"></i><span>mdi-bank-transfer-out</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-barcode"></i><span>mdi-barcode</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-barcode-off"></i><span>mdi-barcode-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-barcode-scan"></i><span>mdi-barcode-scan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-barley"></i><span>mdi-barley</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-barley-off"></i><span>mdi-barley-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-barn"></i><span>mdi-barn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-barrel"></i><span>mdi-barrel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baseball"></i><span>mdi-baseball</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baseball-bat"></i><span>mdi-baseball-bat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baseball-diamond"></i><span>mdi-baseball-diamond</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-baseball-diamond-outline"></i><span>mdi-baseball-diamond-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bash"></i><span>mdi-bash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket"></i><span>mdi-basket</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-check"></i><span>mdi-basket-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-check-outline"></i><span>mdi-basket-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-fill"></i><span>mdi-basket-fill</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-minus"></i><span>mdi-basket-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-minus-outline"></i><span>mdi-basket-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-off"></i><span>mdi-basket-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-off-outline"></i><span>mdi-basket-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-outline"></i><span>mdi-basket-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-plus"></i><span>mdi-basket-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-plus-outline"></i><span>mdi-basket-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-remove"></i><span>mdi-basket-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-remove-outline"></i><span>mdi-basket-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basket-unfill"></i><span>mdi-basket-unfill</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basketball"></i><span>mdi-basketball</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basketball-hoop"></i><span>mdi-basketball-hoop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-basketball-hoop-outline"></i><span>mdi-basketball-hoop-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bat"></i><span>mdi-bat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bathtub"></i><span>mdi-bathtub</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bathtub-outline"></i><span>mdi-bathtub-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery"></i><span>mdi-battery</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-10"></i><span>mdi-battery-10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-10-bluetooth"></i><span>mdi-battery-10-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-20"></i><span>mdi-battery-20</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-20-bluetooth"></i><span>mdi-battery-20-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-30"></i><span>mdi-battery-30</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-30-bluetooth"></i><span>mdi-battery-30-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-40"></i><span>mdi-battery-40</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-40-bluetooth"></i><span>mdi-battery-40-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-50"></i><span>mdi-battery-50</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-50-bluetooth"></i><span>mdi-battery-50-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-60"></i><span>mdi-battery-60</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-60-bluetooth"></i><span>mdi-battery-60-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-70"></i><span>mdi-battery-70</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-70-bluetooth"></i><span>mdi-battery-70-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-80"></i><span>mdi-battery-80</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-80-bluetooth"></i><span>mdi-battery-80-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-90"></i><span>mdi-battery-90</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-90-bluetooth"></i><span>mdi-battery-90-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-alert"></i><span>mdi-battery-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-alert-bluetooth"></i><span>mdi-battery-alert-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-alert-variant"></i><span>mdi-battery-alert-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-alert-variant-outline"></i><span>mdi-battery-alert-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-arrow-down"></i><span>mdi-battery-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-arrow-down-outline"></i><span>mdi-battery-arrow-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-arrow-up"></i><span>mdi-battery-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-arrow-up-outline"></i><span>mdi-battery-arrow-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-bluetooth"></i><span>mdi-battery-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-bluetooth-variant"></i><span>mdi-battery-bluetooth-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging"></i><span>mdi-battery-charging</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-10"></i><span>mdi-battery-charging-10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-100"></i><span>mdi-battery-charging-100</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-20"></i><span>mdi-battery-charging-20</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-30"></i><span>mdi-battery-charging-30</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-40"></i><span>mdi-battery-charging-40</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-50"></i><span>mdi-battery-charging-50</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-60"></i><span>mdi-battery-charging-60</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-70"></i><span>mdi-battery-charging-70</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-80"></i><span>mdi-battery-charging-80</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-90"></i><span>mdi-battery-charging-90</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-high"></i><span>mdi-battery-charging-high</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-low"></i><span>mdi-battery-charging-low</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-medium"></i><span>mdi-battery-charging-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-outline"></i><span>mdi-battery-charging-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless"></i><span>mdi-battery-charging-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-10"></i><span>mdi-battery-charging-wireless-10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-20"></i><span>mdi-battery-charging-wireless-20</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-30"></i><span>mdi-battery-charging-wireless-30</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-40"></i><span>mdi-battery-charging-wireless-40</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-50"></i><span>mdi-battery-charging-wireless-50</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-60"></i><span>mdi-battery-charging-wireless-60</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-70"></i><span>mdi-battery-charging-wireless-70</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-80"></i><span>mdi-battery-charging-wireless-80</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-90"></i><span>mdi-battery-charging-wireless-90</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-alert"></i><span>mdi-battery-charging-wireless-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-charging-wireless-outline"></i><span>mdi-battery-charging-wireless-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-check"></i><span>mdi-battery-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-check-outline"></i><span>mdi-battery-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-heart"></i><span>mdi-battery-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-heart-outline"></i><span>mdi-battery-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-heart-variant"></i><span>mdi-battery-heart-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-high"></i><span>mdi-battery-high</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-lock"></i><span>mdi-battery-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-lock-open"></i><span>mdi-battery-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-low"></i><span>mdi-battery-low</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-medium"></i><span>mdi-battery-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-minus"></i><span>mdi-battery-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-minus-outline"></i><span>mdi-battery-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-minus-variant"></i><span>mdi-battery-minus-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-negative"></i><span>mdi-battery-negative</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-off"></i><span>mdi-battery-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-off-outline"></i><span>mdi-battery-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-outline"></i><span>mdi-battery-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-plus"></i><span>mdi-battery-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-plus-outline"></i><span>mdi-battery-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-plus-variant"></i><span>mdi-battery-plus-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-positive"></i><span>mdi-battery-positive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-remove"></i><span>mdi-battery-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-remove-outline"></i><span>mdi-battery-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-sync"></i><span>mdi-battery-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-sync-outline"></i><span>mdi-battery-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-unknown"></i><span>mdi-battery-unknown</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-battery-unknown-bluetooth"></i><span>mdi-battery-unknown-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beach"></i><span>mdi-beach</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker"></i><span>mdi-beaker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-alert"></i><span>mdi-beaker-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-border-2 bg-body-secondary"></i><span>mdi-beaker-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-check"></i><span>mdi-beaker-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-check-outline"></i><span>mdi-beaker-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-minus"></i><span>mdi-beaker-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-minus-outline"></i><span>mdi-beaker-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-outline"></i><span>mdi-beaker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-plus"></i><span>mdi-beaker-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-plus-outline"></i><span>mdi-beaker-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-question"></i><span>mdi-beaker-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-question-outline"></i><span>mdi-beaker-question-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-remove"></i><span>mdi-beaker-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beaker-remove-outline"></i><span>mdi-beaker-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed"></i><span>mdi-bed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-double"></i><span>mdi-bed-double</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-double-outline"></i><span>mdi-bed-double-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-empty"></i><span>mdi-bed-empty</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-king"></i><span>mdi-bed-king</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-king-outline"></i><span>mdi-bed-king-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-outline"></i><span>mdi-bed-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-queen"></i><span>mdi-bed-queen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-queen-outline"></i><span>mdi-bed-queen-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-single"></i><span>mdi-bed-single</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bed-single-outline"></i><span>mdi-bed-single-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bee"></i><span>mdi-bee</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bee-flower"></i><span>mdi-bee-flower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beehive-off-outline"></i><span>mdi-beehive-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beehive-outline"></i><span>mdi-beehive-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beekeeper"></i><span>mdi-beekeeper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beer"></i><span>mdi-beer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beer-outline"></i><span>mdi-beer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell"></i><span>mdi-bell</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-alert"></i><span>mdi-bell-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-border-2 bg-body-secondary"></i><span>mdi-bell-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-badge"></i><span>mdi-bell-badge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-badge-outline"></i><span>mdi-bell-badge-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-cancel"></i><span>mdi-bell-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-cancel-outline"></i><span>mdi-bell-cancel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-check"></i><span>mdi-bell-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-check-outline"></i><span>mdi-bell-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-circle"></i><span>mdi-bell-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-circle-outline"></i><span>mdi-bell-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-minus"></i><span>mdi-bell-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-minus-outline"></i><span>mdi-bell-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-off"></i><span>mdi-bell-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-off-outline"></i><span>mdi-bell-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-outline"></i><span>mdi-bell-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-plus"></i><span>mdi-bell-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-plus-outline"></i><span>mdi-bell-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-remove"></i><span>mdi-bell-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-remove-outline"></i><span>mdi-bell-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-ring"></i><span>mdi-bell-ring</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-ring-outline"></i><span>mdi-bell-ring-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-sleep"></i><span>mdi-bell-sleep</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bell-sleep-outline"></i><span>mdi-bell-sleep-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-beta"></i><span>mdi-beta</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-betamax"></i><span>mdi-betamax</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-biathlon"></i><span>mdi-biathlon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bicycle"></i><span>mdi-bicycle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bicycle-basket"></i><span>mdi-bicycle-basket</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bicycle-cargo"></i><span>mdi-bicycle-cargo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bicycle-electric"></i><span>mdi-bicycle-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bicycle-penny-farthing"></i><span>mdi-bicycle-penny-farthing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bike"></i><span>mdi-bike</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bike-fast"></i><span>mdi-bike-fast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-billboard"></i><span>mdi-billboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-billiards"></i><span>mdi-billiards</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-billiards-rack"></i><span>mdi-billiards-rack</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-binoculars"></i><span>mdi-binoculars</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bio"></i><span>mdi-bio</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-biohazard"></i><span>mdi-biohazard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bird"></i><span>mdi-bird</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bitbucket"></i><span>mdi-bitbucket</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bitcoin"></i><span>mdi-bitcoin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-black-mesa"></i><span>mdi-black-mesa</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blender"></i><span>mdi-blender</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blender-outline"></i><span>mdi-blender-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blender-software"></i><span>mdi-blender-software</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blinds"></i><span>mdi-blinds</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blinds-open"></i><span>mdi-blinds-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-block-helper"></i><span>mdi-block-helper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blood-bag"></i><span>mdi-blood-bag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bluetooth"></i><span>mdi-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bluetooth-audio"></i><span>mdi-bluetooth-audio</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bluetooth-connect"></i><span>mdi-bluetooth-connect</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bluetooth-off"></i><span>mdi-bluetooth-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bluetooth-settings"></i><span>mdi-bluetooth-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bluetooth-transfer"></i><span>mdi-bluetooth-transfer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blur"></i><span>mdi-blur</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blur-linear"></i><span>mdi-blur-linear</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blur-off"></i><span>mdi-blur-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blur-radial"></i><span>mdi-blur-radial</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bolt"></i><span>mdi-bolt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bomb"></i><span>mdi-bomb</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bomb-off"></i><span>mdi-bomb-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bone"></i><span>mdi-bone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book"></i><span>mdi-book</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-account"></i><span>mdi-book-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-account-outline"></i><span>mdi-book-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-alert"></i><span>mdi-book-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-border-2 bg-body-secondary"></i><span>mdi-book-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-alphabet"></i><span>mdi-book-alphabet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-arrow-down"></i><span>mdi-book-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-arrow-down-outline"></i><span>mdi-book-arrow-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-arrow-left"></i><span>mdi-book-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-arrow-left-outline"></i><span>mdi-book-arrow-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-arrow-right"></i><span>mdi-book-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-arrow-right-outline"></i><span>mdi-book-arrow-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-arrow-up"></i><span>mdi-book-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-arrow-up-outline"></i><span>mdi-book-arrow-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-cancel"></i><span>mdi-book-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-cancel-outline"></i><span>mdi-book-cancel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-check"></i><span>mdi-book-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-check-outline"></i><span>mdi-book-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-clock"></i><span>mdi-book-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-clock-outline"></i><span>mdi-book-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-cog"></i><span>mdi-book-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-cog-outline"></i><span>mdi-book-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-cross"></i><span>mdi-book-cross</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-edit"></i><span>mdi-book-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-edit-outline"></i><span>mdi-book-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-education"></i><span>mdi-book-education</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-education-outline"></i><span>mdi-book-education-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-information-variant"></i><span>mdi-book-information-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-lock"></i><span>mdi-book-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-lock-open"></i><span>mdi-book-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-lock-open-outline"></i><span>mdi-book-lock-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-lock-outline"></i><span>mdi-book-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-marker"></i><span>mdi-book-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-marker-outline"></i><span>mdi-book-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-minus"></i><span>mdi-book-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-minus-multiple"></i><span>mdi-book-minus-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-minus-multiple-outline"></i><span>mdi-book-minus-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-minus-outline"></i><span>mdi-book-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-multiple"></i><span>mdi-book-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-multiple-outline"></i><span>mdi-book-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-music"></i><span>mdi-book-music</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-music-outline"></i><span>mdi-book-music-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-off"></i><span>mdi-book-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-off-outline"></i><span>mdi-book-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-open"></i><span>mdi-book-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-open-blank-variant"></i><span>mdi-book-open-blank-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-open-outline"></i><span>mdi-book-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-open-page-variant"></i><span>mdi-book-open-page-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-open-page-variant-outline"></i><span>mdi-book-open-page-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-open-variant"></i><span>mdi-book-open-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-outline"></i><span>mdi-book-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-play"></i><span>mdi-book-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-play-outline"></i><span>mdi-book-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-plus"></i><span>mdi-book-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-plus-multiple"></i><span>mdi-book-plus-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-plus-multiple-outline"></i><span>mdi-book-plus-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-plus-outline"></i><span>mdi-book-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-refresh"></i><span>mdi-book-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-refresh-outline"></i><span>mdi-book-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-remove"></i><span>mdi-book-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-remove-multiple"></i><span>mdi-book-remove-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-remove-multiple-outline"></i><span>mdi-book-remove-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-remove-outline"></i><span>mdi-book-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-search"></i><span>mdi-book-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-search-outline"></i><span>mdi-book-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-settings"></i><span>mdi-book-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-settings-outline"></i><span>mdi-book-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-sync"></i><span>mdi-book-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-sync-outline"></i><span>mdi-book-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-variant"></i><span>mdi-book-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-book-variant-multiple"></i><span>mdi-book-variant-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark"></i><span>mdi-bookmark</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-box-multiple"></i><span>mdi-bookmark-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-box-multiple-outline"></i><span>mdi-bookmark-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-check"></i><span>mdi-bookmark-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-check-outline"></i><span>mdi-bookmark-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-minus"></i><span>mdi-bookmark-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-minus-outline"></i><span>mdi-bookmark-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-multiple"></i><span>mdi-bookmark-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-multiple-outline"></i><span>mdi-bookmark-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-music"></i><span>mdi-bookmark-music</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-music-outline"></i><span>mdi-bookmark-music-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-off"></i><span>mdi-bookmark-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-off-outline"></i><span>mdi-bookmark-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-outline"></i><span>mdi-bookmark-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-plus"></i><span>mdi-bookmark-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-plus-outline"></i><span>mdi-bookmark-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-remove"></i><span>mdi-bookmark-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookmark-remove-outline"></i><span>mdi-bookmark-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bookshelf"></i><span>mdi-bookshelf</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate"></i><span>mdi-boom-gate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate-alert"></i><span>mdi-boom-gate-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate-border-2 bg-body-secondary"></i><span>mdi-boom-gate-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate-arrow-down"></i><span>mdi-boom-gate-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate-arrow-down-outline"></i><span>mdi-boom-gate-arrow-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate-arrow-up"></i><span>mdi-boom-gate-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate-arrow-up-outline"></i><span>mdi-boom-gate-arrow-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate-outline"></i><span>mdi-boom-gate-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate-up"></i><span>mdi-boom-gate-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boom-gate-up-outline"></i><span>mdi-boom-gate-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boombox"></i><span>mdi-boombox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boomerang"></i><span>mdi-boomerang</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bootstrap"></i><span>mdi-bootstrap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-all"></i><span>mdi-border-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-all-variant"></i><span>mdi-border-all-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-bottom"></i><span>mdi-border-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-bottom-variant"></i><span>mdi-border-bottom-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-color"></i><span>mdi-border-color</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-horizontal"></i><span>mdi-border-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-inside"></i><span>mdi-border-inside</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-left"></i><span>mdi-border-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-left-variant"></i><span>mdi-border-left-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-none"></i><span>mdi-border-none</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-none-variant"></i><span>mdi-border-none-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-outside"></i><span>mdi-border-outside</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-right"></i><span>mdi-border-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-right-variant"></i><span>mdi-border-right-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-style"></i><span>mdi-border-style</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-top"></i><span>mdi-border-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-top-variant"></i><span>mdi-border-top-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-border-vertical"></i><span>mdi-border-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-soda"></i><span>mdi-bottle-soda</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-soda-classic"></i><span>mdi-bottle-soda-classic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-soda-classic-outline"></i><span>mdi-bottle-soda-classic-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-soda-outline"></i><span>mdi-bottle-soda-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-tonic"></i><span>mdi-bottle-tonic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-tonic-outline"></i><span>mdi-bottle-tonic-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-tonic-plus"></i><span>mdi-bottle-tonic-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-tonic-plus-outline"></i><span>mdi-bottle-tonic-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-tonic-skull"></i><span>mdi-bottle-tonic-skull</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-tonic-skull-outline"></i><span>mdi-bottle-tonic-skull-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-wine"></i><span>mdi-bottle-wine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bottle-wine-outline"></i><span>mdi-bottle-wine-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bow-arrow"></i><span>mdi-bow-arrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bow-tie"></i><span>mdi-bow-tie</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bowl"></i><span>mdi-bowl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bowl-mix"></i><span>mdi-bowl-mix</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bowl-mix-outline"></i><span>mdi-bowl-mix-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bowl-outline"></i><span>mdi-bowl-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bowling"></i><span>mdi-bowling</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-box"></i><span>mdi-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-box-cutter"></i><span>mdi-box-cutter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-box-cutter-off"></i><span>mdi-box-cutter-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-box-shadow"></i><span>mdi-box-shadow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-boxing-glove"></i><span>mdi-boxing-glove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-braille"></i><span>mdi-braille</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brain"></i><span>mdi-brain</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bread-slice"></i><span>mdi-bread-slice</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bread-slice-outline"></i><span>mdi-bread-slice-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bridge"></i><span>mdi-bridge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase"></i><span>mdi-briefcase</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-account"></i><span>mdi-briefcase-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-account-outline"></i><span>mdi-briefcase-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-check"></i><span>mdi-briefcase-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-check-outline"></i><span>mdi-briefcase-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-clock"></i><span>mdi-briefcase-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-clock-outline"></i><span>mdi-briefcase-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-download"></i><span>mdi-briefcase-download</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-download-outline"></i><span>mdi-briefcase-download-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-edit"></i><span>mdi-briefcase-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-edit-outline"></i><span>mdi-briefcase-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-eye"></i><span>mdi-briefcase-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-eye-outline"></i><span>mdi-briefcase-eye-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-minus"></i><span>mdi-briefcase-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-minus-outline"></i><span>mdi-briefcase-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-off"></i><span>mdi-briefcase-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-off-outline"></i><span>mdi-briefcase-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-outline"></i><span>mdi-briefcase-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-plus"></i><span>mdi-briefcase-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-plus-outline"></i><span>mdi-briefcase-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-remove"></i><span>mdi-briefcase-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-remove-outline"></i><span>mdi-briefcase-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-search"></i><span>mdi-briefcase-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-search-outline"></i><span>mdi-briefcase-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-upload"></i><span>mdi-briefcase-upload</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-upload-outline"></i><span>mdi-briefcase-upload-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-variant"></i><span>mdi-briefcase-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-variant-off"></i><span>mdi-briefcase-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-variant-off-outline"></i><span>mdi-briefcase-variant-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-briefcase-variant-outline"></i><span>mdi-briefcase-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brightness-1"></i><span>mdi-brightness-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brightness-2"></i><span>mdi-brightness-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brightness-3"></i><span>mdi-brightness-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brightness-4"></i><span>mdi-brightness-4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brightness-5"></i><span>mdi-brightness-5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brightness-6"></i><span>mdi-brightness-6</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brightness-7"></i><span>mdi-brightness-7</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brightness-auto"></i><span>mdi-brightness-auto</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brightness-percent"></i><span>mdi-brightness-percent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-broadcast"></i><span>mdi-broadcast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-broadcast-off"></i><span>mdi-broadcast-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-broom"></i><span>mdi-broom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brush"></i><span>mdi-brush</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brush-off"></i><span>mdi-brush-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-brush-variant"></i><span>mdi-brush-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bucket"></i><span>mdi-bucket</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bucket-outline"></i><span>mdi-bucket-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-buffet"></i><span>mdi-buffet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bug"></i><span>mdi-bug</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bug-check"></i><span>mdi-bug-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bug-check-outline"></i><span>mdi-bug-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bug-outline"></i><span>mdi-bug-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bugle"></i><span>mdi-bugle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bulldozer"></i><span>mdi-bulldozer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bullet"></i><span>mdi-bullet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bulletin-board"></i><span>mdi-bulletin-board</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bullhorn"></i><span>mdi-bullhorn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bullhorn-outline"></i><span>mdi-bullhorn-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bullhorn-variant"></i><span>mdi-bullhorn-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bullhorn-variant-outline"></i><span>mdi-bullhorn-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bullseye"></i><span>mdi-bullseye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bullseye-arrow"></i><span>mdi-bullseye-arrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bulma"></i><span>mdi-bulma</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bunk-bed"></i><span>mdi-bunk-bed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bunk-bed-outline"></i><span>mdi-bunk-bed-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus"></i><span>mdi-bus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-alert"></i><span>mdi-bus-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-articulated-end"></i><span>mdi-bus-articulated-end</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-articulated-front"></i><span>mdi-bus-articulated-front</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-clock"></i><span>mdi-bus-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-double-decker"></i><span>mdi-bus-double-decker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-electric"></i><span>mdi-bus-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-marker"></i><span>mdi-bus-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-multiple"></i><span>mdi-bus-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-school"></i><span>mdi-bus-school</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-side"></i><span>mdi-bus-side</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-stop"></i><span>mdi-bus-stop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-stop-covered"></i><span>mdi-bus-stop-covered</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-bus-stop-uncovered"></i><span>mdi-bus-stop-uncovered</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-butterfly"></i><span>mdi-butterfly</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-butterfly-outline"></i><span>mdi-butterfly-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cabin-a-frame"></i><span>mdi-cabin-a-frame</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cable-data"></i><span>mdi-cable-data</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cached"></i><span>mdi-cached</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cactus"></i><span>mdi-cactus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cake"></i><span>mdi-cake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cake-layered"></i><span>mdi-cake-layered</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cake-variant"></i><span>mdi-cake-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cake-variant-outline"></i><span>mdi-cake-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calculator"></i><span>mdi-calculator</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calculator-variant"></i><span>mdi-calculator-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calculator-variant-outline"></i><span>mdi-calculator-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar"></i><span>mdi-calendar</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-account"></i><span>mdi-calendar-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-account-outline"></i><span>mdi-calendar-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-alert"></i><span>mdi-calendar-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-arrow-left"></i><span>mdi-calendar-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-arrow-right"></i><span>mdi-calendar-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-blank"></i><span>mdi-calendar-blank</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-blank-multiple"></i><span>mdi-calendar-blank-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-blank-outline"></i><span>mdi-calendar-blank-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-check"></i><span>mdi-calendar-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-check-outline"></i><span>mdi-calendar-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-clock"></i><span>mdi-calendar-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-clock-outline"></i><span>mdi-calendar-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-collapse-horizontal"></i><span>mdi-calendar-collapse-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-cursor"></i><span>mdi-calendar-cursor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-edit"></i><span>mdi-calendar-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-end"></i><span>mdi-calendar-end</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-expand-horizontal"></i><span>mdi-calendar-expand-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-export"></i><span>mdi-calendar-export</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-heart"></i><span>mdi-calendar-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-import"></i><span>mdi-calendar-import</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-lock"></i><span>mdi-calendar-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-lock-outline"></i><span>mdi-calendar-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-minus"></i><span>mdi-calendar-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-month"></i><span>mdi-calendar-month</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-month-outline"></i><span>mdi-calendar-month-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-multiple"></i><span>mdi-calendar-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-multiple-check"></i><span>mdi-calendar-multiple-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-multiselect"></i><span>mdi-calendar-multiselect</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-outline"></i><span>mdi-calendar-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-plus"></i><span>mdi-calendar-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-question"></i><span>mdi-calendar-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-range"></i><span>mdi-calendar-range</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-range-outline"></i><span>mdi-calendar-range-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-refresh"></i><span>mdi-calendar-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-refresh-outline"></i><span>mdi-calendar-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-remove"></i><span>mdi-calendar-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-remove-outline"></i><span>mdi-calendar-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-search"></i><span>mdi-calendar-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-star"></i><span>mdi-calendar-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-start"></i><span>mdi-calendar-start</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-sync"></i><span>mdi-calendar-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-sync-outline"></i><span>mdi-calendar-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-text"></i><span>mdi-calendar-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-text-outline"></i><span>mdi-calendar-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-today"></i><span>mdi-calendar-today</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-week"></i><span>mdi-calendar-week</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-week-begin"></i><span>mdi-calendar-week-begin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-weekend"></i><span>mdi-calendar-weekend</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-calendar-weekend-outline"></i><span>mdi-calendar-weekend-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-call-made"></i><span>mdi-call-made</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-call-merge"></i><span>mdi-call-merge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-call-missed"></i><span>mdi-call-missed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-call-received"></i><span>mdi-call-received</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-call-split"></i><span>mdi-call-split</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camcorder"></i><span>mdi-camcorder</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camcorder-off"></i><span>mdi-camcorder-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera"></i><span>mdi-camera</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-account"></i><span>mdi-camera-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-burst"></i><span>mdi-camera-burst</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-control"></i><span>mdi-camera-control</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-document"></i><span>mdi-camera-document</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-document-off"></i><span>mdi-camera-document-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-enhance"></i><span>mdi-camera-enhance</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-enhance-outline"></i><span>mdi-camera-enhance-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-flip"></i><span>mdi-camera-flip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-flip-outline"></i><span>mdi-camera-flip-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-front"></i><span>mdi-camera-front</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-front-variant"></i><span>mdi-camera-front-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-gopro"></i><span>mdi-camera-gopro</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-image"></i><span>mdi-camera-image</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-iris"></i><span>mdi-camera-iris</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-marker"></i><span>mdi-camera-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-marker-outline"></i><span>mdi-camera-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-metering-center"></i><span>mdi-camera-metering-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-metering-matrix"></i><span>mdi-camera-metering-matrix</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-metering-partial"></i><span>mdi-camera-metering-partial</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-metering-spot"></i><span>mdi-camera-metering-spot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-off"></i><span>mdi-camera-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-off-outline"></i><span>mdi-camera-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-outline"></i><span>mdi-camera-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-party-mode"></i><span>mdi-camera-party-mode</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-plus"></i><span>mdi-camera-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-plus-outline"></i><span>mdi-camera-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-rear"></i><span>mdi-camera-rear</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-rear-variant"></i><span>mdi-camera-rear-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-retake"></i><span>mdi-camera-retake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-retake-outline"></i><span>mdi-camera-retake-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-switch"></i><span>mdi-camera-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-switch-outline"></i><span>mdi-camera-switch-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-timer"></i><span>mdi-camera-timer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-wireless"></i><span>mdi-camera-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-camera-wireless-outline"></i><span>mdi-camera-wireless-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-campfire"></i><span>mdi-campfire</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cancel"></i><span>mdi-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-candelabra"></i><span>mdi-candelabra</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-candelabra-fire"></i><span>mdi-candelabra-fire</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-candle"></i><span>mdi-candle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-candy"></i><span>mdi-candy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-candy-off"></i><span>mdi-candy-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-candy-off-outline"></i><span>mdi-candy-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-candy-outline"></i><span>mdi-candy-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-candycane"></i><span>mdi-candycane</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cannabis"></i><span>mdi-cannabis</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cannabis-off"></i><span>mdi-cannabis-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-caps-lock"></i><span>mdi-caps-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car"></i><span>mdi-car</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-2-plus"></i><span>mdi-car-2-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-3-plus"></i><span>mdi-car-3-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-arrow-left"></i><span>mdi-car-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-arrow-right"></i><span>mdi-car-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-back"></i><span>mdi-car-back</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-battery"></i><span>mdi-car-battery</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-brake-abs"></i><span>mdi-car-brake-abs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-brake-alert"></i><span>mdi-car-brake-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-brake-fluid-level"></i><span>mdi-car-brake-fluid-level</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-brake-hold"></i><span>mdi-car-brake-hold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-brake-low-pressure"></i><span>mdi-car-brake-low-pressure</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-brake-parking"></i><span>mdi-car-brake-parking</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-brake-retarder"></i><span>mdi-car-brake-retarder</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-brake-temperature"></i><span>mdi-car-brake-temperature</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-brake-worn-linings"></i><span>mdi-car-brake-worn-linings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-child-seat"></i><span>mdi-car-child-seat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-clock"></i><span>mdi-car-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-clutch"></i><span>mdi-car-clutch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-cog"></i><span>mdi-car-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-connected"></i><span>mdi-car-connected</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-convertible"></i><span>mdi-car-convertible</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-coolant-level"></i><span>mdi-car-coolant-level</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-cruise-control"></i><span>mdi-car-cruise-control</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-defrost-front"></i><span>mdi-car-defrost-front</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-defrost-rear"></i><span>mdi-car-defrost-rear</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-door"></i><span>mdi-car-door</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-door-lock"></i><span>mdi-car-door-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-electric"></i><span>mdi-car-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-electric-outline"></i><span>mdi-car-electric-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-emergency"></i><span>mdi-car-emergency</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-esp"></i><span>mdi-car-esp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-estate"></i><span>mdi-car-estate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-hatchback"></i><span>mdi-car-hatchback</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-info"></i><span>mdi-car-info</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-key"></i><span>mdi-car-key</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-lifted-pickup"></i><span>mdi-car-lifted-pickup</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-light-alert"></i><span>mdi-car-light-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-light-dimmed"></i><span>mdi-car-light-dimmed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-light-fog"></i><span>mdi-car-light-fog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-light-high"></i><span>mdi-car-light-high</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-limousine"></i><span>mdi-car-limousine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-multiple"></i><span>mdi-car-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-off"></i><span>mdi-car-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-outline"></i><span>mdi-car-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-parking-lights"></i><span>mdi-car-parking-lights</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-pickup"></i><span>mdi-car-pickup</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-seat"></i><span>mdi-car-seat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-seat-cooler"></i><span>mdi-car-seat-cooler</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-seat-heater"></i><span>mdi-car-seat-heater</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-select"></i><span>mdi-car-select</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-settings"></i><span>mdi-car-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-shift-pattern"></i><span>mdi-car-shift-pattern</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-side"></i><span>mdi-car-side</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-speed-limiter"></i><span>mdi-car-speed-limiter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-sports"></i><span>mdi-car-sports</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-tire-alert"></i><span>mdi-car-tire-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-traction-control"></i><span>mdi-car-traction-control</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-turbocharger"></i><span>mdi-car-turbocharger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-wash"></i><span>mdi-car-wash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-windshield"></i><span>mdi-car-windshield</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-windshield-outline"></i><span>mdi-car-windshield-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-wireless"></i><span>mdi-car-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-car-wrench"></i><span>mdi-car-wrench</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-carabiner"></i><span>mdi-carabiner</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-caravan"></i><span>mdi-caravan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card"></i><span>mdi-card</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-account-details"></i><span>mdi-card-account-details</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-account-details-outline"></i><span>mdi-card-account-details-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-account-details-star"></i><span>mdi-card-account-details-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-account-details-star-outline"></i><span>mdi-card-account-details-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-account-mail"></i><span>mdi-card-account-mail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-account-mail-outline"></i><span>mdi-card-account-mail-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-account-phone"></i><span>mdi-card-account-phone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-account-phone-outline"></i><span>mdi-card-account-phone-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-bulleted"></i><span>mdi-card-bulleted</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-bulleted-off"></i><span>mdi-card-bulleted-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-bulleted-off-outline"></i><span>mdi-card-bulleted-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-bulleted-outline"></i><span>mdi-card-bulleted-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-bulleted-settings"></i><span>mdi-card-bulleted-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-bulleted-settings-outline"></i><span>mdi-card-bulleted-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-minus"></i><span>mdi-card-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-minus-outline"></i><span>mdi-card-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-multiple"></i><span>mdi-card-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-multiple-outline"></i><span>mdi-card-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-off"></i><span>mdi-card-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-off-outline"></i><span>mdi-card-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-outline"></i><span>mdi-card-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-plus"></i><span>mdi-card-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-plus-outline"></i><span>mdi-card-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-remove"></i><span>mdi-card-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-remove-outline"></i><span>mdi-card-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-search"></i><span>mdi-card-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-search-outline"></i><span>mdi-card-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-text"></i><span>mdi-card-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-card-text-outline"></i><span>mdi-card-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards"></i><span>mdi-cards</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-club"></i><span>mdi-cards-club</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-club-outline"></i><span>mdi-cards-club-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-diamond"></i><span>mdi-cards-diamond</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-diamond-outline"></i><span>mdi-cards-diamond-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-heart"></i><span>mdi-cards-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-heart-outline"></i><span>mdi-cards-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-outline"></i><span>mdi-cards-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing"></i><span>mdi-cards-playing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-club"></i><span>mdi-cards-playing-club</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-club-multiple"></i><span>mdi-cards-playing-club-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-club-multiple-outline"></i><span>mdi-cards-playing-club-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-club-outline"></i><span>mdi-cards-playing-club-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-diamond"></i><span>mdi-cards-playing-diamond</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-diamond-multiple"></i><span>mdi-cards-playing-diamond-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-diamond-multiple-outline"></i><span>mdi-cards-playing-diamond-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-diamond-outline"></i><span>mdi-cards-playing-diamond-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-heart"></i><span>mdi-cards-playing-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-heart-multiple"></i><span>mdi-cards-playing-heart-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-heart-multiple-outline"></i><span>mdi-cards-playing-heart-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-heart-outline"></i><span>mdi-cards-playing-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-outline"></i><span>mdi-cards-playing-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-spade"></i><span>mdi-cards-playing-spade</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-spade-multiple"></i><span>mdi-cards-playing-spade-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-spade-multiple-outline"></i><span>mdi-cards-playing-spade-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-playing-spade-outline"></i><span>mdi-cards-playing-spade-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-spade"></i><span>mdi-cards-spade</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-spade-outline"></i><span>mdi-cards-spade-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cards-variant"></i><span>mdi-cards-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-carrot"></i><span>mdi-carrot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart"></i><span>mdi-cart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-arrow-down"></i><span>mdi-cart-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-arrow-right"></i><span>mdi-cart-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-arrow-up"></i><span>mdi-cart-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-check"></i><span>mdi-cart-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-heart"></i><span>mdi-cart-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-minus"></i><span>mdi-cart-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-off"></i><span>mdi-cart-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-outline"></i><span>mdi-cart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-plus"></i><span>mdi-cart-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-remove"></i><span>mdi-cart-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cart-variant"></i><span>mdi-cart-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-case-sensitive-alt"></i><span>mdi-case-sensitive-alt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash"></i><span>mdi-cash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-100"></i><span>mdi-cash-100</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-check"></i><span>mdi-cash-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-fast"></i><span>mdi-cash-fast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-lock"></i><span>mdi-cash-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-lock-open"></i><span>mdi-cash-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-marker"></i><span>mdi-cash-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-minus"></i><span>mdi-cash-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-multiple"></i><span>mdi-cash-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-plus"></i><span>mdi-cash-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-refund"></i><span>mdi-cash-refund</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-register"></i><span>mdi-cash-register</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cash-remove"></i><span>mdi-cash-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cassette"></i><span>mdi-cassette</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cast"></i><span>mdi-cast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cast-audio"></i><span>mdi-cast-audio</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cast-audio-variant"></i><span>mdi-cast-audio-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cast-connected"></i><span>mdi-cast-connected</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cast-education"></i><span>mdi-cast-education</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cast-off"></i><span>mdi-cast-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cast-variant"></i><span>mdi-cast-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-castle"></i><span>mdi-castle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cat"></i><span>mdi-cat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cctv"></i><span>mdi-cctv</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cctv-off"></i><span>mdi-cctv-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ceiling-fan"></i><span>mdi-ceiling-fan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ceiling-fan-light"></i><span>mdi-ceiling-fan-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ceiling-light"></i><span>mdi-ceiling-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ceiling-light-multiple"></i><span>mdi-ceiling-light-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ceiling-light-multiple-outline"></i><span>mdi-ceiling-light-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ceiling-light-outline"></i><span>mdi-ceiling-light-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone"></i><span>mdi-cellphone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-arrow-down"></i><span>mdi-cellphone-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-basic"></i><span>mdi-cellphone-basic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-charging"></i><span>mdi-cellphone-charging</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-check"></i><span>mdi-cellphone-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-cog"></i><span>mdi-cellphone-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-dock"></i><span>mdi-cellphone-dock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-information"></i><span>mdi-cellphone-information</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-key"></i><span>mdi-cellphone-key</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-link"></i><span>mdi-cellphone-link</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-link-off"></i><span>mdi-cellphone-link-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-lock"></i><span>mdi-cellphone-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-marker"></i><span>mdi-cellphone-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-message"></i><span>mdi-cellphone-message</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-message-off"></i><span>mdi-cellphone-message-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-nfc"></i><span>mdi-cellphone-nfc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-nfc-off"></i><span>mdi-cellphone-nfc-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-off"></i><span>mdi-cellphone-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-play"></i><span>mdi-cellphone-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-remove"></i><span>mdi-cellphone-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-screenshot"></i><span>mdi-cellphone-screenshot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-settings"></i><span>mdi-cellphone-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-sound"></i><span>mdi-cellphone-sound</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-text"></i><span>mdi-cellphone-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cellphone-wireless"></i><span>mdi-cellphone-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-centos"></i><span>mdi-centos</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-certificate"></i><span>mdi-certificate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-certificate-outline"></i><span>mdi-certificate-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chair-rolling"></i><span>mdi-chair-rolling</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chair-school"></i><span>mdi-chair-school</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chandelier"></i><span>mdi-chandelier</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-charity"></i><span>mdi-charity</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-arc"></i><span>mdi-chart-arc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-areaspline"></i><span>mdi-chart-areaspline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-areaspline-variant"></i><span>mdi-chart-areaspline-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-bar"></i><span>mdi-chart-bar</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-bar-stacked"></i><span>mdi-chart-bar-stacked</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-bell-curve"></i><span>mdi-chart-bell-curve</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-bell-curve-cumulative"></i><span>mdi-chart-bell-curve-cumulative</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-box"></i><span>mdi-chart-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-box-outline"></i><span>mdi-chart-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-box-plus-outline"></i><span>mdi-chart-box-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-bubble"></i><span>mdi-chart-bubble</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-donut"></i><span>mdi-chart-donut</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-donut-variant"></i><span>mdi-chart-donut-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-gantt"></i><span>mdi-chart-gantt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-histogram"></i><span>mdi-chart-histogram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-line"></i><span>mdi-chart-line</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-line-stacked"></i><span>mdi-chart-line-stacked</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-line-variant"></i><span>mdi-chart-line-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-multiline"></i><span>mdi-chart-multiline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-multiple"></i><span>mdi-chart-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-pie"></i><span>mdi-chart-pie</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-ppf"></i><span>mdi-chart-ppf</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-sankey"></i><span>mdi-chart-sankey</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-sankey-variant"></i><span>mdi-chart-sankey-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-scatter-plot"></i><span>mdi-chart-scatter-plot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-scatter-plot-hexbin"></i><span>mdi-chart-scatter-plot-hexbin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-timeline"></i><span>mdi-chart-timeline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-timeline-variant"></i><span>mdi-chart-timeline-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-timeline-variant-shimmer"></i><span>mdi-chart-timeline-variant-shimmer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-tree"></i><span>mdi-chart-tree</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chart-waterfall"></i><span>mdi-chart-waterfall</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat"></i><span>mdi-chat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-alert"></i><span>mdi-chat-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-border-2 bg-body-secondary"></i><span>mdi-chat-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-minus"></i><span>mdi-chat-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-minus-outline"></i><span>mdi-chat-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-outline"></i><span>mdi-chat-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-plus"></i><span>mdi-chat-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-plus-outline"></i><span>mdi-chat-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-processing"></i><span>mdi-chat-processing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-processing-outline"></i><span>mdi-chat-processing-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-question"></i><span>mdi-chat-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-question-outline"></i><span>mdi-chat-question-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-remove"></i><span>mdi-chat-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-remove-outline"></i><span>mdi-chat-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-sleep"></i><span>mdi-chat-sleep</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chat-sleep-outline"></i><span>mdi-chat-sleep-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check"></i><span>mdi-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-all"></i><span>mdi-check-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-bold"></i><span>mdi-check-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-circle"></i><span>mdi-check-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-circle-outline"></i><span>mdi-check-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-decagram"></i><span>mdi-check-decagram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-decagram-outline"></i><span>mdi-check-decagram-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-network"></i><span>mdi-check-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-network-outline"></i><span>mdi-check-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-outline"></i><span>mdi-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-underline"></i><span>mdi-check-underline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-underline-circle"></i><span>mdi-check-underline-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-check-underline-circle-outline"></i><span>mdi-check-underline-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbook"></i><span>mdi-checkbook</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-blank"></i><span>mdi-checkbox-blank</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-blank-badge"></i><span>mdi-checkbox-blank-badge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-blank-badge-outline"></i><span>mdi-checkbox-blank-badge-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-blank-circle"></i><span>mdi-checkbox-blank-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-blank-circle-outline"></i><span>mdi-checkbox-blank-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-blank-off"></i><span>mdi-checkbox-blank-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-blank-off-outline"></i><span>mdi-checkbox-blank-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-blank-outline"></i><span>mdi-checkbox-blank-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-intermediate"></i><span>mdi-checkbox-intermediate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-marked"></i><span>mdi-checkbox-marked</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-marked-circle"></i><span>mdi-checkbox-marked-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-marked-circle-outline"></i><span>mdi-checkbox-marked-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-marked-circle-plus-outline"></i><span>mdi-checkbox-marked-circle-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-marked-outline"></i><span>mdi-checkbox-marked-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-multiple-blank"></i><span>mdi-checkbox-multiple-blank</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-multiple-blank-circle"></i><span>mdi-checkbox-multiple-blank-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-multiple-blank-circle-outline"></i><span>mdi-checkbox-multiple-blank-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-multiple-blank-outline"></i><span>mdi-checkbox-multiple-blank-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-multiple-marked"></i><span>mdi-checkbox-multiple-marked</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-multiple-marked-circle"></i><span>mdi-checkbox-multiple-marked-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-multiple-marked-circle-outline"></i><span>mdi-checkbox-multiple-marked-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-multiple-marked-outline"></i><span>mdi-checkbox-multiple-marked-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-multiple-outline"></i><span>mdi-checkbox-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkbox-outline"></i><span>mdi-checkbox-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkerboard"></i><span>mdi-checkerboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkerboard-minus"></i><span>mdi-checkerboard-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkerboard-plus"></i><span>mdi-checkerboard-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-checkerboard-remove"></i><span>mdi-checkerboard-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cheese"></i><span>mdi-cheese</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cheese-off"></i><span>mdi-cheese-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chef-hat"></i><span>mdi-chef-hat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chemical-weapon"></i><span>mdi-chemical-weapon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chess-bishop"></i><span>mdi-chess-bishop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chess-king"></i><span>mdi-chess-king</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chess-knight"></i><span>mdi-chess-knight</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chess-pawn"></i><span>mdi-chess-pawn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chess-queen"></i><span>mdi-chess-queen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chess-rook"></i><span>mdi-chess-rook</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-double-down"></i><span>mdi-chevron-double-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-double-left"></i><span>mdi-chevron-double-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-double-right"></i><span>mdi-chevron-double-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-double-up"></i><span>mdi-chevron-double-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-down"></i><span>mdi-chevron-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-down-box"></i><span>mdi-chevron-down-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-down-box-outline"></i><span>mdi-chevron-down-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-down-circle"></i><span>mdi-chevron-down-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-down-circle-outline"></i><span>mdi-chevron-down-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-left"></i><span>mdi-chevron-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-left-box"></i><span>mdi-chevron-left-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-left-box-outline"></i><span>mdi-chevron-left-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-left-circle"></i><span>mdi-chevron-left-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-left-circle-outline"></i><span>mdi-chevron-left-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-right"></i><span>mdi-chevron-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-right-box"></i><span>mdi-chevron-right-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-right-box-outline"></i><span>mdi-chevron-right-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-right-circle"></i><span>mdi-chevron-right-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-right-circle-outline"></i><span>mdi-chevron-right-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-triple-down"></i><span>mdi-chevron-triple-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-triple-left"></i><span>mdi-chevron-triple-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-triple-right"></i><span>mdi-chevron-triple-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-triple-up"></i><span>mdi-chevron-triple-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-up"></i><span>mdi-chevron-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-up-box"></i><span>mdi-chevron-up-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-up-box-outline"></i><span>mdi-chevron-up-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-up-circle"></i><span>mdi-chevron-up-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chevron-up-circle-outline"></i><span>mdi-chevron-up-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-alert"></i><span>mdi-chili-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-border-2 bg-body-secondary"></i><span>mdi-chili-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-hot"></i><span>mdi-chili-hot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-hot-outline"></i><span>mdi-chili-hot-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-medium"></i><span>mdi-chili-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-medium-outline"></i><span>mdi-chili-medium-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-mild"></i><span>mdi-chili-mild</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-mild-outline"></i><span>mdi-chili-mild-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-off"></i><span>mdi-chili-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chili-off-outline"></i><span>mdi-chili-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-chip"></i><span>mdi-chip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-church"></i><span>mdi-church</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cigar"></i><span>mdi-cigar</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cigar-off"></i><span>mdi-cigar-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle"></i><span>mdi-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-box"></i><span>mdi-circle-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-box-outline"></i><span>mdi-circle-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-double"></i><span>mdi-circle-double</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-edit-outline"></i><span>mdi-circle-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-expand"></i><span>mdi-circle-expand</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-half"></i><span>mdi-circle-half</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-half-full"></i><span>mdi-circle-half-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-medium"></i><span>mdi-circle-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-multiple"></i><span>mdi-circle-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-multiple-outline"></i><span>mdi-circle-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-off-outline"></i><span>mdi-circle-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-opacity"></i><span>mdi-circle-opacity</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-outline"></i><span>mdi-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-slice-1"></i><span>mdi-circle-slice-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-slice-2"></i><span>mdi-circle-slice-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-slice-3"></i><span>mdi-circle-slice-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-slice-4"></i><span>mdi-circle-slice-4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-slice-5"></i><span>mdi-circle-slice-5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-slice-6"></i><span>mdi-circle-slice-6</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-slice-7"></i><span>mdi-circle-slice-7</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-slice-8"></i><span>mdi-circle-slice-8</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circle-small"></i><span>mdi-circle-small</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-circular-saw"></i><span>mdi-circular-saw</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-city"></i><span>mdi-city</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-city-variant"></i><span>mdi-city-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-city-variant-outline"></i><span>mdi-city-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard"></i><span>mdi-clipboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-account"></i><span>mdi-clipboard-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-account-outline"></i><span>mdi-clipboard-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-alert"></i><span>mdi-clipboard-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-border-2 bg-body-secondary"></i><span>mdi-clipboard-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-arrow-down"></i><span>mdi-clipboard-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-arrow-down-outline"></i><span>mdi-clipboard-arrow-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-arrow-left"></i><span>mdi-clipboard-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-arrow-left-outline"></i><span>mdi-clipboard-arrow-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-arrow-right"></i><span>mdi-clipboard-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-arrow-right-outline"></i><span>mdi-clipboard-arrow-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-arrow-up"></i><span>mdi-clipboard-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-arrow-up-outline"></i><span>mdi-clipboard-arrow-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-check"></i><span>mdi-clipboard-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-check-multiple"></i><span>mdi-clipboard-check-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-check-multiple-outline"></i><span>mdi-clipboard-check-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-check-outline"></i><span>mdi-clipboard-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-clock"></i><span>mdi-clipboard-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-clock-outline"></i><span>mdi-clipboard-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-edit"></i><span>mdi-clipboard-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-edit-outline"></i><span>mdi-clipboard-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-file"></i><span>mdi-clipboard-file</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-file-outline"></i><span>mdi-clipboard-file-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-flow"></i><span>mdi-clipboard-flow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-flow-outline"></i><span>mdi-clipboard-flow-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-list"></i><span>mdi-clipboard-list</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-list-outline"></i><span>mdi-clipboard-list-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-minus"></i><span>mdi-clipboard-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-minus-outline"></i><span>mdi-clipboard-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-multiple"></i><span>mdi-clipboard-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-multiple-outline"></i><span>mdi-clipboard-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-off"></i><span>mdi-clipboard-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-off-outline"></i><span>mdi-clipboard-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-outline"></i><span>mdi-clipboard-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-play"></i><span>mdi-clipboard-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-play-multiple"></i><span>mdi-clipboard-play-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-play-multiple-outline"></i><span>mdi-clipboard-play-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-play-outline"></i><span>mdi-clipboard-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-plus"></i><span>mdi-clipboard-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-plus-outline"></i><span>mdi-clipboard-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-pulse"></i><span>mdi-clipboard-pulse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-pulse-outline"></i><span>mdi-clipboard-pulse-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-remove"></i><span>mdi-clipboard-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-remove-outline"></i><span>mdi-clipboard-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-search"></i><span>mdi-clipboard-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-search-outline"></i><span>mdi-clipboard-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text"></i><span>mdi-clipboard-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-clock"></i><span>mdi-clipboard-text-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-clock-outline"></i><span>mdi-clipboard-text-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-multiple"></i><span>mdi-clipboard-text-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-multiple-outline"></i><span>mdi-clipboard-text-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-off"></i><span>mdi-clipboard-text-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-off-outline"></i><span>mdi-clipboard-text-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-outline"></i><span>mdi-clipboard-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-play"></i><span>mdi-clipboard-text-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-play-outline"></i><span>mdi-clipboard-text-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-search"></i><span>mdi-clipboard-text-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clipboard-text-search-outline"></i><span>mdi-clipboard-text-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clippy"></i><span>mdi-clippy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock"></i><span>mdi-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-alert"></i><span>mdi-clock-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-border-2 bg-body-secondary"></i><span>mdi-clock-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-check"></i><span>mdi-clock-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-check-outline"></i><span>mdi-clock-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-digital"></i><span>mdi-clock-digital</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-edit"></i><span>mdi-clock-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-edit-outline"></i><span>mdi-clock-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-end"></i><span>mdi-clock-end</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-fast"></i><span>mdi-clock-fast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-in"></i><span>mdi-clock-in</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-minus"></i><span>mdi-clock-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-minus-outline"></i><span>mdi-clock-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-out"></i><span>mdi-clock-out</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-outline"></i><span>mdi-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-plus"></i><span>mdi-clock-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-plus-outline"></i><span>mdi-clock-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-remove"></i><span>mdi-clock-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-remove-outline"></i><span>mdi-clock-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-start"></i><span>mdi-clock-start</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-eight"></i><span>mdi-clock-time-eight</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-eight-outline"></i><span>mdi-clock-time-eight-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-eleven"></i><span>mdi-clock-time-eleven</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-eleven-outline"></i><span>mdi-clock-time-eleven-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-five"></i><span>mdi-clock-time-five</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-five-outline"></i><span>mdi-clock-time-five-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-four"></i><span>mdi-clock-time-four</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-four-outline"></i><span>mdi-clock-time-four-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-nine"></i><span>mdi-clock-time-nine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-nine-outline"></i><span>mdi-clock-time-nine-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-one"></i><span>mdi-clock-time-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-one-outline"></i><span>mdi-clock-time-one-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-seven"></i><span>mdi-clock-time-seven</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-seven-outline"></i><span>mdi-clock-time-seven-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-six"></i><span>mdi-clock-time-six</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-six-outline"></i><span>mdi-clock-time-six-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-ten"></i><span>mdi-clock-time-ten</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-ten-outline"></i><span>mdi-clock-time-ten-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-three"></i><span>mdi-clock-time-three</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-three-outline"></i><span>mdi-clock-time-three-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-twelve"></i><span>mdi-clock-time-twelve</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-twelve-outline"></i><span>mdi-clock-time-twelve-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-two"></i><span>mdi-clock-time-two</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clock-time-two-outline"></i><span>mdi-clock-time-two-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close"></i><span>mdi-close</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-box"></i><span>mdi-close-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-box-multiple"></i><span>mdi-close-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-box-multiple-outline"></i><span>mdi-close-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-box-outline"></i><span>mdi-close-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-circle"></i><span>mdi-close-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-circle-multiple"></i><span>mdi-close-circle-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-circle-multiple-outline"></i><span>mdi-close-circle-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-circle-outline"></i><span>mdi-close-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-network"></i><span>mdi-close-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-network-outline"></i><span>mdi-close-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-octagon"></i><span>mdi-close-octagon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-octagon-outline"></i><span>mdi-close-octagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-outline"></i><span>mdi-close-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-close-thick"></i><span>mdi-close-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-closed-caption"></i><span>mdi-closed-caption</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-closed-caption-outline"></i><span>mdi-closed-caption-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud"></i><span>mdi-cloud</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-alert"></i><span>mdi-cloud-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-braces"></i><span>mdi-cloud-braces</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-check"></i><span>mdi-cloud-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-check-outline"></i><span>mdi-cloud-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-circle"></i><span>mdi-cloud-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-download"></i><span>mdi-cloud-download</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-download-outline"></i><span>mdi-cloud-download-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-lock"></i><span>mdi-cloud-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-lock-outline"></i><span>mdi-cloud-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-off-outline"></i><span>mdi-cloud-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-outline"></i><span>mdi-cloud-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-print"></i><span>mdi-cloud-print</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-print-outline"></i><span>mdi-cloud-print-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-question"></i><span>mdi-cloud-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-refresh"></i><span>mdi-cloud-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-search"></i><span>mdi-cloud-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-search-outline"></i><span>mdi-cloud-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-sync"></i><span>mdi-cloud-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-sync-outline"></i><span>mdi-cloud-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-tags"></i><span>mdi-cloud-tags</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-upload"></i><span>mdi-cloud-upload</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cloud-upload-outline"></i><span>mdi-cloud-upload-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-clover"></i><span>mdi-clover</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coach-lamp"></i><span>mdi-coach-lamp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coat-rack"></i><span>mdi-coat-rack</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-array"></i><span>mdi-code-array</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-braces"></i><span>mdi-code-braces</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-braces-box"></i><span>mdi-code-braces-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-brackets"></i><span>mdi-code-brackets</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-equal"></i><span>mdi-code-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-greater-than"></i><span>mdi-code-greater-than</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-greater-than-or-equal"></i><span>mdi-code-greater-than-or-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-json"></i><span>mdi-code-json</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-less-than"></i><span>mdi-code-less-than</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-less-than-or-equal"></i><span>mdi-code-less-than-or-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-not-equal"></i><span>mdi-code-not-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-not-equal-variant"></i><span>mdi-code-not-equal-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-parentheses"></i><span>mdi-code-parentheses</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-parentheses-box"></i><span>mdi-code-parentheses-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-string"></i><span>mdi-code-string</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-tags"></i><span>mdi-code-tags</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-code-tags-check"></i><span>mdi-code-tags-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-codepen"></i><span>mdi-codepen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee"></i><span>mdi-coffee</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee-maker"></i><span>mdi-coffee-maker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee-maker-check"></i><span>mdi-coffee-maker-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee-maker-check-outline"></i><span>mdi-coffee-maker-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee-maker-outline"></i><span>mdi-coffee-maker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee-off"></i><span>mdi-coffee-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee-off-outline"></i><span>mdi-coffee-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee-outline"></i><span>mdi-coffee-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee-to-go"></i><span>mdi-coffee-to-go</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffee-to-go-outline"></i><span>mdi-coffee-to-go-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coffin"></i><span>mdi-coffin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog"></i><span>mdi-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-box"></i><span>mdi-cog-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-clockwise"></i><span>mdi-cog-clockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-counterclockwise"></i><span>mdi-cog-counterclockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-off"></i><span>mdi-cog-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-off-outline"></i><span>mdi-cog-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-outline"></i><span>mdi-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-pause"></i><span>mdi-cog-pause</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-pause-outline"></i><span>mdi-cog-pause-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-play"></i><span>mdi-cog-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-play-outline"></i><span>mdi-cog-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-refresh"></i><span>mdi-cog-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-refresh-outline"></i><span>mdi-cog-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-stop"></i><span>mdi-cog-stop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-stop-outline"></i><span>mdi-cog-stop-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-sync"></i><span>mdi-cog-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-sync-outline"></i><span>mdi-cog-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-transfer"></i><span>mdi-cog-transfer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cog-transfer-outline"></i><span>mdi-cog-transfer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cogs"></i><span>mdi-cogs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-collage"></i><span>mdi-collage</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-collapse-all"></i><span>mdi-collapse-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-collapse-all-outline"></i><span>mdi-collapse-all-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-color-helper"></i><span>mdi-color-helper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comma"></i><span>mdi-comma</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comma-box"></i><span>mdi-comma-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comma-box-outline"></i><span>mdi-comma-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comma-circle"></i><span>mdi-comma-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comma-circle-outline"></i><span>mdi-comma-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment"></i><span>mdi-comment</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-account"></i><span>mdi-comment-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-account-outline"></i><span>mdi-comment-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-alert"></i><span>mdi-comment-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-border-2 bg-body-secondary"></i><span>mdi-comment-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-arrow-left"></i><span>mdi-comment-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-arrow-left-outline"></i><span>mdi-comment-arrow-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-arrow-right"></i><span>mdi-comment-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-arrow-right-outline"></i><span>mdi-comment-arrow-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-bookmark"></i><span>mdi-comment-bookmark</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-bookmark-outline"></i><span>mdi-comment-bookmark-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-check"></i><span>mdi-comment-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-check-outline"></i><span>mdi-comment-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-edit"></i><span>mdi-comment-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-edit-outline"></i><span>mdi-comment-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-eye"></i><span>mdi-comment-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-eye-outline"></i><span>mdi-comment-eye-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-flash"></i><span>mdi-comment-flash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-flash-outline"></i><span>mdi-comment-flash-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-minus"></i><span>mdi-comment-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-minus-outline"></i><span>mdi-comment-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-multiple"></i><span>mdi-comment-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-multiple-outline"></i><span>mdi-comment-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-off"></i><span>mdi-comment-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-off-outline"></i><span>mdi-comment-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-outline"></i><span>mdi-comment-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-plus"></i><span>mdi-comment-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-plus-outline"></i><span>mdi-comment-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-processing"></i><span>mdi-comment-processing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-processing-outline"></i><span>mdi-comment-processing-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-question"></i><span>mdi-comment-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-question-outline"></i><span>mdi-comment-question-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-quote"></i><span>mdi-comment-quote</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-quote-outline"></i><span>mdi-comment-quote-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-remove"></i><span>mdi-comment-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-remove-outline"></i><span>mdi-comment-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-search"></i><span>mdi-comment-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-search-outline"></i><span>mdi-comment-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-text"></i><span>mdi-comment-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-text-multiple"></i><span>mdi-comment-text-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-text-multiple-outline"></i><span>mdi-comment-text-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-comment-text-outline"></i><span>mdi-comment-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-compare"></i><span>mdi-compare</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-compare-horizontal"></i><span>mdi-compare-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-compare-remove"></i><span>mdi-compare-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-compare-vertical"></i><span>mdi-compare-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-compass"></i><span>mdi-compass</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-compass-off"></i><span>mdi-compass-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-compass-off-outline"></i><span>mdi-compass-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-compass-outline"></i><span>mdi-compass-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-compass-rose"></i><span>mdi-compass-rose</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cone"></i><span>mdi-cone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cone-off"></i><span>mdi-cone-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-connection"></i><span>mdi-connection</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-console"></i><span>mdi-console</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-console-line"></i><span>mdi-console-line</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-console-network"></i><span>mdi-console-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-console-network-outline"></i><span>mdi-console-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-consolidate"></i><span>mdi-consolidate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contactless-payment"></i><span>mdi-contactless-payment</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contactless-payment-circle"></i><span>mdi-contactless-payment-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contactless-payment-circle-outline"></i><span>mdi-contactless-payment-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contacts"></i><span>mdi-contacts</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contacts-outline"></i><span>mdi-contacts-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contain"></i><span>mdi-contain</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contain-end"></i><span>mdi-contain-end</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contain-start"></i><span>mdi-contain-start</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-copy"></i><span>mdi-content-copy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-cut"></i><span>mdi-content-cut</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-duplicate"></i><span>mdi-content-duplicate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-paste"></i><span>mdi-content-paste</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save"></i><span>mdi-content-save</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-alert"></i><span>mdi-content-save-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-border-2 bg-body-secondary"></i><span>mdi-content-save-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-all"></i><span>mdi-content-save-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-all-outline"></i><span>mdi-content-save-all-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-check"></i><span>mdi-content-save-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-check-outline"></i><span>mdi-content-save-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-cog"></i><span>mdi-content-save-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-cog-outline"></i><span>mdi-content-save-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-edit"></i><span>mdi-content-save-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-edit-outline"></i><span>mdi-content-save-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-move"></i><span>mdi-content-save-move</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-move-outline"></i><span>mdi-content-save-move-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-off"></i><span>mdi-content-save-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-off-outline"></i><span>mdi-content-save-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-outline"></i><span>mdi-content-save-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-settings"></i><span>mdi-content-save-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-content-save-settings-outline"></i><span>mdi-content-save-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contrast"></i><span>mdi-contrast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contrast-box"></i><span>mdi-contrast-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-contrast-circle"></i><span>mdi-contrast-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-controller-classic"></i><span>mdi-controller-classic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-controller-classic-outline"></i><span>mdi-controller-classic-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie"></i><span>mdi-cookie</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-alert"></i><span>mdi-cookie-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-border-2 bg-body-secondary"></i><span>mdi-cookie-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-check"></i><span>mdi-cookie-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-check-outline"></i><span>mdi-cookie-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-clock"></i><span>mdi-cookie-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-clock-outline"></i><span>mdi-cookie-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-cog"></i><span>mdi-cookie-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-cog-outline"></i><span>mdi-cookie-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-edit"></i><span>mdi-cookie-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-edit-outline"></i><span>mdi-cookie-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-lock"></i><span>mdi-cookie-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-lock-outline"></i><span>mdi-cookie-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-minus"></i><span>mdi-cookie-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-minus-outline"></i><span>mdi-cookie-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-off"></i><span>mdi-cookie-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-off-outline"></i><span>mdi-cookie-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-outline"></i><span>mdi-cookie-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-plus"></i><span>mdi-cookie-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-plus-outline"></i><span>mdi-cookie-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-refresh"></i><span>mdi-cookie-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-refresh-outline"></i><span>mdi-cookie-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-remove"></i><span>mdi-cookie-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-remove-outline"></i><span>mdi-cookie-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-settings"></i><span>mdi-cookie-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cookie-settings-outline"></i><span>mdi-cookie-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-coolant-temperature"></i><span>mdi-coolant-temperature</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-copyleft"></i><span>mdi-copyleft</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-copyright"></i><span>mdi-copyright</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cordova"></i><span>mdi-cordova</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-corn"></i><span>mdi-corn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-corn-off"></i><span>mdi-corn-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cosine-wave"></i><span>mdi-cosine-wave</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-counter"></i><span>mdi-counter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-countertop"></i><span>mdi-countertop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-countertop-outline"></i><span>mdi-countertop-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cow"></i><span>mdi-cow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cow-off"></i><span>mdi-cow-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cpu-32-bit"></i><span>mdi-cpu-32-bit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cpu-64-bit"></i><span>mdi-cpu-64-bit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cradle"></i><span>mdi-cradle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cradle-outline"></i><span>mdi-cradle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crane"></i><span>mdi-crane</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-creation"></i><span>mdi-creation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-creative-commons"></i><span>mdi-creative-commons</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card"></i><span>mdi-credit-card</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-check"></i><span>mdi-credit-card-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-check-outline"></i><span>mdi-credit-card-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-chip"></i><span>mdi-credit-card-chip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-chip-outline"></i><span>mdi-credit-card-chip-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-clock"></i><span>mdi-credit-card-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-clock-outline"></i><span>mdi-credit-card-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-edit"></i><span>mdi-credit-card-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-edit-outline"></i><span>mdi-credit-card-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-fast"></i><span>mdi-credit-card-fast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-fast-outline"></i><span>mdi-credit-card-fast-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-lock"></i><span>mdi-credit-card-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-lock-outline"></i><span>mdi-credit-card-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-marker"></i><span>mdi-credit-card-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-marker-outline"></i><span>mdi-credit-card-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-minus"></i><span>mdi-credit-card-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-minus-outline"></i><span>mdi-credit-card-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-multiple"></i><span>mdi-credit-card-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-multiple-outline"></i><span>mdi-credit-card-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-off"></i><span>mdi-credit-card-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-off-outline"></i><span>mdi-credit-card-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-outline"></i><span>mdi-credit-card-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-plus"></i><span>mdi-credit-card-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-plus-outline"></i><span>mdi-credit-card-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-refresh"></i><span>mdi-credit-card-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-refresh-outline"></i><span>mdi-credit-card-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-refund"></i><span>mdi-credit-card-refund</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-refund-outline"></i><span>mdi-credit-card-refund-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-remove"></i><span>mdi-credit-card-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-remove-outline"></i><span>mdi-credit-card-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-scan"></i><span>mdi-credit-card-scan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-scan-outline"></i><span>mdi-credit-card-scan-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-search"></i><span>mdi-credit-card-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-search-outline"></i><span>mdi-credit-card-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-settings"></i><span>mdi-credit-card-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-settings-outline"></i><span>mdi-credit-card-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-sync"></i><span>mdi-credit-card-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-sync-outline"></i><span>mdi-credit-card-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-wireless"></i><span>mdi-credit-card-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-wireless-off"></i><span>mdi-credit-card-wireless-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-wireless-off-outline"></i><span>mdi-credit-card-wireless-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-credit-card-wireless-outline"></i><span>mdi-credit-card-wireless-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cricket"></i><span>mdi-cricket</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crop"></i><span>mdi-crop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crop-free"></i><span>mdi-crop-free</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crop-landscape"></i><span>mdi-crop-landscape</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crop-portrait"></i><span>mdi-crop-portrait</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crop-rotate"></i><span>mdi-crop-rotate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crop-square"></i><span>mdi-crop-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cross"></i><span>mdi-cross</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cross-bolnisi"></i><span>mdi-cross-bolnisi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cross-celtic"></i><span>mdi-cross-celtic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cross-outline"></i><span>mdi-cross-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crosshairs"></i><span>mdi-crosshairs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crosshairs-gps"></i><span>mdi-crosshairs-gps</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crosshairs-off"></i><span>mdi-crosshairs-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crosshairs-question"></i><span>mdi-crosshairs-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crowd"></i><span>mdi-crowd</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crown"></i><span>mdi-crown</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crown-circle"></i><span>mdi-crown-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crown-circle-outline"></i><span>mdi-crown-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crown-outline"></i><span>mdi-crown-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cryengine"></i><span>mdi-cryengine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-crystal-ball"></i><span>mdi-crystal-ball</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cube"></i><span>mdi-cube</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cube-off"></i><span>mdi-cube-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cube-off-outline"></i><span>mdi-cube-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cube-outline"></i><span>mdi-cube-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cube-scan"></i><span>mdi-cube-scan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cube-send"></i><span>mdi-cube-send</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cube-unfolded"></i><span>mdi-cube-unfolded</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cup"></i><span>mdi-cup</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cup-off"></i><span>mdi-cup-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cup-off-outline"></i><span>mdi-cup-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cup-outline"></i><span>mdi-cup-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cup-water"></i><span>mdi-cup-water</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cupboard"></i><span>mdi-cupboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cupboard-outline"></i><span>mdi-cupboard-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cupcake"></i><span>mdi-cupcake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-curling"></i><span>mdi-curling</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-bdt"></i><span>mdi-currency-bdt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-brl"></i><span>mdi-currency-brl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-btc"></i><span>mdi-currency-btc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-cny"></i><span>mdi-currency-cny</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-eth"></i><span>mdi-currency-eth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-eur"></i><span>mdi-currency-eur</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-eur-off"></i><span>mdi-currency-eur-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-gbp"></i><span>mdi-currency-gbp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-ils"></i><span>mdi-currency-ils</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-inr"></i><span>mdi-currency-inr</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-jpy"></i><span>mdi-currency-jpy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-krw"></i><span>mdi-currency-krw</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-kzt"></i><span>mdi-currency-kzt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-mnt"></i><span>mdi-currency-mnt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-ngn"></i><span>mdi-currency-ngn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-php"></i><span>mdi-currency-php</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-rial"></i><span>mdi-currency-rial</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-rub"></i><span>mdi-currency-rub</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-rupee"></i><span>mdi-currency-rupee</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-sign"></i><span>mdi-currency-sign</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-try"></i><span>mdi-currency-try</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-twd"></i><span>mdi-currency-twd</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-usd"></i><span>mdi-currency-usd</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-currency-usd-off"></i><span>mdi-currency-usd-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-current-ac"></i><span>mdi-current-ac</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-current-dc"></i><span>mdi-current-dc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cursor-default"></i><span>mdi-cursor-default</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cursor-default-click"></i><span>mdi-cursor-default-click</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cursor-default-click-outline"></i><span>mdi-cursor-default-click-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cursor-default-gesture"></i><span>mdi-cursor-default-gesture</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cursor-default-gesture-outline"></i><span>mdi-cursor-default-gesture-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cursor-default-outline"></i><span>mdi-cursor-default-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cursor-move"></i><span>mdi-cursor-move</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cursor-pointer"></i><span>mdi-cursor-pointer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cursor-text"></i><span>mdi-cursor-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-curtains"></i><span>mdi-curtains</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-curtains-closed"></i><span>mdi-curtains-closed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cylinder"></i><span>mdi-cylinder</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-cylinder-off"></i><span>mdi-cylinder-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dance-ballroom"></i><span>mdi-dance-ballroom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dance-pole"></i><span>mdi-dance-pole</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-data-matrix"></i><span>mdi-data-matrix</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-data-matrix-edit"></i><span>mdi-data-matrix-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-data-matrix-minus"></i><span>mdi-data-matrix-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-data-matrix-plus"></i><span>mdi-data-matrix-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-data-matrix-remove"></i><span>mdi-data-matrix-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-data-matrix-scan"></i><span>mdi-data-matrix-scan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database"></i><span>mdi-database</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-alert"></i><span>mdi-database-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-border-2 bg-body-secondary"></i><span>mdi-database-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-arrow-down"></i><span>mdi-database-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-arrow-down-outline"></i><span>mdi-database-arrow-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-arrow-left"></i><span>mdi-database-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-arrow-left-outline"></i><span>mdi-database-arrow-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-arrow-right"></i><span>mdi-database-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-arrow-right-outline"></i><span>mdi-database-arrow-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-arrow-up"></i><span>mdi-database-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-arrow-up-outline"></i><span>mdi-database-arrow-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-check"></i><span>mdi-database-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-check-outline"></i><span>mdi-database-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-clock"></i><span>mdi-database-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-clock-outline"></i><span>mdi-database-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-cog"></i><span>mdi-database-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-cog-outline"></i><span>mdi-database-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-edit"></i><span>mdi-database-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-edit-outline"></i><span>mdi-database-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-export"></i><span>mdi-database-export</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-export-outline"></i><span>mdi-database-export-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-eye"></i><span>mdi-database-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-eye-off"></i><span>mdi-database-eye-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-eye-off-outline"></i><span>mdi-database-eye-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-eye-outline"></i><span>mdi-database-eye-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-import"></i><span>mdi-database-import</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-import-outline"></i><span>mdi-database-import-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-lock"></i><span>mdi-database-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-lock-outline"></i><span>mdi-database-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-marker"></i><span>mdi-database-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-marker-outline"></i><span>mdi-database-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-minus"></i><span>mdi-database-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-minus-outline"></i><span>mdi-database-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-off"></i><span>mdi-database-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-off-outline"></i><span>mdi-database-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-outline"></i><span>mdi-database-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-plus"></i><span>mdi-database-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-plus-outline"></i><span>mdi-database-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-refresh"></i><span>mdi-database-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-refresh-outline"></i><span>mdi-database-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-remove"></i><span>mdi-database-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-remove-outline"></i><span>mdi-database-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-search"></i><span>mdi-database-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-search-outline"></i><span>mdi-database-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-settings"></i><span>mdi-database-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-settings-outline"></i><span>mdi-database-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-sync"></i><span>mdi-database-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-database-sync-outline"></i><span>mdi-database-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-death-star"></i><span>mdi-death-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-death-star-variant"></i><span>mdi-death-star-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-deathly-hallows"></i><span>mdi-deathly-hallows</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-debian"></i><span>mdi-debian</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-debug-step-into"></i><span>mdi-debug-step-into</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-debug-step-out"></i><span>mdi-debug-step-out</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-debug-step-over"></i><span>mdi-debug-step-over</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-decagram"></i><span>mdi-decagram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-decagram-outline"></i><span>mdi-decagram-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-decimal"></i><span>mdi-decimal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-decimal-comma"></i><span>mdi-decimal-comma</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-decimal-comma-decrease"></i><span>mdi-decimal-comma-decrease</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-decimal-comma-increase"></i><span>mdi-decimal-comma-increase</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-decimal-decrease"></i><span>mdi-decimal-decrease</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-decimal-increase"></i><span>mdi-decimal-increase</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete"></i><span>mdi-delete</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-alert"></i><span>mdi-delete-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-border-2 bg-body-secondary"></i><span>mdi-delete-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-circle"></i><span>mdi-delete-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-circle-outline"></i><span>mdi-delete-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-clock"></i><span>mdi-delete-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-clock-outline"></i><span>mdi-delete-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-empty"></i><span>mdi-delete-empty</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-empty-outline"></i><span>mdi-delete-empty-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-forever"></i><span>mdi-delete-forever</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-forever-outline"></i><span>mdi-delete-forever-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-off"></i><span>mdi-delete-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-off-outline"></i><span>mdi-delete-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-outline"></i><span>mdi-delete-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-restore"></i><span>mdi-delete-restore</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-sweep"></i><span>mdi-delete-sweep</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-sweep-outline"></i><span>mdi-delete-sweep-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delete-variant"></i><span>mdi-delete-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-delta"></i><span>mdi-delta</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-desk"></i><span>mdi-desk</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-desk-lamp"></i><span>mdi-desk-lamp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-deskphone"></i><span>mdi-deskphone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-desktop-classic"></i><span>mdi-desktop-classic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-desktop-mac"></i><span>mdi-desktop-mac</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-desktop-mac-dashboard"></i><span>mdi-desktop-mac-dashboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-desktop-tower"></i><span>mdi-desktop-tower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-desktop-tower-monitor"></i><span>mdi-desktop-tower-monitor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-details"></i><span>mdi-details</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dev-to"></i><span>mdi-dev-to</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-developer-board"></i><span>mdi-developer-board</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-deviantart"></i><span>mdi-deviantart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-devices"></i><span>mdi-devices</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dharmachakra"></i><span>mdi-dharmachakra</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diabetes"></i><span>mdi-diabetes</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dialpad"></i><span>mdi-dialpad</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diameter"></i><span>mdi-diameter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diameter-outline"></i><span>mdi-diameter-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diameter-variant"></i><span>mdi-diameter-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diamond"></i><span>mdi-diamond</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diamond-outline"></i><span>mdi-diamond-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diamond-stone"></i><span>mdi-diamond-stone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-1"></i><span>mdi-dice-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-1-outline"></i><span>mdi-dice-1-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-2"></i><span>mdi-dice-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-2-outline"></i><span>mdi-dice-2-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-3"></i><span>mdi-dice-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-3-outline"></i><span>mdi-dice-3-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-4"></i><span>mdi-dice-4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-4-outline"></i><span>mdi-dice-4-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-5"></i><span>mdi-dice-5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-5-outline"></i><span>mdi-dice-5-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-6"></i><span>mdi-dice-6</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-6-outline"></i><span>mdi-dice-6-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d10"></i><span>mdi-dice-d10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d10-outline"></i><span>mdi-dice-d10-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d12"></i><span>mdi-dice-d12</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d12-outline"></i><span>mdi-dice-d12-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d20"></i><span>mdi-dice-d20</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d20-outline"></i><span>mdi-dice-d20-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d4"></i><span>mdi-dice-d4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d4-outline"></i><span>mdi-dice-d4-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d6"></i><span>mdi-dice-d6</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d6-outline"></i><span>mdi-dice-d6-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d8"></i><span>mdi-dice-d8</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-d8-outline"></i><span>mdi-dice-d8-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-multiple"></i><span>mdi-dice-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dice-multiple-outline"></i><span>mdi-dice-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-digital-ocean"></i><span>mdi-digital-ocean</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dip-switch"></i><span>mdi-dip-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-directions"></i><span>mdi-directions</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-directions-fork"></i><span>mdi-directions-fork</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-disc"></i><span>mdi-disc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-disc-alert"></i><span>mdi-disc-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-disc-player"></i><span>mdi-disc-player</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-discord"></i><span>mdi-discord</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dishwasher"></i><span>mdi-dishwasher</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dishwasher-alert"></i><span>mdi-dishwasher-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dishwasher-off"></i><span>mdi-dishwasher-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-disqus"></i><span>mdi-disqus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-distribute-horizontal-center"></i><span>mdi-distribute-horizontal-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-distribute-horizontal-left"></i><span>mdi-distribute-horizontal-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-distribute-horizontal-right"></i><span>mdi-distribute-horizontal-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-distribute-vertical-bottom"></i><span>mdi-distribute-vertical-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-distribute-vertical-center"></i><span>mdi-distribute-vertical-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-distribute-vertical-top"></i><span>mdi-distribute-vertical-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diversify"></i><span>mdi-diversify</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diving"></i><span>mdi-diving</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diving-flippers"></i><span>mdi-diving-flippers</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diving-helmet"></i><span>mdi-diving-helmet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diving-scuba"></i><span>mdi-diving-scuba</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diving-scuba-flag"></i><span>mdi-diving-scuba-flag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diving-scuba-tank"></i><span>mdi-diving-scuba-tank</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diving-scuba-tank-multiple"></i><span>mdi-diving-scuba-tank-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-diving-snorkel"></i><span>mdi-diving-snorkel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-division"></i><span>mdi-division</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-division-box"></i><span>mdi-division-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dlna"></i><span>mdi-dlna</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dna"></i><span>mdi-dna</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dns"></i><span>mdi-dns</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dns-outline"></i><span>mdi-dns-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dock-bottom"></i><span>mdi-dock-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dock-left"></i><span>mdi-dock-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dock-right"></i><span>mdi-dock-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dock-top"></i><span>mdi-dock-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dock-window"></i><span>mdi-dock-window</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-docker"></i><span>mdi-docker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-doctor"></i><span>mdi-doctor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dog"></i><span>mdi-dog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dog-service"></i><span>mdi-dog-service</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dog-side"></i><span>mdi-dog-side</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dog-side-off"></i><span>mdi-dog-side-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dolby"></i><span>mdi-dolby</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dolly"></i><span>mdi-dolly</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dolphin"></i><span>mdi-dolphin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-domain"></i><span>mdi-domain</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-domain-off"></i><span>mdi-domain-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-domain-plus"></i><span>mdi-domain-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-domain-remove"></i><span>mdi-domain-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dome-light"></i><span>mdi-dome-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-domino-mask"></i><span>mdi-domino-mask</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-donkey"></i><span>mdi-donkey</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-door"></i><span>mdi-door</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-door-closed"></i><span>mdi-door-closed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-door-closed-lock"></i><span>mdi-door-closed-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-door-open"></i><span>mdi-door-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-door-sliding"></i><span>mdi-door-sliding</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-door-sliding-lock"></i><span>mdi-door-sliding-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-door-sliding-open"></i><span>mdi-door-sliding-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-doorbell"></i><span>mdi-doorbell</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-doorbell-video"></i><span>mdi-doorbell-video</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dot-net"></i><span>mdi-dot-net</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-circle"></i><span>mdi-dots-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-grid"></i><span>mdi-dots-grid</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-hexagon"></i><span>mdi-dots-hexagon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-horizontal"></i><span>mdi-dots-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-horizontal-circle"></i><span>mdi-dots-horizontal-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-horizontal-circle-outline"></i><span>mdi-dots-horizontal-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-square"></i><span>mdi-dots-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-triangle"></i><span>mdi-dots-triangle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-vertical"></i><span>mdi-dots-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-vertical-circle"></i><span>mdi-dots-vertical-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dots-vertical-circle-outline"></i><span>mdi-dots-vertical-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download"></i><span>mdi-download</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-box"></i><span>mdi-download-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-box-outline"></i><span>mdi-download-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-circle"></i><span>mdi-download-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-circle-outline"></i><span>mdi-download-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-lock"></i><span>mdi-download-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-lock-outline"></i><span>mdi-download-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-multiple"></i><span>mdi-download-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-network"></i><span>mdi-download-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-network-outline"></i><span>mdi-download-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-off"></i><span>mdi-download-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-off-outline"></i><span>mdi-download-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-download-outline"></i><span>mdi-download-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drag"></i><span>mdi-drag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drag-horizontal"></i><span>mdi-drag-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drag-horizontal-variant"></i><span>mdi-drag-horizontal-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drag-variant"></i><span>mdi-drag-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drag-vertical"></i><span>mdi-drag-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drag-vertical-variant"></i><span>mdi-drag-vertical-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drama-masks"></i><span>mdi-drama-masks</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-draw"></i><span>mdi-draw</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-draw-pen"></i><span>mdi-draw-pen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drawing"></i><span>mdi-drawing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drawing-box"></i><span>mdi-drawing-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dresser"></i><span>mdi-dresser</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dresser-outline"></i><span>mdi-dresser-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drone"></i><span>mdi-drone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dropbox"></i><span>mdi-dropbox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-drupal"></i><span>mdi-drupal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-duck"></i><span>mdi-duck</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dumbbell"></i><span>mdi-dumbbell</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-dump-truck"></i><span>mdi-dump-truck</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ear-hearing"></i><span>mdi-ear-hearing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ear-hearing-off"></i><span>mdi-ear-hearing-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earbuds"></i><span>mdi-earbuds</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earbuds-off"></i><span>mdi-earbuds-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earbuds-off-outline"></i><span>mdi-earbuds-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earbuds-outline"></i><span>mdi-earbuds-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth"></i><span>mdi-earth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-arrow-right"></i><span>mdi-earth-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-box"></i><span>mdi-earth-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-box-minus"></i><span>mdi-earth-box-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-box-off"></i><span>mdi-earth-box-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-box-plus"></i><span>mdi-earth-box-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-box-remove"></i><span>mdi-earth-box-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-minus"></i><span>mdi-earth-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-off"></i><span>mdi-earth-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-plus"></i><span>mdi-earth-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-earth-remove"></i><span>mdi-earth-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-egg"></i><span>mdi-egg</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-egg-easter"></i><span>mdi-egg-easter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-egg-fried"></i><span>mdi-egg-fried</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-egg-off"></i><span>mdi-egg-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-egg-off-outline"></i><span>mdi-egg-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-egg-outline"></i><span>mdi-egg-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eiffel-tower"></i><span>mdi-eiffel-tower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eight-track"></i><span>mdi-eight-track</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eject"></i><span>mdi-eject</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eject-outline"></i><span>mdi-eject-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-electric-switch"></i><span>mdi-electric-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-electric-switch-closed"></i><span>mdi-electric-switch-closed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-electron-framework"></i><span>mdi-electron-framework</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elephant"></i><span>mdi-elephant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elevation-decline"></i><span>mdi-elevation-decline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elevation-rise"></i><span>mdi-elevation-rise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elevator"></i><span>mdi-elevator</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elevator-down"></i><span>mdi-elevator-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elevator-passenger"></i><span>mdi-elevator-passenger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elevator-passenger-off"></i><span>mdi-elevator-passenger-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elevator-passenger-off-outline"></i><span>mdi-elevator-passenger-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elevator-passenger-outline"></i><span>mdi-elevator-passenger-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-elevator-up"></i><span>mdi-elevator-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ellipse"></i><span>mdi-ellipse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ellipse-outline"></i><span>mdi-ellipse-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email"></i><span>mdi-email</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-alert"></i><span>mdi-email-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-border-2 bg-body-secondary"></i><span>mdi-email-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-box"></i><span>mdi-email-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-check"></i><span>mdi-email-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-check-outline"></i><span>mdi-email-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-edit"></i><span>mdi-email-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-edit-outline"></i><span>mdi-email-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-fast"></i><span>mdi-email-fast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-fast-outline"></i><span>mdi-email-fast-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-lock"></i><span>mdi-email-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-mark-as-unread"></i><span>mdi-email-mark-as-unread</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-minus"></i><span>mdi-email-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-minus-outline"></i><span>mdi-email-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-multiple"></i><span>mdi-email-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-multiple-outline"></i><span>mdi-email-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-newsletter"></i><span>mdi-email-newsletter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-off"></i><span>mdi-email-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-off-outline"></i><span>mdi-email-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-open"></i><span>mdi-email-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-open-multiple"></i><span>mdi-email-open-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-open-multiple-outline"></i><span>mdi-email-open-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-open-outline"></i><span>mdi-email-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-outline"></i><span>mdi-email-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-plus"></i><span>mdi-email-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-plus-outline"></i><span>mdi-email-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-receive"></i><span>mdi-email-receive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-receive-outline"></i><span>mdi-email-receive-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-remove"></i><span>mdi-email-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-remove-outline"></i><span>mdi-email-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-seal"></i><span>mdi-email-seal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-seal-outline"></i><span>mdi-email-seal-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-search"></i><span>mdi-email-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-search-outline"></i><span>mdi-email-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-send"></i><span>mdi-email-send</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-send-outline"></i><span>mdi-email-send-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-sync"></i><span>mdi-email-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-sync-outline"></i><span>mdi-email-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-email-variant"></i><span>mdi-email-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ember"></i><span>mdi-ember</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emby"></i><span>mdi-emby</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon"></i><span>mdi-emoticon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-angry"></i><span>mdi-emoticon-angry</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-angry-outline"></i><span>mdi-emoticon-angry-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-confused"></i><span>mdi-emoticon-confused</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-confused-outline"></i><span>mdi-emoticon-confused-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-cool"></i><span>mdi-emoticon-cool</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-cool-outline"></i><span>mdi-emoticon-cool-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-cry"></i><span>mdi-emoticon-cry</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-cry-outline"></i><span>mdi-emoticon-cry-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-dead"></i><span>mdi-emoticon-dead</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-dead-outline"></i><span>mdi-emoticon-dead-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-devil"></i><span>mdi-emoticon-devil</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-devil-outline"></i><span>mdi-emoticon-devil-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-excited"></i><span>mdi-emoticon-excited</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-excited-outline"></i><span>mdi-emoticon-excited-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-frown"></i><span>mdi-emoticon-frown</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-frown-outline"></i><span>mdi-emoticon-frown-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-happy"></i><span>mdi-emoticon-happy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-happy-outline"></i><span>mdi-emoticon-happy-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-kiss"></i><span>mdi-emoticon-kiss</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-kiss-outline"></i><span>mdi-emoticon-kiss-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-lol"></i><span>mdi-emoticon-lol</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-lol-outline"></i><span>mdi-emoticon-lol-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-neutral"></i><span>mdi-emoticon-neutral</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-neutral-outline"></i><span>mdi-emoticon-neutral-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-outline"></i><span>mdi-emoticon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-poop"></i><span>mdi-emoticon-poop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-poop-outline"></i><span>mdi-emoticon-poop-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-sad"></i><span>mdi-emoticon-sad</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-sad-outline"></i><span>mdi-emoticon-sad-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-sick"></i><span>mdi-emoticon-sick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-sick-outline"></i><span>mdi-emoticon-sick-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-tongue"></i><span>mdi-emoticon-tongue</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-tongue-outline"></i><span>mdi-emoticon-tongue-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-wink"></i><span>mdi-emoticon-wink</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-emoticon-wink-outline"></i><span>mdi-emoticon-wink-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-engine"></i><span>mdi-engine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-engine-off"></i><span>mdi-engine-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-engine-off-outline"></i><span>mdi-engine-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-engine-outline"></i><span>mdi-engine-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-epsilon"></i><span>mdi-epsilon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-equal"></i><span>mdi-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-equal-box"></i><span>mdi-equal-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-equalizer"></i><span>mdi-equalizer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-equalizer-outline"></i><span>mdi-equalizer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eraser"></i><span>mdi-eraser</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eraser-variant"></i><span>mdi-eraser-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-escalator"></i><span>mdi-escalator</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-escalator-box"></i><span>mdi-escalator-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-escalator-down"></i><span>mdi-escalator-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-escalator-up"></i><span>mdi-escalator-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eslint"></i><span>mdi-eslint</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-et"></i><span>mdi-et</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ethereum"></i><span>mdi-ethereum</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ethernet"></i><span>mdi-ethernet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ethernet-cable"></i><span>mdi-ethernet-cable</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ethernet-cable-off"></i><span>mdi-ethernet-cable-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ev-plug-ccs1"></i><span>mdi-ev-plug-ccs1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ev-plug-ccs2"></i><span>mdi-ev-plug-ccs2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ev-plug-chademo"></i><span>mdi-ev-plug-chademo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ev-plug-tesla"></i><span>mdi-ev-plug-tesla</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ev-plug-type1"></i><span>mdi-ev-plug-type1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ev-plug-type2"></i><span>mdi-ev-plug-type2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ev-station"></i><span>mdi-ev-station</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-evernote"></i><span>mdi-evernote</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-excavator"></i><span>mdi-excavator</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-exclamation"></i><span>mdi-exclamation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-exclamation-thick"></i><span>mdi-exclamation-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-exit-run"></i><span>mdi-exit-run</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-exit-to-app"></i><span>mdi-exit-to-app</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-expand-all"></i><span>mdi-expand-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-expand-all-outline"></i><span>mdi-expand-all-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-expansion-card"></i><span>mdi-expansion-card</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-expansion-card-variant"></i><span>mdi-expansion-card-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-exponent"></i><span>mdi-exponent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-exponent-box"></i><span>mdi-exponent-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-export"></i><span>mdi-export</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-export-variant"></i><span>mdi-export-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye"></i><span>mdi-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-arrow-left"></i><span>mdi-eye-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-arrow-left-outline"></i><span>mdi-eye-arrow-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-arrow-right"></i><span>mdi-eye-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-arrow-right-outline"></i><span>mdi-eye-arrow-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-check"></i><span>mdi-eye-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-check-outline"></i><span>mdi-eye-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-circle"></i><span>mdi-eye-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-circle-outline"></i><span>mdi-eye-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-minus"></i><span>mdi-eye-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-minus-outline"></i><span>mdi-eye-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-off"></i><span>mdi-eye-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-off-outline"></i><span>mdi-eye-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-outline"></i><span>mdi-eye-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-plus"></i><span>mdi-eye-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-plus-outline"></i><span>mdi-eye-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-refresh"></i><span>mdi-eye-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-refresh-outline"></i><span>mdi-eye-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-remove"></i><span>mdi-eye-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-remove-outline"></i><span>mdi-eye-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-settings"></i><span>mdi-eye-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eye-settings-outline"></i><span>mdi-eye-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eyedropper"></i><span>mdi-eyedropper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eyedropper-minus"></i><span>mdi-eyedropper-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eyedropper-off"></i><span>mdi-eyedropper-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eyedropper-plus"></i><span>mdi-eyedropper-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eyedropper-remove"></i><span>mdi-eyedropper-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-eyedropper-variant"></i><span>mdi-eyedropper-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-agent"></i><span>mdi-face-agent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-man"></i><span>mdi-face-man</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-man-outline"></i><span>mdi-face-man-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-man-profile"></i><span>mdi-face-man-profile</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-man-shimmer"></i><span>mdi-face-man-shimmer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-man-shimmer-outline"></i><span>mdi-face-man-shimmer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-mask"></i><span>mdi-face-mask</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-mask-outline"></i><span>mdi-face-mask-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-recognition"></i><span>mdi-face-recognition</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-woman"></i><span>mdi-face-woman</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-woman-outline"></i><span>mdi-face-woman-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-woman-profile"></i><span>mdi-face-woman-profile</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-woman-shimmer"></i><span>mdi-face-woman-shimmer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-face-woman-shimmer-outline"></i><span>mdi-face-woman-shimmer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-facebook"></i><span>mdi-facebook</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-facebook-gaming"></i><span>mdi-facebook-gaming</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-facebook-messenger"></i><span>mdi-facebook-messenger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-facebook-workplace"></i><span>mdi-facebook-workplace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-factory"></i><span>mdi-factory</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-family-tree"></i><span>mdi-family-tree</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan"></i><span>mdi-fan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-alert"></i><span>mdi-fan-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-auto"></i><span>mdi-fan-auto</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-chevron-down"></i><span>mdi-fan-chevron-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-chevron-up"></i><span>mdi-fan-chevron-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-minus"></i><span>mdi-fan-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-off"></i><span>mdi-fan-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-plus"></i><span>mdi-fan-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-remove"></i><span>mdi-fan-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-speed-1"></i><span>mdi-fan-speed-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-speed-2"></i><span>mdi-fan-speed-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fan-speed-3"></i><span>mdi-fan-speed-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fast-forward"></i><span>mdi-fast-forward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fast-forward-10"></i><span>mdi-fast-forward-10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fast-forward-15"></i><span>mdi-fast-forward-15</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fast-forward-30"></i><span>mdi-fast-forward-30</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fast-forward-5"></i><span>mdi-fast-forward-5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fast-forward-60"></i><span>mdi-fast-forward-60</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fast-forward-outline"></i><span>mdi-fast-forward-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fax"></i><span>mdi-fax</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-feather"></i><span>mdi-feather</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-feature-search"></i><span>mdi-feature-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-feature-search-outline"></i><span>mdi-feature-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fedora"></i><span>mdi-fedora</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fence"></i><span>mdi-fence</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fence-electric"></i><span>mdi-fence-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fencing"></i><span>mdi-fencing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ferris-wheel"></i><span>mdi-ferris-wheel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ferry"></i><span>mdi-ferry</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file"></i><span>mdi-file</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-account"></i><span>mdi-file-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-account-outline"></i><span>mdi-file-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-alert"></i><span>mdi-file-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-border-2 bg-body-secondary"></i><span>mdi-file-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-cabinet"></i><span>mdi-file-cabinet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-cad"></i><span>mdi-file-cad</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-cad-box"></i><span>mdi-file-cad-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-cancel"></i><span>mdi-file-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-cancel-outline"></i><span>mdi-file-cancel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-certificate"></i><span>mdi-file-certificate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-certificate-outline"></i><span>mdi-file-certificate-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-chart"></i><span>mdi-file-chart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-chart-outline"></i><span>mdi-file-chart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-check"></i><span>mdi-file-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-check-outline"></i><span>mdi-file-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-clock"></i><span>mdi-file-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-clock-outline"></i><span>mdi-file-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-cloud"></i><span>mdi-file-cloud</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-cloud-outline"></i><span>mdi-file-cloud-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-code"></i><span>mdi-file-code</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-code-outline"></i><span>mdi-file-code-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-cog"></i><span>mdi-file-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-cog-outline"></i><span>mdi-file-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-compare"></i><span>mdi-file-compare</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-delimited"></i><span>mdi-file-delimited</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-delimited-outline"></i><span>mdi-file-delimited-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-document"></i><span>mdi-file-document</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-document-edit"></i><span>mdi-file-document-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-document-edit-outline"></i><span>mdi-file-document-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-document-multiple"></i><span>mdi-file-document-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-document-multiple-outline"></i><span>mdi-file-document-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-document-outline"></i><span>mdi-file-document-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-download"></i><span>mdi-file-download</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-download-outline"></i><span>mdi-file-download-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-edit"></i><span>mdi-file-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-edit-outline"></i><span>mdi-file-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-excel"></i><span>mdi-file-excel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-excel-box"></i><span>mdi-file-excel-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-excel-box-outline"></i><span>mdi-file-excel-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-excel-outline"></i><span>mdi-file-excel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-export"></i><span>mdi-file-export</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-export-outline"></i><span>mdi-file-export-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-eye"></i><span>mdi-file-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-eye-outline"></i><span>mdi-file-eye-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-find"></i><span>mdi-file-find</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-find-outline"></i><span>mdi-file-find-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-gif-box"></i><span>mdi-file-gif-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-hidden"></i><span>mdi-file-hidden</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image"></i><span>mdi-file-image</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image-marker"></i><span>mdi-file-image-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image-marker-outline"></i><span>mdi-file-image-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image-minus"></i><span>mdi-file-image-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image-minus-outline"></i><span>mdi-file-image-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image-outline"></i><span>mdi-file-image-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image-plus"></i><span>mdi-file-image-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image-plus-outline"></i><span>mdi-file-image-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image-remove"></i><span>mdi-file-image-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-image-remove-outline"></i><span>mdi-file-image-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-import"></i><span>mdi-file-import</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-import-outline"></i><span>mdi-file-import-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-jpg-box"></i><span>mdi-file-jpg-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-key"></i><span>mdi-file-key</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-key-outline"></i><span>mdi-file-key-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-link"></i><span>mdi-file-link</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-link-outline"></i><span>mdi-file-link-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-lock"></i><span>mdi-file-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-lock-outline"></i><span>mdi-file-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-marker"></i><span>mdi-file-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-marker-outline"></i><span>mdi-file-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-move"></i><span>mdi-file-move</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-move-outline"></i><span>mdi-file-move-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-multiple"></i><span>mdi-file-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-multiple-outline"></i><span>mdi-file-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-music"></i><span>mdi-file-music</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-music-outline"></i><span>mdi-file-music-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-outline"></i><span>mdi-file-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-pdf-box"></i><span>mdi-file-pdf-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-percent"></i><span>mdi-file-percent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-percent-outline"></i><span>mdi-file-percent-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-phone"></i><span>mdi-file-phone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-phone-outline"></i><span>mdi-file-phone-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-plus"></i><span>mdi-file-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-plus-outline"></i><span>mdi-file-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-png-box"></i><span>mdi-file-png-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-powerpoint"></i><span>mdi-file-powerpoint</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-powerpoint-box"></i><span>mdi-file-powerpoint-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-powerpoint-box-outline"></i><span>mdi-file-powerpoint-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-powerpoint-outline"></i><span>mdi-file-powerpoint-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-presentation-box"></i><span>mdi-file-presentation-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-question"></i><span>mdi-file-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-question-outline"></i><span>mdi-file-question-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-refresh"></i><span>mdi-file-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-refresh-outline"></i><span>mdi-file-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-remove"></i><span>mdi-file-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-remove-outline"></i><span>mdi-file-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-replace"></i><span>mdi-file-replace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-replace-outline"></i><span>mdi-file-replace-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-restore"></i><span>mdi-file-restore</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-restore-outline"></i><span>mdi-file-restore-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-search"></i><span>mdi-file-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-search-outline"></i><span>mdi-file-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-send"></i><span>mdi-file-send</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-send-outline"></i><span>mdi-file-send-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-settings"></i><span>mdi-file-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-settings-outline"></i><span>mdi-file-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-sign"></i><span>mdi-file-sign</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-star"></i><span>mdi-file-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-star-outline"></i><span>mdi-file-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-swap"></i><span>mdi-file-swap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-swap-outline"></i><span>mdi-file-swap-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-sync"></i><span>mdi-file-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-sync-outline"></i><span>mdi-file-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-table"></i><span>mdi-file-table</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-table-box"></i><span>mdi-file-table-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-table-box-multiple"></i><span>mdi-file-table-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-table-box-multiple-outline"></i><span>mdi-file-table-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-table-box-outline"></i><span>mdi-file-table-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-table-outline"></i><span>mdi-file-table-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-tree"></i><span>mdi-file-tree</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-tree-outline"></i><span>mdi-file-tree-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-undo"></i><span>mdi-file-undo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-undo-outline"></i><span>mdi-file-undo-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-upload"></i><span>mdi-file-upload</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-upload-outline"></i><span>mdi-file-upload-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-video"></i><span>mdi-file-video</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-video-outline"></i><span>mdi-file-video-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-word"></i><span>mdi-file-word</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-word-box"></i><span>mdi-file-word-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-word-box-outline"></i><span>mdi-file-word-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-file-word-outline"></i><span>mdi-file-word-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-film"></i><span>mdi-film</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filmstrip"></i><span>mdi-filmstrip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filmstrip-box"></i><span>mdi-filmstrip-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filmstrip-box-multiple"></i><span>mdi-filmstrip-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filmstrip-off"></i><span>mdi-filmstrip-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter"></i><span>mdi-filter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-check"></i><span>mdi-filter-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-check-outline"></i><span>mdi-filter-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-menu"></i><span>mdi-filter-menu</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-menu-outline"></i><span>mdi-filter-menu-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-minus"></i><span>mdi-filter-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-minus-outline"></i><span>mdi-filter-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-off"></i><span>mdi-filter-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-off-outline"></i><span>mdi-filter-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-outline"></i><span>mdi-filter-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-plus"></i><span>mdi-filter-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-plus-outline"></i><span>mdi-filter-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-remove"></i><span>mdi-filter-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-remove-outline"></i><span>mdi-filter-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-variant"></i><span>mdi-filter-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-variant-minus"></i><span>mdi-filter-variant-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-variant-plus"></i><span>mdi-filter-variant-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-filter-variant-remove"></i><span>mdi-filter-variant-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-finance"></i><span>mdi-finance</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-find-replace"></i><span>mdi-find-replace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fingerprint"></i><span>mdi-fingerprint</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fingerprint-off"></i><span>mdi-fingerprint-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fire"></i><span>mdi-fire</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fire-alert"></i><span>mdi-fire-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fire-circle"></i><span>mdi-fire-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fire-extinguisher"></i><span>mdi-fire-extinguisher</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fire-hydrant"></i><span>mdi-fire-hydrant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fire-hydrant-alert"></i><span>mdi-fire-hydrant-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fire-hydrant-off"></i><span>mdi-fire-hydrant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fire-off"></i><span>mdi-fire-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fire-truck"></i><span>mdi-fire-truck</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-firebase"></i><span>mdi-firebase</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-firefox"></i><span>mdi-firefox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fireplace"></i><span>mdi-fireplace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fireplace-off"></i><span>mdi-fireplace-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-firewire"></i><span>mdi-firewire</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-firework"></i><span>mdi-firework</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-firework-off"></i><span>mdi-firework-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fish"></i><span>mdi-fish</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fish-off"></i><span>mdi-fish-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fishbowl"></i><span>mdi-fishbowl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fishbowl-outline"></i><span>mdi-fishbowl-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fit-to-page"></i><span>mdi-fit-to-page</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fit-to-page-outline"></i><span>mdi-fit-to-page-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fit-to-screen"></i><span>mdi-fit-to-screen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fit-to-screen-outline"></i><span>mdi-fit-to-screen-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag"></i><span>mdi-flag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-checkered"></i><span>mdi-flag-checkered</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-minus"></i><span>mdi-flag-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-minus-outline"></i><span>mdi-flag-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-off"></i><span>mdi-flag-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-off-outline"></i><span>mdi-flag-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-outline"></i><span>mdi-flag-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-plus"></i><span>mdi-flag-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-plus-outline"></i><span>mdi-flag-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-remove"></i><span>mdi-flag-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-remove-outline"></i><span>mdi-flag-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-triangle"></i><span>mdi-flag-triangle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-variant"></i><span>mdi-flag-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flag-variant-outline"></i><span>mdi-flag-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flare"></i><span>mdi-flare</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flash"></i><span>mdi-flash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flash-alert"></i><span>mdi-flash-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flash-border-2 bg-body-secondary"></i><span>mdi-flash-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flash-auto"></i><span>mdi-flash-auto</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flash-off"></i><span>mdi-flash-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flash-outline"></i><span>mdi-flash-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flash-red-eye"></i><span>mdi-flash-red-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flashlight"></i><span>mdi-flashlight</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flashlight-off"></i><span>mdi-flashlight-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask"></i><span>mdi-flask</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty"></i><span>mdi-flask-empty</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty-minus"></i><span>mdi-flask-empty-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty-minus-outline"></i><span>mdi-flask-empty-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty-off"></i><span>mdi-flask-empty-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty-off-outline"></i><span>mdi-flask-empty-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty-outline"></i><span>mdi-flask-empty-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty-plus"></i><span>mdi-flask-empty-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty-plus-outline"></i><span>mdi-flask-empty-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty-remove"></i><span>mdi-flask-empty-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-empty-remove-outline"></i><span>mdi-flask-empty-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-minus"></i><span>mdi-flask-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-minus-outline"></i><span>mdi-flask-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-off"></i><span>mdi-flask-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-off-outline"></i><span>mdi-flask-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-outline"></i><span>mdi-flask-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-plus"></i><span>mdi-flask-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-plus-outline"></i><span>mdi-flask-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-remove"></i><span>mdi-flask-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-remove-outline"></i><span>mdi-flask-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-round-bottom"></i><span>mdi-flask-round-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-round-bottom-empty"></i><span>mdi-flask-round-bottom-empty</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-round-bottom-empty-outline"></i><span>mdi-flask-round-bottom-empty-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flask-round-bottom-outline"></i><span>mdi-flask-round-bottom-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fleur-de-lis"></i><span>mdi-fleur-de-lis</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flip-horizontal"></i><span>mdi-flip-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flip-to-back"></i><span>mdi-flip-to-back</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flip-to-front"></i><span>mdi-flip-to-front</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flip-vertical"></i><span>mdi-flip-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floor-lamp"></i><span>mdi-floor-lamp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floor-lamp-dual"></i><span>mdi-floor-lamp-dual</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floor-lamp-dual-outline"></i><span>mdi-floor-lamp-dual-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floor-lamp-outline"></i><span>mdi-floor-lamp-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floor-lamp-torchiere"></i><span>mdi-floor-lamp-torchiere</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floor-lamp-torchiere-outline"></i><span>mdi-floor-lamp-torchiere-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floor-lamp-torchiere-variant"></i><span>mdi-floor-lamp-torchiere-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floor-lamp-torchiere-variant-outline"></i><span>mdi-floor-lamp-torchiere-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floor-plan"></i><span>mdi-floor-plan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floppy"></i><span>mdi-floppy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-floppy-variant"></i><span>mdi-floppy-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flower"></i><span>mdi-flower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flower-outline"></i><span>mdi-flower-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flower-pollen"></i><span>mdi-flower-pollen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flower-pollen-outline"></i><span>mdi-flower-pollen-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flower-poppy"></i><span>mdi-flower-poppy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flower-tulip"></i><span>mdi-flower-tulip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-flower-tulip-outline"></i><span>mdi-flower-tulip-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-focus-auto"></i><span>mdi-focus-auto</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-focus-field"></i><span>mdi-focus-field</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-focus-field-horizontal"></i><span>mdi-focus-field-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-focus-field-vertical"></i><span>mdi-focus-field-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder"></i><span>mdi-folder</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-account"></i><span>mdi-folder-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-account-outline"></i><span>mdi-folder-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-alert"></i><span>mdi-folder-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-border-2 bg-body-secondary"></i><span>mdi-folder-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-check"></i><span>mdi-folder-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-check-outline"></i><span>mdi-folder-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-clock"></i><span>mdi-folder-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-clock-outline"></i><span>mdi-folder-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-cog"></i><span>mdi-folder-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-cog-outline"></i><span>mdi-folder-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-download"></i><span>mdi-folder-download</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-download-outline"></i><span>mdi-folder-download-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-edit"></i><span>mdi-folder-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-edit-outline"></i><span>mdi-folder-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-eye"></i><span>mdi-folder-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-eye-outline"></i><span>mdi-folder-eye-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-google-drive"></i><span>mdi-folder-google-drive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-heart"></i><span>mdi-folder-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-heart-outline"></i><span>mdi-folder-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-hidden"></i><span>mdi-folder-hidden</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-home"></i><span>mdi-folder-home</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-home-outline"></i><span>mdi-folder-home-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-image"></i><span>mdi-folder-image</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-information"></i><span>mdi-folder-information</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-information-outline"></i><span>mdi-folder-information-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-key"></i><span>mdi-folder-key</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-key-network"></i><span>mdi-folder-key-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-key-network-outline"></i><span>mdi-folder-key-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-key-outline"></i><span>mdi-folder-key-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-lock"></i><span>mdi-folder-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-lock-open"></i><span>mdi-folder-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-marker"></i><span>mdi-folder-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-marker-outline"></i><span>mdi-folder-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-move"></i><span>mdi-folder-move</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-move-outline"></i><span>mdi-folder-move-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-multiple"></i><span>mdi-folder-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-multiple-image"></i><span>mdi-folder-multiple-image</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-multiple-outline"></i><span>mdi-folder-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-multiple-plus"></i><span>mdi-folder-multiple-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-multiple-plus-outline"></i><span>mdi-folder-multiple-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-music"></i><span>mdi-folder-music</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-music-outline"></i><span>mdi-folder-music-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-network"></i><span>mdi-folder-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-network-outline"></i><span>mdi-folder-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-open"></i><span>mdi-folder-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-open-outline"></i><span>mdi-folder-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-outline"></i><span>mdi-folder-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-plus"></i><span>mdi-folder-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-plus-outline"></i><span>mdi-folder-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-pound"></i><span>mdi-folder-pound</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-pound-outline"></i><span>mdi-folder-pound-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-refresh"></i><span>mdi-folder-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-refresh-outline"></i><span>mdi-folder-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-remove"></i><span>mdi-folder-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-remove-outline"></i><span>mdi-folder-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-search"></i><span>mdi-folder-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-search-outline"></i><span>mdi-folder-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-settings"></i><span>mdi-folder-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-settings-outline"></i><span>mdi-folder-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-star"></i><span>mdi-folder-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-star-multiple"></i><span>mdi-folder-star-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-star-multiple-outline"></i><span>mdi-folder-star-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-star-outline"></i><span>mdi-folder-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-swap"></i><span>mdi-folder-swap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-swap-outline"></i><span>mdi-folder-swap-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-sync"></i><span>mdi-folder-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-sync-outline"></i><span>mdi-folder-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-table"></i><span>mdi-folder-table</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-table-outline"></i><span>mdi-folder-table-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-text"></i><span>mdi-folder-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-text-outline"></i><span>mdi-folder-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-upload"></i><span>mdi-folder-upload</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-upload-outline"></i><span>mdi-folder-upload-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-zip"></i><span>mdi-folder-zip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-folder-zip-outline"></i><span>mdi-folder-zip-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-font-awesome"></i><span>mdi-font-awesome</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food"></i><span>mdi-food</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-apple"></i><span>mdi-food-apple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-apple-outline"></i><span>mdi-food-apple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-croissant"></i><span>mdi-food-croissant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-drumstick"></i><span>mdi-food-drumstick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-drumstick-off"></i><span>mdi-food-drumstick-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-drumstick-off-outline"></i><span>mdi-food-drumstick-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-drumstick-outline"></i><span>mdi-food-drumstick-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-fork-drink"></i><span>mdi-food-fork-drink</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-halal"></i><span>mdi-food-halal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-hot-dog"></i><span>mdi-food-hot-dog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-kosher"></i><span>mdi-food-kosher</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-off"></i><span>mdi-food-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-off-outline"></i><span>mdi-food-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-outline"></i><span>mdi-food-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-steak"></i><span>mdi-food-steak</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-steak-off"></i><span>mdi-food-steak-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-takeout-box"></i><span>mdi-food-takeout-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-takeout-box-outline"></i><span>mdi-food-takeout-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-turkey"></i><span>mdi-food-turkey</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-variant"></i><span>mdi-food-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-food-variant-off"></i><span>mdi-food-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-foot-print"></i><span>mdi-foot-print</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-football"></i><span>mdi-football</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-football-australian"></i><span>mdi-football-australian</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-football-helmet"></i><span>mdi-football-helmet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-forest"></i><span>mdi-forest</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-forklift"></i><span>mdi-forklift</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-form-dropdown"></i><span>mdi-form-dropdown</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-form-select"></i><span>mdi-form-select</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-form-textarea"></i><span>mdi-form-textarea</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-form-textbox"></i><span>mdi-form-textbox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-form-textbox-lock"></i><span>mdi-form-textbox-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-form-textbox-password"></i><span>mdi-form-textbox-password</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-align-bottom"></i><span>mdi-format-align-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-align-center"></i><span>mdi-format-align-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-align-justify"></i><span>mdi-format-align-justify</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-align-left"></i><span>mdi-format-align-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-align-middle"></i><span>mdi-format-align-middle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-align-right"></i><span>mdi-format-align-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-align-top"></i><span>mdi-format-align-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-annotation-minus"></i><span>mdi-format-annotation-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-annotation-plus"></i><span>mdi-format-annotation-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-bold"></i><span>mdi-format-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-clear"></i><span>mdi-format-clear</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-color-fill"></i><span>mdi-format-color-fill</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-color-highlight"></i><span>mdi-format-color-highlight</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-color-marker-cancel"></i><span>mdi-format-color-marker-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-color-text"></i><span>mdi-format-color-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-columns"></i><span>mdi-format-columns</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-float-center"></i><span>mdi-format-float-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-float-left"></i><span>mdi-format-float-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-float-none"></i><span>mdi-format-float-none</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-float-right"></i><span>mdi-format-float-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-font"></i><span>mdi-format-font</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-font-size-decrease"></i><span>mdi-format-font-size-decrease</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-font-size-increase"></i><span>mdi-format-font-size-increase</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-1"></i><span>mdi-format-header-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-2"></i><span>mdi-format-header-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-3"></i><span>mdi-format-header-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-4"></i><span>mdi-format-header-4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-5"></i><span>mdi-format-header-5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-6"></i><span>mdi-format-header-6</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-decrease"></i><span>mdi-format-header-decrease</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-equal"></i><span>mdi-format-header-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-increase"></i><span>mdi-format-header-increase</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-header-pound"></i><span>mdi-format-header-pound</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-horizontal-align-center"></i><span>mdi-format-horizontal-align-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-horizontal-align-left"></i><span>mdi-format-horizontal-align-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-horizontal-align-right"></i><span>mdi-format-horizontal-align-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-indent-decrease"></i><span>mdi-format-indent-decrease</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-indent-increase"></i><span>mdi-format-indent-increase</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-italic"></i><span>mdi-format-italic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-letter-case"></i><span>mdi-format-letter-case</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-letter-case-lower"></i><span>mdi-format-letter-case-lower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-letter-case-upper"></i><span>mdi-format-letter-case-upper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-letter-ends-with"></i><span>mdi-format-letter-ends-with</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-letter-matches"></i><span>mdi-format-letter-matches</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-letter-spacing"></i><span>mdi-format-letter-spacing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-letter-starts-with"></i><span>mdi-format-letter-starts-with</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-line-spacing"></i><span>mdi-format-line-spacing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-line-style"></i><span>mdi-format-line-style</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-line-weight"></i><span>mdi-format-line-weight</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-bulleted"></i><span>mdi-format-list-bulleted</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-bulleted-square"></i><span>mdi-format-list-bulleted-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-bulleted-triangle"></i><span>mdi-format-list-bulleted-triangle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-bulleted-type"></i><span>mdi-format-list-bulleted-type</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-checkbox"></i><span>mdi-format-list-checkbox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-checks"></i><span>mdi-format-list-checks</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-group"></i><span>mdi-format-list-group</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-numbered"></i><span>mdi-format-list-numbered</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-numbered-rtl"></i><span>mdi-format-list-numbered-rtl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-list-text"></i><span>mdi-format-list-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-overline"></i><span>mdi-format-overline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-page-break"></i><span>mdi-format-page-break</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-page-split"></i><span>mdi-format-page-split</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-paint"></i><span>mdi-format-paint</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-paragraph"></i><span>mdi-format-paragraph</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-pilcrow"></i><span>mdi-format-pilcrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-quote-close"></i><span>mdi-format-quote-close</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-quote-close-outline"></i><span>mdi-format-quote-close-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-quote-open"></i><span>mdi-format-quote-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-quote-open-outline"></i><span>mdi-format-quote-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-rotate-90"></i><span>mdi-format-rotate-90</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-section"></i><span>mdi-format-section</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-size"></i><span>mdi-format-size</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-strikethrough"></i><span>mdi-format-strikethrough</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-strikethrough-variant"></i><span>mdi-format-strikethrough-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-subscript"></i><span>mdi-format-subscript</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-superscript"></i><span>mdi-format-superscript</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text"></i><span>mdi-format-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-rotation-angle-down"></i><span>mdi-format-text-rotation-angle-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-rotation-angle-up"></i><span>mdi-format-text-rotation-angle-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-rotation-down"></i><span>mdi-format-text-rotation-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-rotation-down-vertical"></i><span>mdi-format-text-rotation-down-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-rotation-none"></i><span>mdi-format-text-rotation-none</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-rotation-up"></i><span>mdi-format-text-rotation-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-rotation-vertical"></i><span>mdi-format-text-rotation-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-variant"></i><span>mdi-format-text-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-variant-outline"></i><span>mdi-format-text-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-wrapping-clip"></i><span>mdi-format-text-wrapping-clip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-wrapping-overflow"></i><span>mdi-format-text-wrapping-overflow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-text-wrapping-wrap"></i><span>mdi-format-text-wrapping-wrap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-textbox"></i><span>mdi-format-textbox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-textdirection-l-to-r"></i><span>mdi-format-textdirection-l-to-r</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-textdirection-r-to-l"></i><span>mdi-format-textdirection-r-to-l</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-title"></i><span>mdi-format-title</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-underline"></i><span>mdi-format-underline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-underline-wavy"></i><span>mdi-format-underline-wavy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-vertical-align-bottom"></i><span>mdi-format-vertical-align-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-vertical-align-center"></i><span>mdi-format-vertical-align-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-vertical-align-top"></i><span>mdi-format-vertical-align-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-wrap-inline"></i><span>mdi-format-wrap-inline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-wrap-square"></i><span>mdi-format-wrap-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-wrap-tight"></i><span>mdi-format-wrap-tight</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-format-wrap-top-bottom"></i><span>mdi-format-wrap-top-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-forum"></i><span>mdi-forum</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-forum-outline"></i><span>mdi-forum-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-forward"></i><span>mdi-forward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-forwardburger"></i><span>mdi-forwardburger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fountain"></i><span>mdi-fountain</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fountain-pen"></i><span>mdi-fountain-pen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fountain-pen-tip"></i><span>mdi-fountain-pen-tip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fraction-one-half"></i><span>mdi-fraction-one-half</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-freebsd"></i><span>mdi-freebsd</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-french-fries"></i><span>mdi-french-fries</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-frequently-asked-questions"></i><span>mdi-frequently-asked-questions</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge"></i><span>mdi-fridge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-alert"></i><span>mdi-fridge-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-border-2 bg-body-secondary"></i><span>mdi-fridge-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-bottom"></i><span>mdi-fridge-bottom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-industrial"></i><span>mdi-fridge-industrial</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-industrial-alert"></i><span>mdi-fridge-industrial-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-industrial-border-2 bg-body-secondary"></i><span>mdi-fridge-industrial-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-industrial-off"></i><span>mdi-fridge-industrial-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-industrial-off-outline"></i><span>mdi-fridge-industrial-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-industrial-outline"></i><span>mdi-fridge-industrial-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-off"></i><span>mdi-fridge-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-off-outline"></i><span>mdi-fridge-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-outline"></i><span>mdi-fridge-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-top"></i><span>mdi-fridge-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-variant"></i><span>mdi-fridge-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-variant-alert"></i><span>mdi-fridge-variant-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-variant-border-2 bg-body-secondary"></i><span>mdi-fridge-variant-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-variant-off"></i><span>mdi-fridge-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-variant-off-outline"></i><span>mdi-fridge-variant-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fridge-variant-outline"></i><span>mdi-fridge-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fruit-cherries"></i><span>mdi-fruit-cherries</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fruit-cherries-off"></i><span>mdi-fruit-cherries-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fruit-citrus"></i><span>mdi-fruit-citrus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fruit-citrus-off"></i><span>mdi-fruit-citrus-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fruit-grapes"></i><span>mdi-fruit-grapes</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fruit-grapes-outline"></i><span>mdi-fruit-grapes-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fruit-pineapple"></i><span>mdi-fruit-pineapple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fruit-watermelon"></i><span>mdi-fruit-watermelon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fuel"></i><span>mdi-fuel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fuel-cell"></i><span>mdi-fuel-cell</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fullscreen"></i><span>mdi-fullscreen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fullscreen-exit"></i><span>mdi-fullscreen-exit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-function"></i><span>mdi-function</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-function-variant"></i><span>mdi-function-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-furigana-horizontal"></i><span>mdi-furigana-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-furigana-vertical"></i><span>mdi-furigana-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fuse"></i><span>mdi-fuse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fuse-alert"></i><span>mdi-fuse-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fuse-blade"></i><span>mdi-fuse-blade</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-fuse-off"></i><span>mdi-fuse-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad"></i><span>mdi-gamepad</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-circle"></i><span>mdi-gamepad-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-circle-down"></i><span>mdi-gamepad-circle-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-circle-left"></i><span>mdi-gamepad-circle-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-circle-outline"></i><span>mdi-gamepad-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-circle-right"></i><span>mdi-gamepad-circle-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-circle-up"></i><span>mdi-gamepad-circle-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-down"></i><span>mdi-gamepad-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-left"></i><span>mdi-gamepad-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-outline"></i><span>mdi-gamepad-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-right"></i><span>mdi-gamepad-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-round"></i><span>mdi-gamepad-round</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-round-down"></i><span>mdi-gamepad-round-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-round-left"></i><span>mdi-gamepad-round-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-round-outline"></i><span>mdi-gamepad-round-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-round-right"></i><span>mdi-gamepad-round-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-round-up"></i><span>mdi-gamepad-round-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-square"></i><span>mdi-gamepad-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-square-outline"></i><span>mdi-gamepad-square-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-up"></i><span>mdi-gamepad-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-variant"></i><span>mdi-gamepad-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamepad-variant-outline"></i><span>mdi-gamepad-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gamma"></i><span>mdi-gamma</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gantry-crane"></i><span>mdi-gantry-crane</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-garage"></i><span>mdi-garage</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-garage-alert"></i><span>mdi-garage-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-garage-alert-variant"></i><span>mdi-garage-alert-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-garage-lock"></i><span>mdi-garage-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-garage-open"></i><span>mdi-garage-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-garage-open-variant"></i><span>mdi-garage-open-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-garage-variant"></i><span>mdi-garage-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-garage-variant-lock"></i><span>mdi-garage-variant-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gas-cylinder"></i><span>mdi-gas-cylinder</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gas-station"></i><span>mdi-gas-station</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gas-station-off"></i><span>mdi-gas-station-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gas-station-off-outline"></i><span>mdi-gas-station-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gas-station-outline"></i><span>mdi-gas-station-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate"></i><span>mdi-gate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-alert"></i><span>mdi-gate-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-and"></i><span>mdi-gate-and</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-arrow-left"></i><span>mdi-gate-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-arrow-right"></i><span>mdi-gate-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-nand"></i><span>mdi-gate-nand</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-nor"></i><span>mdi-gate-nor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-not"></i><span>mdi-gate-not</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-open"></i><span>mdi-gate-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-or"></i><span>mdi-gate-or</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-xnor"></i><span>mdi-gate-xnor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gate-xor"></i><span>mdi-gate-xor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gatsby"></i><span>mdi-gatsby</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gauge"></i><span>mdi-gauge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gauge-empty"></i><span>mdi-gauge-empty</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gauge-full"></i><span>mdi-gauge-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gauge-low"></i><span>mdi-gauge-low</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gavel"></i><span>mdi-gavel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gender-female"></i><span>mdi-gender-female</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gender-male"></i><span>mdi-gender-male</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gender-male-female"></i><span>mdi-gender-male-female</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gender-male-female-variant"></i><span>mdi-gender-male-female-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gender-non-binary"></i><span>mdi-gender-non-binary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gender-transgender"></i><span>mdi-gender-transgender</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gentoo"></i><span>mdi-gentoo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture"></i><span>mdi-gesture</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-double-tap"></i><span>mdi-gesture-double-tap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-pinch"></i><span>mdi-gesture-pinch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-spread"></i><span>mdi-gesture-spread</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-swipe"></i><span>mdi-gesture-swipe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-swipe-down"></i><span>mdi-gesture-swipe-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-swipe-horizontal"></i><span>mdi-gesture-swipe-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-swipe-left"></i><span>mdi-gesture-swipe-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-swipe-right"></i><span>mdi-gesture-swipe-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-swipe-up"></i><span>mdi-gesture-swipe-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-swipe-vertical"></i><span>mdi-gesture-swipe-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-tap"></i><span>mdi-gesture-tap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-tap-box"></i><span>mdi-gesture-tap-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-tap-button"></i><span>mdi-gesture-tap-button</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-tap-hold"></i><span>mdi-gesture-tap-hold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-two-double-tap"></i><span>mdi-gesture-two-double-tap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gesture-two-tap"></i><span>mdi-gesture-two-tap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ghost"></i><span>mdi-ghost</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ghost-off"></i><span>mdi-ghost-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ghost-off-outline"></i><span>mdi-ghost-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ghost-outline"></i><span>mdi-ghost-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gift"></i><span>mdi-gift</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gift-off"></i><span>mdi-gift-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gift-off-outline"></i><span>mdi-gift-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gift-open"></i><span>mdi-gift-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gift-open-outline"></i><span>mdi-gift-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gift-outline"></i><span>mdi-gift-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-git"></i><span>mdi-git</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-github"></i><span>mdi-github</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gitlab"></i><span>mdi-gitlab</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-cocktail"></i><span>mdi-glass-cocktail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-cocktail-off"></i><span>mdi-glass-cocktail-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-flute"></i><span>mdi-glass-flute</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-fragile"></i><span>mdi-glass-fragile</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-mug"></i><span>mdi-glass-mug</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-mug-off"></i><span>mdi-glass-mug-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-mug-variant"></i><span>mdi-glass-mug-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-mug-variant-off"></i><span>mdi-glass-mug-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-pint-outline"></i><span>mdi-glass-pint-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-stange"></i><span>mdi-glass-stange</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-tulip"></i><span>mdi-glass-tulip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glass-wine"></i><span>mdi-glass-wine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-glasses"></i><span>mdi-glasses</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-globe-light"></i><span>mdi-globe-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-globe-model"></i><span>mdi-globe-model</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gmail"></i><span>mdi-gmail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gnome"></i><span>mdi-gnome</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-go-kart"></i><span>mdi-go-kart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-go-kart-track"></i><span>mdi-go-kart-track</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gog"></i><span>mdi-gog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gold"></i><span>mdi-gold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-golf"></i><span>mdi-golf</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-golf-cart"></i><span>mdi-golf-cart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-golf-tee"></i><span>mdi-golf-tee</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gondola"></i><span>mdi-gondola</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-goodreads"></i><span>mdi-goodreads</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google"></i><span>mdi-google</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-ads"></i><span>mdi-google-ads</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-analytics"></i><span>mdi-google-analytics</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-assistant"></i><span>mdi-google-assistant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-cardboard"></i><span>mdi-google-cardboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-chrome"></i><span>mdi-google-chrome</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-circles"></i><span>mdi-google-circles</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-circles-communities"></i><span>mdi-google-circles-communities</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-circles-extended"></i><span>mdi-google-circles-extended</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-circles-group"></i><span>mdi-google-circles-group</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-classroom"></i><span>mdi-google-classroom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-cloud"></i><span>mdi-google-cloud</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-controller"></i><span>mdi-google-controller</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-controller-off"></i><span>mdi-google-controller-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-downasaur"></i><span>mdi-google-downasaur</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-drive"></i><span>mdi-google-drive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-earth"></i><span>mdi-google-earth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-fit"></i><span>mdi-google-fit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-glass"></i><span>mdi-google-glass</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-hangouts"></i><span>mdi-google-hangouts</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-home"></i><span>mdi-google-home</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-keep"></i><span>mdi-google-keep</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-lens"></i><span>mdi-google-lens</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-maps"></i><span>mdi-google-maps</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-my-business"></i><span>mdi-google-my-business</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-nearby"></i><span>mdi-google-nearby</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-play"></i><span>mdi-google-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-plus"></i><span>mdi-google-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-podcast"></i><span>mdi-google-podcast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-spreadsheet"></i><span>mdi-google-spreadsheet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-street-view"></i><span>mdi-google-street-view</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-google-translate"></i><span>mdi-google-translate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gradient-horizontal"></i><span>mdi-gradient-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-gradient-vertical"></i><span>mdi-gradient-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-grain"></i><span>mdi-grain</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-graph"></i><span>mdi-graph</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-graph-outline"></i><span>mdi-graph-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-graphql"></i><span>mdi-graphql</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-grass"></i><span>mdi-grass</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-grave-stone"></i><span>mdi-grave-stone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-grease-pencil"></i><span>mdi-grease-pencil</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-greater-than"></i><span>mdi-greater-than</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-greater-than-or-equal"></i><span>mdi-greater-than-or-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-greenhouse"></i><span>mdi-greenhouse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-grid"></i><span>mdi-grid</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-grid-large"></i><span>mdi-grid-large</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-grid-off"></i><span>mdi-grid-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-grill"></i><span>mdi-grill</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-grill-outline"></i><span>mdi-grill-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-group"></i><span>mdi-group</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-guitar-acoustic"></i><span>mdi-guitar-acoustic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-guitar-electric"></i><span>mdi-guitar-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-guitar-pick"></i><span>mdi-guitar-pick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-guitar-pick-outline"></i><span>mdi-guitar-pick-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-guy-fawkes-mask"></i><span>mdi-guy-fawkes-mask</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hail"></i><span>mdi-hail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hair-dryer"></i><span>mdi-hair-dryer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hair-dryer-outline"></i><span>mdi-hair-dryer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-halloween"></i><span>mdi-halloween</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hamburger"></i><span>mdi-hamburger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hamburger-check"></i><span>mdi-hamburger-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hamburger-minus"></i><span>mdi-hamburger-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hamburger-off"></i><span>mdi-hamburger-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hamburger-plus"></i><span>mdi-hamburger-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hamburger-remove"></i><span>mdi-hamburger-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hammer"></i><span>mdi-hammer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hammer-screwdriver"></i><span>mdi-hammer-screwdriver</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hammer-sickle"></i><span>mdi-hammer-sickle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hammer-wrench"></i><span>mdi-hammer-wrench</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-back-left"></i><span>mdi-hand-back-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-back-left-off"></i><span>mdi-hand-back-left-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-back-left-off-outline"></i><span>mdi-hand-back-left-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-back-left-outline"></i><span>mdi-hand-back-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-back-right"></i><span>mdi-hand-back-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-back-right-off"></i><span>mdi-hand-back-right-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-back-right-off-outline"></i><span>mdi-hand-back-right-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-back-right-outline"></i><span>mdi-hand-back-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-clap"></i><span>mdi-hand-clap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-coin"></i><span>mdi-hand-coin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-coin-outline"></i><span>mdi-hand-coin-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-extended"></i><span>mdi-hand-extended</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-extended-outline"></i><span>mdi-hand-extended-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-front-left"></i><span>mdi-hand-front-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-front-left-outline"></i><span>mdi-hand-front-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-front-right"></i><span>mdi-hand-front-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-front-right-outline"></i><span>mdi-hand-front-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-heart"></i><span>mdi-hand-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-heart-outline"></i><span>mdi-hand-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-okay"></i><span>mdi-hand-okay</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-peace"></i><span>mdi-hand-peace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-peace-variant"></i><span>mdi-hand-peace-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-pointing-down"></i><span>mdi-hand-pointing-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-pointing-left"></i><span>mdi-hand-pointing-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-pointing-right"></i><span>mdi-hand-pointing-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-pointing-up"></i><span>mdi-hand-pointing-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-saw"></i><span>mdi-hand-saw</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-wash"></i><span>mdi-hand-wash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-wash-outline"></i><span>mdi-hand-wash-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-water"></i><span>mdi-hand-water</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-wave"></i><span>mdi-hand-wave</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hand-wave-outline"></i><span>mdi-hand-wave-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-handball"></i><span>mdi-handball</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-handcuffs"></i><span>mdi-handcuffs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hands-pray"></i><span>mdi-hands-pray</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-handshake"></i><span>mdi-handshake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-handshake-outline"></i><span>mdi-handshake-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hanger"></i><span>mdi-hanger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hard-hat"></i><span>mdi-hard-hat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-harddisk"></i><span>mdi-harddisk</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-harddisk-plus"></i><span>mdi-harddisk-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-harddisk-remove"></i><span>mdi-harddisk-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hat-fedora"></i><span>mdi-hat-fedora</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hazard-lights"></i><span>mdi-hazard-lights</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hdr"></i><span>mdi-hdr</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hdr-off"></i><span>mdi-hdr-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head"></i><span>mdi-head</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-alert"></i><span>mdi-head-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-border-2 bg-body-secondary"></i><span>mdi-head-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-check"></i><span>mdi-head-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-check-outline"></i><span>mdi-head-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-cog"></i><span>mdi-head-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-cog-outline"></i><span>mdi-head-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-dots-horizontal"></i><span>mdi-head-dots-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-dots-horizontal-outline"></i><span>mdi-head-dots-horizontal-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-flash"></i><span>mdi-head-flash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-flash-outline"></i><span>mdi-head-flash-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-heart"></i><span>mdi-head-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-heart-outline"></i><span>mdi-head-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-lightbulb"></i><span>mdi-head-lightbulb</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-lightbulb-outline"></i><span>mdi-head-lightbulb-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-minus"></i><span>mdi-head-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-minus-outline"></i><span>mdi-head-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-outline"></i><span>mdi-head-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-plus"></i><span>mdi-head-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-plus-outline"></i><span>mdi-head-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-question"></i><span>mdi-head-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-question-outline"></i><span>mdi-head-question-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-remove"></i><span>mdi-head-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-remove-outline"></i><span>mdi-head-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-snowflake"></i><span>mdi-head-snowflake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-snowflake-outline"></i><span>mdi-head-snowflake-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-sync"></i><span>mdi-head-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-head-sync-outline"></i><span>mdi-head-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-headphones"></i><span>mdi-headphones</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-headphones-bluetooth"></i><span>mdi-headphones-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-headphones-box"></i><span>mdi-headphones-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-headphones-off"></i><span>mdi-headphones-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-headphones-settings"></i><span>mdi-headphones-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-headset"></i><span>mdi-headset</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-headset-dock"></i><span>mdi-headset-dock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-headset-off"></i><span>mdi-headset-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart"></i><span>mdi-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-box"></i><span>mdi-heart-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-box-outline"></i><span>mdi-heart-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-broken"></i><span>mdi-heart-broken</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-broken-outline"></i><span>mdi-heart-broken-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-circle"></i><span>mdi-heart-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-circle-outline"></i><span>mdi-heart-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-cog"></i><span>mdi-heart-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-cog-outline"></i><span>mdi-heart-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-flash"></i><span>mdi-heart-flash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-half"></i><span>mdi-heart-half</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-half-full"></i><span>mdi-heart-half-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-half-outline"></i><span>mdi-heart-half-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-minus"></i><span>mdi-heart-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-minus-outline"></i><span>mdi-heart-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-multiple"></i><span>mdi-heart-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-multiple-outline"></i><span>mdi-heart-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-off"></i><span>mdi-heart-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-off-outline"></i><span>mdi-heart-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-outline"></i><span>mdi-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-plus"></i><span>mdi-heart-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-plus-outline"></i><span>mdi-heart-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-pulse"></i><span>mdi-heart-pulse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-remove"></i><span>mdi-heart-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-remove-outline"></i><span>mdi-heart-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-settings"></i><span>mdi-heart-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-heart-settings-outline"></i><span>mdi-heart-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-helicopter"></i><span>mdi-helicopter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-help"></i><span>mdi-help</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-help-box"></i><span>mdi-help-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-help-circle"></i><span>mdi-help-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-help-circle-outline"></i><span>mdi-help-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-help-network"></i><span>mdi-help-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-help-network-outline"></i><span>mdi-help-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-help-rhombus"></i><span>mdi-help-rhombus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-help-rhombus-outline"></i><span>mdi-help-rhombus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexadecimal"></i><span>mdi-hexadecimal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon"></i><span>mdi-hexagon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon-multiple"></i><span>mdi-hexagon-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon-multiple-outline"></i><span>mdi-hexagon-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon-outline"></i><span>mdi-hexagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon-slice-1"></i><span>mdi-hexagon-slice-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon-slice-2"></i><span>mdi-hexagon-slice-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon-slice-3"></i><span>mdi-hexagon-slice-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon-slice-4"></i><span>mdi-hexagon-slice-4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon-slice-5"></i><span>mdi-hexagon-slice-5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagon-slice-6"></i><span>mdi-hexagon-slice-6</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagram"></i><span>mdi-hexagram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hexagram-outline"></i><span>mdi-hexagram-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-high-definition"></i><span>mdi-high-definition</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-high-definition-box"></i><span>mdi-high-definition-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-highway"></i><span>mdi-highway</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hiking"></i><span>mdi-hiking</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-history"></i><span>mdi-history</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hockey-puck"></i><span>mdi-hockey-puck</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hockey-sticks"></i><span>mdi-hockey-sticks</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hololens"></i><span>mdi-hololens</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home"></i><span>mdi-home</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-account"></i><span>mdi-home-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-alert"></i><span>mdi-home-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-border-2 bg-body-secondary"></i><span>mdi-home-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-analytics"></i><span>mdi-home-analytics</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-assistant"></i><span>mdi-home-assistant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-automation"></i><span>mdi-home-automation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-battery"></i><span>mdi-home-battery</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-battery-outline"></i><span>mdi-home-battery-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-circle"></i><span>mdi-home-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-circle-outline"></i><span>mdi-home-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-city"></i><span>mdi-home-city</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-city-outline"></i><span>mdi-home-city-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-edit"></i><span>mdi-home-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-edit-outline"></i><span>mdi-home-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-export-outline"></i><span>mdi-home-export-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-flood"></i><span>mdi-home-flood</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-floor-0"></i><span>mdi-home-floor-0</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-floor-1"></i><span>mdi-home-floor-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-floor-2"></i><span>mdi-home-floor-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-floor-3"></i><span>mdi-home-floor-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-floor-a"></i><span>mdi-home-floor-a</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-floor-b"></i><span>mdi-home-floor-b</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-floor-g"></i><span>mdi-home-floor-g</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-floor-l"></i><span>mdi-home-floor-l</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-floor-negative-1"></i><span>mdi-home-floor-negative-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-group"></i><span>mdi-home-group</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-group-minus"></i><span>mdi-home-group-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-group-plus"></i><span>mdi-home-group-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-group-remove"></i><span>mdi-home-group-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-heart"></i><span>mdi-home-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-import-outline"></i><span>mdi-home-import-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-lightbulb"></i><span>mdi-home-lightbulb</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-lightbulb-outline"></i><span>mdi-home-lightbulb-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-lightning-bolt"></i><span>mdi-home-lightning-bolt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-lightning-bolt-outline"></i><span>mdi-home-lightning-bolt-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-lock"></i><span>mdi-home-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-lock-open"></i><span>mdi-home-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-map-marker"></i><span>mdi-home-map-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-minus"></i><span>mdi-home-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-minus-outline"></i><span>mdi-home-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-modern"></i><span>mdi-home-modern</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-outline"></i><span>mdi-home-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-plus"></i><span>mdi-home-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-plus-outline"></i><span>mdi-home-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-remove"></i><span>mdi-home-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-remove-outline"></i><span>mdi-home-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-roof"></i><span>mdi-home-roof</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-search"></i><span>mdi-home-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-search-outline"></i><span>mdi-home-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-switch"></i><span>mdi-home-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-switch-outline"></i><span>mdi-home-switch-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-thermometer"></i><span>mdi-home-thermometer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-thermometer-outline"></i><span>mdi-home-thermometer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-variant"></i><span>mdi-home-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-home-variant-outline"></i><span>mdi-home-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hook"></i><span>mdi-hook</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hook-off"></i><span>mdi-hook-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hoop-house"></i><span>mdi-hoop-house</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hops"></i><span>mdi-hops</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-horizontal-rotate-clockwise"></i><span>mdi-horizontal-rotate-clockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-horizontal-rotate-counterclockwise"></i><span>mdi-horizontal-rotate-counterclockwise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-horse"></i><span>mdi-horse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-horse-human"></i><span>mdi-horse-human</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-horse-variant"></i><span>mdi-horse-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-horse-variant-fast"></i><span>mdi-horse-variant-fast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-horseshoe"></i><span>mdi-horseshoe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hospital"></i><span>mdi-hospital</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hospital-box"></i><span>mdi-hospital-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hospital-box-outline"></i><span>mdi-hospital-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hospital-building"></i><span>mdi-hospital-building</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hospital-marker"></i><span>mdi-hospital-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hot-tub"></i><span>mdi-hot-tub</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hours-24"></i><span>mdi-hours-24</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hubspot"></i><span>mdi-hubspot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hulu"></i><span>mdi-hulu</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human"></i><span>mdi-human</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-baby-changing-table"></i><span>mdi-human-baby-changing-table</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-cane"></i><span>mdi-human-cane</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-capacity-decrease"></i><span>mdi-human-capacity-decrease</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-capacity-increase"></i><span>mdi-human-capacity-increase</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-child"></i><span>mdi-human-child</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-dolly"></i><span>mdi-human-dolly</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-edit"></i><span>mdi-human-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-female"></i><span>mdi-human-female</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-female-boy"></i><span>mdi-human-female-boy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-female-dance"></i><span>mdi-human-female-dance</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-female-female"></i><span>mdi-human-female-female</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-female-girl"></i><span>mdi-human-female-girl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-greeting"></i><span>mdi-human-greeting</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-greeting-proximity"></i><span>mdi-human-greeting-proximity</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-greeting-variant"></i><span>mdi-human-greeting-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-handsdown"></i><span>mdi-human-handsdown</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-handsup"></i><span>mdi-human-handsup</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male"></i><span>mdi-human-male</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-board"></i><span>mdi-human-male-board</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-board-poll"></i><span>mdi-human-male-board-poll</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-boy"></i><span>mdi-human-male-boy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-child"></i><span>mdi-human-male-child</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-female"></i><span>mdi-human-male-female</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-female-child"></i><span>mdi-human-male-female-child</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-girl"></i><span>mdi-human-male-girl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-height"></i><span>mdi-human-male-height</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-height-variant"></i><span>mdi-human-male-height-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-male-male"></i><span>mdi-human-male-male</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-non-binary"></i><span>mdi-human-non-binary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-pregnant"></i><span>mdi-human-pregnant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-queue"></i><span>mdi-human-queue</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-scooter"></i><span>mdi-human-scooter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-wheelchair"></i><span>mdi-human-wheelchair</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-human-white-cane"></i><span>mdi-human-white-cane</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-humble-bundle"></i><span>mdi-humble-bundle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hvac"></i><span>mdi-hvac</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hvac-off"></i><span>mdi-hvac-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hydraulic-oil-level"></i><span>mdi-hydraulic-oil-level</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hydraulic-oil-temperature"></i><span>mdi-hydraulic-oil-temperature</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hydro-power"></i><span>mdi-hydro-power</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-hydrogen-station"></i><span>mdi-hydrogen-station</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ice-cream"></i><span>mdi-ice-cream</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ice-cream-off"></i><span>mdi-ice-cream-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ice-pop"></i><span>mdi-ice-pop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-id-card"></i><span>mdi-id-card</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-identifier"></i><span>mdi-identifier</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ideogram-cjk"></i><span>mdi-ideogram-cjk</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ideogram-cjk-variant"></i><span>mdi-ideogram-cjk-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image"></i><span>mdi-image</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-album"></i><span>mdi-image-album</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-area"></i><span>mdi-image-area</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-area-close"></i><span>mdi-image-area-close</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-auto-adjust"></i><span>mdi-image-auto-adjust</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-broken"></i><span>mdi-image-broken</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-broken-variant"></i><span>mdi-image-broken-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-edit"></i><span>mdi-image-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-edit-outline"></i><span>mdi-image-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-black-white"></i><span>mdi-image-filter-black-white</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-center-focus"></i><span>mdi-image-filter-center-focus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-center-focus-strong"></i><span>mdi-image-filter-center-focus-strong</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-center-focus-strong-outline"></i><span>mdi-image-filter-center-focus-strong-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-center-focus-weak"></i><span>mdi-image-filter-center-focus-weak</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-drama"></i><span>mdi-image-filter-drama</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-frames"></i><span>mdi-image-filter-frames</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-hdr"></i><span>mdi-image-filter-hdr</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-none"></i><span>mdi-image-filter-none</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-tilt-shift"></i><span>mdi-image-filter-tilt-shift</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-filter-vintage"></i><span>mdi-image-filter-vintage</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-frame"></i><span>mdi-image-frame</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-marker"></i><span>mdi-image-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-marker-outline"></i><span>mdi-image-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-minus"></i><span>mdi-image-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-move"></i><span>mdi-image-move</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-multiple"></i><span>mdi-image-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-multiple-outline"></i><span>mdi-image-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-off"></i><span>mdi-image-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-off-outline"></i><span>mdi-image-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-outline"></i><span>mdi-image-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-plus"></i><span>mdi-image-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-remove"></i><span>mdi-image-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-search"></i><span>mdi-image-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-search-outline"></i><span>mdi-image-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-size-select-actual"></i><span>mdi-image-size-select-actual</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-size-select-large"></i><span>mdi-image-size-select-large</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-size-select-small"></i><span>mdi-image-size-select-small</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-image-text"></i><span>mdi-image-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-import"></i><span>mdi-import</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox"></i><span>mdi-inbox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-arrow-down"></i><span>mdi-inbox-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-arrow-down-outline"></i><span>mdi-inbox-arrow-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-arrow-up"></i><span>mdi-inbox-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-arrow-up-outline"></i><span>mdi-inbox-arrow-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-full"></i><span>mdi-inbox-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-full-outline"></i><span>mdi-inbox-full-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-multiple"></i><span>mdi-inbox-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-multiple-outline"></i><span>mdi-inbox-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-outline"></i><span>mdi-inbox-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-remove"></i><span>mdi-inbox-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-inbox-remove-outline"></i><span>mdi-inbox-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-incognito"></i><span>mdi-incognito</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-incognito-circle"></i><span>mdi-incognito-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-incognito-circle-off"></i><span>mdi-incognito-circle-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-incognito-off"></i><span>mdi-incognito-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-induction"></i><span>mdi-induction</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-infinity"></i><span>mdi-infinity</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-information"></i><span>mdi-information</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-information-off"></i><span>mdi-information-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-information-off-outline"></i><span>mdi-information-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-information-outline"></i><span>mdi-information-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-information-variant"></i><span>mdi-information-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-instagram"></i><span>mdi-instagram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-instrument-triangle"></i><span>mdi-instrument-triangle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-integrated-circuit-chip"></i><span>mdi-integrated-circuit-chip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-invert-colors"></i><span>mdi-invert-colors</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-invert-colors-off"></i><span>mdi-invert-colors-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-iobroker"></i><span>mdi-iobroker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ip"></i><span>mdi-ip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ip-network"></i><span>mdi-ip-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ip-network-outline"></i><span>mdi-ip-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ip-outline"></i><span>mdi-ip-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ipod"></i><span>mdi-ipod</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-iron"></i><span>mdi-iron</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-iron-board"></i><span>mdi-iron-board</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-iron-outline"></i><span>mdi-iron-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-island"></i><span>mdi-island</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-iv-bag"></i><span>mdi-iv-bag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-jabber"></i><span>mdi-jabber</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-jeepney"></i><span>mdi-jeepney</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-jellyfish"></i><span>mdi-jellyfish</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-jellyfish-outline"></i><span>mdi-jellyfish-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-jira"></i><span>mdi-jira</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-jquery"></i><span>mdi-jquery</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-jsfiddle"></i><span>mdi-jsfiddle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-jump-rope"></i><span>mdi-jump-rope</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kabaddi"></i><span>mdi-kabaddi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kangaroo"></i><span>mdi-kangaroo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-karate"></i><span>mdi-karate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kayaking"></i><span>mdi-kayaking</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keg"></i><span>mdi-keg</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettle"></i><span>mdi-kettle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettle-alert"></i><span>mdi-kettle-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettle-border-2 bg-body-secondary"></i><span>mdi-kettle-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettle-off"></i><span>mdi-kettle-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettle-off-outline"></i><span>mdi-kettle-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettle-outline"></i><span>mdi-kettle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettle-pour-over"></i><span>mdi-kettle-pour-over</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettle-steam"></i><span>mdi-kettle-steam</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettle-steam-outline"></i><span>mdi-kettle-steam-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kettlebell"></i><span>mdi-kettlebell</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key"></i><span>mdi-key</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-alert"></i><span>mdi-key-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-border-2 bg-body-secondary"></i><span>mdi-key-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-arrow-right"></i><span>mdi-key-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-chain"></i><span>mdi-key-chain</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-chain-variant"></i><span>mdi-key-chain-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-change"></i><span>mdi-key-change</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-link"></i><span>mdi-key-link</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-minus"></i><span>mdi-key-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-outline"></i><span>mdi-key-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-plus"></i><span>mdi-key-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-remove"></i><span>mdi-key-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-star"></i><span>mdi-key-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-variant"></i><span>mdi-key-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-key-wireless"></i><span>mdi-key-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard"></i><span>mdi-keyboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-backspace"></i><span>mdi-keyboard-backspace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-caps"></i><span>mdi-keyboard-caps</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-close"></i><span>mdi-keyboard-close</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-esc"></i><span>mdi-keyboard-esc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f1"></i><span>mdi-keyboard-f1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f10"></i><span>mdi-keyboard-f10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f11"></i><span>mdi-keyboard-f11</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f12"></i><span>mdi-keyboard-f12</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f2"></i><span>mdi-keyboard-f2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f3"></i><span>mdi-keyboard-f3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f4"></i><span>mdi-keyboard-f4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f5"></i><span>mdi-keyboard-f5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f6"></i><span>mdi-keyboard-f6</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f7"></i><span>mdi-keyboard-f7</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f8"></i><span>mdi-keyboard-f8</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-f9"></i><span>mdi-keyboard-f9</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-off"></i><span>mdi-keyboard-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-off-outline"></i><span>mdi-keyboard-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-outline"></i><span>mdi-keyboard-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-return"></i><span>mdi-keyboard-return</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-settings"></i><span>mdi-keyboard-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-settings-outline"></i><span>mdi-keyboard-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-space"></i><span>mdi-keyboard-space</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-tab"></i><span>mdi-keyboard-tab</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-tab-reverse"></i><span>mdi-keyboard-tab-reverse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-keyboard-variant"></i><span>mdi-keyboard-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-khanda"></i><span>mdi-khanda</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kickstarter"></i><span>mdi-kickstarter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kite"></i><span>mdi-kite</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kite-outline"></i><span>mdi-kite-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kitesurfing"></i><span>mdi-kitesurfing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-klingon"></i><span>mdi-klingon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-knife"></i><span>mdi-knife</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-knife-military"></i><span>mdi-knife-military</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-koala"></i><span>mdi-koala</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kodi"></i><span>mdi-kodi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-kubernetes"></i><span>mdi-kubernetes</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label"></i><span>mdi-label</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label-multiple"></i><span>mdi-label-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label-multiple-outline"></i><span>mdi-label-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label-off"></i><span>mdi-label-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label-off-outline"></i><span>mdi-label-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label-outline"></i><span>mdi-label-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label-percent"></i><span>mdi-label-percent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label-percent-outline"></i><span>mdi-label-percent-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label-variant"></i><span>mdi-label-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-label-variant-outline"></i><span>mdi-label-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ladder"></i><span>mdi-ladder</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ladybug"></i><span>mdi-ladybug</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lambda"></i><span>mdi-lambda</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lamp"></i><span>mdi-lamp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lamp-outline"></i><span>mdi-lamp-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lamps"></i><span>mdi-lamps</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lamps-outline"></i><span>mdi-lamps-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lan"></i><span>mdi-lan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lan-check"></i><span>mdi-lan-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lan-connect"></i><span>mdi-lan-connect</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lan-disconnect"></i><span>mdi-lan-disconnect</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lan-pending"></i><span>mdi-lan-pending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-c"></i><span>mdi-language-c</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-cpp"></i><span>mdi-language-cpp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-csharp"></i><span>mdi-language-csharp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-css3"></i><span>mdi-language-css3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-fortran"></i><span>mdi-language-fortran</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-go"></i><span>mdi-language-go</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-haskell"></i><span>mdi-language-haskell</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-html5"></i><span>mdi-language-html5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-java"></i><span>mdi-language-java</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-javascript"></i><span>mdi-language-javascript</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-kotlin"></i><span>mdi-language-kotlin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-lua"></i><span>mdi-language-lua</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-markdown"></i><span>mdi-language-markdown</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-markdown-outline"></i><span>mdi-language-markdown-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-php"></i><span>mdi-language-php</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-python"></i><span>mdi-language-python</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-r"></i><span>mdi-language-r</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-ruby"></i><span>mdi-language-ruby</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-ruby-on-rails"></i><span>mdi-language-ruby-on-rails</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-rust"></i><span>mdi-language-rust</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-swift"></i><span>mdi-language-swift</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-typescript"></i><span>mdi-language-typescript</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-language-xaml"></i><span>mdi-language-xaml</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-laptop"></i><span>mdi-laptop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-laptop-off"></i><span>mdi-laptop-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-laravel"></i><span>mdi-laravel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-laser-pointer"></i><span>mdi-laser-pointer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lasso"></i><span>mdi-lasso</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lastpass"></i><span>mdi-lastpass</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-latitude"></i><span>mdi-latitude</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-launch"></i><span>mdi-launch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lava-lamp"></i><span>mdi-lava-lamp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers"></i><span>mdi-layers</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-edit"></i><span>mdi-layers-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-minus"></i><span>mdi-layers-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-off"></i><span>mdi-layers-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-off-outline"></i><span>mdi-layers-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-outline"></i><span>mdi-layers-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-plus"></i><span>mdi-layers-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-remove"></i><span>mdi-layers-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-search"></i><span>mdi-layers-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-search-outline"></i><span>mdi-layers-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-triple"></i><span>mdi-layers-triple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-layers-triple-outline"></i><span>mdi-layers-triple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lead-pencil"></i><span>mdi-lead-pencil</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-leaf"></i><span>mdi-leaf</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-leaf-circle"></i><span>mdi-leaf-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-leaf-circle-outline"></i><span>mdi-leaf-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-leaf-maple"></i><span>mdi-leaf-maple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-leaf-maple-off"></i><span>mdi-leaf-maple-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-leaf-off"></i><span>mdi-leaf-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-leak"></i><span>mdi-leak</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-leak-off"></i><span>mdi-leak-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-led-off"></i><span>mdi-led-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-led-on"></i><span>mdi-led-on</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-led-outline"></i><span>mdi-led-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-led-strip"></i><span>mdi-led-strip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-led-strip-variant"></i><span>mdi-led-strip-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-led-variant-off"></i><span>mdi-led-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-led-variant-on"></i><span>mdi-led-variant-on</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-led-variant-outline"></i><span>mdi-led-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-leek"></i><span>mdi-leek</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-less-than"></i><span>mdi-less-than</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-less-than-or-equal"></i><span>mdi-less-than-or-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-library"></i><span>mdi-library</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-library-shelves"></i><span>mdi-library-shelves</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-license"></i><span>mdi-license</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lifebuoy"></i><span>mdi-lifebuoy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-light-flood-down"></i><span>mdi-light-flood-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-light-flood-up"></i><span>mdi-light-flood-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-light-recessed"></i><span>mdi-light-recessed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-light-switch"></i><span>mdi-light-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb"></i><span>mdi-lightbulb</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-auto"></i><span>mdi-lightbulb-auto</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-auto-outline"></i><span>mdi-lightbulb-auto-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-cfl"></i><span>mdi-lightbulb-cfl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-cfl-off"></i><span>mdi-lightbulb-cfl-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-cfl-spiral"></i><span>mdi-lightbulb-cfl-spiral</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-cfl-spiral-off"></i><span>mdi-lightbulb-cfl-spiral-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-fluorescent-tube"></i><span>mdi-lightbulb-fluorescent-tube</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-fluorescent-tube-outline"></i><span>mdi-lightbulb-fluorescent-tube-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-group"></i><span>mdi-lightbulb-group</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-group-off"></i><span>mdi-lightbulb-group-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-group-off-outline"></i><span>mdi-lightbulb-group-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-group-outline"></i><span>mdi-lightbulb-group-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-multiple"></i><span>mdi-lightbulb-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-multiple-off"></i><span>mdi-lightbulb-multiple-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-multiple-off-outline"></i><span>mdi-lightbulb-multiple-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-multiple-outline"></i><span>mdi-lightbulb-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-off"></i><span>mdi-lightbulb-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-off-outline"></i><span>mdi-lightbulb-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-on"></i><span>mdi-lightbulb-on</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-on-outline"></i><span>mdi-lightbulb-on-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-outline"></i><span>mdi-lightbulb-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-spot"></i><span>mdi-lightbulb-spot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-spot-off"></i><span>mdi-lightbulb-spot-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-variant"></i><span>mdi-lightbulb-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightbulb-variant-outline"></i><span>mdi-lightbulb-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lighthouse"></i><span>mdi-lighthouse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lighthouse-on"></i><span>mdi-lighthouse-on</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightning-bolt"></i><span>mdi-lightning-bolt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightning-bolt-circle"></i><span>mdi-lightning-bolt-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lightning-bolt-outline"></i><span>mdi-lightning-bolt-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-line-scan"></i><span>mdi-line-scan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lingerie"></i><span>mdi-lingerie</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link"></i><span>mdi-link</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-box"></i><span>mdi-link-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-box-outline"></i><span>mdi-link-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-box-variant"></i><span>mdi-link-box-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-box-variant-outline"></i><span>mdi-link-box-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-lock"></i><span>mdi-link-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-off"></i><span>mdi-link-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-plus"></i><span>mdi-link-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-variant"></i><span>mdi-link-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-variant-minus"></i><span>mdi-link-variant-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-variant-off"></i><span>mdi-link-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-variant-plus"></i><span>mdi-link-variant-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-link-variant-remove"></i><span>mdi-link-variant-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-linkedin"></i><span>mdi-linkedin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-linux"></i><span>mdi-linux</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-linux-mint"></i><span>mdi-linux-mint</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lipstick"></i><span>mdi-lipstick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-liquid-spot"></i><span>mdi-liquid-spot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-liquor"></i><span>mdi-liquor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-list-status"></i><span>mdi-list-status</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-litecoin"></i><span>mdi-litecoin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-loading"></i><span>mdi-loading</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-location-enter"></i><span>mdi-location-enter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-location-exit"></i><span>mdi-location-exit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock"></i><span>mdi-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-alert"></i><span>mdi-lock-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-border-2 bg-body-secondary"></i><span>mdi-lock-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-check"></i><span>mdi-lock-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-check-outline"></i><span>mdi-lock-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-clock"></i><span>mdi-lock-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-minus"></i><span>mdi-lock-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-minus-outline"></i><span>mdi-lock-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-off"></i><span>mdi-lock-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-off-outline"></i><span>mdi-lock-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open"></i><span>mdi-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-alert"></i><span>mdi-lock-open-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-border-2 bg-body-secondary"></i><span>mdi-lock-open-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-check"></i><span>mdi-lock-open-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-check-outline"></i><span>mdi-lock-open-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-minus"></i><span>mdi-lock-open-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-minus-outline"></i><span>mdi-lock-open-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-outline"></i><span>mdi-lock-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-plus"></i><span>mdi-lock-open-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-plus-outline"></i><span>mdi-lock-open-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-remove"></i><span>mdi-lock-open-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-remove-outline"></i><span>mdi-lock-open-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-variant"></i><span>mdi-lock-open-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-open-variant-outline"></i><span>mdi-lock-open-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-outline"></i><span>mdi-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-pattern"></i><span>mdi-lock-pattern</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-plus"></i><span>mdi-lock-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-plus-outline"></i><span>mdi-lock-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-question"></i><span>mdi-lock-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-remove"></i><span>mdi-lock-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-remove-outline"></i><span>mdi-lock-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-reset"></i><span>mdi-lock-reset</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lock-smart"></i><span>mdi-lock-smart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-locker"></i><span>mdi-locker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-locker-multiple"></i><span>mdi-locker-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-login"></i><span>mdi-login</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-login-variant"></i><span>mdi-login-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-logout"></i><span>mdi-logout</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-logout-variant"></i><span>mdi-logout-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-longitude"></i><span>mdi-longitude</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-looks"></i><span>mdi-looks</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lotion"></i><span>mdi-lotion</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lotion-outline"></i><span>mdi-lotion-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lotion-plus"></i><span>mdi-lotion-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lotion-plus-outline"></i><span>mdi-lotion-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-loupe"></i><span>mdi-loupe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lumx"></i><span>mdi-lumx</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-lungs"></i><span>mdi-lungs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mace"></i><span>mdi-mace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magazine-pistol"></i><span>mdi-magazine-pistol</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magazine-rifle"></i><span>mdi-magazine-rifle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magic-staff"></i><span>mdi-magic-staff</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnet"></i><span>mdi-magnet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnet-on"></i><span>mdi-magnet-on</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify"></i><span>mdi-magnify</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-close"></i><span>mdi-magnify-close</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-expand"></i><span>mdi-magnify-expand</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-minus"></i><span>mdi-magnify-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-minus-cursor"></i><span>mdi-magnify-minus-cursor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-minus-outline"></i><span>mdi-magnify-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-plus"></i><span>mdi-magnify-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-plus-cursor"></i><span>mdi-magnify-plus-cursor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-plus-outline"></i><span>mdi-magnify-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-remove-cursor"></i><span>mdi-magnify-remove-cursor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-remove-outline"></i><span>mdi-magnify-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-magnify-scan"></i><span>mdi-magnify-scan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mail"></i><span>mdi-mail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mailbox"></i><span>mdi-mailbox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mailbox-open"></i><span>mdi-mailbox-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mailbox-open-outline"></i><span>mdi-mailbox-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mailbox-open-up"></i><span>mdi-mailbox-open-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mailbox-open-up-outline"></i><span>mdi-mailbox-open-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mailbox-outline"></i><span>mdi-mailbox-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mailbox-up"></i><span>mdi-mailbox-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mailbox-up-outline"></i><span>mdi-mailbox-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-manjaro"></i><span>mdi-manjaro</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map"></i><span>mdi-map</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-check"></i><span>mdi-map-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-check-outline"></i><span>mdi-map-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-clock"></i><span>mdi-map-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-clock-outline"></i><span>mdi-map-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-legend"></i><span>mdi-map-legend</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker"></i><span>mdi-map-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-account"></i><span>mdi-map-marker-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-account-outline"></i><span>mdi-map-marker-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-alert"></i><span>mdi-map-marker-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-border-2 bg-body-secondary"></i><span>mdi-map-marker-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-check"></i><span>mdi-map-marker-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-check-outline"></i><span>mdi-map-marker-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-circle"></i><span>mdi-map-marker-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-distance"></i><span>mdi-map-marker-distance</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-down"></i><span>mdi-map-marker-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-left"></i><span>mdi-map-marker-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-left-outline"></i><span>mdi-map-marker-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-minus"></i><span>mdi-map-marker-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-minus-outline"></i><span>mdi-map-marker-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-multiple"></i><span>mdi-map-marker-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-multiple-outline"></i><span>mdi-map-marker-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-off"></i><span>mdi-map-marker-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-off-outline"></i><span>mdi-map-marker-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-outline"></i><span>mdi-map-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-path"></i><span>mdi-map-marker-path</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-plus"></i><span>mdi-map-marker-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-plus-outline"></i><span>mdi-map-marker-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-question"></i><span>mdi-map-marker-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-question-outline"></i><span>mdi-map-marker-question-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-radius"></i><span>mdi-map-marker-radius</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-radius-outline"></i><span>mdi-map-marker-radius-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-remove"></i><span>mdi-map-marker-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-remove-outline"></i><span>mdi-map-marker-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-remove-variant"></i><span>mdi-map-marker-remove-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-right"></i><span>mdi-map-marker-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-right-outline"></i><span>mdi-map-marker-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-star"></i><span>mdi-map-marker-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-star-outline"></i><span>mdi-map-marker-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-marker-up"></i><span>mdi-map-marker-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-minus"></i><span>mdi-map-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-outline"></i><span>mdi-map-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-plus"></i><span>mdi-map-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-search"></i><span>mdi-map-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-map-search-outline"></i><span>mdi-map-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mapbox"></i><span>mdi-mapbox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-margin"></i><span>mdi-margin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-marker"></i><span>mdi-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-marker-cancel"></i><span>mdi-marker-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-marker-check"></i><span>mdi-marker-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mastodon"></i><span>mdi-mastodon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-material-design"></i><span>mdi-material-design</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-material-ui"></i><span>mdi-material-ui</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-math-compass"></i><span>mdi-math-compass</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-math-cos"></i><span>mdi-math-cos</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-math-integral"></i><span>mdi-math-integral</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-math-integral-box"></i><span>mdi-math-integral-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-math-log"></i><span>mdi-math-log</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-math-norm"></i><span>mdi-math-norm</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-math-norm-box"></i><span>mdi-math-norm-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-math-sin"></i><span>mdi-math-sin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-math-tan"></i><span>mdi-math-tan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-matrix"></i><span>mdi-matrix</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-medal"></i><span>mdi-medal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-medal-outline"></i><span>mdi-medal-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-medical-bag"></i><span>mdi-medical-bag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-meditation"></i><span>mdi-meditation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-memory"></i><span>mdi-memory</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menorah"></i><span>mdi-menorah</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menorah-fire"></i><span>mdi-menorah-fire</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu"></i><span>mdi-menu</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-down"></i><span>mdi-menu-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-down-outline"></i><span>mdi-menu-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-left"></i><span>mdi-menu-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-left-outline"></i><span>mdi-menu-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-open"></i><span>mdi-menu-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-right"></i><span>mdi-menu-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-right-outline"></i><span>mdi-menu-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-swap"></i><span>mdi-menu-swap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-swap-outline"></i><span>mdi-menu-swap-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-up"></i><span>mdi-menu-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-menu-up-outline"></i><span>mdi-menu-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-merge"></i><span>mdi-merge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message"></i><span>mdi-message</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-alert"></i><span>mdi-message-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-border-2 bg-body-secondary"></i><span>mdi-message-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-arrow-left"></i><span>mdi-message-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-arrow-left-outline"></i><span>mdi-message-arrow-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-arrow-right"></i><span>mdi-message-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-arrow-right-outline"></i><span>mdi-message-arrow-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-badge"></i><span>mdi-message-badge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-badge-outline"></i><span>mdi-message-badge-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-bookmark"></i><span>mdi-message-bookmark</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-bookmark-outline"></i><span>mdi-message-bookmark-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-bulleted"></i><span>mdi-message-bulleted</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-bulleted-off"></i><span>mdi-message-bulleted-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-cog"></i><span>mdi-message-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-cog-outline"></i><span>mdi-message-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-draw"></i><span>mdi-message-draw</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-flash"></i><span>mdi-message-flash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-flash-outline"></i><span>mdi-message-flash-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-image"></i><span>mdi-message-image</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-image-outline"></i><span>mdi-message-image-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-lock"></i><span>mdi-message-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-lock-outline"></i><span>mdi-message-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-minus"></i><span>mdi-message-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-minus-outline"></i><span>mdi-message-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-off"></i><span>mdi-message-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-off-outline"></i><span>mdi-message-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-outline"></i><span>mdi-message-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-plus"></i><span>mdi-message-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-plus-outline"></i><span>mdi-message-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-processing"></i><span>mdi-message-processing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-processing-outline"></i><span>mdi-message-processing-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-question"></i><span>mdi-message-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-question-outline"></i><span>mdi-message-question-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-reply"></i><span>mdi-message-reply</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-reply-outline"></i><span>mdi-message-reply-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-reply-text"></i><span>mdi-message-reply-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-reply-text-outline"></i><span>mdi-message-reply-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-settings"></i><span>mdi-message-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-settings-outline"></i><span>mdi-message-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-star"></i><span>mdi-message-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-star-outline"></i><span>mdi-message-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-text"></i><span>mdi-message-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-text-clock"></i><span>mdi-message-text-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-text-clock-outline"></i><span>mdi-message-text-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-text-lock"></i><span>mdi-message-text-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-text-lock-outline"></i><span>mdi-message-text-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-text-outline"></i><span>mdi-message-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-message-video"></i><span>mdi-message-video</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-meteor"></i><span>mdi-meteor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-metronome"></i><span>mdi-metronome</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-metronome-tick"></i><span>mdi-metronome-tick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-micro-sd"></i><span>mdi-micro-sd</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone"></i><span>mdi-microphone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone-minus"></i><span>mdi-microphone-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone-off"></i><span>mdi-microphone-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone-outline"></i><span>mdi-microphone-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone-plus"></i><span>mdi-microphone-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone-question"></i><span>mdi-microphone-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone-question-outline"></i><span>mdi-microphone-question-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone-settings"></i><span>mdi-microphone-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone-variant"></i><span>mdi-microphone-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microphone-variant-off"></i><span>mdi-microphone-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microscope"></i><span>mdi-microscope</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft"></i><span>mdi-microsoft</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-access"></i><span>mdi-microsoft-access</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-azure"></i><span>mdi-microsoft-azure</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-azure-devops"></i><span>mdi-microsoft-azure-devops</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-bing"></i><span>mdi-microsoft-bing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-dynamics-365"></i><span>mdi-microsoft-dynamics-365</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-edge"></i><span>mdi-microsoft-edge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-excel"></i><span>mdi-microsoft-excel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-internet-explorer"></i><span>mdi-microsoft-internet-explorer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-office"></i><span>mdi-microsoft-office</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-onedrive"></i><span>mdi-microsoft-onedrive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-onenote"></i><span>mdi-microsoft-onenote</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-outlook"></i><span>mdi-microsoft-outlook</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-powerpoint"></i><span>mdi-microsoft-powerpoint</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-sharepoint"></i><span>mdi-microsoft-sharepoint</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-teams"></i><span>mdi-microsoft-teams</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-visual-studio"></i><span>mdi-microsoft-visual-studio</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-visual-studio-code"></i><span>mdi-microsoft-visual-studio-code</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-windows"></i><span>mdi-microsoft-windows</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-windows-classic"></i><span>mdi-microsoft-windows-classic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-word"></i><span>mdi-microsoft-word</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox"></i><span>mdi-microsoft-xbox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller"></i><span>mdi-microsoft-xbox-controller</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-battery-alert"></i><span>mdi-microsoft-xbox-controller-battery-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-battery-charging"></i><span>mdi-microsoft-xbox-controller-battery-charging</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-battery-empty"></i><span>mdi-microsoft-xbox-controller-battery-empty</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-battery-full"></i><span>mdi-microsoft-xbox-controller-battery-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-battery-low"></i><span>mdi-microsoft-xbox-controller-battery-low</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-battery-medium"></i><span>mdi-microsoft-xbox-controller-battery-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-battery-unknown"></i><span>mdi-microsoft-xbox-controller-battery-unknown</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-menu"></i><span>mdi-microsoft-xbox-controller-menu</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-off"></i><span>mdi-microsoft-xbox-controller-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microsoft-xbox-controller-view"></i><span>mdi-microsoft-xbox-controller-view</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microwave"></i><span>mdi-microwave</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-microwave-off"></i><span>mdi-microwave-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-middleware"></i><span>mdi-middleware</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-middleware-outline"></i><span>mdi-middleware-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-midi"></i><span>mdi-midi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-midi-port"></i><span>mdi-midi-port</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mine"></i><span>mdi-mine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minecraft"></i><span>mdi-minecraft</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mini-sd"></i><span>mdi-mini-sd</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minidisc"></i><span>mdi-minidisc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus"></i><span>mdi-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-box"></i><span>mdi-minus-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-box-multiple"></i><span>mdi-minus-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-box-multiple-outline"></i><span>mdi-minus-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-box-outline"></i><span>mdi-minus-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-circle"></i><span>mdi-minus-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-circle-multiple"></i><span>mdi-minus-circle-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-circle-multiple-outline"></i><span>mdi-minus-circle-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-circle-off"></i><span>mdi-minus-circle-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-circle-off-outline"></i><span>mdi-minus-circle-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-circle-outline"></i><span>mdi-minus-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-network"></i><span>mdi-minus-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-network-outline"></i><span>mdi-minus-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-minus-thick"></i><span>mdi-minus-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mirror"></i><span>mdi-mirror</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mirror-rectangle"></i><span>mdi-mirror-rectangle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mirror-variant"></i><span>mdi-mirror-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mixed-martial-arts"></i><span>mdi-mixed-martial-arts</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mixed-reality"></i><span>mdi-mixed-reality</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-molecule"></i><span>mdi-molecule</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-molecule-co"></i><span>mdi-molecule-co</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-molecule-co2"></i><span>mdi-molecule-co2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor"></i><span>mdi-monitor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-cellphone"></i><span>mdi-monitor-cellphone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-cellphone-star"></i><span>mdi-monitor-cellphone-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-dashboard"></i><span>mdi-monitor-dashboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-edit"></i><span>mdi-monitor-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-eye"></i><span>mdi-monitor-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-lock"></i><span>mdi-monitor-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-multiple"></i><span>mdi-monitor-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-off"></i><span>mdi-monitor-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-screenshot"></i><span>mdi-monitor-screenshot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-share"></i><span>mdi-monitor-share</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-shimmer"></i><span>mdi-monitor-shimmer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-small"></i><span>mdi-monitor-small</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-speaker"></i><span>mdi-monitor-speaker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-speaker-off"></i><span>mdi-monitor-speaker-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-monitor-star"></i><span>mdi-monitor-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moon-first-quarter"></i><span>mdi-moon-first-quarter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moon-full"></i><span>mdi-moon-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moon-last-quarter"></i><span>mdi-moon-last-quarter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moon-new"></i><span>mdi-moon-new</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moon-waning-crescent"></i><span>mdi-moon-waning-crescent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moon-waning-gibbous"></i><span>mdi-moon-waning-gibbous</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moon-waxing-crescent"></i><span>mdi-moon-waxing-crescent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moon-waxing-gibbous"></i><span>mdi-moon-waxing-gibbous</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moped"></i><span>mdi-moped</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moped-electric"></i><span>mdi-moped-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moped-electric-outline"></i><span>mdi-moped-electric-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-moped-outline"></i><span>mdi-moped-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-more"></i><span>mdi-more</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mortar-pestle"></i><span>mdi-mortar-pestle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mortar-pestle-plus"></i><span>mdi-mortar-pestle-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mosque"></i><span>mdi-mosque</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mother-heart"></i><span>mdi-mother-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mother-nurse"></i><span>mdi-mother-nurse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motion"></i><span>mdi-motion</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motion-outline"></i><span>mdi-motion-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motion-pause"></i><span>mdi-motion-pause</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motion-pause-outline"></i><span>mdi-motion-pause-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motion-play"></i><span>mdi-motion-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motion-play-outline"></i><span>mdi-motion-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motion-sensor"></i><span>mdi-motion-sensor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motion-sensor-off"></i><span>mdi-motion-sensor-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motorbike"></i><span>mdi-motorbike</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-motorbike-electric"></i><span>mdi-motorbike-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mouse"></i><span>mdi-mouse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mouse-bluetooth"></i><span>mdi-mouse-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mouse-move-down"></i><span>mdi-mouse-move-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mouse-move-up"></i><span>mdi-mouse-move-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mouse-move-vertical"></i><span>mdi-mouse-move-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mouse-off"></i><span>mdi-mouse-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mouse-variant"></i><span>mdi-mouse-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mouse-variant-off"></i><span>mdi-mouse-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-move-resize"></i><span>mdi-move-resize</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-move-resize-variant"></i><span>mdi-move-resize-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie"></i><span>mdi-movie</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-check"></i><span>mdi-movie-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-check-outline"></i><span>mdi-movie-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-cog"></i><span>mdi-movie-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-cog-outline"></i><span>mdi-movie-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-edit"></i><span>mdi-movie-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-edit-outline"></i><span>mdi-movie-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-filter"></i><span>mdi-movie-filter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-filter-outline"></i><span>mdi-movie-filter-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-minus"></i><span>mdi-movie-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-minus-outline"></i><span>mdi-movie-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-off"></i><span>mdi-movie-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-off-outline"></i><span>mdi-movie-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open"></i><span>mdi-movie-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-check"></i><span>mdi-movie-open-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-check-outline"></i><span>mdi-movie-open-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-cog"></i><span>mdi-movie-open-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-cog-outline"></i><span>mdi-movie-open-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-edit"></i><span>mdi-movie-open-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-edit-outline"></i><span>mdi-movie-open-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-minus"></i><span>mdi-movie-open-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-minus-outline"></i><span>mdi-movie-open-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-off"></i><span>mdi-movie-open-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-off-outline"></i><span>mdi-movie-open-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-outline"></i><span>mdi-movie-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-play"></i><span>mdi-movie-open-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-play-outline"></i><span>mdi-movie-open-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-plus"></i><span>mdi-movie-open-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-plus-outline"></i><span>mdi-movie-open-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-remove"></i><span>mdi-movie-open-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-remove-outline"></i><span>mdi-movie-open-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-settings"></i><span>mdi-movie-open-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-settings-outline"></i><span>mdi-movie-open-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-star"></i><span>mdi-movie-open-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-open-star-outline"></i><span>mdi-movie-open-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-outline"></i><span>mdi-movie-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-play"></i><span>mdi-movie-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-play-outline"></i><span>mdi-movie-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-plus"></i><span>mdi-movie-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-plus-outline"></i><span>mdi-movie-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-remove"></i><span>mdi-movie-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-remove-outline"></i><span>mdi-movie-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-roll"></i><span>mdi-movie-roll</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-search"></i><span>mdi-movie-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-search-outline"></i><span>mdi-movie-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-settings"></i><span>mdi-movie-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-settings-outline"></i><span>mdi-movie-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-star"></i><span>mdi-movie-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-movie-star-outline"></i><span>mdi-movie-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mower"></i><span>mdi-mower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mower-bag"></i><span>mdi-mower-bag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-muffin"></i><span>mdi-muffin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-multicast"></i><span>mdi-multicast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-multiplication"></i><span>mdi-multiplication</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-multiplication-box"></i><span>mdi-multiplication-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mushroom"></i><span>mdi-mushroom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mushroom-off"></i><span>mdi-mushroom-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mushroom-off-outline"></i><span>mdi-mushroom-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mushroom-outline"></i><span>mdi-mushroom-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music"></i><span>mdi-music</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-accidental-double-flat"></i><span>mdi-music-accidental-double-flat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-accidental-double-sharp"></i><span>mdi-music-accidental-double-sharp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-accidental-flat"></i><span>mdi-music-accidental-flat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-accidental-natural"></i><span>mdi-music-accidental-natural</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-accidental-sharp"></i><span>mdi-music-accidental-sharp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-box"></i><span>mdi-music-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-box-multiple"></i><span>mdi-music-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-box-multiple-outline"></i><span>mdi-music-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-box-outline"></i><span>mdi-music-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-circle"></i><span>mdi-music-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-circle-outline"></i><span>mdi-music-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-clef-alto"></i><span>mdi-music-clef-alto</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-clef-bass"></i><span>mdi-music-clef-bass</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-clef-treble"></i><span>mdi-music-clef-treble</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note"></i><span>mdi-music-note</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-bluetooth"></i><span>mdi-music-note-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-bluetooth-off"></i><span>mdi-music-note-bluetooth-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-eighth"></i><span>mdi-music-note-eighth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-eighth-dotted"></i><span>mdi-music-note-eighth-dotted</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-half"></i><span>mdi-music-note-half</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-half-dotted"></i><span>mdi-music-note-half-dotted</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-off"></i><span>mdi-music-note-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-off-outline"></i><span>mdi-music-note-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-outline"></i><span>mdi-music-note-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-plus"></i><span>mdi-music-note-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-quarter"></i><span>mdi-music-note-quarter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-quarter-dotted"></i><span>mdi-music-note-quarter-dotted</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-sixteenth"></i><span>mdi-music-note-sixteenth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-sixteenth-dotted"></i><span>mdi-music-note-sixteenth-dotted</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-whole"></i><span>mdi-music-note-whole</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-note-whole-dotted"></i><span>mdi-music-note-whole-dotted</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-off"></i><span>mdi-music-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-rest-eighth"></i><span>mdi-music-rest-eighth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-rest-half"></i><span>mdi-music-rest-half</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-rest-quarter"></i><span>mdi-music-rest-quarter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-rest-sixteenth"></i><span>mdi-music-rest-sixteenth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-music-rest-whole"></i><span>mdi-music-rest-whole</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-mustache"></i><span>mdi-mustache</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nail"></i><span>mdi-nail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nas"></i><span>mdi-nas</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nativescript"></i><span>mdi-nativescript</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nature"></i><span>mdi-nature</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nature-people"></i><span>mdi-nature-people</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-navigation"></i><span>mdi-navigation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-navigation-outline"></i><span>mdi-navigation-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-navigation-variant"></i><span>mdi-navigation-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-navigation-variant-outline"></i><span>mdi-navigation-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-near-me"></i><span>mdi-near-me</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-necklace"></i><span>mdi-necklace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-needle"></i><span>mdi-needle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-netflix"></i><span>mdi-netflix</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network"></i><span>mdi-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-off"></i><span>mdi-network-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-off-outline"></i><span>mdi-network-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-outline"></i><span>mdi-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-1"></i><span>mdi-network-strength-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-1-alert"></i><span>mdi-network-strength-1-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-2"></i><span>mdi-network-strength-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-2-alert"></i><span>mdi-network-strength-2-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-3"></i><span>mdi-network-strength-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-3-alert"></i><span>mdi-network-strength-3-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-4"></i><span>mdi-network-strength-4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-4-alert"></i><span>mdi-network-strength-4-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-4-cog"></i><span>mdi-network-strength-4-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-off"></i><span>mdi-network-strength-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-off-outline"></i><span>mdi-network-strength-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-network-strength-outline"></i><span>mdi-network-strength-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-new-box"></i><span>mdi-new-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-newspaper"></i><span>mdi-newspaper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-newspaper-check"></i><span>mdi-newspaper-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-newspaper-minus"></i><span>mdi-newspaper-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-newspaper-plus"></i><span>mdi-newspaper-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-newspaper-remove"></i><span>mdi-newspaper-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-newspaper-variant"></i><span>mdi-newspaper-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-newspaper-variant-multiple"></i><span>mdi-newspaper-variant-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-newspaper-variant-multiple-outline"></i><span>mdi-newspaper-variant-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-newspaper-variant-outline"></i><span>mdi-newspaper-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nfc"></i><span>mdi-nfc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nfc-search-variant"></i><span>mdi-nfc-search-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nfc-tap"></i><span>mdi-nfc-tap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nfc-variant"></i><span>mdi-nfc-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nfc-variant-off"></i><span>mdi-nfc-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ninja"></i><span>mdi-ninja</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nintendo-game-boy"></i><span>mdi-nintendo-game-boy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nintendo-switch"></i><span>mdi-nintendo-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nintendo-wii"></i><span>mdi-nintendo-wii</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nintendo-wiiu"></i><span>mdi-nintendo-wiiu</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nix"></i><span>mdi-nix</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nodejs"></i><span>mdi-nodejs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-noodles"></i><span>mdi-noodles</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-not-equal"></i><span>mdi-not-equal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-not-equal-variant"></i><span>mdi-not-equal-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note"></i><span>mdi-note</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-alert"></i><span>mdi-note-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-border-2 bg-body-secondary"></i><span>mdi-note-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-check"></i><span>mdi-note-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-check-outline"></i><span>mdi-note-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-edit"></i><span>mdi-note-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-edit-outline"></i><span>mdi-note-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-minus"></i><span>mdi-note-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-minus-outline"></i><span>mdi-note-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-multiple"></i><span>mdi-note-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-multiple-outline"></i><span>mdi-note-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-off"></i><span>mdi-note-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-off-outline"></i><span>mdi-note-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-outline"></i><span>mdi-note-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-plus"></i><span>mdi-note-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-plus-outline"></i><span>mdi-note-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-remove"></i><span>mdi-note-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-remove-outline"></i><span>mdi-note-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-search"></i><span>mdi-note-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-search-outline"></i><span>mdi-note-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-text"></i><span>mdi-note-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-note-text-outline"></i><span>mdi-note-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook"></i><span>mdi-notebook</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-check"></i><span>mdi-notebook-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-check-outline"></i><span>mdi-notebook-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-edit"></i><span>mdi-notebook-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-edit-outline"></i><span>mdi-notebook-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-minus"></i><span>mdi-notebook-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-minus-outline"></i><span>mdi-notebook-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-multiple"></i><span>mdi-notebook-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-outline"></i><span>mdi-notebook-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-plus"></i><span>mdi-notebook-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-plus-outline"></i><span>mdi-notebook-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-remove"></i><span>mdi-notebook-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notebook-remove-outline"></i><span>mdi-notebook-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-notification-clear-all"></i><span>mdi-notification-clear-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-npm"></i><span>mdi-npm</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nuke"></i><span>mdi-nuke</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-null"></i><span>mdi-null</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric"></i><span>mdi-numeric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-0"></i><span>mdi-numeric-0</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-0-box"></i><span>mdi-numeric-0-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-0-box-multiple"></i><span>mdi-numeric-0-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-0-box-multiple-outline"></i><span>mdi-numeric-0-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-0-box-outline"></i><span>mdi-numeric-0-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-0-circle"></i><span>mdi-numeric-0-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-0-circle-outline"></i><span>mdi-numeric-0-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-1"></i><span>mdi-numeric-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-1-box"></i><span>mdi-numeric-1-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-1-box-multiple"></i><span>mdi-numeric-1-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-1-box-multiple-outline"></i><span>mdi-numeric-1-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-1-box-outline"></i><span>mdi-numeric-1-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-1-circle"></i><span>mdi-numeric-1-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-1-circle-outline"></i><span>mdi-numeric-1-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-10"></i><span>mdi-numeric-10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-10-box"></i><span>mdi-numeric-10-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-10-box-multiple"></i><span>mdi-numeric-10-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-10-box-multiple-outline"></i><span>mdi-numeric-10-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-10-box-outline"></i><span>mdi-numeric-10-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-10-circle"></i><span>mdi-numeric-10-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-10-circle-outline"></i><span>mdi-numeric-10-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-2"></i><span>mdi-numeric-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-2-box"></i><span>mdi-numeric-2-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-2-box-multiple"></i><span>mdi-numeric-2-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-2-box-multiple-outline"></i><span>mdi-numeric-2-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-2-box-outline"></i><span>mdi-numeric-2-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-2-circle"></i><span>mdi-numeric-2-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-2-circle-outline"></i><span>mdi-numeric-2-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-3"></i><span>mdi-numeric-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-3-box"></i><span>mdi-numeric-3-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-3-box-multiple"></i><span>mdi-numeric-3-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-3-box-multiple-outline"></i><span>mdi-numeric-3-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-3-box-outline"></i><span>mdi-numeric-3-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-3-circle"></i><span>mdi-numeric-3-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-3-circle-outline"></i><span>mdi-numeric-3-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-4"></i><span>mdi-numeric-4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-4-box"></i><span>mdi-numeric-4-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-4-box-multiple"></i><span>mdi-numeric-4-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-4-box-multiple-outline"></i><span>mdi-numeric-4-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-4-box-outline"></i><span>mdi-numeric-4-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-4-circle"></i><span>mdi-numeric-4-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-4-circle-outline"></i><span>mdi-numeric-4-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-5"></i><span>mdi-numeric-5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-5-box"></i><span>mdi-numeric-5-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-5-box-multiple"></i><span>mdi-numeric-5-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-5-box-multiple-outline"></i><span>mdi-numeric-5-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-5-box-outline"></i><span>mdi-numeric-5-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-5-circle"></i><span>mdi-numeric-5-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-5-circle-outline"></i><span>mdi-numeric-5-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-6"></i><span>mdi-numeric-6</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-6-box"></i><span>mdi-numeric-6-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-6-box-multiple"></i><span>mdi-numeric-6-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-6-box-multiple-outline"></i><span>mdi-numeric-6-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-6-box-outline"></i><span>mdi-numeric-6-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-6-circle"></i><span>mdi-numeric-6-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-6-circle-outline"></i><span>mdi-numeric-6-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-7"></i><span>mdi-numeric-7</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-7-box"></i><span>mdi-numeric-7-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-7-box-multiple"></i><span>mdi-numeric-7-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-7-box-multiple-outline"></i><span>mdi-numeric-7-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-7-box-outline"></i><span>mdi-numeric-7-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-7-circle"></i><span>mdi-numeric-7-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-7-circle-outline"></i><span>mdi-numeric-7-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-8"></i><span>mdi-numeric-8</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-8-box"></i><span>mdi-numeric-8-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-8-box-multiple"></i><span>mdi-numeric-8-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-8-box-multiple-outline"></i><span>mdi-numeric-8-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-8-box-outline"></i><span>mdi-numeric-8-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-8-circle"></i><span>mdi-numeric-8-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-8-circle-outline"></i><span>mdi-numeric-8-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9"></i><span>mdi-numeric-9</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-box"></i><span>mdi-numeric-9-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-box-multiple"></i><span>mdi-numeric-9-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-box-multiple-outline"></i><span>mdi-numeric-9-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-box-outline"></i><span>mdi-numeric-9-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-circle"></i><span>mdi-numeric-9-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-circle-outline"></i><span>mdi-numeric-9-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-plus"></i><span>mdi-numeric-9-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-plus-box"></i><span>mdi-numeric-9-plus-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-plus-box-multiple"></i><span>mdi-numeric-9-plus-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-plus-box-multiple-outline"></i><span>mdi-numeric-9-plus-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-plus-box-outline"></i><span>mdi-numeric-9-plus-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-plus-circle"></i><span>mdi-numeric-9-plus-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-9-plus-circle-outline"></i><span>mdi-numeric-9-plus-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-negative-1"></i><span>mdi-numeric-negative-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-numeric-positive-1"></i><span>mdi-numeric-positive-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nut"></i><span>mdi-nut</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nutrition"></i><span>mdi-nutrition</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-nuxt"></i><span>mdi-nuxt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-oar"></i><span>mdi-oar</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ocarina"></i><span>mdi-ocarina</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-oci"></i><span>mdi-oci</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ocr"></i><span>mdi-ocr</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-octagon"></i><span>mdi-octagon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-octagon-outline"></i><span>mdi-octagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-octagram"></i><span>mdi-octagram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-octagram-outline"></i><span>mdi-octagram-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-octahedron"></i><span>mdi-octahedron</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-octahedron-off"></i><span>mdi-octahedron-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-odnoklassniki"></i><span>mdi-odnoklassniki</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-offer"></i><span>mdi-offer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-office-building"></i><span>mdi-office-building</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-office-building-cog"></i><span>mdi-office-building-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-office-building-cog-outline"></i><span>mdi-office-building-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-office-building-marker"></i><span>mdi-office-building-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-office-building-marker-outline"></i><span>mdi-office-building-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-office-building-outline"></i><span>mdi-office-building-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-oil"></i><span>mdi-oil</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-oil-lamp"></i><span>mdi-oil-lamp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-oil-level"></i><span>mdi-oil-level</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-oil-temperature"></i><span>mdi-oil-temperature</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-om"></i><span>mdi-om</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-omega"></i><span>mdi-omega</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-one-up"></i><span>mdi-one-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-onepassword"></i><span>mdi-onepassword</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-opacity"></i><span>mdi-opacity</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-open-in-app"></i><span>mdi-open-in-app</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-open-in-new"></i><span>mdi-open-in-new</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-open-source-initiative"></i><span>mdi-open-source-initiative</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-openid"></i><span>mdi-openid</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-opera"></i><span>mdi-opera</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-orbit"></i><span>mdi-orbit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-orbit-variant"></i><span>mdi-orbit-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-order-alphabetical-ascending"></i><span>mdi-order-alphabetical-ascending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-order-alphabetical-descending"></i><span>mdi-order-alphabetical-descending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-order-bool-ascending"></i><span>mdi-order-bool-ascending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-order-bool-ascending-variant"></i><span>mdi-order-bool-ascending-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-order-bool-descending"></i><span>mdi-order-bool-descending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-order-bool-descending-variant"></i><span>mdi-order-bool-descending-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-order-numeric-ascending"></i><span>mdi-order-numeric-ascending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-order-numeric-descending"></i><span>mdi-order-numeric-descending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-origin"></i><span>mdi-origin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ornament"></i><span>mdi-ornament</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ornament-variant"></i><span>mdi-ornament-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-outdoor-lamp"></i><span>mdi-outdoor-lamp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-overscan"></i><span>mdi-overscan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-owl"></i><span>mdi-owl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pac-man"></i><span>mdi-pac-man</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-package"></i><span>mdi-package</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-package-down"></i><span>mdi-package-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-package-up"></i><span>mdi-package-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-package-variant"></i><span>mdi-package-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-package-variant-closed"></i><span>mdi-package-variant-closed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-first"></i><span>mdi-page-first</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-last"></i><span>mdi-page-last</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-layout-body"></i><span>mdi-page-layout-body</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-layout-footer"></i><span>mdi-page-layout-footer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-layout-header"></i><span>mdi-page-layout-header</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-layout-header-footer"></i><span>mdi-page-layout-header-footer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-layout-sidebar-left"></i><span>mdi-page-layout-sidebar-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-layout-sidebar-right"></i><span>mdi-page-layout-sidebar-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-next"></i><span>mdi-page-next</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-next-outline"></i><span>mdi-page-next-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-previous"></i><span>mdi-page-previous</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-page-previous-outline"></i><span>mdi-page-previous-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail"></i><span>mdi-pail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail-minus"></i><span>mdi-pail-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail-minus-outline"></i><span>mdi-pail-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail-off"></i><span>mdi-pail-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail-off-outline"></i><span>mdi-pail-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail-outline"></i><span>mdi-pail-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail-plus"></i><span>mdi-pail-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail-plus-outline"></i><span>mdi-pail-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail-remove"></i><span>mdi-pail-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pail-remove-outline"></i><span>mdi-pail-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-palette"></i><span>mdi-palette</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-palette-advanced"></i><span>mdi-palette-advanced</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-palette-outline"></i><span>mdi-palette-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-palette-swatch"></i><span>mdi-palette-swatch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-palette-swatch-outline"></i><span>mdi-palette-swatch-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-palette-swatch-variant"></i><span>mdi-palette-swatch-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-palm-tree"></i><span>mdi-palm-tree</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan"></i><span>mdi-pan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-bottom-left"></i><span>mdi-pan-bottom-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-bottom-right"></i><span>mdi-pan-bottom-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-down"></i><span>mdi-pan-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-horizontal"></i><span>mdi-pan-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-left"></i><span>mdi-pan-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-right"></i><span>mdi-pan-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-top-left"></i><span>mdi-pan-top-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-top-right"></i><span>mdi-pan-top-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-up"></i><span>mdi-pan-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pan-vertical"></i><span>mdi-pan-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panda"></i><span>mdi-panda</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pandora"></i><span>mdi-pandora</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama"></i><span>mdi-panorama</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-fisheye"></i><span>mdi-panorama-fisheye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-horizontal"></i><span>mdi-panorama-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-horizontal-outline"></i><span>mdi-panorama-horizontal-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-outline"></i><span>mdi-panorama-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-sphere"></i><span>mdi-panorama-sphere</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-sphere-outline"></i><span>mdi-panorama-sphere-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-variant"></i><span>mdi-panorama-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-variant-outline"></i><span>mdi-panorama-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-vertical"></i><span>mdi-panorama-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-vertical-outline"></i><span>mdi-panorama-vertical-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-wide-angle"></i><span>mdi-panorama-wide-angle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-panorama-wide-angle-outline"></i><span>mdi-panorama-wide-angle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-paper-cut-vertical"></i><span>mdi-paper-cut-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-paper-roll"></i><span>mdi-paper-roll</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-paper-roll-outline"></i><span>mdi-paper-roll-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-paperclip"></i><span>mdi-paperclip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-parachute"></i><span>mdi-parachute</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-parachute-outline"></i><span>mdi-parachute-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-paragliding"></i><span>mdi-paragliding</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-parking"></i><span>mdi-parking</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-party-popper"></i><span>mdi-party-popper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-passport"></i><span>mdi-passport</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-passport-biometric"></i><span>mdi-passport-biometric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pasta"></i><span>mdi-pasta</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-patio-heater"></i><span>mdi-patio-heater</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-patreon"></i><span>mdi-patreon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pause"></i><span>mdi-pause</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pause-circle"></i><span>mdi-pause-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pause-circle-outline"></i><span>mdi-pause-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pause-octagon"></i><span>mdi-pause-octagon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pause-octagon-outline"></i><span>mdi-pause-octagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-paw"></i><span>mdi-paw</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-paw-off"></i><span>mdi-paw-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-paw-off-outline"></i><span>mdi-paw-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-paw-outline"></i><span>mdi-paw-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-peace"></i><span>mdi-peace</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-peanut"></i><span>mdi-peanut</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-peanut-off"></i><span>mdi-peanut-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-peanut-off-outline"></i><span>mdi-peanut-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-peanut-outline"></i><span>mdi-peanut-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pen"></i><span>mdi-pen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pen-lock"></i><span>mdi-pen-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pen-minus"></i><span>mdi-pen-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pen-off"></i><span>mdi-pen-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pen-plus"></i><span>mdi-pen-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pen-remove"></i><span>mdi-pen-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil"></i><span>mdi-pencil</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-box"></i><span>mdi-pencil-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-box-multiple"></i><span>mdi-pencil-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-box-multiple-outline"></i><span>mdi-pencil-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-box-outline"></i><span>mdi-pencil-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-circle"></i><span>mdi-pencil-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-circle-outline"></i><span>mdi-pencil-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-lock"></i><span>mdi-pencil-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-lock-outline"></i><span>mdi-pencil-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-minus"></i><span>mdi-pencil-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-minus-outline"></i><span>mdi-pencil-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-off"></i><span>mdi-pencil-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-off-outline"></i><span>mdi-pencil-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-outline"></i><span>mdi-pencil-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-plus"></i><span>mdi-pencil-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-plus-outline"></i><span>mdi-pencil-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-remove"></i><span>mdi-pencil-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-remove-outline"></i><span>mdi-pencil-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pencil-ruler"></i><span>mdi-pencil-ruler</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-penguin"></i><span>mdi-penguin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pentagon"></i><span>mdi-pentagon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pentagon-outline"></i><span>mdi-pentagon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pentagram"></i><span>mdi-pentagram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-percent"></i><span>mdi-percent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-percent-outline"></i><span>mdi-percent-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-periodic-table"></i><span>mdi-periodic-table</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-perspective-less"></i><span>mdi-perspective-less</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-perspective-more"></i><span>mdi-perspective-more</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ph"></i><span>mdi-ph</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone"></i><span>mdi-phone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-alert"></i><span>mdi-phone-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-border-2 bg-body-secondary"></i><span>mdi-phone-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-bluetooth"></i><span>mdi-phone-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-bluetooth-outline"></i><span>mdi-phone-bluetooth-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-cancel"></i><span>mdi-phone-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-cancel-outline"></i><span>mdi-phone-cancel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-check"></i><span>mdi-phone-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-check-outline"></i><span>mdi-phone-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-classic"></i><span>mdi-phone-classic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-classic-off"></i><span>mdi-phone-classic-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-dial"></i><span>mdi-phone-dial</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-dial-outline"></i><span>mdi-phone-dial-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-forward"></i><span>mdi-phone-forward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-forward-outline"></i><span>mdi-phone-forward-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-hangup"></i><span>mdi-phone-hangup</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-hangup-outline"></i><span>mdi-phone-hangup-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-in-talk"></i><span>mdi-phone-in-talk</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-in-talk-outline"></i><span>mdi-phone-in-talk-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-incoming"></i><span>mdi-phone-incoming</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-incoming-outline"></i><span>mdi-phone-incoming-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-lock"></i><span>mdi-phone-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-lock-outline"></i><span>mdi-phone-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-log"></i><span>mdi-phone-log</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-log-outline"></i><span>mdi-phone-log-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-message"></i><span>mdi-phone-message</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-message-outline"></i><span>mdi-phone-message-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-minus"></i><span>mdi-phone-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-minus-outline"></i><span>mdi-phone-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-missed"></i><span>mdi-phone-missed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-missed-outline"></i><span>mdi-phone-missed-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-off"></i><span>mdi-phone-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-off-outline"></i><span>mdi-phone-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-outgoing"></i><span>mdi-phone-outgoing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-outgoing-outline"></i><span>mdi-phone-outgoing-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-outline"></i><span>mdi-phone-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-paused"></i><span>mdi-phone-paused</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-paused-outline"></i><span>mdi-phone-paused-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-plus"></i><span>mdi-phone-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-plus-outline"></i><span>mdi-phone-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-refresh"></i><span>mdi-phone-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-refresh-outline"></i><span>mdi-phone-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-remove"></i><span>mdi-phone-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-remove-outline"></i><span>mdi-phone-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-return"></i><span>mdi-phone-return</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-return-outline"></i><span>mdi-phone-return-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-ring"></i><span>mdi-phone-ring</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-ring-outline"></i><span>mdi-phone-ring-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-rotate-landscape"></i><span>mdi-phone-rotate-landscape</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-rotate-portrait"></i><span>mdi-phone-rotate-portrait</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-settings"></i><span>mdi-phone-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-settings-outline"></i><span>mdi-phone-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-sync"></i><span>mdi-phone-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-sync-outline"></i><span>mdi-phone-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-phone-voip"></i><span>mdi-phone-voip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pi"></i><span>mdi-pi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pi-box"></i><span>mdi-pi-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pi-hole"></i><span>mdi-pi-hole</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-piano"></i><span>mdi-piano</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-piano-off"></i><span>mdi-piano-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pickaxe"></i><span>mdi-pickaxe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-picture-in-picture-bottom-right"></i><span>mdi-picture-in-picture-bottom-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-picture-in-picture-bottom-right-outline"></i><span>mdi-picture-in-picture-bottom-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-picture-in-picture-top-right"></i><span>mdi-picture-in-picture-top-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-picture-in-picture-top-right-outline"></i><span>mdi-picture-in-picture-top-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pier"></i><span>mdi-pier</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pier-crane"></i><span>mdi-pier-crane</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pig"></i><span>mdi-pig</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pig-variant"></i><span>mdi-pig-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pig-variant-outline"></i><span>mdi-pig-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-piggy-bank"></i><span>mdi-piggy-bank</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-piggy-bank-outline"></i><span>mdi-piggy-bank-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pill"></i><span>mdi-pill</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pillar"></i><span>mdi-pillar</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pin"></i><span>mdi-pin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pin-off"></i><span>mdi-pin-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pin-off-outline"></i><span>mdi-pin-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pin-outline"></i><span>mdi-pin-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pine-tree"></i><span>mdi-pine-tree</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pine-tree-box"></i><span>mdi-pine-tree-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pine-tree-fire"></i><span>mdi-pine-tree-fire</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pinterest"></i><span>mdi-pinterest</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pinwheel"></i><span>mdi-pinwheel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pinwheel-outline"></i><span>mdi-pinwheel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pipe"></i><span>mdi-pipe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pipe-disconnected"></i><span>mdi-pipe-disconnected</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pipe-leak"></i><span>mdi-pipe-leak</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pipe-valve"></i><span>mdi-pipe-valve</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pipe-wrench"></i><span>mdi-pipe-wrench</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pirate"></i><span>mdi-pirate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pistol"></i><span>mdi-pistol</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-piston"></i><span>mdi-piston</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pitchfork"></i><span>mdi-pitchfork</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pizza"></i><span>mdi-pizza</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play"></i><span>mdi-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-box"></i><span>mdi-play-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-box-multiple"></i><span>mdi-play-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-box-multiple-outline"></i><span>mdi-play-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-box-outline"></i><span>mdi-play-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-circle"></i><span>mdi-play-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-circle-outline"></i><span>mdi-play-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-network"></i><span>mdi-play-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-network-outline"></i><span>mdi-play-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-outline"></i><span>mdi-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-pause"></i><span>mdi-play-pause</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-protected-content"></i><span>mdi-play-protected-content</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-play-speed"></i><span>mdi-play-speed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-playlist-check"></i><span>mdi-playlist-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-playlist-edit"></i><span>mdi-playlist-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-playlist-minus"></i><span>mdi-playlist-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-playlist-music"></i><span>mdi-playlist-music</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-playlist-music-outline"></i><span>mdi-playlist-music-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-playlist-play"></i><span>mdi-playlist-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-playlist-plus"></i><span>mdi-playlist-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-playlist-remove"></i><span>mdi-playlist-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-playlist-star"></i><span>mdi-playlist-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plex"></i><span>mdi-plex</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pliers"></i><span>mdi-pliers</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus"></i><span>mdi-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-box"></i><span>mdi-plus-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-box-multiple"></i><span>mdi-plus-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-box-multiple-outline"></i><span>mdi-plus-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-box-outline"></i><span>mdi-plus-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-circle"></i><span>mdi-plus-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-circle-multiple"></i><span>mdi-plus-circle-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-circle-multiple-outline"></i><span>mdi-plus-circle-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-circle-outline"></i><span>mdi-plus-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-minus"></i><span>mdi-plus-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-minus-box"></i><span>mdi-plus-minus-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-minus-variant"></i><span>mdi-plus-minus-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-network"></i><span>mdi-plus-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-network-outline"></i><span>mdi-plus-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-outline"></i><span>mdi-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-plus-thick"></i><span>mdi-plus-thick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-podcast"></i><span>mdi-podcast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-podium"></i><span>mdi-podium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-podium-bronze"></i><span>mdi-podium-bronze</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-podium-gold"></i><span>mdi-podium-gold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-podium-silver"></i><span>mdi-podium-silver</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-point-of-sale"></i><span>mdi-point-of-sale</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pokeball"></i><span>mdi-pokeball</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pokemon-go"></i><span>mdi-pokemon-go</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-poker-chip"></i><span>mdi-poker-chip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-polaroid"></i><span>mdi-polaroid</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-police-badge"></i><span>mdi-police-badge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-police-badge-outline"></i><span>mdi-police-badge-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-police-station"></i><span>mdi-police-station</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-poll"></i><span>mdi-poll</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-polo"></i><span>mdi-polo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-polymer"></i><span>mdi-polymer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pool"></i><span>mdi-pool</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-popcorn"></i><span>mdi-popcorn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-post"></i><span>mdi-post</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-post-outline"></i><span>mdi-post-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-postage-stamp"></i><span>mdi-postage-stamp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pot"></i><span>mdi-pot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pot-mix"></i><span>mdi-pot-mix</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pot-mix-outline"></i><span>mdi-pot-mix-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pot-outline"></i><span>mdi-pot-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pot-steam"></i><span>mdi-pot-steam</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pot-steam-outline"></i><span>mdi-pot-steam-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pound"></i><span>mdi-pound</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pound-box"></i><span>mdi-pound-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pound-box-outline"></i><span>mdi-pound-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power"></i><span>mdi-power</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-cycle"></i><span>mdi-power-cycle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-off"></i><span>mdi-power-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-on"></i><span>mdi-power-on</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-plug"></i><span>mdi-power-plug</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-plug-off"></i><span>mdi-power-plug-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-plug-off-outline"></i><span>mdi-power-plug-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-plug-outline"></i><span>mdi-power-plug-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-settings"></i><span>mdi-power-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-sleep"></i><span>mdi-power-sleep</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket"></i><span>mdi-power-socket</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket-au"></i><span>mdi-power-socket-au</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket-ch"></i><span>mdi-power-socket-ch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket-de"></i><span>mdi-power-socket-de</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket-eu"></i><span>mdi-power-socket-eu</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket-fr"></i><span>mdi-power-socket-fr</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket-it"></i><span>mdi-power-socket-it</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket-jp"></i><span>mdi-power-socket-jp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket-uk"></i><span>mdi-power-socket-uk</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-socket-us"></i><span>mdi-power-socket-us</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-power-standby"></i><span>mdi-power-standby</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-powershell"></i><span>mdi-powershell</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-prescription"></i><span>mdi-prescription</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-presentation"></i><span>mdi-presentation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-presentation-play"></i><span>mdi-presentation-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pretzel"></i><span>mdi-pretzel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer"></i><span>mdi-printer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-3d"></i><span>mdi-printer-3d</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-3d-nozzle"></i><span>mdi-printer-3d-nozzle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-3d-nozzle-alert"></i><span>mdi-printer-3d-nozzle-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-3d-nozzle-border-2 bg-body-secondary"></i><span>mdi-printer-3d-nozzle-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-3d-nozzle-heat"></i><span>mdi-printer-3d-nozzle-heat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-3d-nozzle-heat-outline"></i><span>mdi-printer-3d-nozzle-heat-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-3d-nozzle-outline"></i><span>mdi-printer-3d-nozzle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-alert"></i><span>mdi-printer-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-check"></i><span>mdi-printer-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-eye"></i><span>mdi-printer-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-off"></i><span>mdi-printer-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-off-outline"></i><span>mdi-printer-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-outline"></i><span>mdi-printer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-pos"></i><span>mdi-printer-pos</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-search"></i><span>mdi-printer-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-settings"></i><span>mdi-printer-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-printer-wireless"></i><span>mdi-printer-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-priority-high"></i><span>mdi-priority-high</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-priority-low"></i><span>mdi-priority-low</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-professional-hexagon"></i><span>mdi-professional-hexagon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-alert"></i><span>mdi-progress-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-check"></i><span>mdi-progress-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-clock"></i><span>mdi-progress-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-close"></i><span>mdi-progress-close</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-download"></i><span>mdi-progress-download</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-pencil"></i><span>mdi-progress-pencil</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-question"></i><span>mdi-progress-question</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-star"></i><span>mdi-progress-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-upload"></i><span>mdi-progress-upload</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-progress-wrench"></i><span>mdi-progress-wrench</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-projector"></i><span>mdi-projector</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-projector-screen"></i><span>mdi-projector-screen</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-projector-screen-off"></i><span>mdi-projector-screen-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-projector-screen-off-outline"></i><span>mdi-projector-screen-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-projector-screen-outline"></i><span>mdi-projector-screen-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-projector-screen-variant"></i><span>mdi-projector-screen-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-projector-screen-variant-off"></i><span>mdi-projector-screen-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-projector-screen-variant-off-outline"></i><span>mdi-projector-screen-variant-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-projector-screen-variant-outline"></i><span>mdi-projector-screen-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-propane-tank"></i><span>mdi-propane-tank</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-propane-tank-outline"></i><span>mdi-propane-tank-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-protocol"></i><span>mdi-protocol</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-publish"></i><span>mdi-publish</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-publish-off"></i><span>mdi-publish-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pulse"></i><span>mdi-pulse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pump"></i><span>mdi-pump</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pumpkin"></i><span>mdi-pumpkin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-purse"></i><span>mdi-purse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-purse-outline"></i><span>mdi-purse-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle"></i><span>mdi-puzzle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-check"></i><span>mdi-puzzle-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-check-outline"></i><span>mdi-puzzle-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-edit"></i><span>mdi-puzzle-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-edit-outline"></i><span>mdi-puzzle-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-heart"></i><span>mdi-puzzle-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-heart-outline"></i><span>mdi-puzzle-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-minus"></i><span>mdi-puzzle-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-minus-outline"></i><span>mdi-puzzle-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-outline"></i><span>mdi-puzzle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-plus"></i><span>mdi-puzzle-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-plus-outline"></i><span>mdi-puzzle-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-remove"></i><span>mdi-puzzle-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-remove-outline"></i><span>mdi-puzzle-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-star"></i><span>mdi-puzzle-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-puzzle-star-outline"></i><span>mdi-puzzle-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pyramid"></i><span>mdi-pyramid</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-pyramid-off"></i><span>mdi-pyramid-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-qi"></i><span>mdi-qi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-qqchat"></i><span>mdi-qqchat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-qrcode"></i><span>mdi-qrcode</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-qrcode-edit"></i><span>mdi-qrcode-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-qrcode-minus"></i><span>mdi-qrcode-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-qrcode-plus"></i><span>mdi-qrcode-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-qrcode-remove"></i><span>mdi-qrcode-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-qrcode-scan"></i><span>mdi-qrcode-scan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-quadcopter"></i><span>mdi-quadcopter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-quality-high"></i><span>mdi-quality-high</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-quality-low"></i><span>mdi-quality-low</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-quality-medium"></i><span>mdi-quality-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-quora"></i><span>mdi-quora</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rabbit"></i><span>mdi-rabbit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-racing-helmet"></i><span>mdi-racing-helmet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-racquetball"></i><span>mdi-racquetball</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radar"></i><span>mdi-radar</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radiator"></i><span>mdi-radiator</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radiator-disabled"></i><span>mdi-radiator-disabled</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radiator-off"></i><span>mdi-radiator-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radio"></i><span>mdi-radio</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radio-am"></i><span>mdi-radio-am</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radio-fm"></i><span>mdi-radio-fm</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radio-handheld"></i><span>mdi-radio-handheld</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radio-off"></i><span>mdi-radio-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radio-tower"></i><span>mdi-radio-tower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radioactive"></i><span>mdi-radioactive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radioactive-circle"></i><span>mdi-radioactive-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radioactive-circle-outline"></i><span>mdi-radioactive-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radioactive-off"></i><span>mdi-radioactive-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radiobox-blank"></i><span>mdi-radiobox-blank</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radiobox-marked"></i><span>mdi-radiobox-marked</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radiology-box"></i><span>mdi-radiology-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radiology-box-outline"></i><span>mdi-radiology-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radius"></i><span>mdi-radius</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-radius-outline"></i><span>mdi-radius-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-railroad-light"></i><span>mdi-railroad-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rake"></i><span>mdi-rake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-raspberry-pi"></i><span>mdi-raspberry-pi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ray-end"></i><span>mdi-ray-end</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ray-end-arrow"></i><span>mdi-ray-end-arrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ray-start"></i><span>mdi-ray-start</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ray-start-arrow"></i><span>mdi-ray-start-arrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ray-start-end"></i><span>mdi-ray-start-end</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ray-start-vertex-end"></i><span>mdi-ray-start-vertex-end</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ray-vertex"></i><span>mdi-ray-vertex</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-razor-double-edge"></i><span>mdi-razor-double-edge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-razor-single-edge"></i><span>mdi-razor-single-edge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-react"></i><span>mdi-react</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-read"></i><span>mdi-read</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-receipt"></i><span>mdi-receipt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-record"></i><span>mdi-record</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-record-circle"></i><span>mdi-record-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-record-circle-outline"></i><span>mdi-record-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-record-player"></i><span>mdi-record-player</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-record-rec"></i><span>mdi-record-rec</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rectangle"></i><span>mdi-rectangle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rectangle-outline"></i><span>mdi-rectangle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-recycle"></i><span>mdi-recycle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-recycle-variant"></i><span>mdi-recycle-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reddit"></i><span>mdi-reddit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-redhat"></i><span>mdi-redhat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-redo"></i><span>mdi-redo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-redo-variant"></i><span>mdi-redo-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reflect-horizontal"></i><span>mdi-reflect-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reflect-vertical"></i><span>mdi-reflect-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-refresh"></i><span>mdi-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-refresh-auto"></i><span>mdi-refresh-auto</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-refresh-circle"></i><span>mdi-refresh-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-regex"></i><span>mdi-regex</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-registered-trademark"></i><span>mdi-registered-trademark</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reiterate"></i><span>mdi-reiterate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-many-to-many"></i><span>mdi-relation-many-to-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-many-to-one"></i><span>mdi-relation-many-to-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-many-to-one-or-many"></i><span>mdi-relation-many-to-one-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-many-to-only-one"></i><span>mdi-relation-many-to-only-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-many-to-zero-or-many"></i><span>mdi-relation-many-to-zero-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-many-to-zero-or-one"></i><span>mdi-relation-many-to-zero-or-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-or-many-to-many"></i><span>mdi-relation-one-or-many-to-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-or-many-to-one"></i><span>mdi-relation-one-or-many-to-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-or-many-to-one-or-many"></i><span>mdi-relation-one-or-many-to-one-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-or-many-to-only-one"></i><span>mdi-relation-one-or-many-to-only-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-or-many-to-zero-or-many"></i><span>mdi-relation-one-or-many-to-zero-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-or-many-to-zero-or-one"></i><span>mdi-relation-one-or-many-to-zero-or-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-to-many"></i><span>mdi-relation-one-to-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-to-one"></i><span>mdi-relation-one-to-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-to-one-or-many"></i><span>mdi-relation-one-to-one-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-to-only-one"></i><span>mdi-relation-one-to-only-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-to-zero-or-many"></i><span>mdi-relation-one-to-zero-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-one-to-zero-or-one"></i><span>mdi-relation-one-to-zero-or-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-only-one-to-many"></i><span>mdi-relation-only-one-to-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-only-one-to-one"></i><span>mdi-relation-only-one-to-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-only-one-to-one-or-many"></i><span>mdi-relation-only-one-to-one-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-only-one-to-only-one"></i><span>mdi-relation-only-one-to-only-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-only-one-to-zero-or-many"></i><span>mdi-relation-only-one-to-zero-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-only-one-to-zero-or-one"></i><span>mdi-relation-only-one-to-zero-or-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-many-to-many"></i><span>mdi-relation-zero-or-many-to-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-many-to-one"></i><span>mdi-relation-zero-or-many-to-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-many-to-one-or-many"></i><span>mdi-relation-zero-or-many-to-one-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-many-to-only-one"></i><span>mdi-relation-zero-or-many-to-only-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-many-to-zero-or-many"></i><span>mdi-relation-zero-or-many-to-zero-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-many-to-zero-or-one"></i><span>mdi-relation-zero-or-many-to-zero-or-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-one-to-many"></i><span>mdi-relation-zero-or-one-to-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-one-to-one"></i><span>mdi-relation-zero-or-one-to-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-one-to-one-or-many"></i><span>mdi-relation-zero-or-one-to-one-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-one-to-only-one"></i><span>mdi-relation-zero-or-one-to-only-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-one-to-zero-or-many"></i><span>mdi-relation-zero-or-one-to-zero-or-many</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relation-zero-or-one-to-zero-or-one"></i><span>mdi-relation-zero-or-one-to-zero-or-one</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-relative-scale"></i><span>mdi-relative-scale</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reload"></i><span>mdi-reload</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reload-alert"></i><span>mdi-reload-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reminder"></i><span>mdi-reminder</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-remote"></i><span>mdi-remote</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-remote-desktop"></i><span>mdi-remote-desktop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-remote-off"></i><span>mdi-remote-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-remote-tv"></i><span>mdi-remote-tv</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-remote-tv-off"></i><span>mdi-remote-tv-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rename-box"></i><span>mdi-rename-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reorder-horizontal"></i><span>mdi-reorder-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reorder-vertical"></i><span>mdi-reorder-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-repeat"></i><span>mdi-repeat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-repeat-off"></i><span>mdi-repeat-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-repeat-once"></i><span>mdi-repeat-once</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-repeat-variant"></i><span>mdi-repeat-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-replay"></i><span>mdi-replay</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reply"></i><span>mdi-reply</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reply-all"></i><span>mdi-reply-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reply-all-outline"></i><span>mdi-reply-all-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reply-circle"></i><span>mdi-reply-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reply-outline"></i><span>mdi-reply-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-reproduction"></i><span>mdi-reproduction</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-resistor"></i><span>mdi-resistor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-resistor-nodes"></i><span>mdi-resistor-nodes</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-resize"></i><span>mdi-resize</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-resize-bottom-right"></i><span>mdi-resize-bottom-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-responsive"></i><span>mdi-responsive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-restart"></i><span>mdi-restart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-restart-alert"></i><span>mdi-restart-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-restart-off"></i><span>mdi-restart-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-restore"></i><span>mdi-restore</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-restore-alert"></i><span>mdi-restore-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rewind"></i><span>mdi-rewind</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rewind-10"></i><span>mdi-rewind-10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rewind-15"></i><span>mdi-rewind-15</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rewind-30"></i><span>mdi-rewind-30</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rewind-5"></i><span>mdi-rewind-5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rewind-60"></i><span>mdi-rewind-60</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rewind-outline"></i><span>mdi-rewind-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rhombus"></i><span>mdi-rhombus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rhombus-medium"></i><span>mdi-rhombus-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rhombus-medium-outline"></i><span>mdi-rhombus-medium-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rhombus-outline"></i><span>mdi-rhombus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rhombus-split"></i><span>mdi-rhombus-split</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rhombus-split-outline"></i><span>mdi-rhombus-split-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ribbon"></i><span>mdi-ribbon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rice"></i><span>mdi-rice</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rickshaw"></i><span>mdi-rickshaw</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rickshaw-electric"></i><span>mdi-rickshaw-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ring"></i><span>mdi-ring</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rivet"></i><span>mdi-rivet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-road"></i><span>mdi-road</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-road-variant"></i><span>mdi-road-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robber"></i><span>mdi-robber</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot"></i><span>mdi-robot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-angry"></i><span>mdi-robot-angry</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-angry-outline"></i><span>mdi-robot-angry-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-confused"></i><span>mdi-robot-confused</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-confused-outline"></i><span>mdi-robot-confused-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-dead"></i><span>mdi-robot-dead</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-dead-outline"></i><span>mdi-robot-dead-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-excited"></i><span>mdi-robot-excited</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-excited-outline"></i><span>mdi-robot-excited-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-happy"></i><span>mdi-robot-happy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-happy-outline"></i><span>mdi-robot-happy-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-industrial"></i><span>mdi-robot-industrial</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-love"></i><span>mdi-robot-love</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-love-outline"></i><span>mdi-robot-love-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-mower"></i><span>mdi-robot-mower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-mower-outline"></i><span>mdi-robot-mower-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-off"></i><span>mdi-robot-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-off-outline"></i><span>mdi-robot-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-outline"></i><span>mdi-robot-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-vacuum"></i><span>mdi-robot-vacuum</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-robot-vacuum-variant"></i><span>mdi-robot-vacuum-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rocket"></i><span>mdi-rocket</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rocket-launch"></i><span>mdi-rocket-launch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rocket-launch-outline"></i><span>mdi-rocket-launch-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rocket-outline"></i><span>mdi-rocket-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rodent"></i><span>mdi-rodent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roller-skate"></i><span>mdi-roller-skate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roller-skate-off"></i><span>mdi-roller-skate-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rollerblade"></i><span>mdi-rollerblade</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rollerblade-off"></i><span>mdi-rollerblade-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rollupjs"></i><span>mdi-rollupjs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-1"></i><span>mdi-roman-numeral-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-10"></i><span>mdi-roman-numeral-10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-2"></i><span>mdi-roman-numeral-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-3"></i><span>mdi-roman-numeral-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-4"></i><span>mdi-roman-numeral-4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-5"></i><span>mdi-roman-numeral-5</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-6"></i><span>mdi-roman-numeral-6</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-7"></i><span>mdi-roman-numeral-7</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-8"></i><span>mdi-roman-numeral-8</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-roman-numeral-9"></i><span>mdi-roman-numeral-9</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-room-service"></i><span>mdi-room-service</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-room-service-outline"></i><span>mdi-room-service-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rotate-360"></i><span>mdi-rotate-360</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rotate-3d"></i><span>mdi-rotate-3d</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rotate-3d-variant"></i><span>mdi-rotate-3d-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rotate-left"></i><span>mdi-rotate-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rotate-left-variant"></i><span>mdi-rotate-left-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rotate-orbit"></i><span>mdi-rotate-orbit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rotate-right"></i><span>mdi-rotate-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rotate-right-variant"></i><span>mdi-rotate-right-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rounded-corner"></i><span>mdi-rounded-corner</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-router"></i><span>mdi-router</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-router-network"></i><span>mdi-router-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-router-wireless"></i><span>mdi-router-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-router-wireless-off"></i><span>mdi-router-wireless-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-router-wireless-settings"></i><span>mdi-router-wireless-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-routes"></i><span>mdi-routes</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-routes-clock"></i><span>mdi-routes-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rowing"></i><span>mdi-rowing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rss"></i><span>mdi-rss</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rss-box"></i><span>mdi-rss-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rss-off"></i><span>mdi-rss-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rug"></i><span>mdi-rug</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rugby"></i><span>mdi-rugby</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ruler"></i><span>mdi-ruler</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ruler-square"></i><span>mdi-ruler-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ruler-square-compass"></i><span>mdi-ruler-square-compass</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-run"></i><span>mdi-run</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-run-fast"></i><span>mdi-run-fast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-rv-truck"></i><span>mdi-rv-truck</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sack"></i><span>mdi-sack</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sack-percent"></i><span>mdi-sack-percent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-safe"></i><span>mdi-safe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-safe-square"></i><span>mdi-safe-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-safe-square-outline"></i><span>mdi-safe-square-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-safety-goggles"></i><span>mdi-safety-goggles</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sail-boat"></i><span>mdi-sail-boat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sale"></i><span>mdi-sale</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-salesforce"></i><span>mdi-salesforce</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sass"></i><span>mdi-sass</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-satellite"></i><span>mdi-satellite</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-satellite-uplink"></i><span>mdi-satellite-uplink</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-satellite-variant"></i><span>mdi-satellite-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sausage"></i><span>mdi-sausage</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sausage-off"></i><span>mdi-sausage-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-saw-blade"></i><span>mdi-saw-blade</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sawtooth-wave"></i><span>mdi-sawtooth-wave</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-saxophone"></i><span>mdi-saxophone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scale"></i><span>mdi-scale</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scale-balance"></i><span>mdi-scale-balance</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scale-bathroom"></i><span>mdi-scale-bathroom</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scale-off"></i><span>mdi-scale-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scale-unbalanced"></i><span>mdi-scale-unbalanced</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scan-helper"></i><span>mdi-scan-helper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scanner"></i><span>mdi-scanner</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scanner-off"></i><span>mdi-scanner-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scatter-plot"></i><span>mdi-scatter-plot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scatter-plot-outline"></i><span>mdi-scatter-plot-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scent"></i><span>mdi-scent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scent-off"></i><span>mdi-scent-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-school"></i><span>mdi-school</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-school-outline"></i><span>mdi-school-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scissors-cutting"></i><span>mdi-scissors-cutting</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scooter"></i><span>mdi-scooter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scooter-electric"></i><span>mdi-scooter-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scoreboard"></i><span>mdi-scoreboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-scoreboard-outline"></i><span>mdi-scoreboard-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-screen-rotation"></i><span>mdi-screen-rotation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-screen-rotation-lock"></i><span>mdi-screen-rotation-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-screw-flat-top"></i><span>mdi-screw-flat-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-screw-lag"></i><span>mdi-screw-lag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-screw-machine-flat-top"></i><span>mdi-screw-machine-flat-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-screw-machine-round-top"></i><span>mdi-screw-machine-round-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-screw-round-top"></i><span>mdi-screw-round-top</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-screwdriver"></i><span>mdi-screwdriver</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-script"></i><span>mdi-script</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-script-outline"></i><span>mdi-script-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-script-text"></i><span>mdi-script-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-script-text-key"></i><span>mdi-script-text-key</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-script-text-key-outline"></i><span>mdi-script-text-key-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-script-text-outline"></i><span>mdi-script-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-script-text-play"></i><span>mdi-script-text-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-script-text-play-outline"></i><span>mdi-script-text-play-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sd"></i><span>mdi-sd</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seal"></i><span>mdi-seal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seal-variant"></i><span>mdi-seal-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-search-web"></i><span>mdi-search-web</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat"></i><span>mdi-seat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-flat"></i><span>mdi-seat-flat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-flat-angled"></i><span>mdi-seat-flat-angled</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-individual-suite"></i><span>mdi-seat-individual-suite</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-legroom-extra"></i><span>mdi-seat-legroom-extra</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-legroom-normal"></i><span>mdi-seat-legroom-normal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-legroom-reduced"></i><span>mdi-seat-legroom-reduced</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-outline"></i><span>mdi-seat-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-passenger"></i><span>mdi-seat-passenger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-recline-extra"></i><span>mdi-seat-recline-extra</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seat-recline-normal"></i><span>mdi-seat-recline-normal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seatbelt"></i><span>mdi-seatbelt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-security"></i><span>mdi-security</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-security-network"></i><span>mdi-security-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seed"></i><span>mdi-seed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seed-off"></i><span>mdi-seed-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seed-off-outline"></i><span>mdi-seed-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seed-outline"></i><span>mdi-seed-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-seesaw"></i><span>mdi-seesaw</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-segment"></i><span>mdi-segment</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select"></i><span>mdi-select</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-all"></i><span>mdi-select-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-color"></i><span>mdi-select-color</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-compare"></i><span>mdi-select-compare</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-drag"></i><span>mdi-select-drag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-group"></i><span>mdi-select-group</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-inverse"></i><span>mdi-select-inverse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-marker"></i><span>mdi-select-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-multiple"></i><span>mdi-select-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-multiple-marker"></i><span>mdi-select-multiple-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-off"></i><span>mdi-select-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-place"></i><span>mdi-select-place</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-remove"></i><span>mdi-select-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-select-search"></i><span>mdi-select-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection"></i><span>mdi-selection</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-drag"></i><span>mdi-selection-drag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-ellipse"></i><span>mdi-selection-ellipse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-ellipse-arrow-inside"></i><span>mdi-selection-ellipse-arrow-inside</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-ellipse-remove"></i><span>mdi-selection-ellipse-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-marker"></i><span>mdi-selection-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-multiple"></i><span>mdi-selection-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-multiple-marker"></i><span>mdi-selection-multiple-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-off"></i><span>mdi-selection-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-remove"></i><span>mdi-selection-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-selection-search"></i><span>mdi-selection-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-semantic-web"></i><span>mdi-semantic-web</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send"></i><span>mdi-send</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send-check"></i><span>mdi-send-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send-check-outline"></i><span>mdi-send-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send-circle"></i><span>mdi-send-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send-circle-outline"></i><span>mdi-send-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send-clock"></i><span>mdi-send-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send-clock-outline"></i><span>mdi-send-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send-lock"></i><span>mdi-send-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send-lock-outline"></i><span>mdi-send-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-send-outline"></i><span>mdi-send-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-serial-port"></i><span>mdi-serial-port</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-server"></i><span>mdi-server</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-server-minus"></i><span>mdi-server-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-server-network"></i><span>mdi-server-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-server-network-off"></i><span>mdi-server-network-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-server-off"></i><span>mdi-server-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-server-plus"></i><span>mdi-server-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-server-remove"></i><span>mdi-server-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-server-security"></i><span>mdi-server-security</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-all"></i><span>mdi-set-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-center"></i><span>mdi-set-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-center-right"></i><span>mdi-set-center-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-left"></i><span>mdi-set-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-left-center"></i><span>mdi-set-left-center</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-left-right"></i><span>mdi-set-left-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-merge"></i><span>mdi-set-merge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-none"></i><span>mdi-set-none</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-right"></i><span>mdi-set-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-split"></i><span>mdi-set-split</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-square"></i><span>mdi-set-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-set-top-box"></i><span>mdi-set-top-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-settings-helper"></i><span>mdi-settings-helper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shaker"></i><span>mdi-shaker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shaker-outline"></i><span>mdi-shaker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shape"></i><span>mdi-shape</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shape-circle-plus"></i><span>mdi-shape-circle-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shape-outline"></i><span>mdi-shape-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shape-oval-plus"></i><span>mdi-shape-oval-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shape-plus"></i><span>mdi-shape-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shape-polygon-plus"></i><span>mdi-shape-polygon-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shape-rectangle-plus"></i><span>mdi-shape-rectangle-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shape-square-plus"></i><span>mdi-shape-square-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shape-square-rounded-plus"></i><span>mdi-shape-square-rounded-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-share"></i><span>mdi-share</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-share-all"></i><span>mdi-share-all</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-share-all-outline"></i><span>mdi-share-all-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-share-circle"></i><span>mdi-share-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-share-off"></i><span>mdi-share-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-share-off-outline"></i><span>mdi-share-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-share-outline"></i><span>mdi-share-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-share-variant"></i><span>mdi-share-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-share-variant-outline"></i><span>mdi-share-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shark"></i><span>mdi-shark</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shark-fin"></i><span>mdi-shark-fin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shark-fin-outline"></i><span>mdi-shark-fin-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shark-off"></i><span>mdi-shark-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sheep"></i><span>mdi-sheep</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield"></i><span>mdi-shield</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-account"></i><span>mdi-shield-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-account-outline"></i><span>mdi-shield-account-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-account-variant"></i><span>mdi-shield-account-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-account-variant-outline"></i><span>mdi-shield-account-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-airplane"></i><span>mdi-shield-airplane</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-airplane-outline"></i><span>mdi-shield-airplane-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-alert"></i><span>mdi-shield-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-border-2 bg-body-secondary"></i><span>mdi-shield-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-bug"></i><span>mdi-shield-bug</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-bug-outline"></i><span>mdi-shield-bug-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-car"></i><span>mdi-shield-car</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-check"></i><span>mdi-shield-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-check-outline"></i><span>mdi-shield-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-cross"></i><span>mdi-shield-cross</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-cross-outline"></i><span>mdi-shield-cross-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-crown"></i><span>mdi-shield-crown</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-crown-outline"></i><span>mdi-shield-crown-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-edit"></i><span>mdi-shield-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-edit-outline"></i><span>mdi-shield-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-half"></i><span>mdi-shield-half</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-half-full"></i><span>mdi-shield-half-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-home"></i><span>mdi-shield-home</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-home-outline"></i><span>mdi-shield-home-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-key"></i><span>mdi-shield-key</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-key-outline"></i><span>mdi-shield-key-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-link-variant"></i><span>mdi-shield-link-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-link-variant-outline"></i><span>mdi-shield-link-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-lock"></i><span>mdi-shield-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-lock-open"></i><span>mdi-shield-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-lock-open-outline"></i><span>mdi-shield-lock-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-lock-outline"></i><span>mdi-shield-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-moon"></i><span>mdi-shield-moon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-moon-outline"></i><span>mdi-shield-moon-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-off"></i><span>mdi-shield-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-off-outline"></i><span>mdi-shield-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-outline"></i><span>mdi-shield-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-plus"></i><span>mdi-shield-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-plus-outline"></i><span>mdi-shield-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-refresh"></i><span>mdi-shield-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-refresh-outline"></i><span>mdi-shield-refresh-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-remove"></i><span>mdi-shield-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-remove-outline"></i><span>mdi-shield-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-search"></i><span>mdi-shield-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-star"></i><span>mdi-shield-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-star-outline"></i><span>mdi-shield-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-sun"></i><span>mdi-shield-sun</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-sun-outline"></i><span>mdi-shield-sun-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-sword"></i><span>mdi-shield-sword</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-sword-outline"></i><span>mdi-shield-sword-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-sync"></i><span>mdi-shield-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shield-sync-outline"></i><span>mdi-shield-sync-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shimmer"></i><span>mdi-shimmer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ship-wheel"></i><span>mdi-ship-wheel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shipping-pallet"></i><span>mdi-shipping-pallet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shoe-ballet"></i><span>mdi-shoe-ballet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shoe-cleat"></i><span>mdi-shoe-cleat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shoe-formal"></i><span>mdi-shoe-formal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shoe-heel"></i><span>mdi-shoe-heel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shoe-print"></i><span>mdi-shoe-print</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shoe-sneaker"></i><span>mdi-shoe-sneaker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shopping"></i><span>mdi-shopping</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shopping-music"></i><span>mdi-shopping-music</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shopping-outline"></i><span>mdi-shopping-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shopping-search"></i><span>mdi-shopping-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shore"></i><span>mdi-shore</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shovel"></i><span>mdi-shovel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shovel-off"></i><span>mdi-shovel-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shower"></i><span>mdi-shower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shower-head"></i><span>mdi-shower-head</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shredder"></i><span>mdi-shredder</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shuffle"></i><span>mdi-shuffle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shuffle-disabled"></i><span>mdi-shuffle-disabled</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shuffle-variant"></i><span>mdi-shuffle-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-shuriken"></i><span>mdi-shuriken</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sickle"></i><span>mdi-sickle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sigma"></i><span>mdi-sigma</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sigma-lower"></i><span>mdi-sigma-lower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sign-caution"></i><span>mdi-sign-caution</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sign-direction"></i><span>mdi-sign-direction</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sign-direction-minus"></i><span>mdi-sign-direction-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sign-direction-plus"></i><span>mdi-sign-direction-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sign-direction-remove"></i><span>mdi-sign-direction-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sign-pole"></i><span>mdi-sign-pole</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sign-real-estate"></i><span>mdi-sign-real-estate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sign-text"></i><span>mdi-sign-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal"></i><span>mdi-signal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-2g"></i><span>mdi-signal-2g</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-3g"></i><span>mdi-signal-3g</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-4g"></i><span>mdi-signal-4g</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-5g"></i><span>mdi-signal-5g</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-cellular-1"></i><span>mdi-signal-cellular-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-cellular-2"></i><span>mdi-signal-cellular-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-cellular-3"></i><span>mdi-signal-cellular-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-cellular-outline"></i><span>mdi-signal-cellular-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-distance-variant"></i><span>mdi-signal-distance-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-hspa"></i><span>mdi-signal-hspa</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-hspa-plus"></i><span>mdi-signal-hspa-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-off"></i><span>mdi-signal-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signal-variant"></i><span>mdi-signal-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signature"></i><span>mdi-signature</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signature-freehand"></i><span>mdi-signature-freehand</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signature-image"></i><span>mdi-signature-image</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-signature-text"></i><span>mdi-signature-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-silo"></i><span>mdi-silo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-silverware"></i><span>mdi-silverware</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-silverware-clean"></i><span>mdi-silverware-clean</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-silverware-fork"></i><span>mdi-silverware-fork</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-silverware-fork-knife"></i><span>mdi-silverware-fork-knife</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-silverware-spoon"></i><span>mdi-silverware-spoon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-silverware-variant"></i><span>mdi-silverware-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sim"></i><span>mdi-sim</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sim-alert"></i><span>mdi-sim-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sim-border-2 bg-body-secondary"></i><span>mdi-sim-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sim-off"></i><span>mdi-sim-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sim-off-outline"></i><span>mdi-sim-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sim-outline"></i><span>mdi-sim-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-simple-icons"></i><span>mdi-simple-icons</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sina-weibo"></i><span>mdi-sina-weibo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sine-wave"></i><span>mdi-sine-wave</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sitemap"></i><span>mdi-sitemap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sitemap-outline"></i><span>mdi-sitemap-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-size-l"></i><span>mdi-size-l</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-size-m"></i><span>mdi-size-m</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-size-s"></i><span>mdi-size-s</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-size-xl"></i><span>mdi-size-xl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-size-xs"></i><span>mdi-size-xs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-size-xxl"></i><span>mdi-size-xxl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-size-xxs"></i><span>mdi-size-xxs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-size-xxxl"></i><span>mdi-size-xxxl</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skate"></i><span>mdi-skate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skate-off"></i><span>mdi-skate-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skateboard"></i><span>mdi-skateboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skateboarding"></i><span>mdi-skateboarding</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skew-less"></i><span>mdi-skew-less</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skew-more"></i><span>mdi-skew-more</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ski"></i><span>mdi-ski</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ski-cross-country"></i><span>mdi-ski-cross-country</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ski-water"></i><span>mdi-ski-water</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-backward"></i><span>mdi-skip-backward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-backward-outline"></i><span>mdi-skip-backward-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-forward"></i><span>mdi-skip-forward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-forward-outline"></i><span>mdi-skip-forward-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-next"></i><span>mdi-skip-next</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-next-circle"></i><span>mdi-skip-next-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-next-circle-outline"></i><span>mdi-skip-next-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-next-outline"></i><span>mdi-skip-next-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-previous"></i><span>mdi-skip-previous</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-previous-circle"></i><span>mdi-skip-previous-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-previous-circle-outline"></i><span>mdi-skip-previous-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skip-previous-outline"></i><span>mdi-skip-previous-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skull"></i><span>mdi-skull</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skull-crossbones"></i><span>mdi-skull-crossbones</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skull-crossbones-outline"></i><span>mdi-skull-crossbones-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skull-outline"></i><span>mdi-skull-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skull-scan"></i><span>mdi-skull-scan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skull-scan-outline"></i><span>mdi-skull-scan-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skype"></i><span>mdi-skype</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-skype-business"></i><span>mdi-skype-business</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-slack"></i><span>mdi-slack</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-slash-forward"></i><span>mdi-slash-forward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-slash-forward-box"></i><span>mdi-slash-forward-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sledding"></i><span>mdi-sledding</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sleep"></i><span>mdi-sleep</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sleep-off"></i><span>mdi-sleep-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-slide"></i><span>mdi-slide</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-slope-downhill"></i><span>mdi-slope-downhill</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-slope-uphill"></i><span>mdi-slope-uphill</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-slot-machine"></i><span>mdi-slot-machine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-slot-machine-outline"></i><span>mdi-slot-machine-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smart-card"></i><span>mdi-smart-card</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smart-card-off"></i><span>mdi-smart-card-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smart-card-off-outline"></i><span>mdi-smart-card-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smart-card-outline"></i><span>mdi-smart-card-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smart-card-reader"></i><span>mdi-smart-card-reader</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smart-card-reader-outline"></i><span>mdi-smart-card-reader-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smog"></i><span>mdi-smog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke"></i><span>mdi-smoke</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke-detector"></i><span>mdi-smoke-detector</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke-detector-alert"></i><span>mdi-smoke-detector-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke-detector-border-2 bg-body-secondary"></i><span>mdi-smoke-detector-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke-detector-off"></i><span>mdi-smoke-detector-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke-detector-off-outline"></i><span>mdi-smoke-detector-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke-detector-outline"></i><span>mdi-smoke-detector-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke-detector-variant"></i><span>mdi-smoke-detector-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke-detector-variant-alert"></i><span>mdi-smoke-detector-variant-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoke-detector-variant-off"></i><span>mdi-smoke-detector-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoking"></i><span>mdi-smoking</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoking-off"></i><span>mdi-smoking-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoking-pipe"></i><span>mdi-smoking-pipe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-smoking-pipe-off"></i><span>mdi-smoking-pipe-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snail"></i><span>mdi-snail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snake"></i><span>mdi-snake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snapchat"></i><span>mdi-snapchat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snowboard"></i><span>mdi-snowboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snowflake"></i><span>mdi-snowflake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snowflake-alert"></i><span>mdi-snowflake-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snowflake-melt"></i><span>mdi-snowflake-melt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snowflake-off"></i><span>mdi-snowflake-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snowflake-variant"></i><span>mdi-snowflake-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snowman"></i><span>mdi-snowman</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-snowmobile"></i><span>mdi-snowmobile</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-soccer"></i><span>mdi-soccer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-soccer-field"></i><span>mdi-soccer-field</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-social-distance-2-meters"></i><span>mdi-social-distance-2-meters</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-social-distance-6-feet"></i><span>mdi-social-distance-6-feet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sofa"></i><span>mdi-sofa</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sofa-outline"></i><span>mdi-sofa-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sofa-single"></i><span>mdi-sofa-single</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sofa-single-outline"></i><span>mdi-sofa-single-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-solar-panel"></i><span>mdi-solar-panel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-solar-panel-large"></i><span>mdi-solar-panel-large</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-solar-power"></i><span>mdi-solar-power</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-soldering-iron"></i><span>mdi-soldering-iron</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-solid"></i><span>mdi-solid</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sony-playstation"></i><span>mdi-sony-playstation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort"></i><span>mdi-sort</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-alphabetical-ascending"></i><span>mdi-sort-alphabetical-ascending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-alphabetical-ascending-variant"></i><span>mdi-sort-alphabetical-ascending-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-alphabetical-descending"></i><span>mdi-sort-alphabetical-descending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-alphabetical-descending-variant"></i><span>mdi-sort-alphabetical-descending-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-alphabetical-variant"></i><span>mdi-sort-alphabetical-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-ascending"></i><span>mdi-sort-ascending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-bool-ascending"></i><span>mdi-sort-bool-ascending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-bool-ascending-variant"></i><span>mdi-sort-bool-ascending-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-bool-descending"></i><span>mdi-sort-bool-descending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-bool-descending-variant"></i><span>mdi-sort-bool-descending-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-calendar-ascending"></i><span>mdi-sort-calendar-ascending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-calendar-descending"></i><span>mdi-sort-calendar-descending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-clock-ascending"></i><span>mdi-sort-clock-ascending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-clock-ascending-outline"></i><span>mdi-sort-clock-ascending-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-clock-descending"></i><span>mdi-sort-clock-descending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-clock-descending-outline"></i><span>mdi-sort-clock-descending-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-descending"></i><span>mdi-sort-descending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-numeric-ascending"></i><span>mdi-sort-numeric-ascending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-numeric-ascending-variant"></i><span>mdi-sort-numeric-ascending-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-numeric-descending"></i><span>mdi-sort-numeric-descending</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-numeric-descending-variant"></i><span>mdi-sort-numeric-descending-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-numeric-variant"></i><span>mdi-sort-numeric-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-reverse-variant"></i><span>mdi-sort-reverse-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-variant"></i><span>mdi-sort-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-variant-lock"></i><span>mdi-sort-variant-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-variant-lock-open"></i><span>mdi-sort-variant-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sort-variant-remove"></i><span>mdi-sort-variant-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-soundbar"></i><span>mdi-soundbar</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-soundcloud"></i><span>mdi-soundcloud</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-branch"></i><span>mdi-source-branch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-branch-check"></i><span>mdi-source-branch-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-branch-minus"></i><span>mdi-source-branch-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-branch-plus"></i><span>mdi-source-branch-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-branch-refresh"></i><span>mdi-source-branch-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-branch-remove"></i><span>mdi-source-branch-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-branch-sync"></i><span>mdi-source-branch-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-commit"></i><span>mdi-source-commit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-commit-end"></i><span>mdi-source-commit-end</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-commit-end-local"></i><span>mdi-source-commit-end-local</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-commit-local"></i><span>mdi-source-commit-local</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-commit-next-local"></i><span>mdi-source-commit-next-local</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-commit-start"></i><span>mdi-source-commit-start</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-commit-start-next-local"></i><span>mdi-source-commit-start-next-local</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-fork"></i><span>mdi-source-fork</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-merge"></i><span>mdi-source-merge</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-pull"></i><span>mdi-source-pull</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-repository"></i><span>mdi-source-repository</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-source-repository-multiple"></i><span>mdi-source-repository-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-soy-sauce"></i><span>mdi-soy-sauce</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-soy-sauce-off"></i><span>mdi-soy-sauce-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spa"></i><span>mdi-spa</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spa-outline"></i><span>mdi-spa-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-space-invaders"></i><span>mdi-space-invaders</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-space-station"></i><span>mdi-space-station</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spade"></i><span>mdi-spade</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-speaker"></i><span>mdi-speaker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-speaker-bluetooth"></i><span>mdi-speaker-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-speaker-multiple"></i><span>mdi-speaker-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-speaker-off"></i><span>mdi-speaker-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-speaker-wireless"></i><span>mdi-speaker-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spear"></i><span>mdi-spear</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-speedometer"></i><span>mdi-speedometer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-speedometer-medium"></i><span>mdi-speedometer-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-speedometer-slow"></i><span>mdi-speedometer-slow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spellcheck"></i><span>mdi-spellcheck</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sphere"></i><span>mdi-sphere</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sphere-off"></i><span>mdi-sphere-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spider"></i><span>mdi-spider</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spider-thread"></i><span>mdi-spider-thread</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spider-web"></i><span>mdi-spider-web</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spirit-level"></i><span>mdi-spirit-level</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spoon-sugar"></i><span>mdi-spoon-sugar</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spotify"></i><span>mdi-spotify</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spotlight"></i><span>mdi-spotlight</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spotlight-beam"></i><span>mdi-spotlight-beam</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spray"></i><span>mdi-spray</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-spray-bottle"></i><span>mdi-spray-bottle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sprinkler"></i><span>mdi-sprinkler</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sprinkler-fire"></i><span>mdi-sprinkler-fire</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sprinkler-variant"></i><span>mdi-sprinkler-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sprout"></i><span>mdi-sprout</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sprout-outline"></i><span>mdi-sprout-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square"></i><span>mdi-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-circle"></i><span>mdi-square-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-edit-outline"></i><span>mdi-square-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-medium"></i><span>mdi-square-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-medium-outline"></i><span>mdi-square-medium-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-off"></i><span>mdi-square-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-off-outline"></i><span>mdi-square-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-opacity"></i><span>mdi-square-opacity</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-outline"></i><span>mdi-square-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-root"></i><span>mdi-square-root</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-root-box"></i><span>mdi-square-root-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-rounded"></i><span>mdi-square-rounded</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-rounded-outline"></i><span>mdi-square-rounded-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-small"></i><span>mdi-square-small</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-square-wave"></i><span>mdi-square-wave</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-squeegee"></i><span>mdi-squeegee</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ssh"></i><span>mdi-ssh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stack-exchange"></i><span>mdi-stack-exchange</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stack-overflow"></i><span>mdi-stack-overflow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stackpath"></i><span>mdi-stackpath</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stadium"></i><span>mdi-stadium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stadium-variant"></i><span>mdi-stadium-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stairs"></i><span>mdi-stairs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stairs-box"></i><span>mdi-stairs-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stairs-down"></i><span>mdi-stairs-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stairs-up"></i><span>mdi-stairs-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stamper"></i><span>mdi-stamper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-standard-definition"></i><span>mdi-standard-definition</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star"></i><span>mdi-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-box"></i><span>mdi-star-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-box-multiple"></i><span>mdi-star-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-box-multiple-outline"></i><span>mdi-star-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-box-outline"></i><span>mdi-star-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-check"></i><span>mdi-star-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-check-outline"></i><span>mdi-star-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-circle"></i><span>mdi-star-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-circle-outline"></i><span>mdi-star-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-cog"></i><span>mdi-star-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-cog-outline"></i><span>mdi-star-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-crescent"></i><span>mdi-star-crescent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-david"></i><span>mdi-star-david</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-face"></i><span>mdi-star-face</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-four-points"></i><span>mdi-star-four-points</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-four-points-outline"></i><span>mdi-star-four-points-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-half"></i><span>mdi-star-half</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-half-full"></i><span>mdi-star-half-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-minus"></i><span>mdi-star-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-minus-outline"></i><span>mdi-star-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-off"></i><span>mdi-star-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-off-outline"></i><span>mdi-star-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-outline"></i><span>mdi-star-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-plus"></i><span>mdi-star-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-plus-outline"></i><span>mdi-star-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-remove"></i><span>mdi-star-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-remove-outline"></i><span>mdi-star-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-settings"></i><span>mdi-star-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-settings-outline"></i><span>mdi-star-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-shooting"></i><span>mdi-star-shooting</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-shooting-outline"></i><span>mdi-star-shooting-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-three-points"></i><span>mdi-star-three-points</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-star-three-points-outline"></i><span>mdi-star-three-points-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-state-machine"></i><span>mdi-state-machine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-steam"></i><span>mdi-steam</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-steering"></i><span>mdi-steering</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-steering-off"></i><span>mdi-steering-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-step-backward"></i><span>mdi-step-backward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-step-backward-2"></i><span>mdi-step-backward-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-step-forward"></i><span>mdi-step-forward</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-step-forward-2"></i><span>mdi-step-forward-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stethoscope"></i><span>mdi-stethoscope</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker"></i><span>mdi-sticker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-alert"></i><span>mdi-sticker-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-border-2 bg-body-secondary"></i><span>mdi-sticker-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-check"></i><span>mdi-sticker-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-check-outline"></i><span>mdi-sticker-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-circle-outline"></i><span>mdi-sticker-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-emoji"></i><span>mdi-sticker-emoji</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-minus"></i><span>mdi-sticker-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-minus-outline"></i><span>mdi-sticker-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-outline"></i><span>mdi-sticker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-plus"></i><span>mdi-sticker-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-plus-outline"></i><span>mdi-sticker-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-remove"></i><span>mdi-sticker-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-remove-outline"></i><span>mdi-sticker-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-text"></i><span>mdi-sticker-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sticker-text-outline"></i><span>mdi-sticker-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stocking"></i><span>mdi-stocking</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stomach"></i><span>mdi-stomach</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stool"></i><span>mdi-stool</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stool-outline"></i><span>mdi-stool-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stop"></i><span>mdi-stop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stop-circle"></i><span>mdi-stop-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stop-circle-outline"></i><span>mdi-stop-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store"></i><span>mdi-store</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-24-hour"></i><span>mdi-store-24-hour</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-alert"></i><span>mdi-store-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-border-2 bg-body-secondary"></i><span>mdi-store-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-check"></i><span>mdi-store-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-check-outline"></i><span>mdi-store-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-clock"></i><span>mdi-store-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-clock-outline"></i><span>mdi-store-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-cog"></i><span>mdi-store-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-cog-outline"></i><span>mdi-store-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-edit"></i><span>mdi-store-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-edit-outline"></i><span>mdi-store-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-marker"></i><span>mdi-store-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-marker-outline"></i><span>mdi-store-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-minus"></i><span>mdi-store-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-minus-outline"></i><span>mdi-store-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-off"></i><span>mdi-store-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-off-outline"></i><span>mdi-store-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-outline"></i><span>mdi-store-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-plus"></i><span>mdi-store-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-plus-outline"></i><span>mdi-store-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-remove"></i><span>mdi-store-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-remove-outline"></i><span>mdi-store-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-search"></i><span>mdi-store-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-search-outline"></i><span>mdi-store-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-settings"></i><span>mdi-store-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-store-settings-outline"></i><span>mdi-store-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-storefront"></i><span>mdi-storefront</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-storefront-outline"></i><span>mdi-storefront-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stove"></i><span>mdi-stove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-strategy"></i><span>mdi-strategy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stretch-to-page"></i><span>mdi-stretch-to-page</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-stretch-to-page-outline"></i><span>mdi-stretch-to-page-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-string-lights"></i><span>mdi-string-lights</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-string-lights-off"></i><span>mdi-string-lights-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-subdirectory-arrow-left"></i><span>mdi-subdirectory-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-subdirectory-arrow-right"></i><span>mdi-subdirectory-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-submarine"></i><span>mdi-submarine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-subtitles"></i><span>mdi-subtitles</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-subtitles-outline"></i><span>mdi-subtitles-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-subway"></i><span>mdi-subway</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-subway-alert-variant"></i><span>mdi-subway-alert-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-subway-variant"></i><span>mdi-subway-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-summit"></i><span>mdi-summit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sun-compass"></i><span>mdi-sun-compass</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sun-snowflake"></i><span>mdi-sun-snowflake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sun-thermometer"></i><span>mdi-sun-thermometer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sun-thermometer-outline"></i><span>mdi-sun-thermometer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sun-wireless"></i><span>mdi-sun-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sun-wireless-outline"></i><span>mdi-sun-wireless-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sunglasses"></i><span>mdi-sunglasses</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-surfing"></i><span>mdi-surfing</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-surround-sound"></i><span>mdi-surround-sound</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-surround-sound-2-0"></i><span>mdi-surround-sound-2-0</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-surround-sound-2-1"></i><span>mdi-surround-sound-2-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-surround-sound-3-1"></i><span>mdi-surround-sound-3-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-surround-sound-5-1"></i><span>mdi-surround-sound-5-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-surround-sound-5-1-2"></i><span>mdi-surround-sound-5-1-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-surround-sound-7-1"></i><span>mdi-surround-sound-7-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-svg"></i><span>mdi-svg</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-horizontal"></i><span>mdi-swap-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-horizontal-bold"></i><span>mdi-swap-horizontal-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-horizontal-circle"></i><span>mdi-swap-horizontal-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-horizontal-circle-outline"></i><span>mdi-swap-horizontal-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-horizontal-variant"></i><span>mdi-swap-horizontal-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-vertical"></i><span>mdi-swap-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-vertical-bold"></i><span>mdi-swap-vertical-bold</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-vertical-circle"></i><span>mdi-swap-vertical-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-vertical-circle-outline"></i><span>mdi-swap-vertical-circle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swap-vertical-variant"></i><span>mdi-swap-vertical-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-swim"></i><span>mdi-swim</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-switch"></i><span>mdi-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sword"></i><span>mdi-sword</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sword-cross"></i><span>mdi-sword-cross</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-syllabary-hangul"></i><span>mdi-syllabary-hangul</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-syllabary-hiragana"></i><span>mdi-syllabary-hiragana</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-syllabary-katakana"></i><span>mdi-syllabary-katakana</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-syllabary-katakana-halfwidth"></i><span>mdi-syllabary-katakana-halfwidth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-symbol"></i><span>mdi-symbol</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-symfony"></i><span>mdi-symfony</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sync"></i><span>mdi-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sync-alert"></i><span>mdi-sync-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sync-circle"></i><span>mdi-sync-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-sync-off"></i><span>mdi-sync-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tab"></i><span>mdi-tab</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tab-minus"></i><span>mdi-tab-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tab-plus"></i><span>mdi-tab-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tab-remove"></i><span>mdi-tab-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tab-search"></i><span>mdi-tab-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tab-unselected"></i><span>mdi-tab-unselected</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table"></i><span>mdi-table</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-account"></i><span>mdi-table-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-alert"></i><span>mdi-table-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-arrow-down"></i><span>mdi-table-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-arrow-left"></i><span>mdi-table-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-arrow-right"></i><span>mdi-table-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-arrow-up"></i><span>mdi-table-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-border"></i><span>mdi-table-border</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-cancel"></i><span>mdi-table-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-chair"></i><span>mdi-table-chair</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-check"></i><span>mdi-table-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-clock"></i><span>mdi-table-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-cog"></i><span>mdi-table-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-column"></i><span>mdi-table-column</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-column-plus-after"></i><span>mdi-table-column-plus-after</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-column-plus-before"></i><span>mdi-table-column-plus-before</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-column-remove"></i><span>mdi-table-column-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-column-width"></i><span>mdi-table-column-width</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-edit"></i><span>mdi-table-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-eye"></i><span>mdi-table-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-eye-off"></i><span>mdi-table-eye-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-furniture"></i><span>mdi-table-furniture</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-headers-eye"></i><span>mdi-table-headers-eye</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-headers-eye-off"></i><span>mdi-table-headers-eye-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-heart"></i><span>mdi-table-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-key"></i><span>mdi-table-key</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-large"></i><span>mdi-table-large</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-large-plus"></i><span>mdi-table-large-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-large-remove"></i><span>mdi-table-large-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-lock"></i><span>mdi-table-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-merge-cells"></i><span>mdi-table-merge-cells</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-minus"></i><span>mdi-table-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-multiple"></i><span>mdi-table-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-network"></i><span>mdi-table-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-of-contents"></i><span>mdi-table-of-contents</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-off"></i><span>mdi-table-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-picnic"></i><span>mdi-table-picnic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-pivot"></i><span>mdi-table-pivot</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-plus"></i><span>mdi-table-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-refresh"></i><span>mdi-table-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-remove"></i><span>mdi-table-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-row"></i><span>mdi-table-row</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-row-height"></i><span>mdi-table-row-height</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-row-plus-after"></i><span>mdi-table-row-plus-after</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-row-plus-before"></i><span>mdi-table-row-plus-before</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-row-remove"></i><span>mdi-table-row-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-search"></i><span>mdi-table-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-settings"></i><span>mdi-table-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-split-cell"></i><span>mdi-table-split-cell</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-star"></i><span>mdi-table-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-sync"></i><span>mdi-table-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-table-tennis"></i><span>mdi-table-tennis</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tablet"></i><span>mdi-tablet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tablet-android"></i><span>mdi-tablet-android</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tablet-cellphone"></i><span>mdi-tablet-cellphone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tablet-dashboard"></i><span>mdi-tablet-dashboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-taco"></i><span>mdi-taco</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag"></i><span>mdi-tag</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-arrow-down"></i><span>mdi-tag-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-arrow-down-outline"></i><span>mdi-tag-arrow-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-arrow-left"></i><span>mdi-tag-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-arrow-left-outline"></i><span>mdi-tag-arrow-left-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-arrow-right"></i><span>mdi-tag-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-arrow-right-outline"></i><span>mdi-tag-arrow-right-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-arrow-up"></i><span>mdi-tag-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-arrow-up-outline"></i><span>mdi-tag-arrow-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-faces"></i><span>mdi-tag-faces</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-heart"></i><span>mdi-tag-heart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-heart-outline"></i><span>mdi-tag-heart-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-minus"></i><span>mdi-tag-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-minus-outline"></i><span>mdi-tag-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-multiple"></i><span>mdi-tag-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-multiple-outline"></i><span>mdi-tag-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-off"></i><span>mdi-tag-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-off-outline"></i><span>mdi-tag-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-outline"></i><span>mdi-tag-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-plus"></i><span>mdi-tag-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-plus-outline"></i><span>mdi-tag-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-remove"></i><span>mdi-tag-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-remove-outline"></i><span>mdi-tag-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-search"></i><span>mdi-tag-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-search-outline"></i><span>mdi-tag-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-text"></i><span>mdi-tag-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tag-text-outline"></i><span>mdi-tag-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tailwind"></i><span>mdi-tailwind</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tangram"></i><span>mdi-tangram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tank"></i><span>mdi-tank</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tanker-truck"></i><span>mdi-tanker-truck</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tape-drive"></i><span>mdi-tape-drive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tape-measure"></i><span>mdi-tape-measure</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-target"></i><span>mdi-target</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-target-account"></i><span>mdi-target-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-target-variant"></i><span>mdi-target-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-taxi"></i><span>mdi-taxi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tea"></i><span>mdi-tea</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tea-outline"></i><span>mdi-tea-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-teamviewer"></i><span>mdi-teamviewer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-teddy-bear"></i><span>mdi-teddy-bear</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-telescope"></i><span>mdi-telescope</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television"></i><span>mdi-television</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-ambient-light"></i><span>mdi-television-ambient-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-box"></i><span>mdi-television-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-classic"></i><span>mdi-television-classic</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-classic-off"></i><span>mdi-television-classic-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-guide"></i><span>mdi-television-guide</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-off"></i><span>mdi-television-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-pause"></i><span>mdi-television-pause</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-play"></i><span>mdi-television-play</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-shimmer"></i><span>mdi-television-shimmer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-television-stop"></i><span>mdi-television-stop</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-temperature-celsius"></i><span>mdi-temperature-celsius</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-temperature-fahrenheit"></i><span>mdi-temperature-fahrenheit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-temperature-kelvin"></i><span>mdi-temperature-kelvin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tennis"></i><span>mdi-tennis</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tennis-ball"></i><span>mdi-tennis-ball</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tent"></i><span>mdi-tent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-terraform"></i><span>mdi-terraform</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-terrain"></i><span>mdi-terrain</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-test-tube"></i><span>mdi-test-tube</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-test-tube-empty"></i><span>mdi-test-tube-empty</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-test-tube-off"></i><span>mdi-test-tube-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text"></i><span>mdi-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-account"></i><span>mdi-text-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box"></i><span>mdi-text-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-check"></i><span>mdi-text-box-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-check-outline"></i><span>mdi-text-box-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-minus"></i><span>mdi-text-box-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-minus-outline"></i><span>mdi-text-box-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-multiple"></i><span>mdi-text-box-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-multiple-outline"></i><span>mdi-text-box-multiple-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-outline"></i><span>mdi-text-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-plus"></i><span>mdi-text-box-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-plus-outline"></i><span>mdi-text-box-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-remove"></i><span>mdi-text-box-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-remove-outline"></i><span>mdi-text-box-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-search"></i><span>mdi-text-box-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-box-search-outline"></i><span>mdi-text-box-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-long"></i><span>mdi-text-long</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-recognition"></i><span>mdi-text-recognition</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-search"></i><span>mdi-text-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-shadow"></i><span>mdi-text-shadow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-short"></i><span>mdi-text-short</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-to-speech"></i><span>mdi-text-to-speech</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-text-to-speech-off"></i><span>mdi-text-to-speech-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-texture"></i><span>mdi-texture</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-texture-box"></i><span>mdi-texture-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-theater"></i><span>mdi-theater</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-theme-light-dark"></i><span>mdi-theme-light-dark</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer"></i><span>mdi-thermometer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-alert"></i><span>mdi-thermometer-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-bluetooth"></i><span>mdi-thermometer-bluetooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-chevron-down"></i><span>mdi-thermometer-chevron-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-chevron-up"></i><span>mdi-thermometer-chevron-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-high"></i><span>mdi-thermometer-high</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-lines"></i><span>mdi-thermometer-lines</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-low"></i><span>mdi-thermometer-low</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-minus"></i><span>mdi-thermometer-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-off"></i><span>mdi-thermometer-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermometer-plus"></i><span>mdi-thermometer-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermostat"></i><span>mdi-thermostat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thermostat-box"></i><span>mdi-thermostat-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thought-bubble"></i><span>mdi-thought-bubble</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thought-bubble-outline"></i><span>mdi-thought-bubble-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thumb-down"></i><span>mdi-thumb-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thumb-down-outline"></i><span>mdi-thumb-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thumb-up"></i><span>mdi-thumb-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thumb-up-outline"></i><span>mdi-thumb-up-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thumbs-up-down"></i><span>mdi-thumbs-up-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-thumbs-up-down-outline"></i><span>mdi-thumbs-up-down-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ticket"></i><span>mdi-ticket</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ticket-account"></i><span>mdi-ticket-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ticket-confirmation"></i><span>mdi-ticket-confirmation</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ticket-confirmation-outline"></i><span>mdi-ticket-confirmation-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ticket-outline"></i><span>mdi-ticket-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ticket-percent"></i><span>mdi-ticket-percent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ticket-percent-outline"></i><span>mdi-ticket-percent-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tie"></i><span>mdi-tie</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tilde"></i><span>mdi-tilde</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tilde-off"></i><span>mdi-tilde-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timelapse"></i><span>mdi-timelapse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline"></i><span>mdi-timeline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-alert"></i><span>mdi-timeline-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-border-2 bg-body-secondary"></i><span>mdi-timeline-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-check"></i><span>mdi-timeline-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-check-outline"></i><span>mdi-timeline-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-clock"></i><span>mdi-timeline-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-clock-outline"></i><span>mdi-timeline-clock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-help"></i><span>mdi-timeline-help</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-help-outline"></i><span>mdi-timeline-help-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-minus"></i><span>mdi-timeline-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-minus-outline"></i><span>mdi-timeline-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-outline"></i><span>mdi-timeline-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-plus"></i><span>mdi-timeline-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-plus-outline"></i><span>mdi-timeline-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-remove"></i><span>mdi-timeline-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-remove-outline"></i><span>mdi-timeline-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-text"></i><span>mdi-timeline-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timeline-text-outline"></i><span>mdi-timeline-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer"></i><span>mdi-timer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-10"></i><span>mdi-timer-10</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-3"></i><span>mdi-timer-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-cog"></i><span>mdi-timer-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-cog-outline"></i><span>mdi-timer-cog-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-off"></i><span>mdi-timer-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-off-outline"></i><span>mdi-timer-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-outline"></i><span>mdi-timer-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-sand"></i><span>mdi-timer-sand</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-sand-complete"></i><span>mdi-timer-sand-complete</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-sand-empty"></i><span>mdi-timer-sand-empty</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-sand-full"></i><span>mdi-timer-sand-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-sand-paused"></i><span>mdi-timer-sand-paused</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-settings"></i><span>mdi-timer-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timer-settings-outline"></i><span>mdi-timer-settings-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-timetable"></i><span>mdi-timetable</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tire"></i><span>mdi-tire</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toaster"></i><span>mdi-toaster</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toaster-off"></i><span>mdi-toaster-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toaster-oven"></i><span>mdi-toaster-oven</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toggle-switch"></i><span>mdi-toggle-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toggle-switch-off"></i><span>mdi-toggle-switch-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toggle-switch-off-outline"></i><span>mdi-toggle-switch-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toggle-switch-outline"></i><span>mdi-toggle-switch-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toilet"></i><span>mdi-toilet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toolbox"></i><span>mdi-toolbox</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toolbox-outline"></i><span>mdi-toolbox-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tools"></i><span>mdi-tools</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip"></i><span>mdi-tooltip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-account"></i><span>mdi-tooltip-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-cellphone"></i><span>mdi-tooltip-cellphone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-check"></i><span>mdi-tooltip-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-check-outline"></i><span>mdi-tooltip-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-edit"></i><span>mdi-tooltip-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-edit-outline"></i><span>mdi-tooltip-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-image"></i><span>mdi-tooltip-image</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-image-outline"></i><span>mdi-tooltip-image-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-minus"></i><span>mdi-tooltip-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-minus-outline"></i><span>mdi-tooltip-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-outline"></i><span>mdi-tooltip-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-plus"></i><span>mdi-tooltip-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-plus-outline"></i><span>mdi-tooltip-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-remove"></i><span>mdi-tooltip-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-remove-outline"></i><span>mdi-tooltip-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-text"></i><span>mdi-tooltip-text</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooltip-text-outline"></i><span>mdi-tooltip-text-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooth"></i><span>mdi-tooth</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tooth-outline"></i><span>mdi-tooth-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toothbrush"></i><span>mdi-toothbrush</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toothbrush-electric"></i><span>mdi-toothbrush-electric</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toothbrush-paste"></i><span>mdi-toothbrush-paste</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-torch"></i><span>mdi-torch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tortoise"></i><span>mdi-tortoise</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toslink"></i><span>mdi-toslink</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tournament"></i><span>mdi-tournament</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tow-truck"></i><span>mdi-tow-truck</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tower-beach"></i><span>mdi-tower-beach</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tower-fire"></i><span>mdi-tower-fire</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-town-hall"></i><span>mdi-town-hall</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick"></i><span>mdi-toy-brick</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-marker"></i><span>mdi-toy-brick-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-marker-outline"></i><span>mdi-toy-brick-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-minus"></i><span>mdi-toy-brick-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-minus-outline"></i><span>mdi-toy-brick-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-outline"></i><span>mdi-toy-brick-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-plus"></i><span>mdi-toy-brick-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-plus-outline"></i><span>mdi-toy-brick-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-remove"></i><span>mdi-toy-brick-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-remove-outline"></i><span>mdi-toy-brick-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-search"></i><span>mdi-toy-brick-search</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-toy-brick-search-outline"></i><span>mdi-toy-brick-search-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-track-light"></i><span>mdi-track-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trackpad"></i><span>mdi-trackpad</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trackpad-lock"></i><span>mdi-trackpad-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tractor"></i><span>mdi-tractor</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tractor-variant"></i><span>mdi-tractor-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trademark"></i><span>mdi-trademark</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-traffic-cone"></i><span>mdi-traffic-cone</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-traffic-light"></i><span>mdi-traffic-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-traffic-light-outline"></i><span>mdi-traffic-light-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-train"></i><span>mdi-train</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-train-car"></i><span>mdi-train-car</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-train-car-passenger"></i><span>mdi-train-car-passenger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-train-car-passenger-door"></i><span>mdi-train-car-passenger-door</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-train-car-passenger-door-open"></i><span>mdi-train-car-passenger-door-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-train-car-passenger-variant"></i><span>mdi-train-car-passenger-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-train-variant"></i><span>mdi-train-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tram"></i><span>mdi-tram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tram-side"></i><span>mdi-tram-side</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transcribe"></i><span>mdi-transcribe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transcribe-close"></i><span>mdi-transcribe-close</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transfer"></i><span>mdi-transfer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transfer-down"></i><span>mdi-transfer-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transfer-left"></i><span>mdi-transfer-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transfer-right"></i><span>mdi-transfer-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transfer-up"></i><span>mdi-transfer-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transit-connection"></i><span>mdi-transit-connection</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transit-connection-horizontal"></i><span>mdi-transit-connection-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transit-connection-variant"></i><span>mdi-transit-connection-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transit-detour"></i><span>mdi-transit-detour</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transit-skip"></i><span>mdi-transit-skip</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transit-transfer"></i><span>mdi-transit-transfer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transition"></i><span>mdi-transition</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transition-masked"></i><span>mdi-transition-masked</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-translate"></i><span>mdi-translate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-translate-off"></i><span>mdi-translate-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transmission-tower"></i><span>mdi-transmission-tower</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transmission-tower-export"></i><span>mdi-transmission-tower-export</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-transmission-tower-import"></i><span>mdi-transmission-tower-import</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trash-can"></i><span>mdi-trash-can</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trash-can-outline"></i><span>mdi-trash-can-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tray"></i><span>mdi-tray</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tray-alert"></i><span>mdi-tray-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tray-arrow-down"></i><span>mdi-tray-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tray-arrow-up"></i><span>mdi-tray-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tray-full"></i><span>mdi-tray-full</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tray-minus"></i><span>mdi-tray-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tray-plus"></i><span>mdi-tray-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tray-remove"></i><span>mdi-tray-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-treasure-chest"></i><span>mdi-treasure-chest</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tree"></i><span>mdi-tree</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tree-outline"></i><span>mdi-tree-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trello"></i><span>mdi-trello</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trending-down"></i><span>mdi-trending-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trending-neutral"></i><span>mdi-trending-neutral</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trending-up"></i><span>mdi-trending-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-triangle"></i><span>mdi-triangle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-triangle-outline"></i><span>mdi-triangle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-triangle-wave"></i><span>mdi-triangle-wave</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-triforce"></i><span>mdi-triforce</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trophy"></i><span>mdi-trophy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trophy-award"></i><span>mdi-trophy-award</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trophy-broken"></i><span>mdi-trophy-broken</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trophy-outline"></i><span>mdi-trophy-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trophy-variant"></i><span>mdi-trophy-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trophy-variant-outline"></i><span>mdi-trophy-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck"></i><span>mdi-truck</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-cargo-container"></i><span>mdi-truck-cargo-container</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-check"></i><span>mdi-truck-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-check-outline"></i><span>mdi-truck-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-delivery"></i><span>mdi-truck-delivery</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-delivery-outline"></i><span>mdi-truck-delivery-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-fast"></i><span>mdi-truck-fast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-fast-outline"></i><span>mdi-truck-fast-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-flatbed"></i><span>mdi-truck-flatbed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-minus"></i><span>mdi-truck-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-minus-outline"></i><span>mdi-truck-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-outline"></i><span>mdi-truck-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-plus"></i><span>mdi-truck-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-plus-outline"></i><span>mdi-truck-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-remove"></i><span>mdi-truck-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-remove-outline"></i><span>mdi-truck-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-snowflake"></i><span>mdi-truck-snowflake</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-truck-trailer"></i><span>mdi-truck-trailer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-trumpet"></i><span>mdi-trumpet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tshirt-crew"></i><span>mdi-tshirt-crew</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tshirt-crew-outline"></i><span>mdi-tshirt-crew-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tshirt-v"></i><span>mdi-tshirt-v</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tshirt-v-outline"></i><span>mdi-tshirt-v-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tumble-dryer"></i><span>mdi-tumble-dryer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tumble-dryer-alert"></i><span>mdi-tumble-dryer-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tumble-dryer-off"></i><span>mdi-tumble-dryer-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tune"></i><span>mdi-tune</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tune-variant"></i><span>mdi-tune-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tune-vertical"></i><span>mdi-tune-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tune-vertical-variant"></i><span>mdi-tune-vertical-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tunnel"></i><span>mdi-tunnel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-tunnel-outline"></i><span>mdi-tunnel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-turkey"></i><span>mdi-turkey</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-turnstile"></i><span>mdi-turnstile</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-turnstile-outline"></i><span>mdi-turnstile-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-turtle"></i><span>mdi-turtle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-twitch"></i><span>mdi-twitch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-twitter"></i><span>mdi-twitter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-two-factor-authentication"></i><span>mdi-two-factor-authentication</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-typewriter"></i><span>mdi-typewriter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ubisoft"></i><span>mdi-ubisoft</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ubuntu"></i><span>mdi-ubuntu</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ufo"></i><span>mdi-ufo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ufo-outline"></i><span>mdi-ufo-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ultra-high-definition"></i><span>mdi-ultra-high-definition</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-umbraco"></i><span>mdi-umbraco</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-umbrella"></i><span>mdi-umbrella</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-umbrella-beach"></i><span>mdi-umbrella-beach</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-umbrella-beach-outline"></i><span>mdi-umbrella-beach-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-umbrella-closed"></i><span>mdi-umbrella-closed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-umbrella-closed-outline"></i><span>mdi-umbrella-closed-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-umbrella-closed-variant"></i><span>mdi-umbrella-closed-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-umbrella-outline"></i><span>mdi-umbrella-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-undo"></i><span>mdi-undo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-undo-variant"></i><span>mdi-undo-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unfold-less-horizontal"></i><span>mdi-unfold-less-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unfold-less-vertical"></i><span>mdi-unfold-less-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unfold-more-horizontal"></i><span>mdi-unfold-more-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unfold-more-vertical"></i><span>mdi-unfold-more-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-ungroup"></i><span>mdi-ungroup</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unicode"></i><span>mdi-unicode</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unicorn"></i><span>mdi-unicorn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unicorn-variant"></i><span>mdi-unicorn-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unicycle"></i><span>mdi-unicycle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unity"></i><span>mdi-unity</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-unreal"></i><span>mdi-unreal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-update"></i><span>mdi-update</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-upload"></i><span>mdi-upload</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-upload-lock"></i><span>mdi-upload-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-upload-lock-outline"></i><span>mdi-upload-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-upload-multiple"></i><span>mdi-upload-multiple</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-upload-network"></i><span>mdi-upload-network</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-upload-network-outline"></i><span>mdi-upload-network-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-upload-off"></i><span>mdi-upload-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-upload-off-outline"></i><span>mdi-upload-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-upload-outline"></i><span>mdi-upload-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-usb"></i><span>mdi-usb</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-usb-flash-drive"></i><span>mdi-usb-flash-drive</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-usb-flash-drive-outline"></i><span>mdi-usb-flash-drive-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-usb-port"></i><span>mdi-usb-port</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vacuum"></i><span>mdi-vacuum</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vacuum-outline"></i><span>mdi-vacuum-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-valve"></i><span>mdi-valve</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-valve-closed"></i><span>mdi-valve-closed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-valve-open"></i><span>mdi-valve-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-van-passenger"></i><span>mdi-van-passenger</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-van-utility"></i><span>mdi-van-utility</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vanish"></i><span>mdi-vanish</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vanish-quarter"></i><span>mdi-vanish-quarter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vanity-light"></i><span>mdi-vanity-light</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-variable"></i><span>mdi-variable</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-variable-box"></i><span>mdi-variable-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-arrange-above"></i><span>mdi-vector-arrange-above</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-arrange-below"></i><span>mdi-vector-arrange-below</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-bezier"></i><span>mdi-vector-bezier</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-circle"></i><span>mdi-vector-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-circle-variant"></i><span>mdi-vector-circle-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-combine"></i><span>mdi-vector-combine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-curve"></i><span>mdi-vector-curve</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-difference"></i><span>mdi-vector-difference</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-difference-ab"></i><span>mdi-vector-difference-ab</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-difference-ba"></i><span>mdi-vector-difference-ba</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-ellipse"></i><span>mdi-vector-ellipse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-intersection"></i><span>mdi-vector-intersection</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-line"></i><span>mdi-vector-line</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-link"></i><span>mdi-vector-link</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-point"></i><span>mdi-vector-point</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-polygon"></i><span>mdi-vector-polygon</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-polygon-variant"></i><span>mdi-vector-polygon-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-polyline"></i><span>mdi-vector-polyline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-polyline-edit"></i><span>mdi-vector-polyline-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-polyline-minus"></i><span>mdi-vector-polyline-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-polyline-plus"></i><span>mdi-vector-polyline-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-polyline-remove"></i><span>mdi-vector-polyline-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-radius"></i><span>mdi-vector-radius</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-rectangle"></i><span>mdi-vector-rectangle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-selection"></i><span>mdi-vector-selection</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-square"></i><span>mdi-vector-square</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-square-close"></i><span>mdi-vector-square-close</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-square-edit"></i><span>mdi-vector-square-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-square-minus"></i><span>mdi-vector-square-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-square-open"></i><span>mdi-vector-square-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-square-plus"></i><span>mdi-vector-square-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-square-remove"></i><span>mdi-vector-square-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-triangle"></i><span>mdi-vector-triangle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vector-union"></i><span>mdi-vector-union</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vhs"></i><span>mdi-vhs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vibrate"></i><span>mdi-vibrate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vibrate-off"></i><span>mdi-vibrate-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video"></i><span>mdi-video</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-3d"></i><span>mdi-video-3d</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-3d-off"></i><span>mdi-video-3d-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-3d-variant"></i><span>mdi-video-3d-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-4k-box"></i><span>mdi-video-4k-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-account"></i><span>mdi-video-account</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-box"></i><span>mdi-video-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-box-off"></i><span>mdi-video-box-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-check"></i><span>mdi-video-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-check-outline"></i><span>mdi-video-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-high-definition"></i><span>mdi-video-high-definition</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-image"></i><span>mdi-video-image</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-input-antenna"></i><span>mdi-video-input-antenna</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-input-component"></i><span>mdi-video-input-component</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-input-hdmi"></i><span>mdi-video-input-hdmi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-input-scart"></i><span>mdi-video-input-scart</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-input-svideo"></i><span>mdi-video-input-svideo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-marker"></i><span>mdi-video-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-marker-outline"></i><span>mdi-video-marker-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-minus"></i><span>mdi-video-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-minus-outline"></i><span>mdi-video-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-off"></i><span>mdi-video-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-off-outline"></i><span>mdi-video-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-outline"></i><span>mdi-video-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-plus"></i><span>mdi-video-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-plus-outline"></i><span>mdi-video-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-stabilization"></i><span>mdi-video-stabilization</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-switch"></i><span>mdi-video-switch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-switch-outline"></i><span>mdi-video-switch-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-vintage"></i><span>mdi-video-vintage</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-wireless"></i><span>mdi-video-wireless</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-video-wireless-outline"></i><span>mdi-video-wireless-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-agenda"></i><span>mdi-view-agenda</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-agenda-outline"></i><span>mdi-view-agenda-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-array"></i><span>mdi-view-array</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-array-outline"></i><span>mdi-view-array-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-carousel"></i><span>mdi-view-carousel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-carousel-outline"></i><span>mdi-view-carousel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-column"></i><span>mdi-view-column</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-column-outline"></i><span>mdi-view-column-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-comfy"></i><span>mdi-view-comfy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-comfy-outline"></i><span>mdi-view-comfy-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-compact"></i><span>mdi-view-compact</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-compact-outline"></i><span>mdi-view-compact-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-dashboard"></i><span>mdi-view-dashboard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-dashboard-edit"></i><span>mdi-view-dashboard-edit</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-dashboard-edit-outline"></i><span>mdi-view-dashboard-edit-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-dashboard-outline"></i><span>mdi-view-dashboard-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-dashboard-variant"></i><span>mdi-view-dashboard-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-dashboard-variant-outline"></i><span>mdi-view-dashboard-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-day"></i><span>mdi-view-day</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-day-outline"></i><span>mdi-view-day-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-gallery"></i><span>mdi-view-gallery</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-gallery-outline"></i><span>mdi-view-gallery-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-grid"></i><span>mdi-view-grid</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-grid-outline"></i><span>mdi-view-grid-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-grid-plus"></i><span>mdi-view-grid-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-grid-plus-outline"></i><span>mdi-view-grid-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-headline"></i><span>mdi-view-headline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-list"></i><span>mdi-view-list</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-list-outline"></i><span>mdi-view-list-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-module"></i><span>mdi-view-module</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-module-outline"></i><span>mdi-view-module-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-parallel"></i><span>mdi-view-parallel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-parallel-outline"></i><span>mdi-view-parallel-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-quilt"></i><span>mdi-view-quilt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-quilt-outline"></i><span>mdi-view-quilt-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-sequential"></i><span>mdi-view-sequential</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-sequential-outline"></i><span>mdi-view-sequential-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-split-horizontal"></i><span>mdi-view-split-horizontal</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-split-vertical"></i><span>mdi-view-split-vertical</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-stream"></i><span>mdi-view-stream</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-stream-outline"></i><span>mdi-view-stream-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-week"></i><span>mdi-view-week</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-view-week-outline"></i><span>mdi-view-week-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vimeo"></i><span>mdi-vimeo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-violin"></i><span>mdi-violin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-virtual-reality"></i><span>mdi-virtual-reality</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-virus"></i><span>mdi-virus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-virus-off"></i><span>mdi-virus-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-virus-off-outline"></i><span>mdi-virus-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-virus-outline"></i><span>mdi-virus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vlc"></i><span>mdi-vlc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-voicemail"></i><span>mdi-voicemail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volleyball"></i><span>mdi-volleyball</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-high"></i><span>mdi-volume-high</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-low"></i><span>mdi-volume-low</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-medium"></i><span>mdi-volume-medium</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-minus"></i><span>mdi-volume-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-mute"></i><span>mdi-volume-mute</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-off"></i><span>mdi-volume-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-plus"></i><span>mdi-volume-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-source"></i><span>mdi-volume-source</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-variant-off"></i><span>mdi-volume-variant-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-volume-vibrate"></i><span>mdi-volume-vibrate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vote"></i><span>mdi-vote</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vote-outline"></i><span>mdi-vote-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vpn"></i><span>mdi-vpn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vuejs"></i><span>mdi-vuejs</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-vuetify"></i><span>mdi-vuetify</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-walk"></i><span>mdi-walk</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall"></i><span>mdi-wall</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce"></i><span>mdi-wall-sconce</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce-flat"></i><span>mdi-wall-sconce-flat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce-flat-outline"></i><span>mdi-wall-sconce-flat-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce-flat-variant"></i><span>mdi-wall-sconce-flat-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce-flat-variant-outline"></i><span>mdi-wall-sconce-flat-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce-outline"></i><span>mdi-wall-sconce-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce-round"></i><span>mdi-wall-sconce-round</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce-round-outline"></i><span>mdi-wall-sconce-round-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce-round-variant"></i><span>mdi-wall-sconce-round-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wall-sconce-round-variant-outline"></i><span>mdi-wall-sconce-round-variant-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wallet"></i><span>mdi-wallet</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wallet-giftcard"></i><span>mdi-wallet-giftcard</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wallet-membership"></i><span>mdi-wallet-membership</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wallet-outline"></i><span>mdi-wallet-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wallet-plus"></i><span>mdi-wallet-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wallet-plus-outline"></i><span>mdi-wallet-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wallet-travel"></i><span>mdi-wallet-travel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wallpaper"></i><span>mdi-wallpaper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wan"></i><span>mdi-wan</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wardrobe"></i><span>mdi-wardrobe</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wardrobe-outline"></i><span>mdi-wardrobe-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-warehouse"></i><span>mdi-warehouse</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-washing-machine"></i><span>mdi-washing-machine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-washing-machine-alert"></i><span>mdi-washing-machine-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-washing-machine-off"></i><span>mdi-washing-machine-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watch"></i><span>mdi-watch</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watch-export"></i><span>mdi-watch-export</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watch-export-variant"></i><span>mdi-watch-export-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watch-import"></i><span>mdi-watch-import</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watch-import-variant"></i><span>mdi-watch-import-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watch-variant"></i><span>mdi-watch-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watch-vibrate"></i><span>mdi-watch-vibrate</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watch-vibrate-off"></i><span>mdi-watch-vibrate-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water"></i><span>mdi-water</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-alert"></i><span>mdi-water-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-border-2 bg-body-secondary"></i><span>mdi-water-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-boiler"></i><span>mdi-water-boiler</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-boiler-alert"></i><span>mdi-water-boiler-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-boiler-off"></i><span>mdi-water-boiler-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-check"></i><span>mdi-water-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-check-outline"></i><span>mdi-water-check-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-circle"></i><span>mdi-water-circle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-minus"></i><span>mdi-water-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-minus-outline"></i><span>mdi-water-minus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-off"></i><span>mdi-water-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-off-outline"></i><span>mdi-water-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-opacity"></i><span>mdi-water-opacity</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-outline"></i><span>mdi-water-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-percent"></i><span>mdi-water-percent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-percent-alert"></i><span>mdi-water-percent-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-plus"></i><span>mdi-water-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-plus-outline"></i><span>mdi-water-plus-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-polo"></i><span>mdi-water-polo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-pump"></i><span>mdi-water-pump</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-pump-off"></i><span>mdi-water-pump-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-remove"></i><span>mdi-water-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-remove-outline"></i><span>mdi-water-remove-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-sync"></i><span>mdi-water-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-well"></i><span>mdi-water-well</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-water-well-outline"></i><span>mdi-water-well-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-waterfall"></i><span>mdi-waterfall</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watering-can"></i><span>mdi-watering-can</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watering-can-outline"></i><span>mdi-watering-can-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-watermark"></i><span>mdi-watermark</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wave"></i><span>mdi-wave</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-waveform"></i><span>mdi-waveform</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-waves"></i><span>mdi-waves</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-waves-arrow-left"></i><span>mdi-waves-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-waves-arrow-right"></i><span>mdi-waves-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-waves-arrow-up"></i><span>mdi-waves-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-waze"></i><span>mdi-waze</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-cloudy"></i><span>mdi-weather-cloudy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-cloudy-alert"></i><span>mdi-weather-cloudy-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-cloudy-arrow-right"></i><span>mdi-weather-cloudy-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-cloudy-clock"></i><span>mdi-weather-cloudy-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-fog"></i><span>mdi-weather-fog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-hail"></i><span>mdi-weather-hail</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-hazy"></i><span>mdi-weather-hazy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-hurricane"></i><span>mdi-weather-hurricane</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-lightning"></i><span>mdi-weather-lightning</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-lightning-rainy"></i><span>mdi-weather-lightning-rainy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-night"></i><span>mdi-weather-night</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-night-partly-cloudy"></i><span>mdi-weather-night-partly-cloudy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-partly-cloudy"></i><span>mdi-weather-partly-cloudy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-partly-lightning"></i><span>mdi-weather-partly-lightning</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-partly-rainy"></i><span>mdi-weather-partly-rainy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-partly-snowy"></i><span>mdi-weather-partly-snowy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-partly-snowy-rainy"></i><span>mdi-weather-partly-snowy-rainy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-pouring"></i><span>mdi-weather-pouring</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-rainy"></i><span>mdi-weather-rainy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-snowy"></i><span>mdi-weather-snowy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-snowy-heavy"></i><span>mdi-weather-snowy-heavy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-snowy-rainy"></i><span>mdi-weather-snowy-rainy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-sunny"></i><span>mdi-weather-sunny</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-sunny-alert"></i><span>mdi-weather-sunny-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-sunny-off"></i><span>mdi-weather-sunny-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-sunset"></i><span>mdi-weather-sunset</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-sunset-down"></i><span>mdi-weather-sunset-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-sunset-up"></i><span>mdi-weather-sunset-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-tornado"></i><span>mdi-weather-tornado</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-windy"></i><span>mdi-weather-windy</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weather-windy-variant"></i><span>mdi-weather-windy-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web"></i><span>mdi-web</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-box"></i><span>mdi-web-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-cancel"></i><span>mdi-web-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-check"></i><span>mdi-web-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-clock"></i><span>mdi-web-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-minus"></i><span>mdi-web-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-off"></i><span>mdi-web-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-plus"></i><span>mdi-web-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-refresh"></i><span>mdi-web-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-remove"></i><span>mdi-web-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-web-sync"></i><span>mdi-web-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-webcam"></i><span>mdi-webcam</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-webcam-off"></i><span>mdi-webcam-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-webhook"></i><span>mdi-webhook</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-webpack"></i><span>mdi-webpack</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-webrtc"></i><span>mdi-webrtc</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wechat"></i><span>mdi-wechat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weight"></i><span>mdi-weight</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weight-gram"></i><span>mdi-weight-gram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weight-kilogram"></i><span>mdi-weight-kilogram</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weight-lifter"></i><span>mdi-weight-lifter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-weight-pound"></i><span>mdi-weight-pound</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-whatsapp"></i><span>mdi-whatsapp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wheel-barrow"></i><span>mdi-wheel-barrow</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wheelchair-accessibility"></i><span>mdi-wheelchair-accessibility</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-whistle"></i><span>mdi-whistle</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-whistle-outline"></i><span>mdi-whistle-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-white-balance-auto"></i><span>mdi-white-balance-auto</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-white-balance-incandescent"></i><span>mdi-white-balance-incandescent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-white-balance-iridescent"></i><span>mdi-white-balance-iridescent</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-white-balance-sunny"></i><span>mdi-white-balance-sunny</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-widgets"></i><span>mdi-widgets</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-widgets-outline"></i><span>mdi-widgets-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi"></i><span>mdi-wifi</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-alert"></i><span>mdi-wifi-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-arrow-down"></i><span>mdi-wifi-arrow-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-arrow-left"></i><span>mdi-wifi-arrow-left</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-arrow-left-right"></i><span>mdi-wifi-arrow-left-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-arrow-right"></i><span>mdi-wifi-arrow-right</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-arrow-up"></i><span>mdi-wifi-arrow-up</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-arrow-up-down"></i><span>mdi-wifi-arrow-up-down</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-cancel"></i><span>mdi-wifi-cancel</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-check"></i><span>mdi-wifi-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-cog"></i><span>mdi-wifi-cog</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-lock"></i><span>mdi-wifi-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-lock-open"></i><span>mdi-wifi-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-marker"></i><span>mdi-wifi-marker</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-minus"></i><span>mdi-wifi-minus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-off"></i><span>mdi-wifi-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-plus"></i><span>mdi-wifi-plus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-refresh"></i><span>mdi-wifi-refresh</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-remove"></i><span>mdi-wifi-remove</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-settings"></i><span>mdi-wifi-settings</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-star"></i><span>mdi-wifi-star</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-1"></i><span>mdi-wifi-strength-1</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-1-alert"></i><span>mdi-wifi-strength-1-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-1-lock"></i><span>mdi-wifi-strength-1-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-1-lock-open"></i><span>mdi-wifi-strength-1-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-2"></i><span>mdi-wifi-strength-2</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-2-alert"></i><span>mdi-wifi-strength-2-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-2-lock"></i><span>mdi-wifi-strength-2-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-2-lock-open"></i><span>mdi-wifi-strength-2-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-3"></i><span>mdi-wifi-strength-3</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-3-alert"></i><span>mdi-wifi-strength-3-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-3-lock"></i><span>mdi-wifi-strength-3-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-3-lock-open"></i><span>mdi-wifi-strength-3-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-4"></i><span>mdi-wifi-strength-4</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-4-alert"></i><span>mdi-wifi-strength-4-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-4-lock"></i><span>mdi-wifi-strength-4-lock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-4-lock-open"></i><span>mdi-wifi-strength-4-lock-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-border-2 bg-body-secondary"></i><span>mdi-wifi-strength-border-2 bg-body-secondary</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-lock-open-outline"></i><span>mdi-wifi-strength-lock-open-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-lock-outline"></i><span>mdi-wifi-strength-lock-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-off"></i><span>mdi-wifi-strength-off</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-off-outline"></i><span>mdi-wifi-strength-off-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-strength-outline"></i><span>mdi-wifi-strength-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wifi-sync"></i><span>mdi-wifi-sync</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wikipedia"></i><span>mdi-wikipedia</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wind-turbine"></i><span>mdi-wind-turbine</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wind-turbine-alert"></i><span>mdi-wind-turbine-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wind-turbine-check"></i><span>mdi-wind-turbine-check</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-close"></i><span>mdi-window-close</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-closed"></i><span>mdi-window-closed</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-closed-variant"></i><span>mdi-window-closed-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-maximize"></i><span>mdi-window-maximize</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-minimize"></i><span>mdi-window-minimize</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-open"></i><span>mdi-window-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-open-variant"></i><span>mdi-window-open-variant</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-restore"></i><span>mdi-window-restore</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-shutter"></i><span>mdi-window-shutter</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-shutter-alert"></i><span>mdi-window-shutter-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-window-shutter-open"></i><span>mdi-window-shutter-open</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-windsock"></i><span>mdi-windsock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wiper"></i><span>mdi-wiper</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wiper-wash"></i><span>mdi-wiper-wash</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wiper-wash-alert"></i><span>mdi-wiper-wash-alert</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wizard-hat"></i><span>mdi-wizard-hat</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wordpress"></i><span>mdi-wordpress</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wrap"></i><span>mdi-wrap</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wrap-disabled"></i><span>mdi-wrap-disabled</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wrench"></i><span>mdi-wrench</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wrench-clock"></i><span>mdi-wrench-clock</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-wrench-outline"></i><span>mdi-wrench-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-xamarin"></i><span>mdi-xamarin</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-xml"></i><span>mdi-xml</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-xmpp"></i><span>mdi-xmpp</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-yahoo"></i><span>mdi-yahoo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-yeast"></i><span>mdi-yeast</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-yin-yang"></i><span>mdi-yin-yang</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-yoga"></i><span>mdi-yoga</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-youtube"></i><span>mdi-youtube</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-youtube-gaming"></i><span>mdi-youtube-gaming</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-youtube-studio"></i><span>mdi-youtube-studio</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-youtube-subscription"></i><span>mdi-youtube-subscription</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-youtube-tv"></i><span>mdi-youtube-tv</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-yurt"></i><span>mdi-yurt</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-z-wave"></i><span>mdi-z-wave</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zend"></i><span>mdi-zend</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zigbee"></i><span>mdi-zigbee</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zip-box"></i><span>mdi-zip-box</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zip-box-outline"></i><span>mdi-zip-box-outline</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zip-disk"></i><span>mdi-zip-disk</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-aquarius"></i><span>mdi-zodiac-aquarius</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-aries"></i><span>mdi-zodiac-aries</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-cancer"></i><span>mdi-zodiac-cancer</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-capricorn"></i><span>mdi-zodiac-capricorn</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-gemini"></i><span>mdi-zodiac-gemini</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-leo"></i><span>mdi-zodiac-leo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-libra"></i><span>mdi-zodiac-libra</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-pisces"></i><span>mdi-zodiac-pisces</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-sagittarius"></i><span>mdi-zodiac-sagittarius</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-scorpio"></i><span>mdi-zodiac-scorpio</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-taurus"></i><span>mdi-zodiac-taurus</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-zodiac-virgo"></i><span>mdi-zodiac-virgo</span></div>
                    <div className="col-xl-3 col-lg-4 col-sm-6"><i className="mdi mdi-blank"></i><span>mdi-blank</span></div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Size</h4>
                  <Row className="icon-demo-content">
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-18px mdi-account"></i> mdi-18px
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-24px mdi-account"></i> mdi-24px
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-36px mdi-account"></i> mdi-36px
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-48px mdi-account"></i> mdi-48px
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Rotate</h4>

                  <Row className="icon-demo-content">
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-rotate-45 mdi-account"></i> mdi-rotate-45
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-rotate-90 mdi-account"></i> mdi-rotate-90
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-rotate-135 mdi-account"></i> mdi-rotate-135
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-rotate-180 mdi-account"></i> mdi-rotate-180
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-rotate-225 mdi-account"></i> mdi-rotate-225
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-rotate-270 mdi-account"></i> mdi-rotate-270
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-rotate-315 mdi-account"></i> mdi-rotate-315
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Spin</h4>
                  <Row className="icon-demo-content">
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-spin mdi-loading"></i> mdi-spin
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <i className="mdi mdi-spin mdi-star"></i> mdi-spin
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Materialdesign;
