// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.word-rotate {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  background: linear-gradient(to right, #ef4444, #7f1d1d);
  padding: 0 10px;
  text-align: center;
  margin: 0 10px;
}

.word-rotate-items {
  position: relative;
}

.word-rotate-items span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  transform: translateY(0);
  transition: transform 500ms cubic-bezier(0, 0, 0.2, 1),
    opacity 500ms ease-in-out;
}

.word-rotate-items span.visible {
  opacity: 1;
  transform: translateY(0);
  position: static;
  white-space: nowrap;
}

.word-rotate-items span.hidden {
  opacity: 0;
  transform: translateY(-100%);
  position: absolute;
}

.word-rotate-items span.entering {
  opacity: 1;
  transform: translateY(100%);
  position: static;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Landing/OnePage/components/WordRotate.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,uDAAuD;EACvD,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,wBAAwB;EACxB;6BAC2B;AAC7B;;AAEA;EACE,UAAU;EACV,wBAAwB;EACxB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,2BAA2B;EAC3B,gBAAgB;AAClB","sourcesContent":[".word-rotate {\n  display: inline-flex;\n  position: relative;\n  overflow: hidden;\n  transition: 0.3s;\n  background: linear-gradient(to right, #ef4444, #7f1d1d);\n  padding: 0 10px;\n  text-align: center;\n  margin: 0 10px;\n}\n\n.word-rotate-items {\n  position: relative;\n}\n\n.word-rotate-items span {\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  text-align: center;\n  opacity: 0;\n  transform: translateY(0);\n  transition: transform 500ms cubic-bezier(0, 0, 0.2, 1),\n    opacity 500ms ease-in-out;\n}\n\n.word-rotate-items span.visible {\n  opacity: 1;\n  transform: translateY(0);\n  position: static;\n  white-space: nowrap;\n}\n\n.word-rotate-items span.hidden {\n  opacity: 0;\n  transform: translateY(-100%);\n  position: absolute;\n}\n\n.word-rotate-items span.entering {\n  opacity: 1;\n  transform: translateY(100%);\n  position: static;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
