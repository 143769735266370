import { motion } from "framer-motion";
import "./ThreeGrid/ThreeGrid.css";
import DeltaPartner from "./ThreeGrid/DeltaPartner.jpg";
import DeltaPartnerLogo from "./ThreeGrid/DeltaPartnerLogo.png";
import DeltaStore from "./ThreeGrid/DeltaStore.jpg";
import DeltaStoreLogo from "./ThreeGrid/DeltaStoreLogo.png";
import DeltaPlus from "./ThreeGrid/DeltaPlus.jpg";
import DeltaPlusLogo from "./ThreeGrid/DeltaPlusLogo.png";

const ThreeGrid = () => {
  const blogPosts = [
    {
      title:
        "Geniş ürün yelpazesi ve avantajlı bayilik modeliyle işinizi büyütün!",
      href: "#deltapartner",
      imageUrl: DeltaPartner, // Doğrudan import edilen görüntü değişkeni
      author: {
        imageUrl: DeltaPartnerLogo, // Doğrudan import edilen logo değişkeni
      },
    },
    {
      title:
        "Delta Plus ile Yerinde Yenileme ve Onarım Merkezi olun, fark yaratın!",
      href: "#deltaplus", // Eksik href düzeltildi
      imageUrl: DeltaPlus, // Doğrudan import edilen görüntü değişkeni
      author: {
        imageUrl: DeltaPlusLogo, // Doğrudan import edilen logo değişkeni
      },
    },
    {
      title: "Delta Store mağazası açarak geleceğinize yatırım yapın!",
      href: "#deltastore",
      imageUrl: DeltaStore, // Doğrudan import edilen görüntü değişkeni
      author: {
        imageUrl: DeltaStoreLogo, // Doğrudan import edilen logo değişkeni
      },
    },
  ];

  return (
    <div className="bg-black">
      <div className="container-fluid-home">
        <motion.div
          className="col-12 mx-auto text-center py-5"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          <div className="row">
            <div className="text-center">
              <h2 className="display-4 fw-bold text-white font-family">
                Avantajlı İş Modelleri
              </h2>
              <p className="mt-3 fs-4 text-white">
                Kazancınızı artırmak ve uzun vadeli başarıya ulaşmak için Delta
                İş Ortağım'ın avantajlı iş modellerini keşfedin. <br /> Kapsamlı
                destek ve kaliteli hizmet anlayışımızla işinizi güvenle büyütün.
              </p>
            </div>
          </div>

          {/* Staggered Grid with Framer Motion */}
          <motion.div
            className="row mt-5 px-0"
            initial="hidden"
            animate="show"
            variants={{
              hidden: { opacity: 0 },
              show: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.3,
                },
              },
            }}
          >
            {blogPosts.map((post, index) => (
              <motion.div
                key={index}
                className="col-lg-4 col-md-6 col-12 mb-4 h-100"
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  show: {
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.6, ease: "easeOut" },
                  },
                }}
              >
                <div className="cardthreegrid bg-dark text-white h-100 overflow-hidden card-img-wrapper">
                  <img
                    src={post.imageUrl}
                    className="card-img-top"
                    alt=""
                    style={{
                      height: "450px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  <div className="card-info d-flex flex-column justify-content-end">
                    <h5 className="card-title card-title-white text-center">
                      {post.title}
                    </h5>
                    <div className="d-flex justify-content-center pt-3">
                      <motion.a
                        href={post.href}
                        className="w-50 rounded overflow-hidden thregridphoto"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <img
                          src={post.author.imageUrl}
                          alt=""
                          className="w-100 rounded thregridphoto"
                        />
                      </motion.a>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default ThreeGrid;
