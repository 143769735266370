import React from "react";
import { Link } from "react-router-dom";
import { CardBody, Col } from "reactstrap";

const ProductCard = ({ product }) => {
  const defaultImage =
    "https://static.vecteezy.com/ti/vecteur-libre/t1/22014063-disparu-image-page-pour-site-internet-conception-ou-mobile-app-conception-non-image-disponible-icone-vecteur-vectoriel.jpg";

  const formatCurrency = (value) => {
    const numberValue = parseInt(value, 10);
    return numberValue.toLocaleString("tr-TR");
  };

  return (
    <>
      {product?.forms?.map((form, index) => (
        <Col key={product.variant_id || index} className="mb-4">
          <div className="product-card-wrapper" key={index} id={index}>
            <div className="list-element" key={form.form_id}>
              <div className="explore-box card-animate">
                <Link to={"/outlet-details/" + form.form_id}>
                  <div className="explore-place-bid-img">
                    <img
                      src={form?.images?.image1 || defaultImage}
                      alt={`${product?.brand_title} ${product?.top_model_title}`}
                      className="card-img-top custom-card"
                    />
                  </div>
                  <CardBody>
                    <h6 className="h5 m-0">
                      {product?.brand_title} {product?.top_model_title}{" "}
                      {form?.memory?.storage} GB
                    </h6>
                    <h5 className="h6 m-0">
                      Outlet Cep Telefonu - {form?.grade?.grade_title}
                    </h5>
                    {form?.outletAnswers?.map((answer, index) => (
                      <p
                        key={index}
                        className={`text-secondary badge bg-light px-2 m-2 text-capitalize ${
                          index === 0 ? "mx-0" : ""
                        }`}
                      >
                        {answer}
                      </p>
                    ))}
                    <div className="priceAreaWrapper justify-content-between">
                      <div className="price-label">
                        <span className="text-black fw-bold">
                          {formatCurrency(form?.price)} ₺
                        </span>
                        <del className="text-muted del">
                          {form?.retail_price != null
                            ? formatCurrency(form?.retail_price) + " ₺"
                            : formatCurrency(parseInt(form?.price) * 1.2) +
                              " ₺"}
                        </del>
                      </div>
                      <div>
                        <div className="discount-badge">
                          %
                          {form?.retail_price
                            ? (
                                ((form.retail_price - form.price) /
                                  form.retail_price) *
                                100
                              ).toFixed(0)
                            : 10}{" "}
                          İndirim
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-end">
                      <div className="mt-3 d-flex align-items-center gap-2 ">
                        <span className="text-muted">Renk:</span>
                        <div
                          style={{
                            width: "18px",
                            height: "18px",
                            borderRadius: "50%",
                            backgroundColor: form?.color?.color_name || "#ccc",
                            border:
                              form?.color?.color_name === "White" ||
                              form?.color?.color_name === "Space Grey"
                                ? "1px solid #ccc"
                                : `1px solid ${form?.color?.color_name}`,
                          }}
                        ></div>{" "}
                        {form?.color?.color_name}
                      </div>
                      <div className="storage-info">
                        {form?.memory?.storage} GB
                      </div>
                    </div>
                  </CardBody>
                </Link>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default ProductCard;
