import React from "react";
import "./Footer/Footer.css";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

import LogoNormal from "./Header/logo-white.png";
const Footer = () => {
  const navigation = {
    solutions: [
      { name: "Ana Sayfa", href: "#anasayfa" },
      { name: "Avantajlar", href: "#" },
      { name: "İş Modelleri", href: "#" },
      { name: "Neden Biz", href: "#" },
    ],
    support: [
      { name: "Delta Partner", href: "#" },
      { name: "Delta Plus", href: "#" },
      { name: "Delta Store", href: "#" },
      { name: "Boomerang Sistemi", href: "#boomerang" },
    ],
    company: [
      { name: "İletişim", href: "#" },
      { name: "Merak Edilenler", href: "#" },
      { name: "Hakkımızda", href: "#" },
      { name: "Hemen Başvur", href: "#" },
    ],
    legal: [
      { name: "Delta Servis", href: "#" },
      { name: "Delta Fix", href: "#" },
      { name: "Delta Buyback", href: "#" },
      { name: "ikincieltelefon.com.tr", href: "#" },
    ],
    details: [
      { name: "Cihaz Sat", href: "#" },
      { name: "Cihaz Al", href: "#" },
      { name: "Boomeranla Tamir Ettir", href: "#" },
    ],
    social: [
      {
        name: "Facebook",
        href: "#",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Instagram",
        href: "#",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "LinkedIn",
        href: "https://linkedin.com",
        icon: (props) => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            {...props}
          >
            <path d="M22.225 0H1.771C.792 0 0 .771 0 1.725v20.549C0 23.228.792 24 1.771 24h20.451C23.2 24 24 23.228 24 22.274V1.725C24 .771 23.2 0 22.225 0zM7.125 20.452H3.648V9.045h3.477v11.407zM5.388 7.705a2.012 2.012 0 01-2.022-2.023c0-1.125.898-2.021 2.022-2.021 1.125 0 2.022.897 2.022 2.021A2.011 2.011 0 015.388 7.705zM20.452 20.452h-3.476v-5.635c0-1.345-.024-3.076-1.875-3.076-1.876 0-2.164 1.463-2.164 2.972v5.739h-3.477V9.045h3.338v1.561h.046c.464-.875 1.594-1.797 3.28-1.797 3.505 0 4.149 2.305 4.149 5.299v6.344z" />
          </svg>
        ),
      },
    ],
  };

  return (
    <footer aria-labelledby="footer-heading" className="bg-black">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <img alt="Logo" src={LogoNormal} className="h-8 w-auto" />
            <p className="text-sm leading-6 text-gray-300 space-y-3">
              <div className="flex items-center space-x-3">
                <FaMapMarkerAlt className="h-5 w-5" />
                <span>
                  Bahçelievler Mah. Mehmetçik Sk. Kadir Has No: 1 /1 İç Kapı No:
                  123 Bahçelievler/ İstanbul
                </span>
              </div>
              <div className="flex items-center space-x-3">
                <FaPhoneAlt className="h-5 w-5" />
                <a className="text-white" href="tel:0850 532 0 500">
                  0850 532 0 500
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <FaEnvelope className="h-5 w-5" />
                <a href="mailto:info@deltaservis.com.tr" className="text-white">
                  info@deltaservis.com.tr
                </a>
              </div>
            </p>

            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-500 hover:text-gray-400"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon aria-hidden="true" className="h-6 w-6" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16-grid-cols-setup mt-16-grid-cols-setup-xl">
            <div className="md-grid-setup">
              <div>
                <h3 className="text-sm-font-semibold-leading-6-text-white">
                  Site Haritası
                </h3>
                <ul role="list" className="mt-6-space-y-4 role-list">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm-leading-6-gray-hover-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm-font-semibold-leading-6-text-white">
                  İş Modelleri
                </h3>
                <ul role="list" className="mt-6-space-y-4 role-list">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm-leading-6-gray-hover-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md-grid-setup">
              <div>
                <h3 className="text-sm-font-semibold-leading-6-text-white">
                  İletişim
                </h3>
                <ul role="list" className="mt-6-space-y-4 role-list">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm-leading-6-gray-hover-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-10 md:mt-0">
                <h3 className="text-sm-font-semibold-leading-6-text-white">
                  İştirakler
                </h3>
                <ul role="list" className="mt-6-space-y-4 role-list">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm-leading-6-gray-hover-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-400">
            &copy; Telif Hakkı Delta Yenileme Merkezi ve Bilişim Teknolojileri
            A.Ş. 2024 | Tüm Hakları Saklıdır.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
