import axios from "axios";

const DeltaPay = async (payInfo) => {
  const token = JSON.parse(localStorage.getItem("authUser")).token;
  const response = await axios.post(
    `https://marketplace.deltaservis.com.tr/api/create-order`,
    payInfo,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

export default DeltaPay;
