export const faqItems = [
  {
    question: "Delta İş Ortağım Nedir?",
    answer:
      "Delta İş Ortağım, iş ortaklarına sürdürülebilir kazanç fırsatları sunan bir bayilik platformudur. Avantajlı iş modelleri ve kapsamlı destek mekanizmalarıyla iş ortaklarının gelirlerini artırmayı ve sektörde güçlü bir yer edinmelerini hedefler.",
    // buttonText: "Hemen Başvur",
  },
  {
    question: "Nasıl Başvurabilirim?",
    answer:
      "Web sitemizdeki başvuru formunu doldurarak başvurabilirsiniz. Başvurunuz, uzman ekibimiz tarafından değerlendirilir ve en kısa sürede size geri dönüş yapılır. Sürecin hızlı ve verimli ilerlemesi için eksiksiz ve doğru bilgi vermeniz önemlidir.",
    // buttonText: "Hemen Başvur",
  },
  {
    question: "Başvuru Sonrası Süreç Nasıl İşler?",
    answer:
      "Başvurunuz değerlendirildikten sonra ekibimiz sizinle iletişime geçerek bayilik şartları ve iş modeli hakkında detaylı bilgi paylaşır. Bu süreçte, ihtiyaç duyacağınız eğitim ve operasyonel destek sağlanarak işinizi güçlü bir şekilde başlatmanıza yardımcı olunur.",
  },
  {
    question: "Hangi Bölgelerde Bayilik Veriliyor?",
    answer:
      "Delta İş Ortağım, Türkiye genelinde bayilik fırsatları sunmaktadır. Başvuru sırasında mağazanızın bulunduğu bölge, pazar potansiyeli ve rekabet durumu analiz edilir. Daha fazla bilgi için web sitemizi ziyaret edebilir veya bizimle iletişime geçebilirsiniz.",
  },
];
