import React, { useState } from "react";
import { CardBody } from "reactstrap";

const ProductCard = ({ product }) => {
  const [selectedVariant, setSelectedVariant] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  console.log(product);
  const defaultImage =
    "https://static.vecteezy.com/ti/vecteur-libre/t1/22014063-disparu-image-page-pour-site-internet-conception-ou-mobile-app-conception-non-image-disponible-icone-vecteur-vectoriel.jpg";

  // Resim yüklendiğinde çağrılacak fonksiyon
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // Resim yüklenemezse çağrılacak fonksiyon
  const handleImageError = () => {
    setImageLoaded(false);
  };

  const formatCurrency = (value) => {
    const numberValue = parseInt(value, 10);
    return numberValue.toLocaleString("tr-TR");
  };

  return (
    <div className="list-element">
      <div className="explore-box card-animate">
        <a
          href={"product-details/" + product?.top_model_id}
          rel="noopener noreferrer"
        >
          <div className="explore-place-bid-img">
            <img
              src={
                product?.variants?.[selectedVariant]?.images?.image1 ??
                defaultImage
              }
              alt={product?.brand_title + " " + product?.top_model_title}
              className="card-img-top custom-card"
              onLoad={handleImageLoad} // Yükleme tamamlandığında çağrılır
              onError={handleImageError} // Yükleme hatası olduğunda çağrılır
            />
            {!imageLoaded && (
              <div className="image-loading-placeholder">Yükleniyor...</div>
            )}
          </div>
        </a>
        <CardBody>
          <h6 className="h5 m-0">
            {product?.brand_title} {product?.top_model_title}
          </h6>
          <h5 className="h6 m-0">
            Yenilenmiş -{" "}
            {product?.variants[selectedVariant]?.grade?.grade_id == 10
              ? "Çok İyi"
              : product?.variants[selectedVariant]?.grade?.grade_id == 23
              ? "Mükemmel"
              : "İyi"}
          </h5>
          <p className="text-muted mb-0">{product?.variant_title}</p>
          <div className="priceAreaWrapper justify-content-between">
            <div className="price-label">
              <span className="text-black fw-bold">
                {formatCurrency(product?.variants[selectedVariant]?.price)} ₺
              </span>
              <del className="text-muted del">
                {product?.variants[selectedVariant]?.retail_price != null
                  ? product?.variants[selectedVariant]?.retail_price
                  : formatCurrency(
                      parseInt(product?.variants[selectedVariant]?.price) * 1.2
                    ) + " ₺"}
              </del>
            </div>
            <div>
              <div className="discount-badge">%10 İndirim</div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div className="mt-3 d-flex align-items-center gap-2 ">
              <span className="text-muted">Renk:</span>
              <div className="d-flex gap-1">
                {product?.variants && product?.variants?.length > 0 ? (
                  product?.variants.map((variant, index) => (
                    <div
                      onClick={() => {
                        setSelectedVariant(index);
                      }}
                      key={index}
                      style={{
                        width: "18px",
                        height: "18px",
                        borderRadius: "50%",
                        display: "inline-block",
                        cursor: "pointer",
                        backgroundColor: variant?.color_name,
                        margin: "2px 2px 2px",
                        boxShadow:
                          selectedVariant == index
                            ? "0 0 0 1px rgb(85 85 85)"
                            : variant.color_name == "White"
                            ? "0 0 0 1px rgb(192 192 192)"
                            : `0 0 0 1px ${variant?.color_name}`,
                      }}
                    ></div>
                  ))
                ) : (
                  <span>Renkler mevcut değil</span>
                )}
              </div>
            </div>
            <div className="storage-info">
              {product?.variants[selectedVariant]?.memory?.storage} GB
            </div>
          </div>
        </CardBody>
      </div>
    </div>
  );
};

export default ProductCard;
