import React, { useState } from "react";
import LayoutExternal from "./components/LayoutExternal";
import CategoryBox from "./components/CategoryBox";
import Banner from "./components/Banner";
import SingleCarousel from "./components/SingleCarousel";
import product1 from "./components/CategoryBox/assets/product1.webp";
import product1text from "./components/CategoryBox/assets/product1-text.png";
import product2 from "./components/CategoryBox/assets/product2.webp";
import product2text from "./components/CategoryBox/assets/product2-text.png";
import product3 from "./components/CategoryBox/assets/product3.webp";
import product3text from "./components/CategoryBox/assets/product3-text.png";
import product4 from "./components/CategoryBox/assets/product4.webp";
import product4text from "./components/CategoryBox/assets/product4-text.png";
import hoverImage from "./components/CategoryBox/assets/hover-image.webp";
import FixplanAdvantages from "./components/FixplanSection";
import ThreeGrid from "./components/ThreeGrid";
import BannerDown from "./components/BannerDown";
import SlidingTextVideoComponent from "./components/VideoSection/SlidingTextVideo";
import FAQ from "./components/FAQ/faq";
import SizinSectiklerimiz from "./components/SizinSectiklerimiz/SizinSectiklerimiz";

const categories = [
  { labelImage: product1text, productImage: product2, hoverImage: hoverImage },
  { labelImage: product2text, productImage: product1, hoverImage: hoverImage },
  { labelImage: product3text, productImage: product3, hoverImage: hoverImage },
  { labelImage: product4text, productImage: product4, hoverImage: hoverImage },
];

const Home = () => {
  return (
    <LayoutExternal>
      <Banner />
      <BannerDown />
      <SizinSectiklerimiz />
      <CategoryBox categories={categories} />
      <div className="section">
        <h1 className="header-title">Öne Çıkan Ürün</h1>
        <p className="paragraph">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla, ipsa
          minima vitae quos
        </p>
      </div>

      <SingleCarousel />

      <FixplanAdvantages />

      <ThreeGrid />
      {/* <SubBanner /> */}

      {/* <DeviceSell /> */}
      <FAQ />
      <SlidingTextVideoComponent />
    </LayoutExternal>
  );
};

export default Home;
