import React, { useState } from "react";
import ProductCarousel from "../RelatedCarousel";
import productsData from "./related.json";
import "./RelatedProducts.css";
const SizinSectiklerimiz = () => {
  const [selectedCategory, setSelectedCategory] = useState("DealDay");

  // Veriyi kategorilere ayırmak
  const organizedData = {
    DealDay: productsData.products, // İlk 3 ürün
    MobilePhone: productsData.products.slice(3, 6), // 4-6. ürünler
    OutletProducts: productsData.products.slice(6, 9), // 7-9. ürünler
    TechnologyProduct: productsData.products.slice(9, 12), // 10-12. ürünler
    SmartLife: productsData.products.slice(12, 15), // 13-15. ürünler
  };

  const handleTabClick = (category) => {
    setSelectedCategory(category);
  };

  const categoryTitle = {
    DealDay: "Günün Fırsatı",
    MobilePhone: "Cep Telefonu",
    OutletProducts: "Outlet Ürünleri",
    TechnologyProduct: "Teknoloji Ürünleri",
    SmartLife: "Akıllı Yaşam",
  };

  return (
    <div>
      <div className="section">
        <h1 className="header-title">Benzer Ürünler</h1>
      </div>

      {organizedData[selectedCategory] && (
        <ProductCarousel
          title={`${categoryTitle[selectedCategory]} Ürünleri`}
          data={organizedData.DealDay}
        />
      )}
    </div>
  );
};

export default SizinSectiklerimiz;
