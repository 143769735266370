import WordRotate from "./WordRotate";
import { AiOutlineGlobal, AiOutlineTeam } from "react-icons/ai";
import {
  FaPercentage,
  FaRegHandshake,
  FaMoneyBillWave,
  FaSyncAlt,
} from "react-icons/fa";
import { MdSecurity } from "react-icons/md";

import "./FixplanSection.css";
const FixplanAdvantages = () => {
  const advantages = [
    {
      Icon: AiOutlineGlobal, // Küresel talep
      title: (
        <>
          <span className="d-block d-sm-none">
            Yenilenmiş Ürünlere <br /> Artan Talep
          </span>
          <span className="d-none d-sm-block">
            Yenilenmiş Ürünlere Artan Talep
          </span>
        </>
      ),
    },
    {
      Icon: FaMoneyBillWave, // Yatırım geri dönüşü
      title: (
        <>
          Yatırımın Kısa Sürede <br />
          Geri Dönüşü
        </>
      ),
    },
    {
      Icon: AiOutlineTeam, // Profesyonel ekip
      title: "Alanında Uzman Ekipler",
    },
    {
      Icon: FaPercentage, // KDV avantajı
      title: "Yüzde 1 KDV Avantajı",
    },

    {
      Icon: FaRegHandshake, // 12 taksit imkanı
      title: (
        <>
          Yenilenmiş Cihaz Satışlarında <br /> 12 Taksit İmkanı
        </>
      ),
    },
    {
      Icon: MdSecurity, // Güvenli ürün
      title: "Geniş Ürün Yelpazesi",
    },
    {
      Icon: MdSecurity, // Veri güvenliği
      title: "Yaygın Hizmet Ağı",
    },
    {
      Icon: FaSyncAlt, // Premium parça desteği
      title: "360 Derece Kapsamlı Destek",
    },
  ];

  return (
    <div className="position-relative bg-black">
      <div className="position-relative mx-auto container px-2">
        <div className="row mt-4 mt-lg-5 py-5">
          <div className="col-12 col-sm-4 rounded-3">
            <div className="d-flex flex-column align-items-center justify-content-center h-100 desktop-padding">
              <div className="d-flex flex-column">
                <div className="d-flex flex-column align-items-center justify-content-start text-center">
                  <div className="d-flex flex-column align-items-center justify-content-center pb-3 font-family">
                    <div
                      className="gradient-text pt-1  fw-light text-uppercase"
                      style={{
                        background:
                          "linear-gradient(to top, #60a5fa, #6366f1, #f97316)",
                        WebkitBackgroundClip: "text", // Arka planı sadece metinle sınırlar
                        WebkitTextFillColor: "transparent", // Metni şeffaf yapar
                        fontSize: "55px",
                        lineHeight: "1.1",
                        letterSpacing: "0.05em",
                      }}
                    >
                      <span
                        className="d-block"
                        style={{
                          fontWeight: "300",
                          letterSpacing: "0.05em",
                          fontSize: "55px",
                        }}
                      >
                        GELECEĞİN
                      </span>
                      <span
                        className="d-block"
                        style={{
                          fontWeight: "500",
                          letterSpacing: "0.05em",
                          fontSize: "55px",
                        }}
                      >
                        TEKNOLOJİ
                      </span>
                      <span
                        className="d-block "
                        style={{
                          fontWeight: "600",
                          letterSpacing: "0.05em",
                          fontSize: "55px",
                        }}
                      >
                        PAZARINDA
                      </span>
                      <span
                        className="d-block"
                        style={{
                          fontWeight: "700",
                          letterSpacing: "0.05em",
                          fontSize: "55px",
                        }}
                      >
                        YERİNİZİ
                      </span>
                      <span
                        className="d-block"
                        style={{
                          fontWeight: "800",
                          letterSpacing: "0.05em",
                          fontSize: "55px",
                        }}
                      >
                        ALIN
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-8 content">
            <div className="d-flex flex-column gap-3">
              <div className="d-block d-sm-none">
                <div className="text-center gradient-text fw-bold desktop-padding">
               <span className="font-family"></span>
                  <br /> Delta İş Ortağım <br />
                  Modelleri ile
                  <br />
                  <WordRotate
                    words={[
                      "Ürün Sat",
                      "Boomerangla",
                      "Ürün Topla",
                      "Ortak Havuzdan",
                      "Tamir Ettir",
                    ]}
                    delay={3000}
                    animDelay={3000}
                  />
                  <br />
                  Kazan
                </div>
              </div>
              <div className="d-none d-lg-block">
                <div className="gradient-text  fw-bold font-family">
                  Kazandıran Delta İş Ortağım <br /> Modelleri ile
                  <WordRotate
                    words={[
                      "Ürün Sat ",
                      "Boomerangla ",
                      "Ürün Topla ",
                      "Ortak Havuzdan ",
                      "Tamir Ettir ",
                    ]}
                    delay={3000}
                    animDelay={3000}
                  />
                  Kazan
                </div>
              </div>
              <div className="d-flex flex-column mt-4">
                <p
                  className="fs-5 fw-medium text-white"
                  style={{
                    background: "linear-gradient(to right, #d1d5db, #6b7280)", // Gradyan renkler
                    WebkitBackgroundClip: "text", // Arka planı sadece metinle sınırlar
                    WebkitTextFillColor: "transparent", // Metni şeffaf yapar
                    letterSpacing: "normal", // Harf aralığını varsayılan yapar
                  }}
                >
                  Avantajlı bayilik sistemleri ile geleceğe güvenle bakın. Delta
                  İş Ortağım, güvenli iş modeli ve yüksek standartlarıyla,
                  yatırımınız için hızlı geri dönüş ve sürdürülebilir kazanç
                  fırsatı sunar.
                </p>
              </div>

              <div className="row">
                {advantages.map((advantage, index) => (
                  <div key={index} className="col-md-6 col-12">
                    <ul key={index} className="list-unstyled">
                      <li>
                        <div className="d-flex ">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: "48px", width: "48px" }}
                          >
                            <svg
                              className="h-48 w-48"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <defs>
                                <linearGradient
                                  id={`gradient-${index}`}
                                  x1="0%"
                                  y1="0%"
                                  x2="100%"
                                  y2="100%"
                                >
                                  <stop offset="0%" stopColor="#ff5722" />
                                  <stop offset="60%" stopColor="#3f51b5" />
                                  <stop offset="100%" stopColor="#42a5f5" />
                                </linearGradient>
                              </defs>
                              <advantage.Icon
                                fill={`url(#gradient-${index})`}
                              />
                            </svg>
                          </div>
                          <div className="fs-5 text-white">
                            {advantage.title}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixplanAdvantages;

// Eklenen CSS
// .max-w-1170 {
//   max-width: 1170px;
// }

// .h-48 {
//   height: 48px;
//   width: 48px;
// }
