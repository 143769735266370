// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.from-red-500 {
  --tw-gradient-from: #ef4444 var(--tw-gradient-from-position);
}

.to-red-900 {
  --tw-gradient-to: #7f1d1d var(--tw-gradient-to-position);
}
.row > .col-12 {
  margin-bottom: 20px; /* İki element arasında 20px boşluk */
}

@media (min-width: 768px) {
  /* Daha büyük ekranlar için boşluğu kaldırabilirsiniz */
  .row > .col-12 {
    margin-bottom: 0; /* Geniş ekranlarda boşluğu sıfırla */
  }
}
.desktop-padding {
  padding-right: 100px;
}

@media (max-width: 768px) {
  .desktop-padding {
    padding-right: 0 !important;
    font-weight: 700 !important;
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
    text-align: center !important;
  }
}

.font-family {
  font-weight: 700 !important;
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Landing/OnePage/components/FixplanSection.css"],"names":[],"mappings":"AAAA;EACE,4DAA4D;AAC9D;;AAEA;EACE,wDAAwD;AAC1D;AACA;EACE,mBAAmB,EAAE,qCAAqC;AAC5D;;AAEA;EACE,uDAAuD;EACvD;IACE,gBAAgB,EAAE,qCAAqC;EACzD;AACF;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,2BAA2B;IAC3B,2BAA2B;IAC3B,6BAA6B;IAC7B,8BAA8B;IAC9B,6BAA6B;EAC/B;AACF;;AAEA;EACE,2BAA2B;EAC3B,6BAA6B;EAC7B,8BAA8B;AAChC","sourcesContent":[".from-red-500 {\n  --tw-gradient-from: #ef4444 var(--tw-gradient-from-position);\n}\n\n.to-red-900 {\n  --tw-gradient-to: #7f1d1d var(--tw-gradient-to-position);\n}\n.row > .col-12 {\n  margin-bottom: 20px; /* İki element arasında 20px boşluk */\n}\n\n@media (min-width: 768px) {\n  /* Daha büyük ekranlar için boşluğu kaldırabilirsiniz */\n  .row > .col-12 {\n    margin-bottom: 0; /* Geniş ekranlarda boşluğu sıfırla */\n  }\n}\n.desktop-padding {\n  padding-right: 100px;\n}\n\n@media (max-width: 768px) {\n  .desktop-padding {\n    padding-right: 0 !important;\n    font-weight: 700 !important;\n    font-size: 2.25rem !important;\n    line-height: 2.5rem !important;\n    text-align: center !important;\n  }\n}\n\n.font-family {\n  font-weight: 700 !important;\n  font-size: 2.25rem !important;\n  line-height: 2.5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
