import React, { useState } from "react";
import ProductCarousel from "../Carousel";
import productsData from "./product.json";
import "./SizinSectiklerimiz.css";
const SizinSectiklerimiz = () => {
  const [selectedCategory, setSelectedCategory] = useState("DealDay");

  // Veriyi kategorilere ayırmak
  const organizedData = {
    DealDay: productsData.products, // İlk 3 ürün
    MobilePhone: productsData.products.slice(3, 6), // 4-6. ürünler
    OutletProducts: productsData.products.slice(6, 9), // 7-9. ürünler
    TechnologyProduct: productsData.products.slice(9, 12), // 10-12. ürünler
    SmartLife: productsData.products.slice(12, 15), // 13-15. ürünler
  };

  const handleTabClick = (category) => {
    setSelectedCategory(category);
  };

  const categoryTitle = {
    DealDay: "Günün Fırsatı",
    MobilePhone: "Cep Telefonu",
    OutletProducts: "Outlet Ürünleri",
    TechnologyProduct: "Teknoloji Ürünleri",
    SmartLife: "Akıllı Yaşam",
  };

  return (
    <div>
      <div className="section">
        <h1 className="header-title">Sizin için Seçtiklerimiz</h1>
        <p className="paragraph">
          Avantajlı ürünleri sizin için seçtik. Delta Servis kalitesiyle
          yenilenmiş ürünlere sahip olun.
        </p>
      </div>
      <div className="tabs">
        {Object.keys(organizedData).map((category) => (
          <div
            key={category}
            onClick={() => handleTabClick(category)}
            className={`tab ${
              selectedCategory === category ? "active-tab" : ""
            }`}
          >
            {categoryTitle[category]}
          </div>
        ))}
      </div>

      {organizedData[selectedCategory] && (
        <ProductCarousel
          title={`${categoryTitle[selectedCategory]} Ürünleri`}
          data={organizedData.DealDay}
        />
      )}
    </div>
  );
};

export default SizinSectiklerimiz;
