// Include Both Helper File with needed methods
import axios from "axios";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from "./reducer";

export const loginUser = (user, history) => async (dispatch) => {
  try {
    // Delta Servis API'ye post isteği
    const response = await axios.post(
      "https://marketplace.deltaservis.com.tr/api/login",
      {
        email: user.email,
        password: user.password,
      }
    );

    if (response.user) {
      const token = response.token;
      const user = response.user;
      localStorage.setItem("authUser", JSON.stringify({ token, user }));
      dispatch(loginSuccess({ token, user }));
      history("/dashboard");
    }
  } catch (error) {
    dispatch(apiError(error.response ? error.response.data : "Login failed"));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
