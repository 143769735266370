import { createSlice } from "@reduxjs/toolkit";

// initialState, localStorage'dan alınan verilerle başlatılacak
const initialState = {
  items: JSON.parse(localStorage.getItem("cartItems")) || [], // Eğer localStorage'dan okunan veri varsa, onu kullan
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Ürün ekleme
    addItem: (state, action) => {
      const existingItem = state.items.find(
        (item) => item.id === action.payload.id
      );
      if (existingItem) {
        existingItem.quantity += action.payload.quantity;
      } else {
        state.items.push(action.payload);
      }

      // localStorage'ı güncelle
      saveToLocalStorage(state.items);
    },

    // Ürün silme
    removeItem: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);

      // localStorage'ı güncelle
      saveToLocalStorage(state.items);
    },

    // Ürün miktarını arttırma
    incrementQuantity: (state, action) => {
      const item = state.items.find((item) => item.id === action.payload);

      if (item) {
        item.quantity += 1;
      }

      // localStorage'ı güncelle
      saveToLocalStorage(state.items);
    },

    // Ürün miktarını azaltma
    decrementQuantity: (state, action) => {
      const item = state.items.find((item) => item.id === action.payload);

      if (item && item.quantity > 1) {
        item.quantity -= 1;
      }

      // localStorage'ı güncelle
      saveToLocalStorage(state.items);
    },

    // Sepeti temizleme
    clearCart: (state) => {
      state.items = [];

      // localStorage'ı güncelle
      saveToLocalStorage(state.items);
    },
  },
});

// localStorage'a veri kaydetme fonksiyonu
const saveToLocalStorage = (items) => {
  try {
    localStorage.setItem("cartItems", JSON.stringify(items));
  } catch (e) {
    console.error("localStorage hatası:", e);
  }
};

// Reducer'ı dışarı aktarıyoruz
export const {
  addItem,
  removeItem,
  incrementQuantity,
  decrementQuantity,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
