import React from "react";
import "./Banner/Banner.css";

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <div className="banner-text">
          <h1>
            Delta <span className="gradientbanner">İş Ortağım</span> Avantajları Keşfet
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet.
          </p>
          <div className="banner-images">
            <div className="advantage-list">
              <div className="advantage-item gradient-orange">
                1 YIL <br /> GARANTİ
              </div>
              <div className="advantage-item gradient-blue">
                12 TAKSİT <br /> AVANTAJI
              </div>
              <div className="advantage-item gradient-purple">
                12 AY <br /> GARANTİ
              </div>
            </div>
          </div>
        </div>
        <div className="banner-image">
          <img
            src="https://pngimg.com/uploads/iphone16/iphone16_PNG13.png"
            alt="Example Product"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
