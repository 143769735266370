import React from "react";
import installmentData from "./installments.json"; // JSON dosyasını import edin

const InstallmentGrid = ({ price }) => {
  return (
    <div className="container mt-5">
      <div className="row">
        {installmentData.map((card, index) => (
          <div key={index} className="col-md-4 col-sm-6 mb-4">
            <div
              className="card p-3 shadow-sm"
              style={{
                borderRadius: "10px",
                border: "1px solid #ddd",
                backgroundColor: "#f1f5f9",
                minHeight: "300px",
              }}
            >
              {/* Kart Adı ve Logosu */}
              <div className="text-center mb-3 mt-3">
                <img
                  src={card.cardLogo}
                  alt={card.cardName}
                  style={{ height: "20px", objectFit: "contain" }}
                />
              </div>

              {/* Taksit Tablosu */}
              <table className="table table-bordered text-center mt-3">
                <thead>
                  <tr>
                    <th scope="col">Taksit</th>
                    <th scope="col">Taksit Tutar</th>
                    <th scope="col">Toplam Tutar</th>
                  </tr>
                </thead>
                <tbody>
                  {card.installments.map((month, i) => {
                    const installmentPrice = (
                      (price * card.rates[i]) /
                      month
                    ).toFixed(2);
                    const totalPrice = (price * card.rates[i] ).toFixed(2) ;

                    return (
                      <tr key={i}>
                        <td>{month}</td>
                        <td>{installmentPrice} TL</td>
                        <td>{totalPrice} TL</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstallmentGrid;
