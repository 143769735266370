import React, { useState, useEffect } from "react";
import "./Header/Header.css";
import { Link } from "react-router-dom";

import { Modal, ModalBody } from "reactstrap";
import LogoNormal from "./Header/logo-black.png";
import LogoScrolled from "./Header/logo-white.png";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [modalLoginOpen, setModalLoginOpen] = useState(false);

  const toggleLoginModal = () => {
    setModalLoginOpen(!modalLoginOpen);
  };
  return (
    <header className={`header ${isScrolled ? "scrolled" : ""}`}>
      <nav className="navbar navbar-expand-lg">
        <div className="container p-0">
          <Link
            className="navbar-brand"
            style={{
              marginRight: "100px",
            }}
            to="/"
          >
            <img
              className="logo"
              src={isScrolled ? LogoScrolled : LogoNormal}
              alt="Logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            aria-controls="basic-navbar-nav"
            aria-expanded={expanded}
            aria-label="Toggle navigation"
            onClick={() => setExpanded(!expanded)}
          >
            <GiHamburgerMenu />
          </button>
          <div
            className={`collapse navbar-collapse ${expanded ? "show" : ""}`}
            id="basic-navbar-nav"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to="/cep-telefonu"
                  className="nav-link"
                  onClick={() => setExpanded(false)}
                >
                  Cep Telefonu
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/outlet"
                  className="nav-link"
                  onClick={() => setExpanded(false)}
                >
                  Outlet
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/aksesuar"
                  className="nav-link"
                  onClick={() => setExpanded(false)}
                >
                  Teknoloji Ürünleri
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/tablet"
                  className="nav-link"
                  onClick={() => setExpanded(false)}
                >
                  Akıllı Yaşam
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/tablet"
                  className="nav-link"
                  onClick={() => setExpanded(false)}
                >
                  Kurumsal
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav" style={{
              marginRight:"5px"
            }}>
              <li className="nav-item">
                <button
                  className="nav-link link-login btn"
                  onClick={() => toggleLoginModal()}
                >
                  Giriş Yap
                </button>
              </li>
            </ul>
            <Modal
              id="loginModals"
              tabIndex="-1"
              isOpen={modalLoginOpen}
              toggle={toggleLoginModal}
              centered
            >
              <div className="modal-content border-0 overflow-hidden">
                <ModalBody className="p-5">
                  <h5 className="mb-3 text-center">Giriş Yap</h5>
                  <form>
                    <div className="mb-2">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Mail Adresini Girin"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Şifrenizi Girin"
                      />
                      {/* <div className="mt-1 text-end">
                        <Link to="/auth-pass-reset-basic">
                          Forgot password?
                        </Link>
                      </div> */}
                    </div>
                    <button
                      type="submit"
                      className="btn bg-black text-white w-100"
                    >
                      Giriş Yap
                    </button>
                  </form>
                </ModalBody>
                <ModalBody className="login-modal p-5">
                  {/* <h5 className="text-white fs-20">Login</h5> */}
                  {/* <p className="text-white-50 mb-4">
                      Üye değil misin, {" "}
                      <Link to="/#" className="text-white">
                      Üye Ol
                      </Link>
                         </p> */}
                  <div className="vstack gap-2 justify-content-center">
                    <button className="btn btn-light">
                      Üye değil misin, Üye Ol
                    </button>
                  </div>
                </ModalBody>
              </div>
            </Modal>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  to="/login"
                  className="nav-link link-login"
                  onClick={() => setExpanded(false)}
                >
                  Ürün Listesi
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
