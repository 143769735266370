import React from "react";
import "./Carousel/Carousel.css";
import ProductCard from "./RelatedProducts/RelatedProductCard";

const ProductCarousel = ({ title, data }) => {
  return (
    <>
      <div className="sm-hide mb-5 flex items-center justify-end"></div>
      <div
        className="grid-container"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          gap: "20px", // Elemanlar arasındaki boşluk
        }}
      >
        {data.map((products, index) => (
          <div
            key={products.variant_id || index}
            className="product-card-wrapper"
          >
            <ProductCard product={products} />
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductCarousel;
