// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-title {
  color: black !important;
  font-size: 2.6rem !important;
  font-weight: bold !important;
  margin-bottom: 1rem !important;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}

.paragraph {
  color: black !important;
  font-size: 1.2rem !important;
  line-height: 1.6 !important;
  margin-top: 0.5rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Landing/OnePage/components/RelatedProducts/RelatedProducts.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,4BAA4B;EAC5B,8BAA8B;EAC9B;yDACuD;AACzD;;AAEA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,2BAA2B;EAC3B,6BAA6B;AAC/B","sourcesContent":[".header-title {\n  color: black !important;\n  font-size: 2.6rem !important;\n  font-weight: bold !important;\n  margin-bottom: 1rem !important;\n  font-family: ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\",\n    \"Segoe UI Emoji\", Segoe UI Symbol, \"Noto Color Emoji\";\n}\n\n.paragraph {\n  color: black !important;\n  font-size: 1.2rem !important;\n  line-height: 1.6 !important;\n  margin-top: 0.5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
